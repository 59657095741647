import {
    environment,
    wasmQuery,
    getSymbol,
    latestBlock,
    blockPerDay,
    tokenInfo,
    getLcdClient,
} from "../api";

import {
    fixed,
    leftGreaterThanRight,
    leftLessThanRight,
    minus,
    plus,
} from "../../Math";

import { govStakerState } from "../governance/query";
import { MsgExecuteContract, TxInfo } from "@terra-money/terra.js";

export async function campaignState(
    campaignAddress: string
): Promise<ResponseCampaignState> {
    return await wasmQuery(campaignAddress, {
        campaign_state: {},
    });
}

export async function campaignDeposit(
    campaignAddress: string,
    address: string
): Promise<ResponseCampaignDeposit> {
    return wasmQuery(campaignAddress, {
        deposit: {
            address: address,
        },
    });
}

export async function refineCampaignDeposit(
    item: ResponseCampaignDeposit
): Promise<CampaignDeposit> {
    const block = (await latestBlock()).height;
    const locked = item.locked_amounts.map((a) => {
        return {
            amount: a[0],
            block: a[1],
        };
    });

    let totalLocked = "0";
    let lockedList: { amount: string; block: number }[] = [];
    locked.forEach((item) => {
        if (item.block > block) {
            totalLocked = plus(totalLocked, item.amount);
            lockedList.push(item);
        }
    });

    let claimable = minus(item.deposit_amount, totalLocked);

    return {
        total_deposited: item.deposit_amount,
        locked: lockedList,
        claimable: claimable,
    };
}

async function refineCampaignConfig(
    item: ResponseCampaignConfig
): Promise<CampaignConfig> {
    const denom = item.deposit_denom.token
        ? item.deposit_denom.token
        : item.deposit_denom.native;
    const symbol = await getSymbol(denom!);

    // const latest = await latestBlock();
    // const tpb = (await timePerBlock(latest)) / 1000;

    return {
        governance: item.governance,
        campaign_manager: item.campaign_manager,
        title: item.title,
        description: item.description,
        url: item.url,
        qualification_description: item.qualification_description,
        parameter_key: item.parameter_key,
        qualifier: item.qualifier,

        creator: item.creator,
        admin: item.admin,
        created_at: item.created_at,

        deposit: {
            amount: item.deposit_amount,
            denom: denom!,
            symbol: symbol,
        },

        deposit_lock_period: item.deposit_lock_period,
        deposit_lock_period_day: parseInt(
            fixed(item.deposit_lock_period / blockPerDay, 0)
        ),

        vp_burn_amount: item.vp_burn_amount ?? "0",
    };
}

export async function campaignReward(
    contract: string
): Promise<ResponseCampaignReward> {
    return await wasmQuery(contract, {
        reward_config: {},
    });
}

async function refineCampaignReward(
    item: ResponseCampaignReward
): Promise<CampaignReward> {
    const denom = item.participation_reward_denom.native
        ? item.participation_reward_denom.native
        : item.participation_reward_denom.token;
    const symbol = await getSymbol(denom!);

    const token = await tokenInfo(denom!);

    if (item.participation_reward_distribution_schedule) {
        //일반 캠페인이므로 schedule의 0번만 사용한다.
        let s = item.participation_reward_distribution_schedule[0];
        let period = s[0];
        let reward = s[2];

        return {
            participation: {
                name: token.name,
                symbol: symbol,
                denom: denom!,
                amount: reward,
                lock_period: period,
                lock_day: parseInt(fixed(period / blockPerDay, 0)),
                decimals: token.decimals,
            },
            referral: {
                symbol: await getSymbol(item.referral_reward_token),
                denom: item.referral_reward_token,
                amounts: item.referral_reward_amounts,
                lock_period: item.referral_reward_lock_period ?? 0,
                lock_day: parseInt(
                    fixed(
                        (item.referral_reward_lock_period ?? 0) / blockPerDay,
                        0
                    )
                ),
            },
        };
    } else {
        return {
            participation: {
                name: token.name,
                symbol: symbol,
                denom: denom!,
                amount: item.participation_reward_amount,
                lock_period: item.participation_reward_lock_period ?? 0,
                lock_day: parseInt(
                    fixed(
                        (item.participation_reward_lock_period ?? 0) /
                            blockPerDay,
                        0
                    )
                ),
                decimals: token.decimals,
            },
            referral: {
                symbol: await getSymbol(item.referral_reward_token),
                denom: item.referral_reward_token,
                amounts: item.referral_reward_amounts,
                lock_period: item.referral_reward_lock_period ?? 0,
                lock_day: parseInt(
                    fixed(
                        (item.referral_reward_lock_period ?? 0) / blockPerDay,
                        0
                    )
                ),
            },
        };
    }
}

async function refineCampaignState(
    item: ResponseCampaignState
): Promise<CampaignState> {
    let locked: { denom: string; symbol: string; amount: string }[] = [];
    for (let i = 0; i < item.locked_balances.length; i++) {
        const lockedBalance: any[] = item.locked_balances[i];
        const denom = lockedBalance[0].token
            ? lockedBalance[0].token
            : lockedBalance[0].native;
        const amount = lockedBalance[1];

        locked.push({
            denom: denom,
            symbol: await getSymbol(denom),
            amount: amount,
        });
    }

    let balances: { denom: string; symbol: string; amount: string }[] = [];
    let pool: { denom: string; symbol: string; amount: string }[] = [];
    for (let i = 0; i < item.balances.length; i++) {
        const balance: any[] = item.balances[i];
        const denom = balance[0].token ? balance[0].token : balance[0].native;
        const symbol = await getSymbol(denom);

        const amount = balance[1];
        balances.push({
            denom: denom,
            symbol: symbol,
            amount: amount,
        });

        let poolAmount = amount;

        const lockFiltered = locked.filter((a) => {
            return a.denom === denom;
        });
        if (lockFiltered.length > 0) {
            poolAmount = minus(poolAmount, lockFiltered[0].amount);
        }

        pool.push({
            denom: denom,
            symbol: symbol,
            amount: poolAmount,
        });
    }

    return {
        participation_count: item.participation_count,
        cumulative_participation_reward_amount:
            item.cumulative_participation_reward_amount,
        cumulative_referral_reward_amount:
            item.cumulative_referral_reward_amount,
        locked_balances: locked,
        balances: balances,
        pool_balances: pool,
        is_active: item.is_active,
        is_pending: item.is_pending,
    };
}

export async function campaignSummary(
    contract: string,
    address: string | undefined
) {
    const config: ResponseCampaignConfig = await wasmQuery(contract, {
        campaign_config: {},
    });
    const state: ResponseCampaignState = await campaignState(contract);
    const reward: ResponseCampaignReward = await wasmQuery(contract, {
        reward_config: {},
    });

    let deposit: ResponseCampaignDeposit | undefined;
    let participation: ResponseCampaignParticipation | undefined;
    let shareLink: ResponseCampaignShareLink | undefined;

    if (address) {
        deposit = await wasmQuery(contract, {
            deposit: {
                address: address,
            },
        });
        participation = await wasmQuery(contract, {
            actor: {
                address: address,
            },
        });
        shareLink = await wasmQuery(contract, {
            share_url: {
                address: address,
            },
        });
    }

    return await refineCampaignSummary({
        config: config,
        state: state,
        reward: reward,
        deposit: deposit,
        participation: participation,
        shareLink: shareLink,
    });
}

async function refineCampaignSummary(
    r: ResponseCampaignSummary
): Promise<CampaignSummary> {
    const config = await refineCampaignConfig(r.config);
    const reward = await refineCampaignReward(r.reward);
    const state = await refineCampaignState(r.state);
    let deposit: CampaignDeposit | undefined;
    if (r.deposit) {
        deposit = await refineCampaignDeposit(r.deposit);
    }

    let p: CampaignParticipation | undefined = undefined;

    //일반 캠페인이므로 p리워드의 0번째만 고려한다.
    if (r.participation) {
        const nowBlock = await latestBlock();
        const pList = r.participation.participation_reward_amounts ?? [];
        let pTotal = "0";
        const pRewardAmounts: {
            amount: string;
            block: number;
            start?: number;
            end?: number;
        }[] = pList.map((item: any) => {
            pTotal = plus(pTotal, item[2]);

            return {
                amount: item[2],
                block: item[0],
                start: item[0],
                end: item[1],
            };
        });

        const rList = r.participation.referral_reward_amounts ?? [];
        const rRewardAmounts: { amount: string; block: number }[] = rList.map(
            (item: any) => {
                return {
                    amount: item[0],
                    block: item[1],
                };
            }
        );

        let unlocked_referral_reward_amount = "0";
        rRewardAmounts.forEach((item) => {
            if (item.block <= nowBlock.height) {
                unlocked_referral_reward_amount = plus(
                    unlocked_referral_reward_amount,
                    item.amount
                );
            }
        });

        let claimed =
            r.participation.claimed_participation_reward_amount ?? "0";
        let unlocked =
            r.participation.unlocked_participation_reward_amount ?? "0";
        let locked = minus(minus(pTotal, claimed), unlocked);

        p = {
            address: r.participation.address,
            referer_address: r.participation.referer_address,
            last_participated_at: r.participation.last_participated_at,

            participation_reward: {
                cummulative:
                    r.participation.cumulative_participation_reward_amount,
                count: r.participation.participation_count,
                total: pTotal,
                locked: locked,
                unlocked:
                    r.participation.unlocked_participation_reward_amount ?? "0",
                locked_list: pRewardAmounts.filter((item) => {
                    return (
                        item.block > nowBlock.height &&
                        leftGreaterThanRight(item.amount, 0)
                    );
                }),
            },

            referral_reward: {
                cummulative: r.participation.cumulative_referral_reward_amount,
                count: r.participation.referral_count,
                total: r.participation.referral_reward_amount,
                locked: minus(
                    r.participation.referral_reward_amount,
                    unlocked_referral_reward_amount
                ),
                unlocked: unlocked_referral_reward_amount,
                locked_list: rRewardAmounts.filter((item) => {
                    return (
                        item.block > nowBlock.height &&
                        leftGreaterThanRight(item.amount, 0)
                    );
                }),
            },
        };
    }

    let shareLink = r.shareLink;
    if (shareLink) {
        shareLink.compressed = encodeURIComponent(shareLink.compressed);

        const parameterKey = config.parameter_key + "=";
        const splits = shareLink.url.split(parameterKey);

        let url = "";
        if (splits.length > 0) {
            url = splits[0];
        }

        let param = "";
        if (splits.length > 1) {
            param = encodeURIComponent(splits[1]);
        }

        const combine = url + parameterKey + param;

        shareLink.url = combine;
    }

    return {
        config: config,
        reward: reward,
        state: state,
        participation: p,
        deposit: deposit,
        shareLink: shareLink,
    };
}

export async function campaignManagerConfig(): Promise<ResponseCampaignManagerConfig> {
    return await wasmQuery(environment().contracts.campaignManager, {
        config: {},
    });
}

export async function campaignManagerRefRewardLimitOption(): Promise<ResponseCampaignManagerRefRewardLimitOption> {
    return await wasmQuery(environment().contracts.campaignManager, {
        referral_reward_limit_option: {},
    });
}

export async function campaignParticipation(
    campaignAddress: string,
    address: string
): Promise<ResponseCampaignParticipation | undefined> {
    return wasmQuery(campaignAddress, {
        actor: {
            address: address,
        },
    })
        .then((r) => {
            return r;
        })
        .catch((e) => {
            return undefined;
        });
}

async function calcRefRewardReceivable(
    campaignAddress: string,
    address: string
): Promise<ReferralRewardLimitAmount> {
    return await wasmQuery(campaignAddress, {
        referral_reward_limit_amount: {
            address: address,
        },
    });
}

export async function calcRefRewardReceivableInfo(
    campaignAddress: string,
    address: string
): Promise<ReceivableReward> {
    const rewardLimitOption = await campaignManagerRefRewardLimitOption();
    const govStaked = await govStakerState(address);
    const received = await campaignParticipation(campaignAddress, address);
    const referral_rewards = (await campaignReward(campaignAddress))
        .referral_reward_amounts;
    let referral_rewards_sum = "0";
    referral_rewards.forEach((item) => {
        referral_rewards_sum = plus(referral_rewards_sum, item);
    });

    const r = await calcRefRewardReceivable(campaignAddress, address);
    const receivable = plus(r.actor_limit_amount, r.base_limit_amount);

    return {
        base_count: rewardLimitOption.base_count,
        percent_for_governance_staking:
            rewardLimitOption.percent_for_governance_staking,
        govStaked: govStaked.balance,
        referral_reward_sum: referral_rewards_sum,
        received: received ? received.cumulative_referral_reward_amount : "0",
        receivable: receivable,
    };
}

export async function qualify(
    qualifierAddress: string,
    campaignAddress: string,
    address: string
): Promise<{
    continue_option: string;
    // reason:string
}> {
    return wasmQuery(qualifierAddress, {
        qualify: {
            campaign: campaignAddress,
            sender: address,
            actor: address,
        },
    });
}

export async function fanfuryRequirement(
    isEarlybird: boolean
): Promise<FanfuryRequirement> {
    return await wasmQuery(
        isEarlybird
            ? environment().contracts.fanfury_early.qualifier
            : environment().contracts.fanfury_public.qualifier,
        {
            config: {},
        }
    );
}

export async function talisRequirement(
    isEarlybird: boolean
): Promise<TalisRequirement> {
    return await wasmQuery(
        isEarlybird
            ? environment().contracts.talis_early.qualifier
            : environment().contracts.talis_public.qualifier,
        {
            config: {},
        }
    );
}

export async function tptRequirement(
    qualifier: string
): Promise<TPTRequirement> {
    return await wasmQuery(qualifier, {
        requirement: {},
    });
}

export async function sayveRequirement(
    qualifier: string
): Promise<SayveRequirement> {
    return await wasmQuery(qualifier, {
        requirement: {},
    });
}

export async function validateTpBuyInTx(tx: TxInfo, address: string) {
    if (tx.tx.body.messages.length === 0) {
        return false;
    }

    if (!(tx.tx.body.messages[0] instanceof MsgExecuteContract)) {
        return false;
    }

    const message = tx.tx.body.messages[0] as MsgExecuteContract;
    if (message.sender !== address) {
        return false;
    }

    //action check
    const kv = tx.logs![0].events[2].attributes[6];
    if (kv.key !== "action" && kv.value !== "buy_in") {
        return false;
    }

    //buy in amount check
    const msg = message.execute_msg;
    const contract = (msg as any).send.contract;
    const maxBuyIn = (await wasmQuery(contract, { config: {} })).option
        .max_buy_in;

    const sendAmount = tx.logs![0].events[2].attributes[4].value as string;

    if (leftLessThanRight(sendAmount, maxBuyIn)) {
        return false;
    }
    console.log("As " + tx.txhash + contract);
    //contract check
    let contractInfo = await getLcdClient().wasm.contractInfo(contract);
    if (
        contractInfo.creator !==
        environment().contracts.terrapoker_smartairdrop.table_manager
    ) {
        return false;
    }
    console.log("A");
    // console.log(message);
    // console.log(coins[0].amount + ", " + coins[0].denom);

    return true;
}
