import { useEffect, useState } from "react";
import TxBoxView from "../common/TxBoxView/TxBoxView";
import style from "./Mypage.module.scss";
import MyValue from "./MyValue";
import MyClaimable from "./MyClaimable";
import MyRewards from "./MyRewards";
import {
    claimCampaignRewards,
    claimOtherRewards,
} from "../../libs/api/mypage/execution";
import { useRecoilValue } from "recoil";
import { walletState } from "../../libs/contexts";
import { useConnectedWallet, useWallet } from "@terra-money/wallet-provider";
import PageHeader from "../common/PageHeader/PageHeader";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../App";

import MyDistributionRewards from "./MyDistributionRewards/MyDistributionRewards";
import { claimAirdropV2 } from "../../libs/api/airdrop_v2/execution";

export default function Mypage(props: any) {
    const history = useHistory();
    const connected = useConnectedWallet();
    const wallet = useRecoilValue(walletState);
    const { post } = useWallet();

    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    useEffect(() => {
        if (!connected) {
            history.replace(PATHS.home);
        }
    }, [connected]);

    function claimOtherRewardsPressed() {
        setHash({
            type: "MyPageClaimOtherRewards",
            isPending: true,
        });

        claimOtherRewards(wallet!.terraAddress)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "MyPageClaimOtherRewards",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                setHash({
                    type: "MyPageClaimOtherRewards",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function claimCampaignRewardsPressed() {
        setHash({
            type: "MyPageClaimCampaignRewards",
            isPending: true,
        });

        claimCampaignRewards(wallet!.terraAddress)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "MyPageClaimCampaignRewards",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                setHash({
                    type: "MyPageClaimCampaignRewards",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function airdropClaim() {
        setHash({
            type: "AirdropV2Claim",
            isPending: true,
        });

        claimAirdropV2(wallet?.terraAddress ? wallet.terraAddress : "")
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "AirdropV2Claim",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                setHash({
                    type: "AirdropV2Claim",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    return (
        <div className={style.container}>
            {hash !== undefined ? (
                <PageHeader title="Claim Rewards" docs="" />
            ) : (
                <div />
            )}

            <TxBoxView
                transparent={true}
                hash={hash}
                clearHash={() => {
                    setHash(undefined);
                }}
            >
                <div className={style.myvalue_container}>
                    <MyValue />
                    <div style={{ width: "40px" }} />
                    <MyClaimable claimPressed={claimOtherRewardsPressed} />
                </div>

                <MyDistributionRewards airdropClaim={airdropClaim} />

                <MyRewards claimPressed={claimCampaignRewardsPressed} />
            </TxBoxView>
        </div>
    );
}
