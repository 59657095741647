import { useEffect, useState } from "react";
import TitleLabel from "../common/Labels/TitleLabel";
import style from "./MyClaimable.module.scss";
import * as Utils from "../../libs/utils";
import { govState } from "../../libs/api/governance/query";
import { useRecoilValue } from "recoil";
import { walletState } from "../../libs/contexts";
import { stakeLpInfo } from "../../libs/api/astroport_liquidity/query";
import { campaignClaimableInfo } from "../../libs/api/rest";
import { isZero, multiply, plus } from "../../libs/Math";
import { queryPrice } from "../../libs/api/trade/query";
import { environment } from "../../libs/api/api";
import PageHeader from "../common/PageHeader/PageHeader";
import { ClaimButton } from "../common/NormalButton/NormalButton";

export default function MyClaimable(props: { claimPressed: () => void }) {
    const wallet = useRecoilValue(walletState);

    const [vkrPrice, setVkrPrice] = useState<string | undefined>(undefined);
    const [astroPrice, setAstroPrice] = useState<string | undefined>(undefined);

    const [lpReward, setLpReward] = useState({
        vkr: "0",
        astro: "0",
    });
    const [deposit, setDeposit] = useState({ locked: "0", reward: "0" });
    const [vpReward, setVpReward] = useState("0");

    const [refinedLpReward, setRefinedLpReward] = useState<
        | {
              vkr: AmountSplitModel;
              astro: AmountSplitModel;
          }
        | undefined
    >(undefined);

    const [refinedDeposit, setRefinedDeposit] = useState<
        | {
              locked: AmountSplitModel;
              reward: AmountSplitModel;
          }
        | undefined
    >(undefined);

    const [refinedVp, setRefinedVp] = useState<AmountSplitModel | undefined>(
        undefined
    );

    const [claimable, setClaimable] = useState(false);
    const [totalClaimableAmountSplit, setTotalClaimableAmountSplit] = useState<
        AmountSplitModel | undefined
    >(undefined);

    useEffect(() => {
        queryPrice(environment().contracts.token)
            .then((r) => {
                setVkrPrice(r);
            })
            .catch((e) => {});

        queryPrice(environment().contracts.astroport.token)
            .then((r) => {
                setAstroPrice(r);
            })
            .catch((e) => {});
    }, []);

    useEffect(() => {
        govState(wallet ? wallet.terraAddress : undefined)
            .then((r) => {
                setVpReward(r.pending);
                setRefinedVp(Utils.splitAmountByDot(r.pending));
            })
            .catch((e) => {});

        stakeLpInfo(wallet ? wallet.terraAddress : undefined)
            .then((r) => {
                setLpReward({
                    vkr: r.pending_on_proxy,
                    astro: r.pending,
                });

                setRefinedLpReward({
                    vkr: Utils.splitAmountByDot(r.pending_on_proxy),
                    astro: Utils.splitAmountByDot(r.pending),
                });
            })
            .catch((e) => {});

        campaignClaimableInfo(wallet ? wallet.terraAddress : undefined)
            .then((r) => {
                setDeposit({
                    locked: r.deposit.locked,
                    reward: r.deposit.claimable,
                });

                setRefinedDeposit({
                    locked: Utils.splitAmountByDot(r.deposit.locked),
                    reward: Utils.splitAmountByDot(r.deposit.claimable),
                });
            })
            .catch((e) => {});
    }, [wallet]);

    useEffect(() => {
        if (
            astroPrice &&
            vkrPrice &&
            refinedLpReward &&
            refinedDeposit &&
            refinedVp
        ) {
            let sum = "0";
            sum = plus(sum, multiply(deposit ? deposit.reward : "0", vkrPrice));
            sum = plus(sum, multiply(lpReward.vkr, vkrPrice));
            sum = plus(sum, multiply(lpReward.astro, astroPrice));

            setClaimable(!isZero(sum) || !isZero(vpReward));
            setTotalClaimableAmountSplit(Utils.splitAmountByDot(sum, 3));
        }
    }, [
        astroPrice,
        vkrPrice,
        lpReward,
        deposit,
        vpReward,
        refinedLpReward,
        refinedDeposit,
        refinedVp,
    ]);

    return (
        <div className={style.container}>
            <PageHeader title="Claimable Assets" docs="" />
            <div className={style.total_container_box}>
                <div className={style.total_container}>
                    <div className={style.text}>
                        <TitleLabel
                            text={"My Total Claimable"}
                            color={"#fff"}
                            tooltip={
                                "Total Value of all Claimable asets excluding rewards (Lp Staking Rewards + Locked & Unlocked Campaign Deposit)"
                            }
                            fontWeight={"light"}
                        />
                        <div style={{ height: 5 }} />
                        <TitleLabel
                            size={30}
                            text={
                                totalClaimableAmountSplit
                                    ? totalClaimableAmountSplit.num1
                                    : undefined
                            }
                            color={"white"}
                            symbol={
                                totalClaimableAmountSplit &&
                                totalClaimableAmountSplit.num2
                                    ? "." +
                                      totalClaimableAmountSplit.num2 +
                                      " axlUSDC"
                                    : "axlUSDC"
                            }
                            skeleton={{ width: 100 }}
                        />
                    </div>
                    <div className={style.button}>
                        <ClaimButton
                            title={"Claim All"}
                            disabled={
                                !claimable ||
                                totalClaimableAmountSplit === undefined
                            }
                            textHighlight={
                                !claimable &&
                                totalClaimableAmountSplit !== undefined
                            }
                            selected={false}
                            onClick={() => {
                                if (wallet) {
                                    props.claimPressed();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={style.container_box}>
                <div className={style.lp_container}>
                    <div className={style.text}>
                        <TitleLabel
                            text={"LP"}
                            color={"#fff"}
                            fontWeight={"light"}
                        />
                        <div style={{ height: 11 }} />

                        <TitleLabel
                            size={16}
                            fontWeight={"medium"}
                            text={
                                refinedLpReward
                                    ? refinedLpReward.vkr.num1
                                    : undefined
                            }
                            color={"#ffffff"}
                            symbol={
                                refinedLpReward && refinedLpReward.vkr.num2
                                    ? "." + refinedLpReward.vkr.num2 + " VKR"
                                    : "VKR"
                            }
                            skeleton={{ width: 100 }}
                        />

                        <div style={{ height: 12 }} />

                        <TitleLabel
                            size={16}
                            fontWeight={"medium"}
                            text={
                                refinedLpReward
                                    ? refinedLpReward.astro.num1
                                    : undefined
                            }
                            color={"#ffffff"}
                            symbol={
                                refinedLpReward && refinedLpReward.astro.num2
                                    ? "." +
                                      refinedLpReward.astro.num2 +
                                      " ASTRO"
                                    : "ASTRO"
                            }
                            skeleton={{ width: 100 }}
                        />
                    </div>
                </div>

                <div className={style.line} />

                <div className={style.deposit_container}>
                    <div className={style.text}>
                        <TitleLabel
                            text={"Campaign Deposit"}
                            color={"#fff"}
                            fontWeight={"light"}
                        />
                        <div style={{ height: 7 }} />
                        <div className={style.deposit}>
                            <TitleLabel
                                text={
                                    refinedDeposit
                                        ? refinedDeposit.locked.num1
                                        : undefined
                                }
                                size={12}
                                color={"#f93660"}
                                symbol={
                                    refinedDeposit && refinedDeposit.locked.num2
                                        ? "." +
                                          refinedDeposit.locked.num2 +
                                          " VKR Locked"
                                        : "VKR Locked"
                                }
                                fontWeight={"light"}
                                skeleton={{ width: 100 }}
                            />
                            <div className={style.icon} />
                        </div>
                        <div style={{ height: 8 }} />
                        <TitleLabel
                            text={
                                refinedDeposit
                                    ? refinedDeposit.reward.num1
                                    : undefined
                            }
                            size={16}
                            color={"#ffffff"}
                            symbol={
                                refinedDeposit && refinedDeposit.reward.num2
                                    ? "." + refinedDeposit.reward.num2 + " VKR"
                                    : "VKR"
                            }
                            skeleton={{ width: 100 }}
                        />
                    </div>
                </div>

                <div className={style.line} />

                <div className={style.vp_container}>
                    <div className={style.text}>
                        <TitleLabel
                            text={"VP"}
                            color={"#fff"}
                            fontWeight={"light"}
                            tooltip={
                                "VP is not worth of axlUSDC value. Valkyrian Pass is non-exchangeable, non-refundable and it cannot be transferred to another wallet"
                            }
                        />
                        <div style={{ height: 12 }} />

                        <TitleLabel
                            size={16}
                            fontWeight={"medium"}
                            text={refinedVp ? refinedVp.num1 : undefined}
                            color={"#ffffff"}
                            symbol={
                                refinedVp && refinedVp.num2
                                    ? "." + refinedVp.num2 + " VP"
                                    : "VP"
                            }
                            skeleton={{ width: 100 }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
