import { useEffect, useRef, useState } from "react";

import style from "./TxBoxView.module.scss";

import { TxInfo } from "@terra-money/terra.js";
import classNames from "classnames/bind";
import { useHistory } from "react-router-dom";
import {
    CreateTxFailed,
    Timeout,
    TxFailed,
    TxUnspecifiedError,
    UserDenied,
} from "@terra-money/wallet-provider";
import TxErrorView from "./TxErrorView";
import TxDoneView from "./TxDoneView";
import TxLoadingView from "./TxLoadingView";
import TxPendingView from "./TxPendingView";
import { PATHS } from "../../../App";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";

const cx = classNames.bind(style);

enum TxBoxViewStatus {
    Before,
    Pending,

    TransferError,
    KeyError,
    TitleError,
    DescriptionError,
    SpreadError,
    SplippageError,
    NetworkError,
    UserDenied,
    Loading,
    Done,

    Timeout,
    Error,
}

export default function TxBoxView(props: {
    children: any;
    hash?: HashResult;
    clearHash: () => void;
    transparent?: boolean;
}) {
    const history = useHistory();
    const wallet = useRecoilValue(walletState);
    const [step, setStep] = useState(TxBoxViewStatus.Before);
    const [txInfo, setTxInfo] = useState<TxInfo | undefined>(undefined);

    const errorMessage = useRef("");

    useEffect(() => {
        if (props.hash && props.hash.isPending) {
            setStep(TxBoxViewStatus.Pending);
            return;
        }

        if (props.hash && (props.hash.response || props.hash.error)) {
            if (!txInfo) {
                //before get txInfo

                if (!props.hash.error) {
                    setStep(TxBoxViewStatus.Loading);
                } else {
                    if (
                        // in bombay, localterra
                        props.hash.error instanceof UserDenied ||
                        // in phoenix
                        props.hash.error.name === "WebExtensionUserDenied"
                    ) {
                        props.clearHash();
                    } else if (
                        // in bombay, localterra
                        props.hash.error instanceof CreateTxFailed ||
                        props.hash.error instanceof TxFailed ||
                        props.hash.error instanceof Timeout ||
                        props.hash.error instanceof TxUnspecifiedError ||
                        // in phoenix
                        props.hash.error.name ===
                            "WebExtensionCreateTxFailed" ||
                        props.hash.error.name === "WebExtensionTxFailed" ||
                        props.hash.error.name === "WebExtensionTimeout" ||
                        props.hash.error.name ===
                            "WebExtensionTxUnspecifiedError"
                    ) {
                        errorMessage.current = props.hash.error.message;
                        setStep(TxBoxViewStatus.Error);
                    } else {
                        if (props.hash.error.response !== undefined) {
                            if (
                                props.hash.error.response.data.message ===
                                "failed to execute message; message index: 0: Operation exceeds max spread limit: execute wasm contract failed: invalid request"
                            ) {
                                setStep(TxBoxViewStatus.SpreadError);
                            } else if (
                                props.hash.error.response.data.message ===
                                "failed to execute message; message index: 0: dispatch: submessages: Generic error: Title too short: execute wasm contract failed: invalid request"
                            ) {
                                setStep(TxBoxViewStatus.TitleError);
                            } else if (
                                props.hash.error.response.data.message ===
                                "failed to execute message; message index: 0: dispatch: submessages: Generic error: Description too short: execute wasm contract failed: invalid request"
                            ) {
                                setStep(TxBoxViewStatus.DescriptionError);
                            } else if (
                                props.hash.error.response.data.message ===
                                "rpc error: code = NotFound desc = account " +
                                    wallet?.terraAddress +
                                    " not found: key not found"
                            ) {
                                setStep(TxBoxViewStatus.KeyError);
                            } else if (
                                props.hash.error.response.data.message ===
                                "failed to execute message; message index: 0: dispatch: submessages: Generic error: Can't use Transfer message: execute wasm contract failed: invalid request"
                            ) {
                                setStep(TxBoxViewStatus.TransferError);
                            } else if (
                                props.hash.error.response.data.message ===
                                "failed to execute message; message index: 1: Operation exceeds max splippage tolerance: execute wasm contract failed: invalid request"
                            ) {
                                setStep(TxBoxViewStatus.SplippageError);
                            } else {
                                setStep(TxBoxViewStatus.NetworkError);
                            }
                        } else {
                            setStep(TxBoxViewStatus.NetworkError);
                        }
                    }
                }
            } else {
                if (txInfo.code) {
                    errorMessage.current = txInfo.raw_log;
                    setStep(TxBoxViewStatus.Error);
                } else {
                    setStep(TxBoxViewStatus.Done);
                }
            }
        } else {
            setTxInfo(undefined);
            setStep(TxBoxViewStatus.Before);
        }
    }, [props, txInfo]);

    function confirmPressed(success: boolean) {
        if (props.hash && props.hash.callback) {
            props.hash.callback(success);
        }

        setTxInfo(undefined);
        setStep(TxBoxViewStatus.Before);
        props.clearHash();
    }

    return (
        <div
            className={cx(style.container, {
                transparent: !props.hash && props.transparent,
            })}
        >
            {step === TxBoxViewStatus.Pending ? (
                <TxPendingView />
            ) : step === TxBoxViewStatus.Loading ? (
                <TxLoadingView
                    result={props.hash!}
                    txFound={(info: TxInfo) => {
                        setTxInfo(info);
                    }}
                    timeout={() => {
                        setStep(TxBoxViewStatus.Timeout);
                    }}
                />
            ) : step === TxBoxViewStatus.Done ? (
                <TxDoneView
                    result={props.hash!}
                    txInfo={txInfo!}
                    pressed={() => {
                        if (props.hash!.redirect) {
                            history.push(props.hash!.redirect);
                        } else {
                            if (
                                props.hash?.type ===
                                "AirdropV2ParticipateVesting"
                            ) {
                                history.push(PATHS.mypage);
                            }

                            if (props.hash?.type === "AirdropV2Participate") {
                                history.push(PATHS.home);
                                window.location.reload();
                            }

                            confirmPressed(true);
                        }
                    }}
                />
            ) : step === TxBoxViewStatus.Timeout ? (
                <TxErrorView
                    message="timeout"
                    pressed={() => {
                        confirmPressed(false);
                    }}
                />
            ) : step === TxBoxViewStatus.UserDenied ? (
                <TxErrorView
                    message="User Denied."
                    pressed={() => {
                        confirmPressed(false);
                    }}
                />
            ) : step === TxBoxViewStatus.NetworkError ? (
                <TxErrorView
                    message="Check your network."
                    pressed={() => {
                        confirmPressed(false);
                    }}
                />
            ) : step === TxBoxViewStatus.Error ? (
                <TxErrorView
                    message={errorMessage.current}
                    pressed={() => {
                        confirmPressed(false);
                    }}
                />
            ) : step === TxBoxViewStatus.SpreadError ? (
                <TxErrorView
                    message="Operation exceeds max spread limit."
                    pressed={() => {
                        confirmPressed(false);
                    }}
                />
            ) : step === TxBoxViewStatus.TitleError ? (
                <TxErrorView
                    message="Title too short."
                    pressed={() => {
                        confirmPressed(false);
                    }}
                />
            ) : step === TxBoxViewStatus.DescriptionError ? (
                <TxErrorView
                    message="Description too short."
                    pressed={() => {
                        confirmPressed(false);
                    }}
                />
            ) : step === TxBoxViewStatus.KeyError ? (
                <TxErrorView
                    message={
                        "Account " +
                        wallet?.terraAddress +
                        " not found: key not found."
                    }
                    pressed={() => {
                        confirmPressed(false);
                    }}
                />
            ) : step === TxBoxViewStatus.TransferError ? (
                <TxErrorView
                    message={"Can't use Transfer message."}
                    pressed={() => {
                        confirmPressed(false);
                    }}
                />
            ) : step === TxBoxViewStatus.SplippageError ? (
                <TxErrorView
                    message={"Operation exceeds max splippage tolerance."}
                    pressed={() => {
                        confirmPressed(false);
                    }}
                />
            ) : (
                props.children
            )}
        </div>
    );
}
