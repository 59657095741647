import TitleLabel from "../../common/Labels/TitleLabel";
import style from "./MyDistributionExplainPopup.module.scss";

export default function MyDistributionExplainPopup(props: {
    show: number;
    setShow: (v: number) => void;
}) {
    return (
        <div className={style.container}>
            <div className={style.contents}>
                <div className={style.contents_title}>
                    <TitleLabel
                        size={18}
                        color={"white"}
                        fontWeight="medium"
                        text={"My Total Vesting"}
                    />
                </div>

                <div className={style.popdummy} />
                <div className={style.popupcontents}>
                    <div className={style.popup1}>
                        {
                            "The distribution scheme of the \nnewly launched tokens are as \nfollows:"
                        }
                    </div>
                    <div className={style.popup2}>
                        <span className={style.popup2_sub}>20%</span> of the
                        total distributed tokens will be given at genesis, on
                        launch date.
                    </div>
                    <div className={style.popup3}>
                        <span className={style.popup3_sub}>80%</span> is then
                        linearly vested on a block-by-block basis over 12 months
                        after a one month cliff. Approximately [1/365 * 0.8(your
                        entitled tokens)] per day.
                    </div>
                    <div className={style.popup4}>
                        {
                            "This means that you will receive the entirety of your entitled distributed tokens 13 months from launch date."
                        }
                    </div>
                </div>
                <div
                    className={style.checkbutton}
                    onClick={() => {
                        props.setShow(-1);
                    }}
                >
                    <TitleLabel
                        size={14}
                        color={"black"}
                        fontWeight="medium"
                        text={"Check"}
                    />
                </div>
            </div>
        </div>
    );
}
