import style from "./SayveInputField.module.scss";
import classNames from "classnames/bind";
import BigNumber from "bignumber.js";
import TitleLabel from "../../Labels/TitleLabel";
import { getDecimal, inputFieldValidate } from "../../../../libs/utils";
import { divide, leftGreaterThanRight } from "../../../../libs/Math";

const cx = classNames.bind(style);

export default function SayveInputField(props: {
    balance?: string;
    maxValue: string;
    symbol?: string;
    autofocus?: boolean;
    value: string;
    onChanged: (v: string) => void;
    error?: string;
    errorText?: string;
}) {
    return (
        <div className={style.container}>
            <div className={style.title_container}>
                <TitleLabel
                    text={""}
                    color={"#fff"}
                    size={14}
                    fontWeight={"medium"}
                />

                <div style={{ flex: 1 }} />

                <BalanceView
                    balanceTitle={""}
                    balance={props.balance}
                    symbol={props.symbol}
                    decimal={6}
                    disabled={false}
                    onChanged={(v: string) => {
                        let value = v;
                        if (
                            leftGreaterThanRight(
                                value,
                                divide(props.maxValue, 1000000)
                            )
                        ) {
                            value = divide(props.maxValue, 1000000);
                        }
                        props.onChanged(value);
                    }}
                />
            </div>

            <div
                className={cx(style.body_container, {
                    error: props.error,
                })}
            >
                <input
                    className={cx(
                        style.field,

                        {
                            error: props.error,
                        },
                        {
                            disabled: false,
                        },
                        {
                            combo: false,
                        },
                        {
                            showSymbolIcon: false,
                        }
                    )}
                    maxLength={40}
                    placeholder={"0.000"}
                    disabled={false}
                    value={props.value}
                    onChange={(e) => {
                        let value = e.target.value;

                        if (value) {
                            const validated = inputFieldValidate(value, 6);
                            if (validated) {
                                props.onChanged(validated);
                            }
                        } else {
                            //빈값일때
                            props.onChanged(value);
                        }
                    }}
                    autoFocus={
                        window.innerWidth < 1000
                            ? false
                            : props.autofocus ?? false
                    }
                    type={"tel"}
                    inputMode={"decimal"}
                />

                <SymbolView symbol={props.symbol} />
            </div>

            <ErrorView title={props.error} />
        </div>
    );
}

function BalanceView(props: {
    balance: string | undefined;
    balanceTitle?: string;
    decimal: number;
    symbol?: string;
    disabled?: boolean;
    onChanged: (balance: string) => void;
}) {
    if (!props.balance) {
        return <div />;
    }

    return (
        <div className={style.balance_container}>
            <div className={style.title}>
                <TitleLabel
                    text={props.balanceTitle ? props.balanceTitle : "Balance:"}
                    color="#fff"
                    size={14}
                    fontWeight={"light"}
                />
            </div>
            &nbsp;
            <div
                className={cx(style.value, {
                    disabled: props.disabled,
                })}
                onClick={() => {
                    if (!props.disabled) {
                        props.onChanged(
                            getDecimal(props.balance!, false, props.decimal)
                        );
                    }
                }}
            >
                <TitleLabel
                    text={new BigNumber(
                        getDecimal(props.balance, false, props.decimal)
                    ).toFormat()}
                    symbol={props.symbol}
                    underline={props.disabled ? false : true}
                    size={14}
                    fontWeight={"light"}
                />
            </div>
        </div>
    );
}

function SymbolView(props: { symbol: string | undefined }) {
    if (!props.symbol) {
        return <div />;
    }

    return <div className={style.symbol}>{props.symbol}</div>;
}

function ErrorView(props: { title: string | undefined }) {
    if (!props.title) {
        return <div style={{ height: "30px" }} />;
    }

    return (
        <div className={style.error_container}>
            <div className={style.error_text}>{props.title}</div>
            <div style={{ flex: 1 }} />
        </div>
    );
}
