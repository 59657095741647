import { useState } from "react";
import style from "./Campaigns.module.scss";
import * as Api from "../../libs/api/rest";
import MoreButton from "../common/MoreButton/MoreButton";
import { useEffect } from "react";
import { useRef } from "react";
import CampaignItem from "./CampaignItem/CampaignsItem";
import { useCallback } from "react";

import ComingSoon from "./Comingsoon/ComingSoon";
import { environment } from "../../libs/api/api";

import TPTCampaignItem from "./CampaignItem/TPTCampaignItem";
import PageHeader from "../common/PageHeader/PageHeader";
import Banner from "./Banner/Banner";
import SayveCampaignItem from "./CampaignItem/SayveCampaignItem";

export default function Campaigns(props: any) {
    const loading = useRef(false);
    const cursor1 = useRef<string | undefined>(undefined);
    const cursor2 = useRef<string | undefined>(undefined);
    const cursor3 = useRef<string | undefined>(undefined);

    const loaded1 = useRef(false);
    const loaded2 = useRef(false);
    const loaded3 = useRef(false);

    const [hasNext, setHasNext] = useState(true);
    const [campaignList, setCampaignList] = useState<
        CampaignListItem[] | undefined
    >([
        {
            campaignAddress: environment().contracts.sayve_early.campaign,
            participationCount: 0,
        },
        {
            campaignAddress: environment().contracts.sayve_public.campaign,
            participationCount: 0,
        },
    ]);

    const load = useCallback(() => {
        if (loading.current || !hasNext) {
            return;
        }

        loading.current = true;

        let old = (campaignList ?? []).slice();

        if (!loaded1.current) {
            Api.campaignAllListIpc(cursor1.current)
                .then((r) => {
                    if (loaded1.current === false) {
                        r.items.forEach((item) => {
                            old.push(item);
                        });
                    }

                    cursor1.current = r.nextCursor;

                    if (r.hasNext) {
                        setHasNext(r.hasNext);
                        setCampaignList(old);
                        loading.current = false;
                    } else {
                        loaded1.current = true;
                        Api.campaignAllList(cursor2.current)
                            .then((res) => {
                                if (loaded2.current === false) {
                                    res.items.forEach((item) => {
                                        old.push(item);
                                    });
                                }

                                cursor2.current = res.nextCursor;

                                if (res.hasNext) {
                                    setHasNext(res.hasNext);
                                    setCampaignList(old);
                                    loading.current = false;
                                } else {
                                    loaded2.current = true;
                                    Api.campaignAllListInactive(cursor3.current)
                                        .then((res2) => {
                                            if (loaded3.current === false) {
                                                res2.items.forEach((item) => {
                                                    old.push(item);
                                                });
                                            }

                                            cursor3.current = res2.nextCursor;
                                            setHasNext(res2.hasNext);
                                            setCampaignList(old);
                                            loading.current = false;

                                            if (res2.hasNext) {
                                                //todo 나중에 뭔가 더 생기면 위 구조 따를거.
                                            } else {
                                                loaded3.current = true;
                                            }
                                        })
                                        .catch((e) => {
                                            setHasNext(false);
                                            loading.current = false;
                                        });
                                }
                            })
                            .catch((e) => {
                                setHasNext(false);
                                loading.current = false;
                            });
                    }
                })
                .catch((e) => {
                    setHasNext(false);
                    loading.current = false;
                });
        } else {
            if (!loaded2.current) {
                Api.campaignAllList(cursor2.current)
                    .then((res) => {
                        if (loaded2.current === false) {
                            res.items.forEach((item) => {
                                old.push(item);
                            });
                        }

                        cursor2.current = res.nextCursor;

                        if (res.hasNext) {
                            setHasNext(res.hasNext);
                            setCampaignList(old);
                            loading.current = false;
                        } else {
                            loaded2.current = true;
                            Api.campaignAllListInactive(cursor3.current)
                                .then((res2) => {
                                    if (loaded3.current === false) {
                                        res2.items.forEach((item) => {
                                            old.push(item);
                                        });
                                    }

                                    cursor3.current = res2.nextCursor;
                                    setHasNext(res2.hasNext);
                                    setCampaignList(old);
                                    loading.current = false;

                                    if (res2.hasNext) {
                                        //todo 나중에 뭔가 더 생기면 위 구조 따를거.
                                    } else {
                                        loaded3.current = true;
                                    }
                                })
                                .catch((e) => {
                                    setHasNext(false);
                                    loading.current = false;
                                });
                        }
                    })
                    .catch((e) => {
                        setHasNext(false);
                        loading.current = false;
                    });
            } else {
                Api.campaignAllListInactive(cursor3.current)
                    .then((res2) => {
                        if (loaded3.current === false) {
                            res2.items.forEach((item) => {
                                old.push(item);
                            });
                        }

                        cursor3.current = res2.nextCursor;
                        setHasNext(res2.hasNext);
                        setCampaignList(old);
                        loading.current = false;

                        if (res2.hasNext) {
                            //todo 나중에 뭔가 더 생기면 위 구조 따를거.
                        } else {
                            loaded3.current = true;
                        }
                    })
                    .catch((e) => {
                        setHasNext(false);
                        loading.current = false;
                    });
            }
        }
    }, [hasNext, campaignList]);

    useEffect(() => {
        load();
    }, []);

    return (
        <div className={style.wrap}>
            <div className={style.container}>
                {/* <Banner /> */}

                {/* <PageHeader title="Coming Soon" docs="" /> */}

                {/* <div className={style.comingsoon}>
                    <ComingSoon />
                </div> */}

                <PageHeader title="Campaign List" docs="" />

                {campaignList !== undefined && campaignList.length === 0 ? (
                    <div className={style.item_container}>
                        <div className={style.empty}>
                            <div className={style.img} />
                            <div className={style.texts}>
                                {"A new campaign will start soon."}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={style.campaign_container}>
                        {(campaignList ?? []).map((item, index) => {
                            if (
                                item.campaignAddress ===
                                environment().contracts.sayve_early.campaign
                            ) {
                                return (
                                    <SayveCampaignItem
                                        key={index}
                                        info={item}
                                        isEarlybird={true}
                                    />
                                );
                            } else if (
                                item.campaignAddress ===
                                environment().contracts.sayve_public.campaign
                            ) {
                                return (
                                    <SayveCampaignItem
                                        key={index}
                                        info={item}
                                        isEarlybird={false}
                                    />
                                );
                            } else if (
                                item.campaignAddress ===
                                environment().contracts.terrapoker_exclusive
                                    .campaign
                            ) {
                                return (
                                    <TPTCampaignItem
                                        key={index}
                                        info={item}
                                        isEarlybird={true}
                                    />
                                );
                            } else if (
                                item.campaignAddress ===
                                environment().contracts.terrapoker_nonmember
                                    .campaign
                            ) {
                                return (
                                    <TPTCampaignItem
                                        key={index}
                                        info={item}
                                        isEarlybird={false}
                                    />
                                );
                            } else {
                                return <CampaignItem key={index} info={item} />;
                            }
                        })}
                    </div>
                )}
                <MoreButton
                    isMore={hasNext && (campaignList ?? []).length > 0}
                    onClick={() => {
                        load();
                    }}
                />
                {hasNext && (campaignList ?? []).length > 0 ? (
                    <div style={{ height: "40px" }} />
                ) : (
                    <div />
                )}
            </div>
        </div>
    );
}
