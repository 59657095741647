import style from "./CampaignParticipationAuto.module.scss";
import classNames from "classnames/bind";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import { useEffect, useRef, useState } from "react";
import InputFieldTxPaste from "../../../../common/InputField/InputFieldTxPaste";
import ConfirmButton from "../../../../common/ConfirmButton/ConfirmButton";
import { qualificationTxInfo } from "../../../../../libs/api/campaign/qualification/rest";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../../libs/contexts";
import { getTxs } from "../../../../../libs/api/api";
import { validateTpBuyInTx } from "../../../../../libs/api/campaign/query";
import {
    NormalButton,
    TradeFilterButton,
} from "../../../../common/NormalButton/NormalButton";
import { showUrl } from "../../../../../libs/utils";
import TxSearchPopup from "./TxSearchPopup";
const cx = classNames.bind(style);

export default function CampaignParticipationAuto(props: {
    campaignAddress: string;
    summary: CampaignSummary;
    txPassed: boolean;
    setTxPassed: (v: boolean) => void;
    txHash: string;
    setTxHash: (t: string) => void;
    hashResult: TxQualificationInfo | undefined;
    setHashResult: (v: TxQualificationInfo | undefined) => void;
}) {
    const retryCountDownMax = 3; // will retry 3seconds * 20times
    const wallet = useRecoilValue(walletState);

    const retryCountDown = useRef(0);

    const [showTxSearchPopup, setShowTxSearchPopup] = useState(-1);

    useEffect(() => {
        const body: any = document.querySelector("body");
        body.style.overflow = showTxSearchPopup !== -1 ? "hidden" : "auto";
    }, [showTxSearchPopup]);

    useEffect(() => {
        if (!wallet) {
            props.setHashResult(undefined);
        }
    }, [wallet]);

    useEffect(() => {
        if (props.hashResult) {
            if (
                props.hashResult.campaign === props.campaignAddress &&
                props.hashResult.actor ===
                    (wallet ? wallet.terraAddress : "") &&
                props.hashResult.status === "SUCCESS"
            ) {
                props.setTxPassed(true);
            } else if (props.hashResult.reason) {
                props.setTxPassed(false);
            } else {
                props.setTxPassed(false);
            }
        } else {
            props.setTxHash("");
            props.setTxPassed(false);
        }
    }, [props.hashResult, wallet, props.campaignAddress]);

    function startCheck() {
        retryCountDown.current -= 1;

        qualificationTxInfo(
            wallet!.terraAddress,
            props.txHash,
            props.campaignAddress
        )
            .then((r) => {
                if (r.status === "SUCCESS") {
                    retryCountDown.current = 0;
                    props.setHashResult(r);
                } else {
                    if (retryCountDown.current > 0) {
                        setTimeout(() => {
                            startCheck();
                        }, 3000);
                    } else {
                        retryCountDown.current = 0;
                        props.setHashResult(undefined);
                    }
                }
            })
            .catch((e) => {
                if (retryCountDown.current > 0) {
                    setTimeout(() => {
                        startCheck();
                    }, 3000);
                }
            });
    }

    useEffect(() => {
        if (props.txHash !== "") {
            console.log(props.txHash);
            retryCountDown.current = retryCountDownMax;
            startCheck();
        }
    }, [props.txHash]);

    return (
        <div className={style.container}>
            {!props.hashResult ? (
                <FindTxView
                    setTxHash={props.setTxHash}
                    summary={props.summary}
                />
            ) : (
                <div />
            )}

            {props.hashResult ? (
                <div className={style.box}>
                    <div className={style.titlebox}>
                        <TitleLabel
                            text={"Input TX"}
                            fontWeight="light"
                            size={16}
                        />
                        <div className={style.button}>
                            <TradeFilterButton
                                title="Choose another TX"
                                selected={false}
                                onClick={() => {
                                    setShowTxSearchPopup(0);
                                }}
                            />
                        </div>
                    </div>

                    <div style={{ height: "6px" }} />

                    <InputFieldTxPaste
                        hideTitle={true}
                        value={props.txHash}
                        passed={props.txPassed}
                        setPassed={(v) => {
                            props.setHashResult(undefined);
                        }}
                        disabled={false}
                        onChanged={props.setTxHash}
                    />

                    <div className={style.signature_box}>
                        <div className={style.box1}>
                            <div
                                className={cx(style.icon, {
                                    referrer:
                                        props.hashResult.referrer !== undefined,
                                })}
                            />
                            <div className={style.text}>
                                {props.hashResult.referrer
                                    ? "Connected through referral link"
                                    : "Connected without referral link"}
                            </div>
                        </div>
                        <div className={style.box2} />
                        <div className={style.box3}>
                            <div className={style.text1}>{"Signature"}</div>
                            <div className={style.text2}>
                                {props.hashResult.encodedSignature}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div />
            )}

            {showTxSearchPopup !== -1 ? (
                <TxSearchPopup
                    campaignAddress={props.campaignAddress}
                    setTxHash={(tx) => {
                        props.setTxHash(tx);
                        setShowTxSearchPopup(-1);
                    }}
                    setShow={setShowTxSearchPopup}
                />
            ) : (
                <div />
            )}
        </div>
    );
}

function FindTxView(props: {
    summary: CampaignSummary;
    setTxHash: (tx: string) => void;
}) {
    enum Step {
        firstLoading,
        moreLoading,
        notFound,
    }

    const wallet = useRecoilValue(walletState);

    const [txLoading, setLoading] = useState(false);
    const txLoadingDone = useRef(false);
    const txOffset = useRef(0);

    const [moreLoading, setMoreLoading] = useState(Step.firstLoading);

    async function findHistory(): Promise<string> {
        if (txLoading || !wallet || txLoadingDone.current) {
            return "";
        }

        //tp launching 9/26 00:00:00 : 1664193600

        const limit = 1664193600000;

        const result = await getTxs(
            wallet.terraAddress,
            txOffset.current,
            limit
        );

        if (result.offset) {
            txOffset.current = result.offset;
        } else {
            txLoadingDone.current = true;
        }

        for (let i = 0; i < result.txs.length; i++) {
            const valid = await validateTpBuyInTx(
                result.txs[i],
                wallet.terraAddress
            ).catch((e) => {
                return false;
            });

            if (valid) {
                return result.txs[i].txhash;
            }
        }

        return "";
    }

    useEffect(() => {
        if (!txLoading) {
            console.log(txOffset.current + ", " + txLoadingDone.current);
            if (txOffset.current === 0 && !txLoadingDone.current) {
                setMoreLoading(Step.firstLoading);
            } else if (txLoadingDone.current) {
                setMoreLoading(Step.notFound);
            } else {
                setMoreLoading(Step.moreLoading);
            }
        }
    }, [txLoading]);

    function reload() {
        setLoading(true);
        findHistory()
            .then((r) => {
                setTimeout(() => {
                    if (r !== "") {
                        props.setTxHash(r);
                        txLoadingDone.current = true;
                    }

                    setLoading(false);
                }, 2000);
            })
            .catch((e) => {
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            });
    }

    if (moreLoading === Step.moreLoading) {
        return (
            <div className={style.findtx}>
                <div className={style.title}>{"No TX Found"}</div>
                <div className={style.subtitle}>
                    {
                        "Searches are conducted in batches of 100 txns. Try again to search the next batch."
                    }
                </div>

                {txLoading ? (
                    <div className={style.tx_loading} />
                ) : (
                    <div className={style.button}>
                        <NormalButton title="Try Again" onClick={reload} />
                    </div>
                )}
            </div>
        );
    } else if (moreLoading === Step.notFound) {
        return (
            <div className={style.findtx}>
                <div className={style.title}>{"No Relevant TX Found"}</div>
                <div className={style.subtitle}>
                    {
                        "It looks like you have not participated in the campaign yet. Please go to the Terra Poker Webapp to participate and try again"
                    }
                </div>

                <div className={style.button}>
                    <NormalButton
                        title="Go to Site"
                        onClick={() => {
                            showUrl(props.summary.config.url);
                        }}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div className={style.findtx}>
                <div className={style.title}>{"Find Participation TX"}</div>
                <div className={style.subtitle}>
                    {
                        "Press the below button to find a relevant participation TX from your wallet history"
                    }
                </div>

                {txLoading ? (
                    <div className={style.tx_loading} />
                ) : (
                    <ConfirmButton
                        title="Find TX"
                        onClick={reload}
                        enable={true}
                    />
                )}
            </div>
        );
    }
}
