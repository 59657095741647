import { showUrl } from "../../../../../libs/utils";
import style from "./CampaignParticipation.module.scss";
import classNames from "classnames/bind";
import { environment } from "../../../../../libs/api/api";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../../libs/contexts";
import { isZero } from "../../../../../libs/Math";
import { CreateTxOptions } from "@terra-money/terra.js";
import { participateTxCampaign } from "../../../../../libs/api/campaign/qualification/execution";
import CampaignParticipationFooter from "./CampaignParticipationFooter";
import CampaignParticipationAuto from "./CampaignParticipationAuto";
import CampaignParticipationManual from "./CampaignParticipationManual";

const cx = classNames.bind(style);

export default function CampaignParticipation(props: {
    campaignAddress: string;
    summary: CampaignSummary;
    participatePressed?: (t: CreateTxOptions) => void;
}) {
    const wallet = useRecoilValue(walletState);

    const state = props.summary.state;
    const config = props.summary.config;

    const participation = props.summary.participation;

    const isActive = state.is_active;
    const url = config.url;

    const [auto, setAuto] = useState(true);
    const [txPassed, setTxPassed] = useState(false);
    const [txHash, setTxHash] = useState("");

    const [hashResult, setHashResult] = useState<
        TxQualificationInfo | undefined
    >(undefined);

    useEffect(() => {
        setTxPassed(false);
        setTxHash("");
        setHashResult(undefined);
    }, [auto]);

    if (
        config.qualifier === environment().contracts.tx_qualifier &&
        isZero(participation ? participation.last_participated_at : "0") &&
        wallet !== undefined
    ) {
        return (
            <div className={style.container}>
                <div className={style.dummy} />
                <div className={style.title_container}>
                    <div className={style.title}>{"Participation"}</div>
                    <div
                        className={style.change}
                        onClick={() => {
                            setAuto(!auto);
                        }}
                    >
                        {auto ? "Manual" : "Auto"}
                    </div>
                </div>

                <div className={style.box}>
                    <div className={style.gotosite_container}>
                        <div style={{ flex: 1 }} />
                        <a
                            className={style.gotosite}
                            href={config.url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className={style.text}>{"Go to Site"}</div>
                            <div className={style.arrowicon} />
                        </a>
                    </div>

                    {auto ? (
                        <CampaignParticipationAuto
                            campaignAddress={props.campaignAddress}
                            summary={props.summary}
                            txPassed={txPassed}
                            setTxPassed={setTxPassed}
                            txHash={txHash}
                            setTxHash={setTxHash}
                            hashResult={hashResult}
                            setHashResult={setHashResult}
                        />
                    ) : (
                        <CampaignParticipationManual
                            campaignAddress={props.campaignAddress}
                            summary={props.summary}
                            setTxPassed={setTxPassed}
                            txHash={txHash}
                            setTxHash={setTxHash}
                            setHashResult={setHashResult}
                        />
                    )}

                    {txPassed || !auto ? (
                        <CampaignParticipationFooter
                            campaignAddress={props.campaignAddress}
                            summary={props.summary}
                            txPassed={txPassed}
                            participatePressed={() => {
                                if (hashResult) {
                                    participateTxCampaign(
                                        txHash,
                                        wallet!.terraAddress,
                                        hashResult!.referrer,
                                        props.campaignAddress,
                                        hashResult!.encodedSignature,
                                        hashResult.ruleId
                                    )
                                        .then((r) => {
                                            props.participatePressed!(r);
                                        })
                                        .catch((e) => {});
                                }
                            }}
                        />
                    ) : (
                        <div />
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div className={style.container}>
                <div className={style.dummy3} />

                <div className={style.confirmbutton}>
                    <div
                        className={cx(style.button, { disabled: !isActive })}
                        onClick={() => {
                            if (isActive) {
                                showUrl(url);
                            }
                        }}
                    >
                        <div className={style.button_text}>{"Go to Site"}</div>
                        <div className={style.button_ico} />
                    </div>
                </div>
            </div>
        );
    }
}
