import style from "./NormalButton.module.scss";
import { useState } from "react";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";

const cx = classNames.bind(style);

interface Color {
    color: string;
    opacity: number;
}

interface ColorSet {
    disabled?: Color;
    normal: Color;
    highlighted: Color;
}

export function NormalButton(props: {
    title: string;
    titleSize?: "small" | "medium" | "large";
    icon?: { normal: any; highlighted: any };
    disabled?: boolean;
    onClick: () => void;
}) {
    const bgColor: ColorSet = {
        disabled: {
            color: "rgba(255,255,255,0.1)",
            opacity: 1,
        },
        normal: {
            color: "transparent",
            opacity: 1,
        },
        highlighted: {
            color: "transparent",
            opacity: 1,
        },
    };

    const borderColor: ColorSet = {
        disabled: {
            color: "rgba(255,255,255,0.1)",
            opacity: 1,
        },
        normal: {
            color: "rgba(255,255,255,0.3)",
            opacity: 1,
        },
        highlighted: {
            color: "#ffe646",
            opacity: 1,
        },
    };

    const textColor: ColorSet = {
        disabled: {
            color: "white",
            opacity: 0.3,
        },
        normal: {
            color: "#ffe646",
            opacity: 1,
        },
        highlighted: {
            color: "#ffe646",
            opacity: 1,
        },
    };

    return (
        <RoundedButton
            borderColor={borderColor}
            bgColor={bgColor}
            textColor={textColor}
            icon={props.icon}
            title={props.title}
            titleSize={props.titleSize}
            disabled={props.disabled ?? false}
            onClick={() => {
                if (!props.disabled) {
                    props.onClick();
                }
            }}
        />
    );
}

export function NormalButton2(props: {
    title: string;
    titleSize?: "small" | "medium" | "large";
    icon?: { normal: any; highlighted: any };
    disabled?: boolean;
    onClick: () => void;
}) {
    const bgColor: ColorSet = {
        disabled: {
            color: "rgba(255,255,255,0.1)",
            opacity: 1,
        },
        normal: {
            color: "transparent",
            opacity: 1,
        },
        highlighted: {
            color: "#ffe646",
            opacity: 1,
        },
    };

    const borderColor: ColorSet = {
        disabled: {
            color: "rgba(255,255,255,0.1)",
            opacity: 1,
        },
        normal: {
            color: "rgba(255,255,255,0.3)",
            opacity: 1,
        },
        highlighted: {
            color: "#ffe646",
            opacity: 1,
        },
    };

    const textColor: ColorSet = {
        disabled: {
            color: "white",
            opacity: 0.3,
        },
        normal: {
            color: "#ffe646",
            opacity: 1,
        },
        highlighted: {
            color: "#2d2d2d",
            opacity: 1,
        },
    };

    return (
        <RoundedButton
            borderColor={borderColor}
            bgColor={bgColor}
            textColor={textColor}
            icon={props.icon}
            title={props.title}
            titleSize={props.titleSize}
            disabled={props.disabled ?? false}
            onClick={() => {
                if (!props.disabled) {
                    props.onClick();
                }
            }}
        />
    );
}

export function YellowButton(props: {
    title: string;
    disabled?: boolean;
    loading?: boolean;
    onClick: () => void;
}) {
    const bgColor: ColorSet = {
        disabled: {
            color: "white",
            opacity: 0.1,
        },
        normal: {
            color: "#ffe646",
            opacity: 1,
        },
        highlighted: {
            color: "#ffe646",
            opacity: 0.6,
        },
    };

    const borderColor: ColorSet = {
        disabled: {
            color: "rgba(255,255,255,0.1)",
            opacity: 0.1,
        },
        normal: {
            color: "#ffe646",
            opacity: 1,
        },
        highlighted: {
            color: "#ffe646",
            opacity: 0.6,
        },
    };

    const textColor: ColorSet = {
        disabled: {
            color: "white",
            opacity: 0.3,
        },
        normal: {
            color: "#2d2d2d",
            opacity: 1,
        },
        highlighted: {
            color: "#fff",
            opacity: 1,
        },
    };

    return (
        <RoundedButton
            borderColor={borderColor}
            bgColor={bgColor}
            textColor={textColor}
            loading={props.loading}
            title={props.title}
            disabled={props.disabled ?? false}
            onClick={props.onClick}
        />
    );
}

export function BuyButton(props: {
    title: string;
    disabled?: boolean;
    onClick: () => void;
}) {
    const bgColor: ColorSet = {
        disabled: {
            color: "#313134",
            opacity: 1,
        },
        normal: {
            color: "#435bd1",
            opacity: 1,
        },
        highlighted: {
            color: "#435bd1",
            opacity: 0.6,
        },
    };

    const borderColor: ColorSet = {
        disabled: {
            color: "#313134",
            opacity: 1,
        },
        normal: {
            color: "#435bd1",
            opacity: 1,
        },
        highlighted: {
            color: "#435bd1",
            opacity: 0.6,
        },
    };

    const textColor: ColorSet = {
        disabled: {
            color: "white",
            opacity: 0.3,
        },
        normal: {
            color: "white",
            opacity: 1,
        },
        highlighted: {
            color: "white",
            opacity: 1,
        },
    };

    return (
        <RoundedButton
            borderColor={borderColor}
            bgColor={bgColor}
            textColor={textColor}
            title={props.title}
            disabled={props.disabled ?? false}
            onClick={props.onClick}
        />
    );
}

export function SellButton(props: {
    title: string;
    disabled?: boolean;
    onClick: () => void;
}) {
    const bgColor: ColorSet = {
        disabled: {
            color: "#313134",
            opacity: 1,
        },
        normal: {
            color: "#a02d46",
            opacity: 1,
        },
        highlighted: {
            color: "#a02d46",
            opacity: 0.6,
        },
    };

    const borderColor: ColorSet = {
        disabled: {
            color: "#313134",
            opacity: 1,
        },
        normal: {
            color: "#a02d46",
            opacity: 1,
        },
        highlighted: {
            color: "#a02d46",
            opacity: 0.6,
        },
    };

    const textColor: ColorSet = {
        disabled: {
            color: "white",
            opacity: 0.3,
        },
        normal: {
            color: "white",
            opacity: 1,
        },
        highlighted: {
            color: "white",
            opacity: 1,
        },
    };

    return (
        <RoundedButton
            borderColor={borderColor}
            bgColor={bgColor}
            textColor={textColor}
            title={props.title}
            disabled={props.disabled ?? false}
            onClick={props.onClick}
        />
    );
}

export function InactiveButton(props: { title: string; onClick: () => void }) {
    const bgColor: ColorSet = {
        normal: {
            color: "transparent",
            opacity: 1,
        },
        highlighted: {
            color: "#d43232",
            opacity: 1,
        },
    };

    const borderColor: ColorSet = {
        normal: {
            color: "#2e2b29",
            opacity: 1,
        },
        highlighted: {
            color: "#d43232",
            opacity: 1,
        },
    };

    const textColor: ColorSet = {
        normal: {
            color: "#a02d46",
            opacity: 1,
        },
        highlighted: {
            color: "white",
            opacity: 1,
        },
    };

    return (
        <RoundedButton
            borderColor={borderColor}
            bgColor={bgColor}
            textColor={textColor}
            title={props.title}
            disabled={false}
            onClick={props.onClick}
        />
    );
}

export function TradeFilterButton(props: {
    title: string;
    selected: boolean;
    onClick: () => void;
}) {
    const bgColor: ColorSet = {
        normal: {
            color: props.selected ? "#ffe646" : "transparent",
            opacity: props.selected ? 0.2 : 1,
        },
        highlighted: {
            color: props.selected ? "#ffe646" : "#ffe646",
            opacity: props.selected ? 0.2 : 1,
        },
    };

    const borderColor: ColorSet = {
        normal: {
            color: props.selected ? "#ffe646" : "rgba(255,255,255,0.3)",
            opacity: 1,
        },
        highlighted: {
            color: "#ffe646",
            opacity: 1,
        },
    };

    const textColor: ColorSet = {
        normal: {
            color: props.selected ? "#ffe646" : "white",
            opacity: 1,
        },
        highlighted: {
            color: props.selected ? "#ffe646" : "#000000",
            opacity: 1,
        },
    };

    return (
        <RoundedButton
            borderColor={borderColor}
            bgColor={bgColor}
            textColor={textColor}
            title={props.title}
            titleSize={"small"}
            disabled={false}
            onClick={() => {
                props.onClick();
            }}
        />
    );
}

export function StakeButton(props: {
    title: string;
    isStaked: boolean;
    onClick: () => void;
}) {
    const bgColor: ColorSet = {
        disabled: {
            color: "rgba(255,255,255,0.1)",
            opacity: 1,
        },
        normal: {
            color: "transparent",
            opacity: 1,
        },
        highlighted: {
            color: "#ffe646",
            opacity: 1,
        },
    };

    const borderColor: ColorSet = {
        disabled: {
            color: "rgba(255,255,255,0.1)",
            opacity: 1,
        },
        normal: {
            color: "rgba(255,255,255,0.3)",
            opacity: 1,
        },
        highlighted: {
            color: "#ffe646",
            opacity: 1,
        },
    };

    const textColor: ColorSet = {
        disabled: {
            color: props.isStaked ? "white" : "white",
            opacity: props.isStaked ? 0.3 : 0.3,
        },
        normal: {
            color: props.isStaked ? "white" : "#ffe646",
            opacity: props.isStaked ? 1 : 1,
        },
        highlighted: {
            color: "#2d2d2d",
            opacity: 1,
        },
    };

    return (
        <RoundedButton
            borderColor={borderColor}
            bgColor={bgColor}
            textColor={textColor}
            title={props.title}
            disabled={false}
            titleSize={"large"}
            onClick={() => {
                props.onClick();
            }}
        />
    );
}

export function LinkButton(props: { link: string }) {
    return (
        <div className={style.link_button_container}>
            <Link className={style.link_button} to={props.link}>
                <div className={style.icon} />
            </Link>
        </div>
    );
}

export function ClaimButton(props: {
    title: string;
    disabled?: boolean;
    selected?: boolean;
    textHighlight?: boolean;
    onClick: () => void;
}) {
    const bgColor: ColorSet = {
        disabled: {
            color: props.selected ? "#ffe646" : "rgba(255,255,255,0.1)",
            opacity: props.selected ? 0.2 : 1,
        },
        normal: {
            color: props.selected ? "#ffe646" : "transparent",
            opacity: props.selected ? 0.2 : 1,
        },
        highlighted: {
            color: props.selected ? "#ffe646" : "#ffe646",
            opacity: props.selected ? 0.2 : 1,
        },
    };

    const borderColor: ColorSet = {
        disabled: {
            color: props.selected ? "#ffe646" : "rgba(255,255,255,0.1)",
            opacity: 1,
        },
        normal: {
            color: props.selected ? "#ffe646" : "rgba(255,255,255,0.3)",
            opacity: 1,
        },
        highlighted: {
            color: "#ffe646",
            opacity: 1,
        },
    };

    const textColor: ColorSet = {
        disabled: {
            color: props.textHighlight ? "white" : "white",
            opacity: props.textHighlight ? 0.3 : 0.3,
        },
        normal: {
            color: props.textHighlight ? "white" : "#ffe646",
            opacity: props.textHighlight ? 1 : 1,
        },
        highlighted: {
            color: props.selected ? "#2d2d2d" : "#1a1a1e",
            opacity: 1,
        },
    };

    return (
        <RoundedButton
            borderColor={borderColor}
            bgColor={bgColor}
            textColor={textColor}
            title={props.title}
            disabled={props.disabled ?? false}
            titleSize={"small"}
            onClick={() => {
                props.onClick();
            }}
        />
    );
}

export function HistoryButton(props: { onClick: () => void }) {
    const bgColor: ColorSet = {
        normal: {
            color: "transparent",
            opacity: 1,
        },
        highlighted: {
            color: "transparent",
            opacity: 0.6,
        },
    };

    const borderColor: ColorSet = {
        normal: {
            color: "rgba(255,255,255,0.3)",
            opacity: 1,
        },
        highlighted: {
            color: "rgba(255,255,255,0.3)",
            opacity: 0.6,
        },
    };

    const textColor: ColorSet = {
        normal: {
            color: "white",
            opacity: 1,
        },
        highlighted: {
            color: "white",
            opacity: 0.6,
        },
    };

    return (
        <RoundedButton
            borderColor={borderColor}
            bgColor={bgColor}
            textColor={textColor}
            title={"History"}
            disabled={false}
            onClick={() => {
                props.onClick();
            }}
        />
    );
}

function RoundedButton(props: {
    borderColor: ColorSet;
    bgColor: ColorSet;
    textColor: ColorSet;
    icon?: {
        normal: any;
        highlighted: any;
    };
    title: string;
    titleSize?: "small" | "medium" | "large";
    disabled: boolean;
    loading?: boolean;
    onClick: () => void;
}) {
    const [over, setOver] = useState(false);

    return (
        <div
            onMouseEnter={() => {
                setOver(true);
            }}
            onMouseLeave={() => {
                setOver(false);
            }}
            className={cx(style.button, { disabled: props.disabled })}
            style={{
                borderColor: pickColor(props.disabled, over, props.borderColor)
                    .color,
            }}
            onClick={() => {
                if (!props.disabled) {
                    props.onClick();
                }
            }}
        >
            <div
                className={style.bg}
                style={{
                    backgroundColor: pickColor(
                        props.disabled,
                        over,
                        props.bgColor
                    ).color,
                    opacity: pickColor(props.disabled, over, props.bgColor)
                        .opacity,
                }}
            />
            {props.icon ? (
                <img
                    className={style.icon}
                    src={!over ? props.icon.normal : props.icon.highlighted}
                    alt=""
                />
            ) : (
                <div />
            )}
            {props.loading ? (
                <div className={style.trigger} />
            ) : (
                <div
                    className={cx(style.title, {
                        small: props.titleSize === "small",
                        medium: props.titleSize === "medium",
                        large: props.titleSize === "large",
                    })}
                    style={{
                        color: pickColor(props.disabled, over, props.textColor)
                            .color,
                        opacity: pickColor(
                            props.disabled,
                            over,
                            props.textColor
                        ).opacity,
                    }}
                >
                    {props.title}
                </div>
            )}
        </div>
    );
}

function pickColor(disabled: boolean, over: boolean, color: ColorSet): Color {
    if (disabled) {
        return color.disabled!;
    } else if (over) {
        return color.highlighted;
    } else {
        return color.normal;
    }
}
