import { multiply } from "../../../../../libs/Math";
import { getDecimal } from "../../../../../libs/utils";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./TPTInfo.module.scss";
import TPTInfoVp from "./TPTInfoVp";
import TPTInfoDeposit from "./TPTInfoDeposit";
import TPTInfoSharing from "./TPTInfoSharing";
import TPTInfoParticipation from "./TPTInfoParticipation";
import TPTInfoPool from "./TPTInfoPool";

export default function TPTInfo(props: {
    campaignAddress: string;
    summary: CampaignSummary;
    earlybird: boolean;
}) {
    return (
        <div className={style.container}>
            <div className={style.header}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"Campaign Info"}
                    size={20}
                    color={"#fff"}
                />

                <div className={style.rewards_partamount_sub}>
                    {/* <div className={style.sub}>
                        <div className={style.date}>
                            <div className={style.participants}>
                                <div className={style.icon1} />
                                <div className={style.text}>
                                    {getDecimal(
                                        multiply(
                                            props.summary.state
                                                .participation_count,
                                            1000000
                                        ),
                                        true,
                                        0
                                    )}
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className={style.contents}>
                {/* <TPTInfoPool summary={props.summary} /> */}

                <div className={style.rewards}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"Rewards"}
                        size={16}
                        color={"#fff"}
                    />

                    <div className={style.rewardsdetail}>
                        <TPTInfoParticipation
                            summary={props.summary}
                            campaignAddress={props.campaignAddress}
                        />
                        <TPTInfoSharing summary={props.summary} />
                    </div>
                </div>
                <div className={style.condition}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"Condition"}
                        size={16}
                        color={"#fff"}
                    />
                    <div className={style.conditiondetail}>
                        <TPTInfoDeposit summary={props.summary} />

                        {props.earlybird === true ? (
                            <TPTInfoVp summary={props.summary} />
                        ) : (
                            <div />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
