import { useEffect, useState } from "react";

import style from "./TxDoneView.module.scss";

import { TxInfo } from "@terra-money/terra.js";
import FooterInfoRow, {
    FooterInfoRow2,
    FooterInfoRowTx,
} from "../FooterInfoRow/FooterInfoRow";

import { txParse } from "./TxParser";
import { YellowButton } from "../NormalButton/NormalButton";
import { queryBalances } from "../../../libs/api/trade/query";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { balanceState, walletState } from "../../../libs/contexts";

export default function TxDoneView(props: {
    result: HashResult;
    txInfo: TxInfo;
    pressed: () => void;
}) {
    const wallet = useRecoilValue(walletState);
    const setBalance = useSetRecoilState(balanceState);
    const [parsedMsg, setParsedMsg] = useState<TxMsgParsed[] | undefined>(
        undefined
    );

    useEffect(() => {
        if (wallet) {
            queryBalances(wallet.terraAddress)
                .then((r) => {
                    setBalance(r);
                })
                .catch((e) => {});
        }
    }, [wallet]);

    useEffect(() => {
        txParse(props.result, props.txInfo as any)
            .then((r) => {
                setParsedMsg(r);
            })
            .catch((e) => {});
    }, []);

    return (
        <div className={style.done_box}>
            <div className={style.icon} />
            <div className={style.text1}>{"Complete!"}</div>

            {parsedMsg ? (
                <div className={style.box}>
                    {parsedMsg.map((item, index) => {
                        if (item.values.length === 2) {
                            return (
                                <FooterInfoRow2
                                    key={index}
                                    tooltip=""
                                    title={item.title}
                                    body1={item.values[0].value}
                                    denom1={item.values[0].denom}
                                    body2={item.values[1].value}
                                    denom2={item.values[1].denom}
                                />
                            );
                        } else if (item.values[0].denom === "hash") {
                            return (
                                <FooterInfoRowTx
                                    key={index}
                                    tooltip=""
                                    title={item.title}
                                    tx={item.values[0].value}
                                />
                            );
                        } else {
                            return (
                                <FooterInfoRow
                                    key={index}
                                    tooltip=""
                                    title={item.title}
                                    body={item.values[0].value}
                                    symbol={item.values[0].denom}
                                />
                            );
                        }
                    })}
                </div>
            ) : (
                <div />
            )}
            <div className={style.dummy}>
                <div className={style.dummy1} />
                <div className={style.dummy2} />
            </div>
            <div className={style.button}>
                <YellowButton title={"OK"} onClick={props.pressed} />
            </div>
        </div>
    );
}
