import { useEffect } from "react";
import style from "./TradeSpread.module.scss";
import { useRecoilState } from "recoil";
import { spreadState } from "../../libs/contexts";
import {
    loadSpread,
    saveSpread,
    SPREAD_DEFAULT,
    SPREAD_STEP_1,
    SPREAD_STEP_2,
    SPREAD_STEP_3,
    SPREAD_STEP_4,
    SPREAD_STEP_5,
} from "../../libs/storage";
import { TradeFilterButton } from "../common/NormalButton/NormalButton";
import * as Utils from "../../libs/utils";
import TitleLabel from "../common/Labels/TitleLabel";

export default function SpreadView() {
    const [spread, setSpread] = useRecoilState(spreadState);

    useEffect(() => {
        const loaded = loadSpread();

        if (
            loaded.spreadValue === SPREAD_DEFAULT ||
            loaded.spreadValue === SPREAD_STEP_1 ||
            loaded.spreadValue === SPREAD_STEP_2 ||
            loaded.spreadValue === SPREAD_STEP_3 ||
            loaded.spreadValue === SPREAD_STEP_4 ||
            loaded.spreadValue === SPREAD_STEP_5
        ) {
            setSpread({ spreadValue: spread.spreadValue });
        } else {
            //default 1%
            setSpread({ spreadValue: SPREAD_DEFAULT });
        }
    }, []);

    useEffect(() => {
        if (
            spread.spreadValue === SPREAD_DEFAULT ||
            spread.spreadValue === SPREAD_STEP_1 ||
            spread.spreadValue === SPREAD_STEP_2 ||
            spread.spreadValue === SPREAD_STEP_3 ||
            spread.spreadValue === SPREAD_STEP_4 ||
            spread.spreadValue === SPREAD_STEP_5
        ) {
            saveSpread({ spreadValue: spread.spreadValue });
        } else {
            //default 1%
            saveSpread({ spreadValue: SPREAD_DEFAULT });
        }
    }, [spread]);

    return (
        <div className={style.container}>
            <div className={style.slip}>
                <TitleLabel
                    text={"Slippage Tolerance"}
                    color={"#fff"}
                    size={14}
                    fontWeight={"medium"}
                />
            </div>

            <div className={style.spread}>
                <div className={style.group1}>
                    <div className={style.button}>
                        <TradeFilterButton
                            title={
                                Utils.getPercentFormat(
                                    SPREAD_STEP_4.toString()
                                ) + "%"
                            }
                            selected={spread.spreadValue === SPREAD_STEP_4}
                            onClick={() => {
                                setSpread({ spreadValue: SPREAD_STEP_4 });
                            }}
                        />
                    </div>
                    <div className={style.dummy} />
                    <div className={style.button}>
                        <TradeFilterButton
                            title={
                                Utils.getPercentFormat(
                                    (SPREAD_DEFAULT * 2).toString()
                                ) + "%"
                            }
                            selected={spread.spreadValue === SPREAD_DEFAULT}
                            onClick={() => {
                                setSpread({ spreadValue: SPREAD_DEFAULT });
                            }}
                        />
                    </div>
                    <div className={style.dummy} />
                    <div className={style.button}>
                        <TradeFilterButton
                            title={
                                Utils.getPercentFormat(
                                    SPREAD_STEP_1.toString()
                                ) + "%"
                            }
                            selected={spread.spreadValue === SPREAD_STEP_1}
                            onClick={() => {
                                setSpread({ spreadValue: SPREAD_STEP_1 });
                            }}
                        />
                    </div>
                    <div className={style.dummy2} />
                </div>
                <div className={style.group1}>
                    <div className={style.button}>
                        <TradeFilterButton
                            title={
                                Utils.getPercentFormat(
                                    SPREAD_STEP_2.toString()
                                ) + "%"
                            }
                            selected={spread.spreadValue === SPREAD_STEP_2}
                            onClick={() => {
                                setSpread({ spreadValue: SPREAD_STEP_2 });
                            }}
                        />
                    </div>
                    <div className={style.dummy} />
                    <div className={style.button}>
                        <TradeFilterButton
                            title={
                                Utils.getPercentFormat(
                                    SPREAD_STEP_3.toString()
                                ) + "%"
                            }
                            selected={spread.spreadValue === SPREAD_STEP_3}
                            onClick={() => {
                                setSpread({ spreadValue: SPREAD_STEP_3 });
                            }}
                        />
                    </div>

                    <div className={style.dummy} />
                    <div className={style.button}>
                        <TradeFilterButton
                            title={
                                Utils.getPercentFormat(
                                    SPREAD_STEP_5.toString()
                                ) + "%"
                            }
                            selected={spread.spreadValue === SPREAD_STEP_5}
                            onClick={() => {
                                setSpread({ spreadValue: SPREAD_STEP_5 });
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
