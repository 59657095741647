import style from "./SayveParticipationManual.module.scss";

import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { balanceState } from "../../../../../libs/contexts";
import SayveParticipationFooter from "./SayveParticipationFooter";
import { leftGreaterThanRight } from "../../../../../libs/Math";

export default function SayveParticipationManual(props: {
    campaignAddress: string;
    summary: CampaignSummary;
    earlybird: boolean;
    requirement?: SayveRequirement;
    started: boolean;
    ipcParticipate: (token: string) => void;
}) {
    const balance = useRecoilValue(balanceState);

    const [error1, setError1] = useState<string | undefined>(undefined);

    const [vkrCheck, setVkrCheck] = useState<boolean>(false);
    const [vpCheck, setVpCheck] = useState<boolean>(false);
    const [lunaCheck, setLunaCheck] = useState<boolean>(false);

    const [lunaAmount, setLunaAmount] = useState("");

    useEffect(() => {
        if (
            !leftGreaterThanRight(
                props.summary.config.deposit.amount,
                balance.token
            )
        ) {
            setVkrCheck(true);
        } else {
            setVkrCheck(false);
        }

        if (
            !leftGreaterThanRight(
                props.summary.config.vp_burn_amount,
                balance.vp
            )
        ) {
            setVpCheck(true);
        } else {
            setVpCheck(false);
        }
    }, [balance, props.summary]);

    useEffect(() => {
        if (error1 !== undefined) {
            setError1(undefined);
        }
    }, []);

    return (
        <div className={style.container}>
            <div style={{ height: "6px" }} />

            <SayveParticipationFooter
                summary={props.summary}
                vkrCheck={vkrCheck}
                vpCheck={vpCheck}
                lunaCheck={lunaCheck}
                setVkrCheck={setVkrCheck}
                setVpCheck={setVpCheck}
                setLunaCheck={setLunaCheck}
                tokenBalance={balance.token}
                lunaBalance={balance.Luna}
                vpBalance={balance.vp}
                lunaAmount={lunaAmount}
                setLunaAmount={setLunaAmount}
                error={error1}
                earlybird={props.earlybird}
                started={props.started}
                requirement={props.requirement}
                ipcParticipate={props.ipcParticipate}
            />
        </div>
    );
}
