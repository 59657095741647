import { lpApr } from "../rest";
import { divide, fixed, isZero, min, multiply, sqrt } from "../../Math";
import { environment, wasmQuery } from "../api";

import { queryNativeBalance, queryTokenBalance } from "../trade/query";

export async function queryLpEstimateParams(): Promise<ResponseLpEstimate> {
    const balances = await queryNativeBalance(
        // "uluna",
        environment().contracts.usdc_token,
        environment().contracts.astroport.pair
    );

    const axlUSDC = balances.amount;

    const response1 = await wasmQuery(environment().contracts.token, {
        balance: {
            address: environment().contracts.astroport.pair,
        },
    });

    const response2 = await wasmQuery(
        environment().contracts.astroport.lp_token,
        {
            token_info: {},
        }
    );

    const token = response1.balance;
    const lp_total_supply = response2.total_supply;

    return {
        pools: {
            axlUSDC: axlUSDC,
            token: token,
        },
        lp_total_supply: lp_total_supply,
    };
}

export async function stakeLpEstimate(
    pools: {
        token: string;
        axlUSDC: string;
    },
    deposits: {
        token: string;
        axlUSDC: string;
    },
    lp_total_supply: string
): Promise<string> {
    let estimate = "0";

    if (isZero(lp_total_supply)) {
        estimate = sqrt(multiply(deposits.token, deposits.axlUSDC));
    } else {
        const v1 = divide(
            multiply(deposits.axlUSDC, lp_total_supply),
            pools.axlUSDC
        );
        const v2 = divide(
            multiply(deposits.token, lp_total_supply),
            pools.token
        );

        estimate = min(v1, v2);
    }

    return fixed(estimate, 6);
}

export async function unstakeLpEstimate(
    pools: {
        token: string;
        axlUSDC: string;
    },
    amount: string,
    lp_total_supply: string
): Promise<{
    token: string;
    axlUSDC: string;
}> {
    const shareRatio =
        lp_total_supply === "0" ? "0" : divide(amount, lp_total_supply);

    const token = multiply(pools.token, shareRatio);
    const usdc = multiply(pools.axlUSDC, shareRatio);

    return {
        token: fixed(token, 0),
        axlUSDC: fixed(usdc, 0),
    };
}

export async function astroportDepositAmount(address: string): Promise<string> {
    return await wasmQuery(environment().contracts.astroport.generator, {
        deposit: {
            lp_token: environment().contracts.astroport.lp_token,
            user: address,
        },
    });
}

async function astroportRewardInfo(
    address: string
): Promise<ResponseAstroportRewardInfo> {
    return await wasmQuery(environment().contracts.astroport.generator, {
        pending_token: {
            lp_token: environment().contracts.astroport.lp_token,
            user: address,
        },
    });
}

async function lpStakingState(): Promise<ResponseLpStakeStateInfo> {
    return await wasmQuery(environment().contracts.astroport.lp_staking, {
        state: {},
    });
}

export async function stakeLpInfo(
    address: string | undefined
): Promise<StakeLpHeaderInfo> {
    const apr = await lpApr();
    const state = await lpStakingState();

    let balance = "0";
    let bond_amount = "0";
    let pending = "0";
    let pending_on_proxy = "0";

    if (address) {
        balance = (await queryTokenBalance(undefined, address)).amount;
        const info = await astroportRewardInfo(address);
        bond_amount = await astroportDepositAmount(address);
        pending = info.pending;

        if (
            info.pending_on_proxy !== undefined &&
            info.pending_on_proxy.length !== 0
        ) {
            pending_on_proxy = info.pending_on_proxy[0].amount ?? "0";
        }
    }

    return {
        apr: apr,
        totalStaked: state.total_bond_amount,
        stakable: balance,
        staked: bond_amount,
        unstakable: bond_amount,
        pending: pending,
        pending_on_proxy: pending_on_proxy,
    };
}
