import { getDecimal } from "../../../../../libs/utils";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./SayveInfoSharing.module.scss";

export default function SayveInfoSharing(props: { summary: CampaignSummary }) {
    const reward = props.summary.reward;

    const rAmount = reward.referral.amounts;
    const rSymbol = reward.referral.symbol;

    return (
        <div className={style.container}>
            <div className={style.sharing_title}>
                <TitleLabel
                    fontWeight={"medium"}
                    text={"Sharing Scheme"}
                    size={14}
                    color={"#ffe646"}
                />
            </div>
            <div className={style.sharing_contents}>
                <div className={style.sharing_contents_amount}>
                    <div className={style.sharing_contents_amount_ico} />

                    {reward.referral.amounts.length === 0 ? (
                        <div className={style.sharing_contents_amount_text}>
                            <TitleLabel
                                size={16}
                                text={"None"}
                                color={"#ffe646"}
                            />
                        </div>
                    ) : (
                        <div className={style.sharing_contents_amount_text}>
                            <TitleLabel
                                size={16}
                                text={getDecimal(
                                    rAmount[0],
                                    true,
                                    rSymbol === "LUNA" ? 3 : 6
                                )}
                                color={"#ffe646"}
                                symbol={rSymbol + " -"}
                            />
                            <div className={style.amount_text_right}>
                                <TitleLabel
                                    size={12}
                                    text={getDecimal(
                                        rAmount[1],
                                        true,
                                        rSymbol === "LUNA" ? 3 : 6
                                    )}
                                    color={"#ffe646"}
                                    symbol={rSymbol}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {reward.referral.amounts.length === 0 ? (
                    <div />
                ) : (
                    <>
                        <div className={style.sharing_contents_dummy} />
                        <div className={style.sharing_contents_day}>
                            <div className={style.sharing_contents_day_text}>
                                {props.summary.reward.referral.lock_day.toString() +
                                    " Days"}
                            </div>

                            <div className={style.sharing_contents_day_ico} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
