import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { environment } from "../../../../../libs/api/api";
import { queryTokenBalance } from "../../../../../libs/api/trade/query";
import { walletState } from "../../../../../libs/contexts";
import { leftLessThanRight } from "../../../../../libs/Math";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./SayveTierTable.module.scss";
import classNames from "classnames/bind";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../../../App";

enum VPTier {
    black,
    gold,
    silver,
    general,
}

const cx = classNames.bind(style);

export default function SayveTierTable() {
    const wallet = useRecoilValue(walletState);
    const history = useHistory();
    const [vpBalance, setVpBalance] = useState<string>("0");
    const [tier, setTier] = useState<VPTier>(VPTier.general);

    useEffect(() => {
        if (wallet !== undefined) {
            queryTokenBalance(
                environment().contracts.vp_token,
                wallet.terraAddress
            )
                .then((r) => {
                    setVpBalance(r.amount);
                })
                .catch((e) => {
                    setVpBalance("0");
                });
        }
    }, [wallet]);

    useEffect(() => {
        if (!leftLessThanRight(vpBalance, 20000000000)) {
            setTier(VPTier.black);
        } else if (!leftLessThanRight(vpBalance, 10000000000)) {
            setTier(VPTier.gold);
        } else if (!leftLessThanRight(vpBalance, 3000000000)) {
            setTier(VPTier.silver);
        } else {
            setTier(VPTier.general);
        }
    }, [vpBalance]);

    function tierPressed(pressedTier: VPTier) {
        if (pressedTier === tier) {
            history.push(PATHS.vp);
        }
    }

    return (
        <div className={style.container}>
            <div className={style.titleWrapper}>
                <div className={style.membership}>{"Membership Tier"}</div>
                <div className={style.dummy} />
                <div className={style.max}>{"Max"}</div>
                <div className={style.min}>{"Min"}</div>
            </div>
            <div className={style.contentWrapper}>
                <div className={style.tier}>
                    <div
                        className={cx(style.tierBox, {
                            highlight: tier === VPTier.black,
                        })}
                        onClick={() => {
                            tierPressed(VPTier.black);
                        }}
                    >
                        <div className={style.title}>
                            <div
                                className={cx(style.blackImg, {
                                    highlight: tier === VPTier.black,
                                })}
                            />
                            <div className={style.webTitle}>
                                {"Black"}
                                <TitleLabel
                                    size={16}
                                    text={""}
                                    color={"#ffffff"}
                                    fontWeight={"light"}
                                    tooltip={"Holds over 20,000VP"}
                                />
                            </div>
                        </div>
                        <div className={style.detail}>
                            <div className={style.luna}>
                                {"1,500 "}
                                <span>{"LUNA"}</span>
                            </div>
                            <div className={style.tokenWrapper}>
                                <div className={style.special}>&asymp;</div>
                                <div className={style.token}>
                                    {"45,045.045 SAYVE"}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={cx(style.tierBox, {
                            highlight: tier === VPTier.gold,
                        })}
                        onClick={() => {
                            tierPressed(VPTier.gold);
                        }}
                    >
                        <div className={style.title}>
                            <div
                                className={cx(style.goldImg, {
                                    highlight: tier === VPTier.gold,
                                })}
                            />
                            <div className={style.webTitle}>
                                {"Gold"}
                                <TitleLabel
                                    size={16}
                                    text={""}
                                    color={"#ffffff"}
                                    fontWeight={"light"}
                                    tooltip={"Holds over 10,000VP"}
                                />
                            </div>
                        </div>
                        <div className={style.detail}>
                            <div className={style.luna}>
                                {"900 "}
                                <span>{"LUNA"}</span>
                            </div>
                            <div className={style.tokenWrapper}>
                                <div className={style.special}>&asymp;</div>
                                <div className={style.token}>
                                    {"27,027.027 SAYVE"}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={cx(style.tierBox, {
                            highlight: tier === VPTier.silver,
                        })}
                        onClick={() => {
                            tierPressed(VPTier.silver);
                        }}
                    >
                        <div className={style.title}>
                            <div
                                className={cx(style.silverImg, {
                                    highlight: tier === VPTier.silver,
                                })}
                            />
                            <div className={style.webTitle}>
                                {"Silver"}
                                <TitleLabel
                                    size={16}
                                    text={""}
                                    color={"#ffffff"}
                                    fontWeight={"light"}
                                    tooltip={"Holds over 3,000VP"}
                                />
                            </div>
                        </div>
                        <div className={style.detail}>
                            <div className={style.luna}>
                                {"600 "}
                                <span>{"LUNA"}</span>
                            </div>
                            <div className={style.tokenWrapper}>
                                <div className={style.special}>&asymp;</div>
                                <div className={style.token}>
                                    {"18,018.018 SAYVE"}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={cx(style.tierBox, {
                            highlight: tier === VPTier.general,
                        })}
                        onClick={() => {
                            tierPressed(VPTier.general);
                        }}
                    >
                        <div className={style.title}>
                            <div
                                className={cx(style.generalImg, {
                                    highlight: tier === VPTier.general,
                                })}
                            />
                            <div className={style.webTitle}>
                                {"General"}
                                <TitleLabel
                                    size={16}
                                    text={""}
                                    color={"#000000"}
                                    fontWeight={"light"}
                                />
                            </div>
                        </div>
                        <div className={style.detail}>
                            <div className={style.luna}>
                                {"300 "}
                                <span>{"LUNA"}</span>
                            </div>
                            <div className={style.tokenWrapper}>
                                <div className={style.special}>&asymp;</div>
                                <div className={style.token}>
                                    {"9,009.009 SAYVE"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.min}>
                    <div className={style.minWrapper}>
                        <div className={style.luna}>
                            {"40 "}
                            <span>{"LUNA"}</span>
                        </div>
                        <div className={style.tokenWrapper}>
                            <div className={style.special}>&asymp;</div>
                            <div className={style.token}>
                                {"1,201.201 SAYVE"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
