import style from "./MainSection2.module.scss";

import { useRef } from "react";

import DashboardChartHeader from "../common/DashboardChartHeader/DashboardChartHeader";
import { useState } from "react";
import { useEffect } from "react";
import { totalValueLocked } from "../../libs/api/rest";
import { useRecoilValue } from "recoil";
import { refreshState, walletState } from "../../libs/contexts";
import { Pie } from "@nivo/pie";
import { divide } from "../../libs/Math";

const cellMeta = {
    title: "Total Value Locked",
    tooltip: "Total axlUSDC value locked in LP, Campaigns, and Governance",
    symbol: "axlUSDC",
    items: [
        {
            title: "Liquidity",
            color: "#ffe646",
        },
        {
            title: "Gov Staked",
            color: "#a37e30",
        },
        {
            title: "Reward Pool",
            color: "#4e461b",
        },
    ],
};

export default function MainSection2() {
    const refresh = useRecoilValue(refreshState);
    const wallet = useRecoilValue(walletState);
    const isLoading = useRef(false);

    const [value, setValue] = useState({
        total: "0",
        values: ["0", "0", "0"],
    });

    const [selected, setSelected] = useState("");

    useEffect(() => {
        if (isLoading.current) {
            return;
        }
        isLoading.current = true;

        totalValueLocked(wallet ? wallet.terraAddress : undefined)
            .then((r) => {
                setValue({
                    total: r.total,
                    values: [r.lp, r.gov, r.deposit],
                });
                isLoading.current = false;
            })
            .catch((e) => {
                isLoading.current = false;
            });
    }, [wallet, refresh]);

    function chartSelected(selectLabel: string) {
        setSelected(selectLabel);
    }

    return (
        <div
            className={style.container}
            onMouseLeave={() => {
                chartSelected("");
            }}
            onTouchStart={() => {
                chartSelected("");
            }}
            onTouchMove={() => {
                chartSelected("");
            }}
        >
            <div className={style.inner_container}>
                <DashboardChartHeader
                    meta={cellMeta}
                    value={value}
                    selected={selected}
                />

                <Chart value={value} chartSelected={chartSelected} />
            </div>
        </div>
    );
}

function Chart(props: {
    value: {
        total: string;
        values: string[];
    };
    chartSelected: (selectLabel: string) => void;
}) {
    let datas = props.value.values.map((item) => {
        return parseFloat(item);
    });

    let labels = ["Liquidity", "Gov Staked", "Reward Pool"];

    let colors = cellMeta.items.map((item) => {
        return item.color;
    });

    let hoverColors = [
        "rgba(255,230,70,0.8)",
        "rgba(163,126,48,0.8)",
        "rgba(78,70,27,0.8)",
    ];
    const [changeColor, setChangeColor] = useState(-1);

    const data = [
        {
            id: labels[0],
            label: labels[0],
            value: divide(datas[0], props.value.total),
            color: changeColor === 0 ? colors[0] : hoverColors[0],
        },
        {
            id: labels[1],
            label: labels[1],
            value: divide(datas[1], props.value.total),
            color: changeColor === 1 ? colors[1] : hoverColors[1],
        },
        {
            id: labels[2],
            label: labels[2],
            value: divide(datas[2], props.value.total),
            color: changeColor === 2 ? colors[2] : hoverColors[2],
        },
    ];

    return (
        <div className={style.chart_container}>
            <Pie
                data={data}
                height={256}
                width={256}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                innerRadius={0.5}
                borderWidth={1}
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                }}
                enableArcLinkLabels={false}
                arcLabel={""}
                animate={true}
                colors={{ datum: "data.color" }}
                theme={{
                    tooltip: {
                        container: {
                            visibility: "hidden",
                            contentVisibility: "hidden",
                        },
                    },
                }}
                onMouseEnter={(data) => {
                    props.chartSelected(data.label.toString());
                    setChangeColor(data.arc.index);
                }}
                onMouseLeave={() => {
                    props.chartSelected("");
                    setChangeColor(-1);
                }}
            />
        </div>
    );
}
