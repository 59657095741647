import style from "./InputFieldIconCombo.module.scss";
import classNames from "classnames/bind";
import { useState } from "react";
import ComboBox from "../ComboBox/ComboBox";
import TitleLabel from "../Labels/TitleLabel";
import TooltipIcon from "../TooltipIcon/TooltipIcon";
import { inputFieldValidate } from "../../../libs/utils";

const cx = classNames.bind(style);

export default function InputFieldIconCombo(props: {
    title: string;
    balance?: string;
    disabled?: boolean;
    tooltip?: string;
    denom?: string;
    placeholder?: string;
    decimal: number;
    value: string;
    onChanged: (v: string) => void;
    error?: string;
    combo: {
        selected: number;
        setSelected: (index: number) => void;
        list: string[];
    };
}) {
    return (
        <div className={style.container}>
            <div className={style.title_container}>
                {props.tooltip ? (
                    <div style={{ display: "flex", marginRight: "4px" }}>
                        <TooltipIcon tooltip={props.tooltip} />
                    </div>
                ) : (
                    <div />
                )}
                <TitleLabel
                    text={props.title}
                    color={
                        props.error && !props.disabled ? "#a02d46" : "#786f64"
                    }
                />
                <div style={{ flex: 1 }} />
            </div>

            <div
                className={cx(style.body_container, {
                    error: props.error,
                })}
            >
                <div className={style.coin_icon}>
                    {/* <SymbolIcon denom={props.denom} /> */}
                </div>

                <input
                    className={cx(
                        style.field,

                        {
                            error: props.error && !props.disabled,
                        },
                        {
                            disabled: props.disabled,
                        },
                        {
                            combo: true,
                        }
                    )}
                    maxLength={40}
                    placeholder={"0.000"}
                    disabled={props.disabled}
                    value={props.value}
                    onChange={(e) => {
                        let value = e.target.value;

                        if (value) {
                            const validated = inputFieldValidate(
                                value,
                                props.decimal ?? 6
                            );
                            if (validated) {
                                props.onChanged(validated);
                            }
                        } else {
                            //빈값일때
                            props.onChanged(value);
                        }
                    }}
                />
                <ComboView value={props.combo!} />
            </div>
            <ErrorView title={props.error} />
        </div>
    );
}

function ErrorView(props: { title: string | undefined }) {
    if (!props.title) {
        return <div style={{ height: "32px" }} />;
    }

    return (
        <div className={style.error_container}>
            <div className={style.error_text}>{props.title}</div>
            <div style={{ flex: 1 }} />
        </div>
    );
}

function ComboView(props: {
    value: {
        selected: number;
        setSelected: (index: number) => void;
        list: string[];
    };
}) {
    const [showCombo, setShowCombo] = useState(false);

    return (
        <div
            className={cx(style.combobox)}
            onClick={() => {
                setShowCombo(true);
            }}
        >
            <div className={style.dummy} />
            <div style={{ flex: 1 }} />
            <div className={style.symbol}>
                {props.value.list[props.value.selected]}
            </div>
            <div className={style.icon} />

            <ComboBox
                showCombo={showCombo}
                setShowCombo={setShowCombo}
                selected={props.value.selected}
                setSelected={(v) => {
                    props.value.setSelected(v);
                }}
                list={props.value.list.map((item, index) => {
                    return {
                        value: index.toString(),
                        display: props.value.list[index],
                    };
                })}
            />
        </div>
    );
}
