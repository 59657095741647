import { useEffect, useState } from "react";
import * as Utils from "../../libs/utils";
import style from "./Trade.module.scss";
import {
    tokenSimulate,
    tokenReverseSimulate,
    queryPrice,
} from "../../libs/api/trade/query";
import InputField from "../common/InputField/InputField";
import {
    divide,
    fixed,
    isNumber,
    isZero,
    leftGreaterThanRight,
    multiply,
} from "../../libs/Math";
import TxInfoView from "../common/TxInfoView/TxInfoView";
import { useRecoilValue } from "recoil";
import { spreadState, walletState } from "../../libs/contexts";
import SpreadView from "./TradeSpread";
// import { getFeeNormal } from "../../libs/api/fee";
import classNames from "classnames/bind";
import { environment } from "../../libs/api/api";
import { getFeeRaw } from "../../libs/api/fee";

const cx = classNames.bind(style);

export default function TradeSell(props: {
    balance: { token: string; axlUSDC: string };
    sellPressed: (amount: string, spread: number) => void;
}) {
    const wallet = useRecoilValue(walletState);
    const spread = useRecoilValue(spreadState);

    const [token, setToken] = useState("");
    const [axlUSDC, setAxlUSDC] = useState("");
    const [simulate, setSimulate] = useState<ResponseSimulate | undefined>(
        undefined
    );

    const [error1, setError1] = useState<string | undefined>(undefined);
    const [error2, setError2] = useState<string | undefined>(undefined);

    const [fee, setFee] = useState("0");
    const [beliefPrice, setBeliefPrice] = useState<string>("");

    useEffect(() => {
        queryPrice(environment().contracts.token).then((r) => {
            setBeliefPrice(r);
        });
    }, []);

    useEffect(() => {
        if (
            leftGreaterThanRight(token, Utils.getDecimal(props.balance.token))
        ) {
            setError1("Insufficient balance");
        } else if (isZero(token)) {
            setError1("Amount must be greater than 0");
        } else {
            setError1(undefined);
        }

        let exes: WasmExecute[] = [];

        let subMsg = {
            swap: {
                belief_price: fixed(divide(1, beliefPrice), 18),
                max_spread: spread.spreadValue.toString(),
            },
        };

        exes.push({
            contract: environment().contracts.token,
            msg: {
                send: {
                    contract: environment().contracts.astroport.pair,
                    amount: fixed(multiply(token, "1000000"), 0),
                    msg: btoa(JSON.stringify(subMsg)),
                },
            },
            coin: undefined,
        });

        getFeeRaw(wallet?.terraAddress ? wallet.terraAddress : "", exes)
            .then((r) => {
                let uluna = "uluna";
                setFee(r.amount.toString().replace(uluna, ""));
            })
            .catch((e) => {
                Utils.getFeeError(e.response.data.message, setError1);
                setFee("0");
            });
    }, [token, props.balance.token, spread]);

    useEffect(() => {
        simulate1(token, spread.spreadValue);
    }, [spread]);

    function simulate1(token: string, spread: number) {
        if (token) {
            tokenSimulate(false, multiply(token, 1000000), spread)
                .then((r: ResponseSimulate) => {
                    setSimulate(r);
                    setAxlUSDC(Utils.getDecimal(r.to, false, 3));
                    setError2(undefined);
                })
                .catch((e) => {});
        }
    }

    return (
        <div className={style.trade_inner_container}>
            <div className={style.from}>
                <InputField
                    title={"From"}
                    type="amount"
                    balance={props.balance.token}
                    symbol={"VKR"}
                    onChanged={(v: string) => {
                        setToken(v);

                        if (v) {
                            simulate1(v, spread.spreadValue);
                        } else {
                            setAxlUSDC("");
                        }
                    }}
                    value={token}
                    error={error1}
                    autofocus={true}
                    showSymbolIcon={true}
                />
            </div>

            {/* <div className={style.trade_exchangeicon} /> */}
            <div
                className={cx(style.arrow, {
                    error: error1,
                })}
            />

            <div className={style.to}>
                <InputField
                    title={"To"}
                    type="amount"
                    symbol={"axlUSDC"}
                    decimal={6}
                    onChanged={(v: string) => {
                        setAxlUSDC(v);
                        if (v) {
                            tokenReverseSimulate(
                                false,
                                multiply(v, 1000000),
                                spread.spreadValue
                            )
                                .then((r: ResponseSimulate) => {
                                    setSimulate(r);
                                    setToken(Utils.getDecimal(r.to));
                                    setError2(undefined);
                                })
                                .catch((e) => {
                                    setError2("Simulation failed");
                                });
                        } else {
                            setToken("");
                        }
                    }}
                    value={axlUSDC}
                    error={error2}
                    showSymbolIcon={true}
                />
            </div>

            <SpreadView />

            {token && simulate ? (
                <InfoView simulate={simulate} isTrade={true} fee={fee} />
            ) : (
                <div />
            )}

            <div className={style.spreaddummy} />

            <div className={style.tradebutton}>
                <div
                    className={cx(style.button, {
                        enable2: !error1 && !error2 && token !== "",
                    })}
                    onClick={() => {
                        if (!error1 && !error2 && token !== "") {
                            props.sellPressed(
                                multiply(token, 1000000),
                                spread.spreadValue
                            );
                        }
                    }}
                >
                    {"SELL"}
                </div>
            </div>

            {/* <ConfirmButton
                title="SELL"
                enable={!error1 && !error2 && token !== ""}
                isBuy={false}
                onClick={() => {
                    props.sellPressed(
                        multiply(token, 1000000),
                        spread.spreadValue
                    );
                }}
            /> */}
        </div>
    );
}

function InfoView(props: {
    simulate: ResponseSimulate;
    isTrade?: boolean;
    fee: string;
}) {
    const spread = useRecoilValue(spreadState);

    const [price, setPrice] = useState("0");
    useEffect(() => {
        let price = divide(1, props.simulate.rate);

        if (!isNumber(price)) {
            price = "0";
        }
        const p = multiply(price, 1000000);

        setPrice(Utils.getDecimal(p, true, 6));
    }, [props.simulate]);

    const [list, setList] = useState<any[]>([]);

    useEffect(() => {
        let list: any[] = [];

        list.push({
            title: "Estimated Price",
            body: price,
            symbol: " axlUSDC per VKR",
        });

        list.push({
            title: "Minimum Received",
            body: Utils.getDecimal(props.simulate.minimumReceive, true, 6),
            symbol: "axlUSDC",
        });

        list.push({
            title: "Trading Fee",
            body: Utils.getDecimal(props.simulate.tradingFee, true, 6),
            symbol: "axlUSDC",
        });

        setList(list);
    }, [props, props.fee, price, spread]);

    return <TxInfoView list={list} fee={props.fee} />;
}
