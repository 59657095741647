import { useEffect, useState } from "react";
import {
    leftGreaterThanRight,
    multiply,
    divide,
    fixed,
    isZero,
} from "../../libs/Math";
import { getDecimal } from "../../libs/utils";
import InputField from "../common/InputField/InputField";
import style from "./VPExchange.module.scss";
import classNames from "classnames/bind";
import { environment } from "../../libs/api/api";
import { getFeeRaw } from "../../libs/api/fee";
import { useConnectedWallet } from "@terra-money/use-wallet";

const cx = classNames.bind(style);

export default function VPExchange(props: {
    vkrbalance: string;
    vpratio: string;
    excheck: (get: boolean) => void;
    vkramountChange: (get: string) => void;
    setFee: (get: string) => void;
}) {
    const wallet = useConnectedWallet();
    const [vkramount, setVkramount] = useState("");
    const [VPamount, setVPamount] = useState("");
    const [error1, setError1] = useState<string | undefined>(undefined);
    const [error2, setError2] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (
            leftGreaterThanRight(multiply(vkramount, 1000000), props.vkrbalance)
        ) {
            setError1("The quantity you entered is insufficient.");
            props.excheck(false);
        } else {
            setError1(undefined);
            if (leftGreaterThanRight(divide(vkramount, props.vpratio), "0")) {
                props.excheck(true);
            } else {
                props.excheck(false);
            }
        }
    }, [vkramount, props.vpratio, props.vkrbalance]);

    useEffect(() => {
        simulate1(vkramount);
    }, [vkramount]);

    useEffect(() => {
        props.vkramountChange(multiply(vkramount ?? "0", 1000000));
    }, [vkramount]);

    function simulate1(vkr: string) {
        if (vkr) {
            const input = multiply(vkr, 1000000);

            let get = fixed(
                multiply(divide(vkramount, props.vpratio), 1000000),
                0
            );

            setVPamount(getDecimal(get, false, 6));
            if (leftGreaterThanRight(input, "0")) {
                if (isZero(get)) {
                    setError2("The quantity you entered is insufficient.");
                    props.excheck(false);
                } else {
                    setError2(undefined);
                }
            }
        } else {
            setVPamount("");
        }
    }

    return (
        <div className={style.container}>
            <div className={style.from}>
                <InputField
                    title="From"
                    type="amount"
                    disabled={false}
                    symbol="VKR"
                    value={vkramount}
                    balance={props.vkrbalance}
                    onChanged={(v: string) => {
                        setVkramount(v);

                        let exes: WasmExecute[] = [];
                        exes.push({
                            contract: environment().contracts.token,
                            msg: {
                                send: {
                                    contract: environment().contracts.vp_token,
                                    amount: multiply(v, "1000000"),
                                    msg: btoa(
                                        JSON.stringify({
                                            mint: {},
                                        })
                                    ),
                                },
                            },
                            coin: undefined,
                        });

                        getFeeRaw(wallet ? wallet.terraAddress : "", exes)
                            .then((r) => {
                                let uluna = "uluna";
                                props.setFee(
                                    r.amount.toString().replace(uluna, "")
                                );
                            })
                            .catch((e) => {
                                props.setFee("0");
                            });
                    }}
                    error={error1 ? error1 : undefined}
                    autofocus={true}
                    showSymbolIcon={true}
                />
            </div>
            <div
                className={cx(style.arrow, {
                    error: error1 ? error1 : undefined,
                })}
            />

            <div className={style.to}>
                <InputField
                    title="To"
                    type="amount"
                    disabled={false}
                    symbol="VP"
                    value={VPamount}
                    onChanged={(v: string) => {
                        setVPamount(v);

                        let exes: WasmExecute[] = [];

                        if (v) {
                            let splits = multiply(v, props.vpratio).split(".");
                            if (splits[1] !== undefined) {
                                if (splits[1].length <= 6) {
                                    setVkramount(multiply(v, props.vpratio));
                                }
                            } else {
                                setVkramount(multiply(v, props.vpratio));
                            }

                            exes.push({
                                contract: environment().contracts.token,
                                msg: {
                                    send: {
                                        contract:
                                            environment().contracts.vp_token,
                                        amount: multiply(
                                            multiply(v, props.vpratio),
                                            "1000000"
                                        ),
                                        msg: btoa(
                                            JSON.stringify({
                                                mint: {},
                                            })
                                        ),
                                    },
                                },
                                coin: undefined,
                            });
                        } else {
                            setVkramount("");

                            exes.push({
                                contract: environment().contracts.token,
                                msg: {
                                    send: {
                                        contract:
                                            environment().contracts.vp_token,
                                        amount: "0",
                                        msg: btoa(
                                            JSON.stringify({
                                                mint: {},
                                            })
                                        ),
                                    },
                                },
                                coin: undefined,
                            });
                        }

                        getFeeRaw(wallet ? wallet.terraAddress : "", exes)
                            .then((r) => {
                                let uluna = "uluna";
                                props.setFee(
                                    r.amount.toString().replace(uluna, "")
                                );
                            })
                            .catch((e) => {
                                props.setFee("0");
                            });
                    }}
                    error={error2 ? error2 : undefined}
                    showSymbolIcon={true}
                />
            </div>
        </div>
    );
}
