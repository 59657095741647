import style from "./TxSearchPopup.module.scss";

import { useEffect, useRef, useState } from "react";
import * as Utils from "../../../../../libs/utils";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../../libs/contexts";
import { getTxs } from "../../../../../libs/api/api";
import { validateTpBuyInTx } from "../../../../../libs/api/campaign/query";
import MoreButton from "../../../../common/MoreButton/MoreButton";
import classNames from "classnames/bind";
import { TradeFilterButton } from "../../../../common/NormalButton/NormalButton";
import { qualificationTxInfo } from "../../../../../libs/api/campaign/qualification/rest";
const cx = classNames.bind(style);
export default function TxSearchPopup(props: {
    campaignAddress: string;
    setTxHash: (tx: string) => void;
    setShow: (v: number) => void;
}) {
    const [list, setList] = useState<string[]>([]);

    const wallet = useRecoilValue(walletState);

    const [txLoading, setLoading] = useState(false);
    const [txLoadingDone, setTxLoadingDone] = useState(false);
    const txOffset = useRef(0);

    const [selectedIndex, setSelectedIndex] = useState(-1);

    async function findHistory(): Promise<string> {
        if (txLoading || !wallet || txLoadingDone) {
            return "";
        }

        let oldList = list.slice();

        //tp launching 9/23 00:00:00 : 1663891200000
        let limit = 1663891200000;
        if (window.location.origin.includes("localhost")) {
            limit = 0;
        }

        const result = await getTxs(
            wallet.terraAddress,
            txOffset.current,
            limit
        );

        if (result.offset) {
            txOffset.current = result.offset;
        } else {
            setTxLoadingDone(true);
        }

        for (let i = 0; i < result.txs.length; i++) {
            const valid = await validateTpBuyInTx(
                result.txs[i],
                wallet.terraAddress
            ).catch((e) => {
                return false;
            });

            if (valid) {
                oldList.push(result.txs[i].txhash);
            }
        }

        setList(oldList);

        return "";
    }

    useEffect(() => {
        setLoading(true);
        findHistory()
            .then((r) => {
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, []);

    function checkPressed() {
        if (selectedIndex !== -1) {
            props.setTxHash(list[selectedIndex]);
        }
    }

    return (
        <div className={style.container}>
            <div className={style.contents}>
                <div className={style.contents_title}>
                    <TitleLabel
                        size={18}
                        color={"white"}
                        fontWeight="medium"
                        text={"Choose another TX"}
                    />
                </div>

                <div className={style.popdummy} />
                {/* <div className={style.claimable}>{"Claimable"}</div> */}
                <div className={style.list}>
                    {list.map((item, index) => {
                        return (
                            <Item
                                key={index}
                                item={{
                                    txHash: item,
                                    campaignAddress: props.campaignAddress,
                                }}
                                index={index}
                                selected={index === selectedIndex}
                                setSelectedIndex={setSelectedIndex}
                            />
                        );
                    })}

                    {!txLoadingDone ? (
                        <div className={style.morebutton}>
                            <TradeFilterButton
                                title="More"
                                selected={false}
                                onClick={() => {
                                    findHistory()
                                        .then((r) => {
                                            setLoading(false);
                                        })
                                        .catch((e) => {
                                            setLoading(false);
                                        });
                                }}
                            />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>

                <div className={style.bar} />
                <div className={style.buttons}>
                    <div
                        className={style.button1}
                        onClick={() => {
                            props.setShow(-1);
                        }}
                    >
                        <TitleLabel
                            size={14}
                            color={"black"}
                            fontWeight="medium"
                            text={"Cancel"}
                        />
                    </div>
                    <div
                        className={cx(style.button2, {
                            disabled: selectedIndex === -1,
                        })}
                        onClick={() => {
                            checkPressed();
                        }}
                    >
                        <TitleLabel
                            size={14}
                            color={
                                selectedIndex === -1
                                    ? "rgba('white', 0.3)"
                                    : "black"
                            }
                            fontWeight="medium"
                            text={"Check"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function Item(props: {
    item: {
        txHash: string;
        campaignAddress: string;
    };
    index: number;
    selected: boolean;
    setSelectedIndex: (index: number) => void;
}) {
    const wallet = useRecoilValue(walletState);

    const [referrer, setReferrer] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (wallet) {
            qualificationTxInfo(
                wallet.terraAddress,
                props.item.txHash,
                props.item.campaignAddress
            )
                .then((r) => {
                    setReferrer(r.referrer);
                })
                .catch((e) => {});
        }
    }, [props.item, wallet]);

    return (
        <div
            className={style.card}
            onClick={() => {
                props.setSelectedIndex(props.index);
            }}
        >
            <div
                className={cx(style.referral_icon, {
                    exist: referrer !== undefined,
                })}
            />
            <TitleLabel
                size={12}
                color={"white"}
                fontWeight={"light"}
                text={
                    props.item.txHash.substring(0, 6) +
                    "..." +
                    props.item.txHash.substring(
                        props.item.txHash.length - 6,
                        props.item.txHash.length
                    )
                }
            />

            <div style={{ flex: 1 }} />

            <div
                className={cx(style.check_icon, { selected: props.selected })}
            />

            {/* <TitleLabel
                        size={10}
                        color={"#c8c8c8"}
                        fontWeight={"light"}
                        text={time ? Utils.dateFormat5(time * 1000) : undefined}
                        skeleton={{ width: 100 }}
                    /> */}
        </div>
    );
}
