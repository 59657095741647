import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./TPSmartAirdropTierTable.module.scss";
import classNames from "classnames/bind";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../../../App";
import { getTierCondition, TPTTier } from "../TPSmartAirdrop";
import { getDecimal } from "../../../../../libs/utils";

const cx = classNames.bind(style);

export default function TPSmartAirdropTierTable(props: {
    summary: CampaignSummary;
    tier: TPTTier;
}) {
    const history = useHistory();

    function tierPressed(pressedTier: TPTTier) {
        if (pressedTier === props.tier) {
            history.push(PATHS.vp);
        }
    }

    return (
        <div className={style.container}>
            <div className={style.titleWrapper}>
                <div className={style.membership}>{"VP Status"}</div>
                <div className={style.title_box}>
                    <div className={style.title}>
                        {"Participation"}
                        <div style={{ height: "3px" }} />
                        {"Reward"}
                    </div>
                </div>
                <div className={style.title_box}>
                    <div className={style.title}>{"Sharing Reward"}</div>

                    <div className={style.lockperiod}>
                        <div className={style.icon} />
                        <div className={style.text}>{"2 Weeks"}</div>
                    </div>
                </div>
                <div className={style.title_box}>
                    <div className={style.title}>{"Deposit"}</div>

                    <div className={style.lockperiod}>
                        <div className={style.icon} />
                        <div className={style.text}>{"4 Weeks"}</div>
                    </div>
                </div>
            </div>

            <div className={style.tier}>
                <TierBox
                    tier={props.tier}
                    summary={props.summary}
                    targetTier={TPTTier.black}
                    tierPressed={tierPressed}
                />
                <TierBox
                    tier={props.tier}
                    summary={props.summary}
                    targetTier={TPTTier.gold}
                    tierPressed={tierPressed}
                />
                <TierBox
                    tier={props.tier}
                    summary={props.summary}
                    targetTier={TPTTier.silver}
                    tierPressed={tierPressed}
                />
                <TierBox
                    tier={props.tier}
                    summary={props.summary}
                    targetTier={TPTTier.general}
                    tierPressed={tierPressed}
                />
            </div>
        </div>
    );
}

function TierBox(props: {
    tier: TPTTier;
    summary: CampaignSummary;
    targetTier: TPTTier;
    tierPressed: (v: TPTTier) => void;
}) {
    return (
        <div
            className={cx(style.tierBox, {
                highlight: props.tier === props.targetTier,
            })}
            onClick={() => {
                props.tierPressed(props.targetTier);
            }}
        >
            <div className={style.title}>
                <div
                    className={cx(
                        props.targetTier === TPTTier.black
                            ? style.blackImg
                            : props.targetTier === TPTTier.gold
                            ? style.goldImg
                            : props.targetTier === TPTTier.silver
                            ? style.silverImg
                            : style.generalImg,
                        {
                            highlight: props.tier === props.targetTier,
                        }
                    )}
                />

                <TitleLabel
                    text={
                        props.targetTier === TPTTier.black
                            ? "Black"
                            : props.targetTier === TPTTier.gold
                            ? "Gold"
                            : props.targetTier === TPTTier.silver
                            ? "Silver"
                            : "General"
                    }
                    color={"#ffffff"}
                    fontWeight={"light"}
                    tooltip={
                        props.targetTier === TPTTier.black
                            ? "Holds over 20,000VP"
                            : props.targetTier === TPTTier.gold
                            ? "Holds over 10,000VP"
                            : props.targetTier === TPTTier.silver
                            ? "Holds over 3,000VP"
                            : undefined
                    }
                />
            </div>
            <div className={style.detail}>
                <TitleLabel
                    fontWeight="light"
                    color={"#ffe646"}
                    text={getDecimal(
                        getTierCondition(props.targetTier).pReward,
                        true
                    )}
                    symbol={"TPT"}
                />
            </div>
            <div className={style.detail}>
                <TitleLabel
                    fontWeight="light"
                    color={"#ffe646"}
                    text={getDecimal(
                        getTierCondition(props.targetTier).rReward[0],
                        true
                    )}
                    symbol={
                        " - " +
                        getDecimal(
                            getTierCondition(props.targetTier).rReward[1],
                            true
                        ) +
                        " VKR"
                    }
                />
            </div>
            <div className={style.detail}>
                <TitleLabel
                    fontWeight="light"
                    color={"white"}
                    text={
                        getDecimal(props.summary.config.deposit.amount, true) +
                        " "
                    }
                    symbol={"VKR"}
                />
            </div>
        </div>
    );
}
