import style from "./FooterInfoRow.module.scss";
import TitleLabel from "../Labels/TitleLabel";
import * as Utils from "../../../libs/utils";

export default function FooterInfoRow(props: {
    tooltip?: string;
    title: string;
    body: string;
    symbol?: string;
}) {
    return (
        <div className={style.container}>
            <div className={style.row2}>
                <div className={style.row2_title}>
                    <div className={style.row2_title_ico} />
                    <TitleLabel
                        text={props.title}
                        tooltip={props.tooltip}
                        color="rgba(255,255,255,0.5)"
                        size={16}
                        fontWeight={"light"}
                    />
                </div>

                <div style={{ flex: 1 }} />
                <div className={style.row2_content}>
                    <div className={style.row2_content_text}>
                        <TitleLabel
                            text={props.body}
                            size={16}
                            symbol={props.symbol}
                            color="#fff"
                            fontWeight="medium"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export function FooterInfoRowTx(props: {
    tooltip: string;
    title: string;
    tx: string;
}) {
    const tx =
        props.tx.substring(0, 6) +
        "..." +
        props.tx.substring(props.tx.length - 6);
    return (
        <div className={style.container}>
            <div className={style.row2}>
                <div className={style.row2_title}>
                    <div className={style.row2_title_ico} />
                    <TitleLabel
                        text={props.title}
                        tooltip={props.tooltip}
                        size={16}
                        fontWeight="light"
                        color="rgba(255,255,255,0.5)"
                    />
                </div>

                <div style={{ flex: 1 }} />
                <div className={style.row2_content}>
                    <div className={style.row2_content_text}>
                        <div
                            className={style.link}
                            onClick={() => {
                                Utils.showFinderTx(props.tx);
                            }}
                        >
                            <TitleLabel
                                text={tx}
                                size={16}
                                color="white"
                                underline={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function FooterInfoRow2(props: {
    tooltip: string;
    title: string;
    body1: string;
    denom1: string;
    body2: string;
    denom2: string;
}) {
    return (
        <div className={style.container}>
            <div className={style.row2}>
                <div className={style.row2_title}>
                    <div className={style.row2_title_ico} />
                    <TitleLabel
                        text={props.title}
                        tooltip={props.tooltip}
                        size={16}
                        fontWeight="light"
                        color="rgba(255,255,255,0.5)"
                    />
                </div>
                <div style={{ flex: 1 }} />
                <div className={style.row2_content}>
                    <div className={style.row2_content_text}>
                        <TitleLabel
                            text={props.body1}
                            color="white"
                            size={16}
                            symbol={props.denom1}
                        />
                        <div className={style.dummy} />
                        <TitleLabel
                            text={"+ " + props.body2}
                            color="white"
                            size={16}
                            symbol={props.denom2}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
