import { useEffect, useState } from "react";
import { isZero, multiply } from "../../../../../libs/Math";
import { getDecimal } from "../../../../../libs/utils";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./BaseCampaignGuide.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(style);

export default function BaseCampaignGuide(props: {
    campaignAddress: string;
    summary: CampaignSummary;
}) {
    const state = props.summary.state;
    const reward = props.summary.reward;

    const pFiltered = state.pool_balances.filter((item) => {
        return item.denom === reward.participation.denom;
    });
    const pReward = pFiltered.length > 0 ? pFiltered[0].amount : "0";
    const pAmount = reward.participation.amount;
    const pSymbol = reward.participation.symbol;
    const pDenom = reward.participation.denom;
    const pDecimals = reward.participation.decimals;

    const rFiltered = state.pool_balances.filter((item) => {
        return item.denom === reward.referral.denom;
    });
    const rReward = rFiltered.length > 0 ? rFiltered[0].amount : "0";
    const rAmount = reward.referral.amounts;
    const rSymbol = reward.referral.symbol;
    const rDenom = reward.referral.denom;

    const [depositcheck, setDepositcheck] = useState(false);

    useEffect(() => {
        if (props.summary.config.deposit.amount === "0") {
            setDepositcheck(false);
        } else {
            setDepositcheck(true);
        }
    }, [props.summary.config.deposit.amount]);

    return (
        <div className={style.container}>
            <div className={style.header}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"Info"}
                    size={20}
                    color={"#fff"}
                />

                <div className={style.rewards_partamount_sub}>
                    <div className={style.sub}>
                        <div className={style.date}>
                            <div className={style.participants}>
                                <div className={style.icon1} />
                                <div className={style.text}>
                                    {getDecimal(
                                        multiply(
                                            props.summary.state
                                                .participation_count,
                                            1000000
                                        ),
                                        true,
                                        0
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.contents}>
                <div className={style.pool}>
                    {props.summary.state.is_active === true ? (
                        <div className={style.wavewrapper}>
                            <div className={style.wave}></div>
                            <div className={style.wave}></div>
                        </div>
                    ) : (
                        <div />
                    )}
                    <div className={style.pooltitle}>
                        <div className={style.pooltitle_ico} />
                        <div className={style.pooltitle_text}>
                            <TitleLabel
                                fontWeight={"medium"}
                                text={"Campaign Pool"}
                                size={14}
                                color={"#fff"}
                            />
                        </div>
                    </div>
                    <div
                        className={cx(style.poolamount, {
                            amountlong: pDenom !== rDenom,
                        })}
                    >
                        <div className={style.poolamount_text}>
                            <div className={style.sub}>
                                <div
                                    className={cx(style.text2, {
                                        isZero: isZero(pReward),
                                    })}
                                >
                                    {getDecimal(
                                        pReward,
                                        true,
                                        pDenom === "axlUSDC" ? 3 : pDecimals
                                    )}
                                    &nbsp;
                                    <small>{pSymbol}</small>
                                </div>

                                {pDenom !== rDenom ? (
                                    <div className={style.dummy}>
                                        &nbsp;<small>{"/"}</small>&nbsp;
                                    </div>
                                ) : (
                                    <div />
                                )}

                                {pDenom !== rDenom ? (
                                    <div
                                        className={cx(style.text3, {
                                            isZero: isZero(rReward),
                                        })}
                                    >
                                        {getDecimal(
                                            rReward,
                                            true,
                                            rDenom === "axlUSDC" ? 3 : 6
                                        )}
                                        &nbsp;
                                        <small>{rSymbol}</small>
                                    </div>
                                ) : (
                                    <div />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.rewards}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"Rewards"}
                        size={16}
                        color={"#fff"}
                    />

                    <div className={style.rewardsdetail}>
                        <div className={style.participants}>
                            <div className={style.participants_title}>
                                <TitleLabel
                                    fontWeight={"medium"}
                                    text={"Participants"}
                                    size={14}
                                    color={"#ffe646"}
                                />
                            </div>
                            <div className={style.participants_contents}>
                                <div
                                    className={
                                        style.participants_contents_amount
                                    }
                                >
                                    <div
                                        className={cx(
                                            style.participants_contents_amount_ico,
                                            {
                                                isIpc: false,
                                            }
                                        )}
                                    />
                                    <div
                                        className={
                                            style.participants_contents_amount_text
                                        }
                                    >
                                        <TitleLabel
                                            size={16}
                                            text={getDecimal(
                                                pAmount,
                                                true,
                                                pSymbol === "axlUSDC"
                                                    ? 3
                                                    : pDecimals
                                            )}
                                            color={"#ffe646"}
                                            symbol={pSymbol}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={
                                        style.participants_contents_dummy
                                    }
                                />
                                <div
                                    className={style.participants_contents_day}
                                >
                                    <div
                                        className={
                                            style.participants_contents_day_text
                                        }
                                    >
                                        {props.summary.reward.participation.lock_day.toString() +
                                            " Days"}
                                    </div>

                                    <div
                                        className={
                                            style.participants_contents_day_ico
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={style.sharing}>
                            <div className={style.sharing_title}>
                                <TitleLabel
                                    fontWeight={"medium"}
                                    text={"Sharing Scheme"}
                                    size={14}
                                    color={"#ffe646"}
                                />
                            </div>
                            <div className={style.sharing_contents}>
                                <div className={style.sharing_contents_amount}>
                                    <div
                                        className={
                                            style.sharing_contents_amount_ico
                                        }
                                    />
                                    <div
                                        className={
                                            style.sharing_contents_amount_text
                                        }
                                    >
                                        <TitleLabel
                                            size={16}
                                            text={getDecimal(
                                                rAmount[0],
                                                true,
                                                rSymbol === "axlUSDC" ? 3 : 6
                                            )}
                                            color={"#ffe646"}
                                            symbol={rSymbol + " -"}
                                        />
                                        <div
                                            className={style.amount_text_right}
                                        >
                                            <TitleLabel
                                                size={12}
                                                text={getDecimal(
                                                    rAmount[1],
                                                    true,
                                                    rSymbol === "axlUSDC"
                                                        ? 3
                                                        : 6
                                                )}
                                                color={"#ffe646"}
                                                symbol={rSymbol}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={style.sharing_contents_dummy} />
                                <div className={style.sharing_contents_day}>
                                    <div
                                        className={
                                            style.sharing_contents_day_text
                                        }
                                    >
                                        {props.summary.reward.referral.lock_day.toString() +
                                            " Days"}
                                    </div>

                                    <div
                                        className={
                                            style.sharing_contents_day_ico
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.condition}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"Condition"}
                        size={16}
                        color={"#fff"}
                    />
                    <div
                        className={cx(style.conditiondetail, {
                            vpfalse: true,
                        })}
                    >
                        <div className={style.deposit}>
                            <div className={style.deposit_title}>
                                <TitleLabel
                                    fontWeight={"medium"}
                                    text={"Campaign Deposit"}
                                    size={14}
                                    color={"#fff"}
                                />
                            </div>
                            {depositcheck ? (
                                <div className={style.deposit_contents}>
                                    <div
                                        className={
                                            style.deposit_contents_amount
                                        }
                                    >
                                        <div
                                            className={
                                                style.deposit_contents_amount_ico
                                            }
                                        />
                                        <div
                                            className={
                                                style.deposit_contents_amount_text
                                            }
                                        >
                                            <TitleLabel
                                                size={16}
                                                text={getDecimal(
                                                    props.summary.config.deposit
                                                        .amount,
                                                    true,
                                                    props.summary.config.deposit
                                                        .symbol === "axlUSDC"
                                                        ? 3
                                                        : 6
                                                )}
                                                color={"#fff"}
                                                symbol={
                                                    props.summary.config.deposit
                                                        .symbol
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={style.deposit_contents_dummy}
                                    />
                                    <div className={style.deposit_contents_day}>
                                        <div
                                            className={
                                                style.deposit_contents_day_text
                                            }
                                        >
                                            {props.summary.config.deposit_lock_period_day.toString() +
                                                " Days"}
                                        </div>

                                        <div
                                            className={
                                                style.deposit_contents_day_ico
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className={style.deposit_contents}>
                                    <div
                                        className={style.deposit_contents_none}
                                    >
                                        <div
                                            className={
                                                style.deposit_contents_amount_ico
                                            }
                                        />
                                        <TitleLabel
                                            size={16}
                                            text={"None"}
                                            color={"#fff"}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
