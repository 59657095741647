import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./TPTParticipation.module.scss";
import classNames from "classnames/bind";
import TPTParticipationManual from "./TPTParticipationManual";

const cx = classNames.bind(style);
export default function TPTParticipation(props: {
    summary: CampaignSummary;
    earlybird: boolean;
    campaignAddress: string;
    // requirement?: TPTRequirement;
    // started: boolean;
    // ipcParticipate: (token: string) => void;
    // refcheck: boolean;
}) {
    return (
        <div className={style.container}>
            {props.earlybird === true ? (
                <div />
            ) : (
                <div className={cx(style.dummy, { isIpc: true })} />
            )}

            <div className={style.title}>
                <div className={style.titlewrap}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"Participation"}
                        size={20}
                        color={"#fff"}
                    />
                    <div className={style.title_sub}>
                        <TitleLabel
                            fontWeight={"light"}
                            text={
                                props.earlybird === true
                                    ? "( VP EXCLUSIVE )"
                                    : "( PUBLIC )"
                            }
                            size={16}
                            color={"#fff"}
                        />
                    </div>
                </div>
            </div>

            <div className={style.box}>
                {/* {props.summary.state.is_active ? (
                    <div>
                        <div className={style.gotosite_container}>
                            <a
                                className={style.gotosite}
                                href={"https://terrapoker.games"}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className={style.text}>{"Go to Site"}</div>
                                <div className={style.arrowicon} />
                            </a>
                            <div className={style.referralcheck}>
                                <div
                                    className={cx(style.referralcheck_ico, {
                                        reftrue: props.refcheck,
                                    })}
                                />
                                <div className={style.referralcheck_title}>
                                    {props.refcheck ? (
                                        <TitleLabel
                                            fontWeight={"medium"}
                                            text={
                                                "Connected through referral link"
                                            }
                                            size={12}
                                            color={"#55f559"}
                                        />
                                    ) : (
                                        <TitleLabel
                                            fontWeight={"medium"}
                                            text={
                                                "Connected without referral link"
                                            }
                                            size={12}
                                            color={"rgba(255,255,255,0.5)"}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <TPTParticipationManual
                            campaignAddress={props.campaignAddress}
                            summary={props.summary}
                            earlybird={props.earlybird}
                            requirement={props.requirement}
                            started={props.started}
                            ipcParticipate={props.ipcParticipate}
                        />
                    </div>
                ) : ( */}
                <div className={style.contents}>
                    <ParticipationCheckNot summary={props.summary} />
                </div>
                {/* )} */}
            </div>
        </div>
    );
}

function ParticipationCheckNot(props: { summary: CampaignSummary }) {
    return (
        <div className={style.particontainer3}>
            <div className={style.comingsoon}>
                <div className={style.textwrap}>
                    {props.summary.config.title + " is Closed"}
                </div>
            </div>
        </div>
    );
}
