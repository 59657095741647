import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../App";

import style from "./ConnectWay.module.scss";

import {
    Connection,
    ConnectType,
    Installation,
    useWallet,
} from "@terra-money/wallet-provider";
import { useRecoilValue } from "recoil";
import { walletState } from "../../libs/contexts";
import TitleLabel from "../common/Labels/TitleLabel";

import stationIcon from "../../resources/ico-connect-station.svg";
import xdefiIcon from "../../resources/ico-connect-xdefi.svg";
import walletconnectIcon from "../../resources/ico-connect-wallet.svg";
import leapIcon from "../../resources/ico-connect-leap.png";
import classNames from "classnames/bind";
const cx = classNames.bind(style);

interface ConnectInfo {
    type: ConnectType;
    identifier: string | undefined;
    name: string;
    icon: string;
    available: boolean;
    url: string | undefined;
}

export default function ConnectWay() {
    const history = useHistory();
    const wallet = useRecoilValue(walletState);

    const { availableConnections, availableInstallations } = useWallet();
    const [connectionList, setConnectionList] = useState<ConnectInfo[]>([]);

    useEffect(() => {
        if (wallet) {
            history.replace(PATHS.home);
        }
    }, [wallet]);

    useEffect(() => {
        let list: ConnectInfo[] = [];

        availableConnections
            .filter((item) => {
                return item.type !== "READONLY";
            })
            .map((item): ConnectInfo => {
                return {
                    type: item.type,
                    icon: item.icon,
                    url: undefined,
                    identifier: item.identifier,
                    available: true,
                    name: item.name,
                };
            })
            .forEach((item) => {
                list.push(item);
            });

        availableInstallations
            .filter((item) => {
                return item.identifier !== "falcon-wallet";
            })
            .map((item): ConnectInfo => {
                return {
                    type: item.type,
                    icon: item.icon,
                    url: item.url,
                    identifier: item.identifier,
                    available: false,
                    name: "Install " + item.name,
                };
            })
            .forEach((item) => {
                list.push(item);
            });

        setConnectionList(list);
    }, [availableConnections, availableInstallations]);

    if (wallet) {
        return <div />;
    }

    return (
        <div className={style.container}>
            <div className={style.inner_container}>
                <TitleLabel
                    text="Connect Wallet"
                    fontWeight="light"
                    size={20}
                    color={"white"}
                />

                <div className={style.box}>
                    {connectionList.map((item, index) => {
                        return <ConnectButton key={index} meta={item} />;
                    })}
                </div>
            </div>
        </div>
    );
}

function ConnectButton(props: { meta: ConnectInfo }) {
    const { connect } = useWallet();

    const icon =
        props.meta.identifier === "station"
            ? stationIcon
            : props.meta.identifier === "xdefi-wallet"
            ? xdefiIcon
            : props.meta.identifier === "leap-wallet"
            ? leapIcon
            : props.meta.type === ConnectType.WALLETCONNECT
            ? walletconnectIcon
            : props.meta.icon;

    return (
        <div
            className={cx(style.connect_button, {
                available: props.meta.available,
            })}
            onClick={() => {
                if (!props.meta.available) {
                    window.open(props.meta.url, "_blank");
                } else if (props.meta.type === ConnectType.WALLETCONNECT) {
                    connect(ConnectType.WALLETCONNECT);
                } else {
                    connect(ConnectType.EXTENSION, props.meta.identifier);
                    window.location.reload();
                }
            }}
        >
            <div className={style.text}>{props.meta.name}</div>
            <img
                className={cx(style.icon, { available: props.meta.available })}
                src={icon}
                alt={props.meta.name}
            />
        </div>
    );
}
