import { isZero, leftGreaterThanRight } from "../../Math";
import { environment, wasmExecutes } from "../api";
import {
    campaignDeposit,
    campaignSummary,
    refineCampaignDeposit,
} from "../campaign/query";
import { govState } from "../governance/query";
// import { getFeeHigh } from "../fee";
import { campaignClaimableList } from "../rest";

export async function claimCampaignRewards(address: string) {
    let exes: WasmExecute[] = [];

    const campaigns = await campaignClaimableList(address, true, undefined);
    for (let i = 0; i < campaigns.length; i++) {
        const campaign = campaigns[i];

        const summary = await campaignSummary(campaign, address);
        const p = summary.participation;

        const unlockedParticipationReward = p
            ? p.participation_reward.unlocked
            : "0";
        const unlockedReferralReward = p ? p.referral_reward.unlocked : "0";

        if (leftGreaterThanRight(unlockedParticipationReward, 0)) {
            exes.push({
                contract: campaign,
                msg: {
                    claim_participation_reward: {},
                },
                coin: undefined,
            });
        }

        if (leftGreaterThanRight(unlockedReferralReward, 0)) {
            exes.push({
                contract: campaign,
                msg: {
                    claim_referral_reward: {},
                },
                coin: undefined,
            });
        }
    }

    // fee
    // const fee = await getFeeHigh();
    // return await wasmExecutes(address, exes, undefined, fee);
    return await wasmExecutes(address, exes, undefined);
}

export async function claimOtherRewards(address: string) {
    //lp
    let exes: WasmExecute[] = [];
    exes.push({
        contract: environment().contracts.astroport.generator,
        msg: {
            withdraw: {
                lp_token: environment().contracts.astroport.lp_token,
                amount: "0",
            },
        },
        coin: undefined,
    });

    //deposit

    const campaigns = await campaignClaimableList(address, true, undefined);
    for (let i = 0; i < campaigns.length; i++) {
        const campaign = campaigns[i];
        const deposit = await campaignDeposit(campaign, address);
        const refined = await refineCampaignDeposit(deposit);
        if (leftGreaterThanRight(refined.claimable, 0)) {
            exes.push({
                contract: campaign,
                msg: {
                    withdraw: {
                        amount: refined.claimable,
                    },
                },
                coin: undefined,
            });
        }
    }

    //vp

    const gs = await govState(address);
    if (!isZero(gs.pending)) {
        exes.push({
            contract: environment().contracts.governance,
            msg: {
                ticket_claim: {},
            },
            coin: undefined,
        });
    }

    // const fee = await getFeeHigh();

    // return await wasmExecutes(address, exes, undefined, fee);
    return await wasmExecutes(address, exes, undefined);
}
