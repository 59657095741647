import style from "./ConditionView.module.scss";
import { useEffect, useState } from "react";
import { ConditionLabel } from "./ConditionLabel";
import { ConditionValue } from "./ConditionValue";
import { isZero } from "../../../../../libs/Math";

export enum ConditionType {
    deposit,
    vp,
}

export function ConditionView(props: {
    passed: boolean;
    type: ConditionType;
    summary: CampaignSummary;
}) {
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState("");

    useEffect(() => {
        const config = props.summary.config;
        if (props.type === ConditionType.deposit) {
            setTitle("Deposit VKR For Participation");
            setVisible(!isZero(config.deposit.amount));
        } else if (props.type === ConditionType.vp) {
            setTitle("VP For Participation");
            setVisible(!isZero(config.vp_burn_amount));
        }
    }, [props.type, props.summary]);

    if (visible) {
        return (
            <div className={style.container}>
                <ConditionLabel title={title} passed={props.passed} />

                <ConditionValue
                    passed={props.passed}
                    summary={props.summary}
                    type={props.type}
                />
            </div>
        );
    } else {
        return <div />;
    }
}
