import { environment } from "./api";
import moment from "moment";
import { queryPrice } from "./trade/query";
import axios from "axios";
import * as astroport from "./astroport_liquidity/query";
import { govState } from "./governance/query";
import { isZero, max, minus, multiply, plus } from "../Math";
import { campaignSummary } from "./campaign/query";

async function get(path: string, params?: any): Promise<any> {
    return axios
        .get(path, {
            baseURL: environment().api,
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
            params: params,
        })
        .then((response) => {
            return response.data.body;
        });
}

async function campaignRawList(
    // frameSize: number,
    // keyword: string | undefined,
    cursor: string | undefined,
    orderBy: string,
    isAscending: boolean,
    status: string | string[] | undefined,
    creatorAddress: string | undefined,
    isImminentIPC?: boolean | undefined
): Promise<PageModel<CampaignListItem[]>> {
    return await get("/campaign/page", {
        // keyword: keyword ? keyword : null,
        cursor: cursor,
        frame_size: 6,
        order_by: orderBy,
        is_ascending: isAscending,
        status: status,
        admin: creatorAddress,
        is_imminent_ipc: isImminentIPC,
    });
}

export async function campaignAllList(
    cursor: string | undefined
): Promise<PageModel<CampaignListItem[]>> {
    let response = await campaignRawList(
        cursor,
        "CREATED_AT", // filter,
        false, //asc,
        // ["ACTIVE", "INACTIVE"],
        "ACTIVE",
        undefined,
        false
    );

    return response;
}

export async function campaignAllListInactive(
    cursor: string | undefined
): Promise<PageModel<CampaignListItem[]>> {
    let response = await campaignRawList(
        cursor,
        "CREATED_AT", // filter,
        false, //asc,
        // ["ACTIVE", "INACTIVE"],
        "INACTIVE",
        undefined
    );

    return response;
}

export async function campaignAllListIpc(
    cursor: string | undefined
): Promise<PageModel<CampaignListItem[]>> {
    let response = await campaignRawList(
        cursor,
        "IPC_STARTED_AT", // filter,
        false, //asc,
        "ACTIVE",
        undefined,
        true // is imminent ipc
    );

    return response;
}

export async function producerList(
    cursor: string | undefined,
    address: string
): Promise<PageModel<CampaignListItem[]>> {
    let response = await campaignRawList(
        cursor,
        "CREATED_AT",
        false,
        undefined,
        address
        // undefined
    );

    return response;
}

export async function txHistory(
    cursor: string | undefined,
    frameSize: number,
    category: string | undefined,
    walletAddress: String
): Promise<PageModel<TxHistory[]>> {
    return await get("/transaction/page", {
        cursor: cursor ? cursor : null,
        frame_size: frameSize,
        wallet_address: walletAddress,
        category: category ? category : null,
    });
}

export async function campaignClaimableList(
    address: string | undefined,
    has_remained_reward?: boolean,
    isIpc?: boolean
): Promise<string[]> {
    if (address) {
        const response = await get("/campaign", {
            participant: address,
            // has_claimable_reward: true,
            has_remained_reward: has_remained_reward,
            is_ipc: isIpc,
        });

        const list: { campaignAddress: string }[] = response.items;

        return list.map((item) => {
            return item.campaignAddress;
        });
    } else {
        return [];
    }
}

export async function participationCampaignList(
    address: string | undefined,
    isIpc: boolean
): Promise<string[]> {
    return await campaignClaimableList(address, undefined, isIpc); //true: ipc //false: campaign
}

export async function mypageCampaignInfo(
    campaignAddress: string,
    address: string
): Promise<MyPageCampaignInfo> {
    let summary = await campaignSummary(campaignAddress, address);
    let dataList: MyPageCampaignInfo = {
        title: summary.config.title,
        denom: "",
        symbol: "",
        claimed: {
            tokenAmount: "0",
            axlUSDC: "0",
            decimal: 0,
        },
        locked: {
            tokenAmount: "0",
            axlUSDC: "0",
            decimal: 0,
        },
        claimable: {
            tokenAmount: "0",
            axlUSDC: "0",
            decimal: 0,
        },
        campaignAddress: campaignAddress,
    };

    if (summary.participation) {
        let denom = summary.reward.participation.denom;
        let symbol = summary.reward.participation.symbol;
        let price = await queryPrice(summary.reward.participation.denom);
        let decimal = summary.reward.participation.decimals;
        // 1VKR = ??? UST

        // 1000000 axlUSDC = 1UST -> decimals = 6
        // 갯수 * price / 1000000

        // 100000000 whsd = 1whsd -> decimals = 8
        // 갯수 * price / 100000000

        //axlUSDC 계산
        let referralPrice = await queryPrice(summary.reward.referral.denom);

        dataList.locked = {
            tokenAmount: summary.participation.participation_reward.locked,
            axlUSDC: plus(
                multiply(
                    summary.participation.participation_reward.locked,
                    price
                ),
                multiply(
                    summary.participation.referral_reward.locked,
                    referralPrice
                )
            ),
            decimal: decimal,
        };

        dataList.claimable = {
            tokenAmount: summary.participation.participation_reward.unlocked,
            axlUSDC: plus(
                multiply(
                    summary.participation.participation_reward.unlocked,
                    price
                ),
                multiply(
                    summary.participation.referral_reward.unlocked,
                    referralPrice
                )
            ),
            decimal: decimal,
        };
        dataList.claimed = {
            tokenAmount: minus(
                minus(
                    summary.participation.participation_reward.cummulative,
                    summary.participation.participation_reward.locked
                ),
                summary.participation.participation_reward.unlocked
            ),
            axlUSDC: multiply(
                minus(
                    minus(
                        summary.participation.participation_reward.cummulative,
                        summary.participation.participation_reward.locked
                    ),
                    summary.participation.participation_reward.unlocked
                ),
                price
            ),
            decimal: decimal,
        };

        dataList.denom = denom;
        dataList.symbol = symbol;
    }

    return dataList;
}

export async function campaignClaimableInfo(
    address: string | undefined
): Promise<{
    participation: { locked: string; claimable: string };
    sharing: { locked: string; claimable: string };
    deposit: { locked: string; claimable: string };
}> {
    let priceCache: { denom: string; price: string }[] = [];

    if (address) {
        const campaignList = await campaignClaimableList(address, true);

        let participation_locked = "0";
        let participation_claimable = "0";
        let sharing_locked = "0";
        let sharing_claimable = "0";
        let deposit_locked = "0";
        let deposit_claimable = "0";

        for (let i = 0; i < campaignList.length; i++) {
            let summary = await campaignSummary(campaignList[i], address);
            if (summary.participation === undefined) {
                continue;
            }

            let pCached = priceCache.filter((item) => {
                return item.denom === summary.reward.participation.denom;
            });

            let pRewardPrice = "0";
            if (pCached.length > 0) {
                pRewardPrice = pCached[0].price;
            } else {
                pRewardPrice = await queryPrice(
                    summary.reward.participation.denom
                );

                priceCache.push({
                    denom: summary.reward.participation.denom,
                    price: pRewardPrice,
                });
            }

            let rCached = priceCache.filter((item) => {
                return item.denom === summary.reward.referral.denom;
            });

            let rRewardPrice = "0";
            if (rCached.length > 0) {
                rRewardPrice = rCached[0].price;
            } else {
                rRewardPrice = await queryPrice(summary.reward.referral.denom);
                priceCache.push({
                    denom: summary.reward.referral.denom,
                    price: rRewardPrice,
                });
            }

            const pReawrd = summary.participation.participation_reward;
            const rReward = summary.participation.referral_reward;

            participation_locked = plus(
                multiply(pRewardPrice, pReawrd.locked),
                participation_locked
            );

            participation_claimable = plus(
                multiply(pRewardPrice, pReawrd.unlocked),
                participation_claimable
            );

            sharing_claimable = plus(
                multiply(rRewardPrice, rReward.unlocked),
                sharing_claimable
            );
            sharing_locked = plus(
                multiply(rRewardPrice, rReward.locked),
                sharing_locked
            );

            if (summary.deposit !== undefined) {
                for (let j = 0; j < summary.deposit.locked.length; j++) {
                    let locked = summary.deposit.locked[j];
                    deposit_locked = plus(locked.amount, deposit_locked);
                }

                deposit_claimable = plus(
                    summary.deposit.claimable,
                    deposit_claimable
                );
            }
        }

        return {
            participation: {
                locked: participation_locked,
                claimable: participation_claimable,
            },
            sharing: {
                locked: sharing_locked,
                claimable: sharing_claimable,
            },
            deposit: {
                locked: deposit_locked,
                claimable: deposit_claimable,
            },
        };
    } else {
        return {
            participation: {
                locked: "0",
                claimable: "0",
            },
            sharing: {
                locked: "0",
                claimable: "0",
            },
            deposit: {
                locked: "0",
                claimable: "0",
            },
        };
    }
}

export async function vkrPriceChart(
    type: "day" | "week" | "month"
): Promise<{ x: number; y: number }[]> {
    let result: { date: number; price: number }[] = [];

    if (type === "month") {
        const today = new Date().getTime();
        const yesterday = new Date().getTime() - 60 * 60 * 24 * 30 * 1000;

        const from = moment(yesterday).format("YYYY-MM-DD");
        const to = moment(today).format("YYYY-MM-DD");
        const response = await get("/valkyrie/price/history/1d", {
            from_date: from,
            to_date: to,
        });
        result = response.items;
    } else if (type === "week") {
        const today = new Date().getTime();
        const aWeekAgo = new Date().getTime() - 60 * 60 * 24 * 7 * 1000;

        const response = await get("/valkyrie/price/history/4h", {
            from: aWeekAgo,
            to: today,
        });
        result = response.items;
    } else if (type === "day") {
        const today = new Date().getTime();
        const yesterday = new Date().getTime() - 60 * 60 * 24 * 1000;

        const response = await get("/valkyrie/price/history/30m", {
            from: yesterday,
            to: today,
        });

        result = response.items;
    } else {
        result = [];
    }

    const price = await queryPrice(environment().contracts.token);

    const r = result.map((item, index) => {
        if (index === 0) {
            //0 is latest
            const p = parseFloat(price);
            return {
                x: item.date,
                y: p === 0 ? item.price : p,
            };
        } else {
            return {
                x: item.date,
                y: item.price,
            };
        }
    });

    return r;
}

export async function lpApr(): Promise<string> {
    return get("/liquidity-provision/stake/apr")
        .then((r) => {
            return r.apr;
        })
        .catch((e) => {
            return "0";
        });
}

export async function govApr(): Promise<string> {
    return get("/governance/stake/apr")
        .then((r) => {
            return r.apr;
        })
        .catch((e) => {
            return "0";
        });
}

async function participationCounts(): Promise<{
    total: string;
    participation: string;
    referral: string;
}> {
    const total = await participationCount(undefined);
    const ref = await participationCount("BY_REFERRAL");

    return {
        total: total,
        participation: minus(total, ref),
        referral: ref,
    };
}

async function totalDistributed(): Promise<{
    total: string;
    participation: string;
    referral: string;
}> {
    const total = await rewardValue(undefined);
    const ref = await rewardValue("BY_REFERRAL");

    return {
        total: total,
        participation: minus(total, ref),
        referral: ref,
    };
}

export async function pCountAndDistributed() {
    return {
        participationCount: await participationCounts(),
        totalDistributed: await totalDistributed(),
    };
}

async function participationCount(
    filter: undefined | "BY_REFERRAL"
): Promise<string> {
    return (
        await get("/participation/count", { filter: filter })
    ).count.toString();
}

async function rewardValue(filter: undefined | "BY_REFERRAL"): Promise<string> {
    return (
        await get("/reward/value", {
            filter: filter,
        })
    ).ustValue;
}

export async function poolValue(): Promise<string> {
    const price = await queryPrice(environment().contracts.token);

    const r = await get("/campaign/pool/valkyrie/amount", {});

    return multiply(r.amount, price);
}

export async function circulatingSupply(address: string | undefined): Promise<{
    total: string;
    lp: string;
    gov: string;
    deposit: string;
    others: string;
}> {
    const total = await get("/circulating-supply")
        .then((r) => {
            return r.amount;
        })
        .catch((e) => {
            return "0";
        });

    // 10억 - (Foundation + Airdrop + Community + Investor + Team + Distributor(추가 개발필요))  + (Lp-staking + Governance)

    const astroLpParams = await astroport.queryLpEstimateParams();
    const astroLpVkr = astroLpParams.pools.token;

    // const lpVkr = astroLpVkr;
    /*
    1. Circulating Supply
    token컨트랙트의 총 발행량(10억개)

    2. lp
    lp스테이킹할때 UST+VKR을 pair컨트랙트에 전송 후 생성된 LP토큰을 스테이킹 하는데, 이떄 pair컨트랙트에 보관중인 VKR balance
    
    3. gov
    gov컨트랙트에 스테이킹 되어있는 전체 물량

    4. reward pool (@dawn)
    /campaign/pool/valkyrie/amount api호출결과의 amount값

    5. other
    1 - (2+3+4)

     */

    const gov = await govState(address);
    const govVkr = gov.totalStaked;

    const deposit = await vkrAmountInPool()
        .then((r) => {
            return r;
        })
        .catch((e) => {
            return "0";
        });

    const others = max(
        minus(minus(minus(total, astroLpVkr), govVkr), deposit),
        0
    );
    // const others = max(minus(minus(total, govVkr), deposit), 0);

    return {
        total: total,
        // lp: lpVkr,
        lp: astroLpVkr,
        gov: govVkr,
        deposit: deposit,
        others: others,
    };
}

async function vkrAmountInPool(): Promise<string> {
    return (await get("/campaign/pool/valkyrie/amount")).amount;
}

export async function totalValueLocked(address: string | undefined): Promise<{
    total: string;
    lp: string;
    gov: string;
    deposit: string;
}> {
    const astroLpParams = await astroport.queryLpEstimateParams();
    const vkrPrice = await queryPrice(environment().contracts.token);

    const astroLpPoolVkr = astroLpParams.pools.token;
    const astroLpPoolUsdc = astroLpParams.pools.axlUSDC;

    const lpPoolVkr = astroLpPoolVkr;
    const lpPoolUsdc = astroLpPoolUsdc;
    const lpUsdc = plus(multiply(vkrPrice, lpPoolVkr), lpPoolUsdc);

    const gov = await govState(address);
    const govUsdc = multiply(gov.totalStaked, vkrPrice);

    // const deposit = await poolValue(undefined)
    const deposit = await poolValue()
        .then((r) => {
            return r;
        })
        .catch((e) => {
            return "0";
        });

    const total = plus(plus(lpUsdc, govUsdc), deposit);

    return {
        total: total,
        lp: lpUsdc,
        gov: govUsdc,
        deposit: deposit,
    };
}

// export async function airdropList(address: string) {
//     let items: AirdropItem[] = [];

//     const r: AirdropItem[] = (
//         await get("/airdrop", {
//             wallet_address: address,
//             filter: "CLAIMABLE",
//         })
//     ).items;

//     const loaded = loadAirdrop(address);

//     for (let i = 0; i < r.length; i++) {
//         let item = r[i];

//         if (!loaded.includes(item.stage)) {
//             items.push(item);
//         }
//     }

//     return items;
// }

export async function isWhitelistedCreator(address?: string): Promise<boolean> {
    if (!address) {
        return false;
    }

    const list: string[] = await get("/whitelist/creator")
        .then((r) => {
            return r.items.map((item: any) => {
                return item.creator;
            });
        })
        .catch((e) => {
            return [];
        });

    if (list.includes(address)) {
        return true;
    } else {
        return false;
    }
}

export async function isWhitelistedCampaign(
    campaignAddress: string
): Promise<boolean> {
    const list: string[] = await get("/whitelist/campaign")
        .then((r) => {
            return r.items.map((item: any) => {
                return item.campaignAddress;
            });
        })
        .catch((e) => {
            // return [];
            //네티워크 실패시 허용
            return [campaignAddress];
        });

    if (list.includes(campaignAddress)) {
        return true;
    } else {
        return false;
    }
}
