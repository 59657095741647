import { useEffect, useState } from "react";
import style from "./StakeGovernance.module.scss";
import TitleLabel from "../../common/Labels/TitleLabel";
import * as Utils from "../../../libs/utils";
import StakeGovernanceStake from "./StakeGovernanceStake";
import StakeGovernanceUnstake from "./StakeGovernanceUnstake";
import { govState } from "../../../libs/api/governance/query";
import { stakeGov, unstakeGov } from "../../../libs/api/governance/execution";
import PageHeader from "../../common/PageHeader/PageHeader";
import TxBoxView from "../../common/TxBoxView/TxBoxView";
import { PATHS } from "../../../App";
import { useRecoilValue } from "recoil";
import { balanceState, walletState } from "../../../libs/contexts";
import TabBox from "../../common/TabBox/TabBox";
import { useWallet } from "@terra-money/wallet-provider";
import CreateVpButton from "../../common/CreateVpButton/CreateVpButton";

export default function StakeGovernance(props: any) {
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);
    const [hash, setHash] = useState<HashResult | undefined>(undefined);
    const balance = useRecoilValue(balanceState);

    const [stakeInfo, setStakeInfo] = useState<StakeGovHeaderInfo | undefined>(
        undefined
    );

    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        govState(wallet ? wallet.terraAddress : undefined).then((r) => {
            setStakeInfo(r);
        });
    }, [wallet]);

    function stakeGovPressed(amount: string) {
        setHash({
            type: "GovStake",
            isPending: true,
        });

        stakeGov(wallet!.terraAddress, amount)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "GovStake",
                    response: response,
                    redirect: PATHS.governance,
                });
            })
            .catch((error) => {
                setHash({
                    type: "GovStake",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function unstakeGovPressed(amount: string) {
        setHash({
            type: "GovUnstake",
            isPending: true,
        });

        unstakeGov(wallet!.terraAddress, amount)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "GovUnstake",
                    response: response,
                    redirect: PATHS.governance,
                });
            })
            .catch((error) => {
                console.log(error);
                setHash({
                    type: "GovUnstake",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    return (
        <div className={style.container}>
            {hash !== undefined ? (
                <PageHeader title="Governance Stake" docs="" />
            ) : (
                <div />
            )}
            <TxBoxView
                transparent={true}
                hash={hash}
                clearHash={() => {
                    setHash(undefined);
                }}
            >
                <Header info={stakeInfo} vpTokenBalance={balance.vp} />

                <PageHeader title="Governance Stake" docs="" />

                <div className={style.tradetab}>
                    <TabBox
                        titleLeft="Stake"
                        titleRight="Unstake"
                        tabIndex={tabIndex}
                        tabChanged={(index) => {
                            setTabIndex(index);
                        }}
                    />

                    <div className={style.tradetab_bottom}>
                        {tabIndex === 0 ? (
                            <StakeGovernanceStake
                                token={balance.token}
                                pressed={stakeGovPressed}
                            />
                        ) : (
                            <StakeGovernanceUnstake
                                token={stakeInfo ? stakeInfo.unstakable : "0"}
                                locked={stakeInfo ? stakeInfo.vote_locked : "0"}
                                pressed={unstakeGovPressed}
                            />
                        )}
                    </div>
                </div>
            </TxBoxView>
        </div>
    );
}

function Header(props: { info?: StakeGovHeaderInfo; vpTokenBalance?: string }) {
    const [totalStaked, setTotalStaked] = useState<
        AmountSplitModel | undefined
    >(undefined);
    const [myStaked, setMyStaked] = useState<AmountSplitModel | undefined>(
        undefined
    );
    const [myStakable, setMyStakable] = useState<AmountSplitModel | undefined>(
        undefined
    );
    const [myVP, setMyVP] = useState<AmountSplitModel | undefined>(undefined);

    useEffect(() => {
        if (props.info && props.vpTokenBalance) {
            setTotalStaked(Utils.splitAmountByDot(props.info.totalStaked));
            setMyStaked(Utils.splitAmountByDot(props.info.staked));
            setMyStakable(Utils.splitAmountByDot(props.info.stakable));
            setMyVP(Utils.splitAmountByDot(props.vpTokenBalance));
        }
    }, [props.info, props.vpTokenBalance]);
    return (
        <div className={style.header_container}>
            <div className={style.item_container}>
                <PageHeader title="Overview" docs="" />

                <div className={style.row}>
                    <div className={style.item}>
                        <TitleLabel
                            text="APR"
                            color={"white"}
                            size={14}
                            fontWeight={"light"}
                        />
                        <div style={{ height: "8px" }} />

                        <TitleLabel
                            size={30}
                            color={"#ffffff"}
                            text={
                                props.info
                                    ? Utils.getPercentFormat(props.info.apr) +
                                      " %"
                                    : undefined
                            }
                            symbol=""
                            skeleton={{ width: 100 }}
                        />
                    </div>

                    <div className={style.overview_dummy} />
                    <div className={style.number_item}>
                        <TitleLabel
                            text="Total Staked"
                            color={"#ffffff"}
                            size={14}
                            fontWeight={"light"}
                        />
                        <div style={{ height: "8px" }} />
                        <div className={style.item}>
                            <TitleLabel
                                size={30}
                                fontWeight={"medium"}
                                text={
                                    totalStaked ? totalStaked.num1 : undefined
                                }
                                color={"#ffffff"}
                                symbol={
                                    totalStaked && totalStaked.num2
                                        ? "." + totalStaked.num2 + " VKR"
                                        : "VKR"
                                }
                                skeleton={{ width: 100 }}
                            />
                        </div>
                    </div>

                    {/* <div style={{ flex: 1 }} /> */}
                </div>
            </div>
            <div className={style.spacer} />
            <div className={style.item_container}>
                <PageHeader title="My Value" docs="" />
                <div className={style.row}>
                    <div className={style.number_item}>
                        <TitleLabel
                            text="Staked"
                            color={"#ffffff"}
                            size={14}
                            fontWeight={"light"}
                        />
                        <div style={{ height: "7px" }} />

                        <TitleLabel
                            size={16}
                            fontWeight={"medium"}
                            text={myStaked ? myStaked.num1 : undefined}
                            color={"#ffe646"}
                            symbol={
                                myStaked && myStaked.num2
                                    ? "." + myStaked.num2 + " VKR"
                                    : "VKR"
                            }
                            skeleton={{ width: 100 }}
                        />
                    </div>
                    <div className={style.dummy} />
                    <div className={style.number_item}>
                        <TitleLabel
                            text="Stakable"
                            color={"#ffffff"}
                            size={14}
                            fontWeight={"light"}
                        />
                        <div style={{ height: "7px" }} />

                        <TitleLabel
                            size={16}
                            fontWeight={"medium"}
                            text={myStakable ? myStakable.num1 : undefined}
                            color={"#ffe646"}
                            symbol={
                                myStakable && myStakable.num2
                                    ? "." + myStakable.num2 + " VKR"
                                    : "VKR"
                            }
                            skeleton={{ width: 100 }}
                        />
                    </div>
                    <div className={style.dummy} />
                    <div className={style.vp_item}>
                        <div className={style.number_item}>
                            <TitleLabel
                                text="VP"
                                color={"#ffffff"}
                                size={14}
                                fontWeight={"light"}
                            />
                            <div style={{ height: "7px" }} />

                            <TitleLabel
                                size={16}
                                fontWeight={"medium"}
                                text={myVP ? myVP.num1 : undefined}
                                color={"#ffe646"}
                                symbol={
                                    myVP && myVP.num2
                                        ? "." + myVP.num2 + " VP"
                                        : "VP"
                                }
                                skeleton={{ width: 100 }}
                            />
                        </div>
                        <div className={style.required_createvp}>
                            <CreateVpButton rounded={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
