import TitleLabel from "../../../common/Labels/TitleLabel";
import SymbolIcon from "../../../common/SymbolIcon/SymbolIcon";
import style from "./CampaignDetailTitle.module.scss";

export default function CampaignDetailTitle(props: {
    summary: CampaignSummary;
}) {
    const reward = props.summary ? props.summary.reward : undefined;
    const config = props.summary ? props.summary.config : undefined;

    const pSymbol = reward ? reward.participation.symbol : undefined;
    const pDenom = reward ? reward.participation.denom : undefined;
    const pName = reward ? reward.participation.name : undefined;

    const title = config ? config.title : "";

    return (
        <div className={style.container}>
            <div className={style.symbols}>
                <div className={style.symbol}>
                    <SymbolIcon
                        denom={pDenom}
                        isActive={props.summary.state.is_active}
                    />
                </div>
                <div className={style.symboltitle}>
                    <div className={style.symboltitle_symbol}>
                        <TitleLabel
                            fontWeight={"bold"}
                            text={pSymbol}
                            size={20}
                            color={"#fff"}
                        />
                    </div>
                    <div className={style.symboltitle_title}>
                        <TitleLabel
                            fontWeight={"medium"}
                            text={pName}
                            size={12}
                            color={"#fff"}
                        />
                    </div>
                </div>
            </div>
            <div className={style.titles}>
                <div className={style.title}>
                    <TitleLabel
                        fontWeight={"medium"}
                        text={title}
                        size={28}
                        color={"#fff"}
                    />
                </div>
                <div />
            </div>
        </div>
    );
}
