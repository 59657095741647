import { Link, useHistory, useLocation } from "react-router-dom";
import * as Utils from "../../libs/utils";
import style from "./HeaderPc.module.scss";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { balanceState, walletState } from "../../libs/contexts";
import { PATHS } from "../../App";
import { HistoryButton } from "../common/NormalButton/NormalButton";
import TestnetBadge from "../common/CampaignComponents/TestnetBadge";

const cx = classNames.bind(style);

export default function HeaderPc(props: {
    isCreator: boolean;
    connect: () => void;
    disconnect: () => void;
    menus: { title: string; path: string }[];
}) {
    const location = useLocation();

    return (
        <div className={style.container}>
            <Link className={style.logo} to={PATHS.home} />
            <TestnetBadge />
            <div className={style.spacer} />
            {props.menus.map((item, index) => {
                if (!props.isCreator && item.path === PATHS.producer) {
                    return <div key={index} />;
                }

                return (
                    <Menu
                        key={index}
                        title={item.title}
                        path={item.path}
                        selected={location.pathname.startsWith(item.path)}
                    />
                );
            })}

            <div className={style.spacer2} />

            <div
                className={style.docsbutton}
                onClick={() => {
                    Utils.showUrl("https://docs.valkyrieprotocol.com/webapp/");
                }}
            >
                <div className={style.docsicon} />
                <div className={style.docstitle}>{"Docs"}</div>
            </div>

            <Connect connect={props.connect} disconnect={props.disconnect} />
        </div>
    );
}

function Menu(props: { title: string; path: string; selected: boolean }) {
    return (
        <Link
            className={cx(style.menu, {
                selected: props.selected,
            })}
            to={props.path}
        >
            {props.title}
        </Link>
    );
}

function Connect(props: { connect: () => void; disconnect: () => void }) {
    const wallet = useRecoilValue(walletState);

    return (
        <div className={style.connect_container}>
            {wallet && wallet.terraAddress ? (
                <PcConnected
                    address={wallet.terraAddress}
                    disconnect={props.disconnect}
                />
            ) : (
                <PcConnect connect={props.connect} />
            )}
        </div>
    );
}

function PcConnect(props: { connect: () => void }) {
    return (
        <div className={style.connect} onClick={props.connect}>
            <div className={style.icon} />
            <div className={style.text}>{"Connect"}</div>
        </div>
    );
}

function PcConnected(props: { address: string; disconnect: () => void }) {
    const balance = useRecoilValue(balanceState);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (!props.address) {
            setShowPopup(false);
        }
    }, [props.address]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            const obj1 = document.getElementById("header_connected_popup");
            const obj2 = document.getElementById("header_connected");
            if (
                obj1 &&
                obj2 &&
                !(obj1 as any).contains(event.target) &&
                !(obj2 as any).contains(event.target)
            ) {
                setShowPopup(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowPopup]);

    return (
        <div
            id="header_connected"
            className={style.connected}
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <div
                className={style.button}
                onClick={() => {
                    setShowPopup(!showPopup);
                }}
            >
                <div className={style.icon1} />

                <div className={style.text1}>
                    {props.address.substring(0, 6) +
                        "..." +
                        props.address.substring(
                            props.address.length - 6,
                            props.address.length
                        )}
                </div>
                <div className={style.icon2} />

                <div className={style.text2}>
                    {Utils.getDecimal(balance.axlUSDC, true, 3) + " axlUSDC"}
                </div>
            </div>

            {showPopup ? (
                <Popup address={props.address} disconnect={props.disconnect} />
            ) : (
                <div />
            )}
        </div>
    );
}

function Popup(props: { address: string; disconnect: () => void }) {
    const history = useHistory();

    const [copyString, setCopyString] = useState("Copy");

    return (
        <div id="header_connected_popup" className={style.popup}>
            <div className={style.row0}>
                <div className={style.icon} />
                <div className={style.text}>{"My Wallet"}</div>
            </div>

            <div className={style.row1}>{props.address}</div>

            <div className={style.row2}>
                <div
                    className={style.copy_box}
                    onClick={() => {
                        Utils.copyString(props.address);
                        setCopyString("Copied");
                        setTimeout(() => {
                            setCopyString("Copy");
                        }, 1000);
                    }}
                >
                    <div className={style.copy_box_icon} />
                    <div className={style.copy_box_text}>{copyString}</div>
                </div>
            </div>

            <div className={style.row3}>
                <div className={style.dummy1} />
                <div className={style.dummy2} />
            </div>

            <div className={style.row4}>
                <div className={style.button}>
                    <HistoryButton
                        onClick={() => {
                            history.push(PATHS.history);
                        }}
                    />
                </div>
            </div>

            <div className={style.row5}>
                <div style={{ flex: 1 }} />
                <div className={style.button} onClick={props.disconnect}>
                    <div className={style.text}>{"Disconnect"}</div>
                    <div className={style.icon} />
                </div>
            </div>
        </div>
    );
}
