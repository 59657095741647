import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import style from "./TooltipIcon.module.scss";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";

const cx = classNames.bind(style);

export default function TooltipIcon(props: {
    tooltip: string;

    isCampaignfalse?: boolean;
    hovercheck?: boolean;
}) {

    const [tooltipPlace, setTooltipPlace] = useState(false);

    function onResize() {
        if (window.innerWidth <= 550) {
            setTooltipPlace(true);
        } else {
            setTooltipPlace(false);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    return (
        <span className={style.container}>
            <a
                className={cx(style.icon, {
                    campaignfalse: props.isCampaignfalse,
                    hovercheck: props.hovercheck,
                })}
                data-tooltip-content={props.tooltip}
                data-tooltip-id="custom-tooltip"
                data-tooltip-place={tooltipPlace ? "right" : "top"}
                data-tooltip-className={style.tooltip}
                data-tooltip-delayHide={0}
            />

            <Tooltip id="custom-tooltip" className={style.tooltip}/>
            
        </span>
    );
}
