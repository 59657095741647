import {
    CreateTxOptions,
    Fee,
    MsgExecuteContract,
} from "@terra-money/terra.js";
import { fixed, multiply } from "../../Math";
import { environment, getLcdClient, wasmExecutes } from "../api";
import { tpGov, tpToken, xtpToken } from "./query";

export async function tpForceGovReward(
    address: string,
    amount: string
): Promise<CreateTxOptions> {
    const exe: WasmExecute = {
        contract: tpToken,
        msg: {
            transfer: {
                recipient: tpGov,
                amount: fixed(amount, 0),
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe]);
}

export async function tpGovStake(
    address: string,
    amount: string
): Promise<CreateTxOptions> {
    const exe: WasmExecute = {
        contract: tpToken,
        msg: {
            send: {
                contract: tpGov,
                amount: fixed(amount, 0),
                msg: btoa(
                    JSON.stringify({
                        stake_token: {},
                    })
                ),
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe]);
}

export async function tpGovUnstake(
    address: string,
    amount: string
): Promise<CreateTxOptions> {
    const exe: WasmExecute = {
        contract: xtpToken,
        msg: {
            send: {
                contract: tpGov,
                amount: fixed(amount, 0),
                msg: btoa(
                    JSON.stringify({
                        unstake_governance_token: {},
                    })
                ),
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe]);
}

export async function tpGovClaim(address: string): Promise<CreateTxOptions> {
    const exe: WasmExecute = {
        contract: tpGov,
        msg: {
            claim_unstaked_token: {},
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe]);
}

export async function tpCreatePoll(
    address: string,
    info: { title: string; desc: string; link?: string }
): Promise<CreateTxOptions> {
    const subMsg = {
        create_poll: {
            title: info.title,
            description: info.desc,
            link: info.link ? info.link : undefined,
            executions: [],
        },
    };

    const exe1: WasmExecute = {
        contract: xtpToken,
        msg: {
            send: {
                contract: tpGov,
                amount: "10000000000",
                msg: btoa(JSON.stringify(subMsg)),
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe1]);
}

export async function tpPollVote(
    address: string,
    id: number,
    vote: "yes" | "no" | "abstain",
    amount: string
): Promise<CreateTxOptions> {
    const exe: WasmExecute = {
        contract: tpGov,
        msg: {
            cast_vote: {
                poll_id: id,
                vote: vote,
                amount: amount,
            },
        },
        coin: undefined,
    };

    return wasmExecutes(address, [exe]);
}

export async function tpEndPoll(
    address: string,
    id: number
): Promise<CreateTxOptions> {
    const exe: WasmExecute = {
        contract: tpGov,
        msg: {
            end_poll: {
                poll_id: id,
            },
        },
        coin: undefined,
    };

    return wasmExecutes(address, [exe]);
}

export async function tpExecutePoll(
    address: string,
    id: number
): Promise<CreateTxOptions> {
    const exe: WasmExecute = {
        contract: tpGov,
        msg: {
            execute_poll: {
                poll_id: id,
            },
        },
        coin: undefined,
    };

    return wasmExecutes(address, [exe]);
}

export async function tpTryTransferSimulate(
    address: string,
    amount: string
): Promise<Fee> {
    const exes: MsgExecuteContract[] = [
        new MsgExecuteContract(
            address,
            xtpToken,
            {
                transfer: {
                    recipient:
                        "terra19ux80phz3ln2z2g66stvdveshhkfen3mnxh4d09ghnw0vdnn393q0j4c4q",
                    amount: fixed(amount, 0),
                },
            },
            undefined
        ),
    ];

    const acc = await getLcdClient().auth.accountInfo(address);

    const fee = await getLcdClient().tx.estimateFee(
        [
            {
                sequenceNumber: acc.getSequenceNumber(),
                publicKey: acc.getPublicKey(),
            },
        ],
        {
            msgs: exes,
            gasPrices: { uluna: "0.015" },
            gasAdjustment: "1.2",
            feeDenoms: ["uluna"],
        }
    );

    return fee;
}
