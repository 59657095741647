import style from "./TPTCampaignDetail.module.scss";
import { useEffect, useState } from "react";
import {
    claimReferralDeposit,
    claimParticipationReward,
} from "../../../../libs/api/campaign/execution";
import { tptRequirement } from "../../../../libs/api/campaign/query";
import CampaignDetailPoolHistory from "../../../common/CampaignComponents/CampaignDetailPoolHistory";

import { multiply } from "../../../../libs/Math";
import TxBoxView from "../../../common/TxBoxView/TxBoxView";
import { useWallet } from "@terra-money/wallet-provider";
import { useRecoilValue } from "recoil";
import { blockState, walletState } from "../../../../libs/contexts";
import classNames from "classnames/bind";
import TPTInfo from "./Info/TPTInfo";
import TPTParticipation from "./Participation/TPTParticipation";
import TPTCampaignAbout from "./About/TPTCampaignAbout";
import IPCDetailTitle from "./IPC/Title/IPCDetailTitle";
import TPTGuide from "./Guide/TPTGuide";
import TPTReward from "./Reward/TPTReward";
import TPTStandbyBanner from "./StandbyBanner/TPTStandbyBanner";
import CampaignDescription from "../Common/CampaignDescription";
import MySharing from "./MySharing";
import MyRewards from "./MyRewards";
import { joinTPT } from "../../../../libs/api/campaign/qualification/execution";
import TPTTierTable from "./TierTable/TPTTierTable";

const cx = classNames.bind(style);

export const parameter = "vkr";

export default function TPTCampaignDetail(props: {
    campaignAddress: string;
    summary: CampaignSummary;
    earlybird: boolean;
    refresh: () => void;
}) {
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);
    // const block = useRecoilValue(blockState);

    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    const [participated, setParticipated] = useState(false);
    // const [requirement, setRequirement] = useState<TPTRequirement | undefined>(
    //     undefined
    // );
    // const [started, setStarted] = useState(false);
    // const [started, setStarted] = useState(true);
    // const [refcheck, setRefcheck] = useState(false);

    useEffect(() => {
        if (
            props.summary &&
            props.summary.participation &&
            props.summary.participation.last_participated_at > 0
        ) {
            setParticipated(true);
        } else {
            setParticipated(false);
        }
    }, [props.summary]);

    // useEffect(() => {
    //     if (props.summary.config.qualifier) {
    //         tptRequirement(props.summary.config.qualifier ?? "")
    //             .then((r) => {
    //                 setRequirement(r);
    //             })
    //             .catch((e) => {});
    //     }
    // }, [props.summary]);

    // useEffect(() => {
    //     // if (requirement) {
    //     //     if (block.block >= requirement.start_block) {
    //     setStarted(true);
    //     //     }
    //     // }
    // }, [block, requirement]);

    // useEffect(() => {
    //     const r = getReferrer();

    //     if (r !== undefined) {
    //         setRefcheck(true);
    //     } else {
    //         setRefcheck(false);
    //     }
    // }, []);

    // function getReferrer() {
    //     const params = new URLSearchParams(window.location.search);

    //     const ref = params.get(parameter);
    //     const value = ref ?? "";

    //     const r = value.replaceAll(" ", "+");

    //     if (r !== "") {
    //         return r;
    //     } else {
    //         return undefined;
    //     }
    // }

    // function ipcParticipate(token: string) {
    //     if (wallet) {
    //         setHash({
    //             type: "IPCParticipate",
    //             isPending: true,
    //         });

    //         joinTPT(
    //             props.campaignAddress,
    //             wallet!.terraAddress,
    //             multiply(token, 1000000),
    //             getReferrer()
    //         )
    //             .then((r) => {
    //                 return post(r);
    //             })
    //             .then((r) => {
    //                 setHash({
    //                     type: "IPCParticipate",
    //                     response: r,
    //                     redirect: undefined,
    //                     callback: (success: boolean) => {
    //                         if (success) {
    //                             props.refresh();
    //                         }
    //                     },
    //                 });
    //             })
    //             .catch((e) => {
    //                 setHash({
    //                     type: "IPCParticipate",
    //                     error: e,
    //                     redirect: undefined,
    //                 });
    //             });
    //     }
    // }

    function claimPReward() {
        if (props.campaignAddress && wallet) {
            setHash({
                type: "IPCClaim",
                isPending: true,
            });

            claimParticipationReward(props.campaignAddress, wallet.terraAddress)
                .then((r) => {
                    return post(r);
                })
                .then((r) => {
                    setHash({
                        type: "IPCClaim",
                        response: r,
                        redirect: undefined,
                    });
                })
                .catch((e) => {
                    setHash({
                        type: "IPCClaim",
                        error: e,
                        redirect: undefined,
                    });
                });
        }
    }

    function claimReferralDepositReward() {
        setHash({
            type: "CampaignClaimOne",
            isPending: true,
        });

        claimReferralDeposit(props.campaignAddress, wallet!.terraAddress)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "CampaignClaimOne",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                setHash({
                    type: "CampaignClaimOne",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    return (
        <div className={style.wrap}>
            {hash === undefined ? <div className={style.bg_footer} /> : <div />}

            <div className={cx(style.container, { hash: hash !== undefined })}>
                <TxBoxView
                    hash={hash}
                    clearHash={() => {
                        setHash(undefined);
                    }}
                    transparent={true}
                >
                    {/* {!started ? (
                        <div className={style.ipcbeforeopen}>
                            <TPTStandbyBanner
                                earlybird={props.earlybird}
                                requirement={requirement}
                            />
                        </div>
                    ) : (
                        <div />
                    )} */}

                    <IPCDetailTitle
                        earlybird={props.earlybird}
                        summary={props.summary}
                    />

                    {participated ? (
                        <TPTReward
                            campaignAddress={props.campaignAddress}
                            summary={props.summary}
                            claimParticipationReward={claimPReward}
                        />
                    ) : (
                        <div />
                    )}

                    {participated ? (
                        <div className={style.header_container}>
                            <MyRewards
                                summary={props.summary}
                                campaignAddress={props.campaignAddress}
                                claimPressed={claimReferralDepositReward}
                            />
                            <MySharing
                                summary={props.summary}
                                campaignAddress={props.campaignAddress}
                            />
                        </div>
                    ) : (
                        <div />
                    )}

                    <TPTGuide earlybird={props.earlybird} />

                    {props.earlybird === true ? <TPTTierTable /> : <div />}

                    {participated === true ? (
                        <div />
                    ) : (
                        <TPTParticipation
                            summary={props.summary}
                            earlybird={props.earlybird}
                            campaignAddress={props.campaignAddress}
                            // requirement={requirement}
                            // started={started}
                            // ipcParticipate={ipcParticipate}
                            // refcheck={refcheck}
                        />
                    )}
                    {participated === true ? (
                        <div style={{ marginTop: 40 }} />
                    ) : (
                        <div />
                    )}

                    <TPTInfo
                        campaignAddress={props.campaignAddress}
                        summary={props.summary}
                        earlybird={props.earlybird}
                    />

                    <TPTCampaignAbout />

                    <CampaignDescription
                        summary={props.summary}
                        isIpc={true}
                        title={"Terra Poker"}
                    />

                    {/* <CampaignDetailPoolHistory
                        campaignAddress={props.campaignAddress}
                    /> */}
                </TxBoxView>
            </div>
        </div>
    );
}
