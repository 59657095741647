import { blockPerDay, environment, wasmExecutes } from "../api";
import * as Utils from "../../utils";
import {
    campaignDeposit,
    campaignSummary,
    refineCampaignDeposit,
} from "./query";
import { fixed, leftGreaterThanRight, multiply } from "../../Math";
import { CreateTxOptions } from "@terra-money/terra.js";

export async function createCampaign(
    address: string,
    title: string,
    url: string,
    desc: string,
    rewardDenom: {
        native?: string;
        token?: string;
    },
    parameterKey: string,

    referral_reward_amounts: string[],
    deposit: string,
    depositUnlockPeriodDay: string | undefined,
    participation_reward_distribution_schedule: string,
    referral_reward_lock_period: number,
    vpBurnAmount: string,
    qualification?: string,
    qualificationDesc?: string
) {
    let pSchedule: { startDay: number; endDay: number; amount: string }[] =
        JSON.parse(participation_reward_distribution_schedule);

    const submsg = {
        title: title,
        description: desc,
        url: url,
        parameter_key: parameterKey.toLowerCase(),
        participation_reward_denom: {
            native: rewardDenom.native,
            token: rewardDenom.token,
        },
        participation_reward_distribution_schedule: pSchedule.map((item) => {
            return [
                item.startDay * blockPerDay,
                item.endDay * blockPerDay,
                fixed(multiply(item.amount, 1000000), 0),
            ];
        }),
        referral_reward_amounts: referral_reward_amounts,
        referral_reward_lock_period: referral_reward_lock_period,
    };

    const msg1 = {
        create_campaign: {
            config_msg: btoa(JSON.stringify(submsg)),
            qualifier: qualification,
            qualification_description: qualificationDesc,
            deposit_denom: {
                token: environment().contracts.token,
            },
            deposit_amount: deposit,
            deposit_lock_period: depositUnlockPeriodDay
                ? parseInt(depositUnlockPeriodDay) * blockPerDay
                : undefined,
            vp_burn_amount: vpBurnAmount,
        },
    };

    let exes: WasmExecute[] = [];
    exes.push({
        contract: environment().contracts.campaignManager,
        msg: msg1,
        coin: undefined,
    });

    return await wasmExecutes(address, exes);
}

export async function updateCampaignInfo(
    address: string,
    pending: boolean,
    campaign: string,
    title: string,
    desc: string,
    url?: string,
    parameterKey?: string,
    deposit?: string,
    depositUnlockPeriodDay?: string,
    pReward?: string,
    rReward?: string[],
    pRewardUnlockPeriodDay?: string,
    rRewardUnlockPeriodDay?: string
) {
    const exes: WasmExecute[] = [];
    exes.push({
        contract: campaign,
        msg: {
            update_campaign_config: {
                title: title,
                description: desc,
                url: pending ? url : undefined,
                parameter_key: pending ? parameterKey : undefined,
                deposit_amount: pending ? deposit : undefined,
                deposit_lock_period:
                    pending && depositUnlockPeriodDay
                        ? parseInt(depositUnlockPeriodDay) * blockPerDay
                        : undefined,
            },
        },
        coin: undefined,
    });

    if (
        pending &&
        (pReward || rReward || pRewardUnlockPeriodDay || rRewardUnlockPeriodDay)
    ) {
        exes.push({
            contract: campaign,
            msg: {
                update_reward_config: {
                    participation_reward_amount: pReward,
                    referral_reward_amounts: rReward,
                    participation_reward_lock_period: pRewardUnlockPeriodDay
                        ? parseInt(pRewardUnlockPeriodDay) * blockPerDay
                        : undefined,
                    referral_reward_lock_period: rRewardUnlockPeriodDay
                        ? parseInt(rRewardUnlockPeriodDay) * blockPerDay
                        : undefined,
                },
            },
            coin: undefined,
        });
    }

    return await wasmExecutes(address, exes);
}

export async function updateCampaignActivate(
    address: string,
    campaign: string,
    activate: boolean
) {
    const exe: WasmExecute = {
        contract: campaign,
        msg: {
            update_activation: {
                active: activate,
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe]);
}

export async function campaignPoolDeposit(
    address: string,
    campaignAddress: string,
    amount1: string,
    amount1Denom: string,
    amount2: string
): Promise<CreateTxOptions> {
    let exes: WasmExecute[] = [];

    if (Utils.isNativeToken(amount1Denom)) {
        exes.push({
            contract: environment().contracts.token,
            msg: {
                increase_allowance: {
                    spender: campaignAddress,
                    amount: fixed(amount2, 0),
                },
            },
            coin: undefined,
        });

        exes.push({
            contract: campaignAddress,
            msg: {
                add_reward_pool: {
                    participation_reward_amount: fixed(amount1, 0),
                    referral_reward_amount: fixed(amount2, 0),
                },
            },
            coin: fixed(amount1, 0) + amount1Denom,
        });
    } else {
        exes.push({
            contract: environment().contracts.token,
            msg: {
                increase_allowance: {
                    spender: campaignAddress,
                    amount: fixed(amount2, 0),
                },
            },
            coin: undefined,
        });

        exes.push({
            contract: amount1Denom,
            msg: {
                increase_allowance: {
                    spender: campaignAddress,
                    amount: fixed(amount1, 0),
                },
            },
            coin: undefined,
        });

        exes.push({
            contract: campaignAddress,
            msg: {
                add_reward_pool: {
                    participation_reward_amount: fixed(amount1, 0),
                    referral_reward_amount: fixed(amount2, 0),
                },
            },
            coin: undefined,
        });
    }

    return await wasmExecutes(address, exes);
}

export async function campaignPoolWithdraw(
    address: string,
    contract: string,
    amount: string,
    denom: string
): Promise<CreateTxOptions> {
    const isNative = Utils.isNativeToken(denom);

    const native: string | undefined = isNative ? denom : undefined;
    const token: string | undefined = !isNative ? denom : undefined;

    const exe: WasmExecute = {
        contract: contract,
        msg: {
            remove_reward_pool: {
                amount: fixed(amount, 0),
                denom: {
                    native: native,
                    token: token,
                },
            },
        },
        coin: undefined,
    };
    return await wasmExecutes(address, [exe]);
}

export async function campaignAllClaim(list: string[], address: string) {
    const exes: WasmExecute[] = [];
    for (let i = 0; i < list.length; i++) {
        const campaignAddress = list[i];

        const r = await campaignSummary(campaignAddress, address);

        if (r && r.participation) {
            if (
                leftGreaterThanRight(
                    r.participation.participation_reward.unlocked,
                    0
                )
            ) {
                exes.push({
                    contract: campaignAddress,
                    msg: {
                        claim_participation_reward: {},
                    },
                    coin: undefined,
                });
            }

            if (
                leftGreaterThanRight(
                    r.participation.referral_reward.unlocked,
                    0
                )
            ) {
                exes.push({
                    contract: campaignAddress,
                    msg: {
                        claim_referral_reward: {},
                    },
                    coin: undefined,
                });
            }
        }

        if (r && r.deposit && leftGreaterThanRight(r.deposit.claimable, 0)) {
            exes.push({
                contract: campaignAddress,
                msg: {
                    withdraw: {
                        amount: r.deposit.claimable,
                    },
                },
                coin: undefined,
            });
        }
    }

    return await wasmExecutes(address, exes);
}

export async function campaignClaim(campaignAddress: string, address: string) {
    const r = await campaignSummary(campaignAddress, address);
    const exes: WasmExecute[] = [];
    if (r.participation) {
        if (
            leftGreaterThanRight(
                r.participation.participation_reward.unlocked,
                0
            )
        ) {
            exes.push({
                contract: campaignAddress,
                msg: {
                    claim_participation_reward: {},
                },
                coin: undefined,
            });
        }

        if (leftGreaterThanRight(r.participation.referral_reward.unlocked, 0)) {
            exes.push({
                contract: campaignAddress,
                msg: {
                    claim_referral_reward: {},
                },
                coin: undefined,
            });
        }
    }

    const deposit = await campaignDeposit(campaignAddress, address);
    const refined = await refineCampaignDeposit(deposit);

    if (leftGreaterThanRight(refined.claimable, 0)) {
        exes.push({
            contract: campaignAddress,
            msg: {
                withdraw: {
                    amount: refined.claimable,
                },
            },
            coin: undefined,
        });
    }

    return await wasmExecutes(address, exes);
}

export async function updateQualification(
    address: string,
    campaignAddress: string,
    qualificationAddress?: string,
    desc?: string
) {
    const exe1: WasmExecute = {
        contract: campaignAddress,
        msg: {
            update_campaign_config: {
                qualifier: qualificationAddress,
                qualification_description: desc,
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe1]);
}

export async function claimParticipationReward(
    campaignAddress: string,
    address: string
) {
    const summary = await campaignSummary(campaignAddress, address);
    const participation = summary.participation;

    const exes: WasmExecute[] = [];

    if (participation) {
        if (
            leftGreaterThanRight(participation.participation_reward.unlocked, 0)
        ) {
            exes.push({
                contract: campaignAddress,
                msg: {
                    claim_participation_reward: {},
                },
                coin: undefined,
            });
        }
    }

    return await wasmExecutes(address, exes);
}

export async function claimReferralDeposit(
    campaignAddress: string,
    address: string
) {
    const summary = await campaignSummary(campaignAddress, address);
    const participation = summary.participation;
    const deposit = summary.deposit;

    const exes: WasmExecute[] = [];
    if (participation) {
        if (leftGreaterThanRight(participation.referral_reward.unlocked, 0)) {
            exes.push({
                contract: campaignAddress,
                msg: {
                    claim_referral_reward: {},
                },
                coin: undefined,
            });
        }
    }

    if (deposit && leftGreaterThanRight(deposit.claimable, 0)) {
        exes.push({
            contract: campaignAddress,
            msg: {
                withdraw: {
                    amount: deposit.claimable,
                },
            },
            coin: undefined,
        });
    }

    return await wasmExecutes(address, exes);
}
