import style from "./TokenAddPopup.module.scss";
import TitleLabel from "../../common/Labels/TitleLabel";
import ConfirmButton from "../../common/ConfirmButton/ConfirmButton";
import {
    NormalButton,
    SellButton,
    YellowButton,
} from "../../common/NormalButton/NormalButton";
import { tokenInfo } from "../../../libs/api/api";
import { useState } from "react";

export default function TokenAddPopup(props: {
    showTokenAddPopup: boolean;
    setShowTokenAddPopup: (v: boolean) => void;
    add: (v: { value: string; title: string }) => void;
}) {
    const [error, setError] = useState("");
    const [contract, setContract] = useState("");

    function cancelPressed() {
        props.setShowTokenAddPopup(false);
    }

    function addPressed() {
        tokenInfo(contract)
            .then((r) => {
                props.add({
                    title: r.symbol,
                    value: contract,
                });
                props.setShowTokenAddPopup(false);
            })
            .catch((e) => {
                setError("invalid address");
            });
        // props.setShowTokenAddPopup(false)
    }

    if (error) {
        return (
            <div className={style.container}>
                <div className={style.error}>
                    <div className={style.title}>{"error"}</div>
                    <div className={style.body}>{error}</div>

                    <div className={style.buttons}>
                        <div className={style.button}>
                            <YellowButton
                                title={"Ok"}
                                onClick={() => {
                                    setError("");
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={style.container}>
            <div className={style.add_popup}>
                <TitleLabel
                    size={14}
                    text={"+ Token Contract"}
                    color={"white"}
                    fontWeight={"bold"}
                />

                <div style={{ height: "20px" }} />

                <textarea
                    maxLength={64}
                    placeholder={
                        "Please enter the CW20 Contract address for the reward."
                    }
                    value={contract}
                    onChange={(e) => {
                        let value = e.target.value;
                        setContract(value);
                    }}
                />

                <div className={style.paste}>
                    <div className={style.icon} />
                    <div className={style.text}>{"Paste"}</div>
                </div>

                <div className={style.buttons}>
                    <div className={style.button}>
                        <NormalButton
                            title={"Cancel"}
                            onClick={cancelPressed}
                        />
                    </div>
                    <div className={style.button}>
                        <YellowButton
                            disabled={contract === ""}
                            title={"Add"}
                            onClick={addPressed}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
