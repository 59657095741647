import { useEffect, useState } from "react";
import { splitAmountByDot } from "../../../libs/utils";
import TitleLabel from "../../common/Labels/TitleLabel";
import MyDistributionExplainPopup from "./MyDistributionExplainPopup";
import style from "./MyDistributionRewardsTotal.module.scss";

export default function MyDistributionRewardsTotal(props: {
    tokenAmount: string;
    claimedAmount: string;
}) {
    const [showExplainPopup, setShowExplainPopup] = useState(-1);

    const [tokenAmountSplit, setTokenAmountSplit] = useState<AmountSplitModel>({
        num1: "0",
        num2: undefined,
    });

    const [claimedAmountSplit, setClaimedAmountSplit] =
        useState<AmountSplitModel>({
            num1: "0",
            num2: undefined,
        });

    useEffect(() => {
        setTokenAmountSplit(splitAmountByDot(props.tokenAmount));
    }, [props.tokenAmount]);

    useEffect(() => {
        setClaimedAmountSplit(splitAmountByDot(props.claimedAmount));
    }, [props.claimedAmount]);

    useEffect(() => {
        const body: any = document.querySelector("body");
        body.style.overflow = showExplainPopup !== -1 ? "hidden" : "auto";
    }, [showExplainPopup]);

    return (
        <div className={style.container}>
            <TitleLabel
                text={"My Total Distribution"}
                size={14}
                color={"#fff"}
                fontWeight={"light"}
            />

            <div
                className={style.mytotal_amount}
                onClick={() => {
                    setShowExplainPopup(1);
                }}
            >
                {tokenAmountSplit.num1}
                <span>
                    {tokenAmountSplit.num2 !== undefined
                        ? "." + tokenAmountSplit.num2 + " VKR"
                        : " VKR"}
                </span>
            </div>

            <div className={style.mytotal_line} />
            <TitleLabel
                text={"Claimed"}
                size={12}
                color={"#fff"}
                fontWeight={"light"}
            />

            <div className={style.mytotal_claimed}>
                {claimedAmountSplit.num1}
                <span>
                    {claimedAmountSplit.num2 !== undefined
                        ? "." + claimedAmountSplit.num2 + " VKR"
                        : " VKR"}
                </span>
            </div>

            {showExplainPopup !== -1 ? (
                <MyDistributionExplainPopup
                    show={showExplainPopup}
                    setShow={setShowExplainPopup}
                />
            ) : (
                <div />
            )}
        </div>
    );
}
