import { multiply, plus } from "../../Math";
import { environment } from "../api";

import { queryBalances, queryPrice } from "../trade/query";
import { govState } from "../governance/query";
import * as astroport from "../astroport_liquidity/query";

export async function myTotalValue(
    address: string | undefined
): Promise<MyPageValue> {
    const vkrPrice = await queryPrice(environment().contracts.token);
    const balances = await queryBalances(address ? address : "");

    const gov = await govState(address);
    const govUsdc = multiply(gov.staked, vkrPrice);

    const lp = await astroport.stakeLpInfo(address);
    const pools = await astroport.queryLpEstimateParams();
    const lpInfo = await astroport.unstakeLpEstimate(
        pools.pools,
        lp.staked,
        pools.lp_total_supply
    );
    const lpUsdc = plus(lpInfo.axlUSDC, multiply(lpInfo.token, vkrPrice));
    const holdingUsdc = multiply(balances.token, vkrPrice);

    const total = plus(
        plus(plus(balances.axlUSDC, holdingUsdc), govUsdc),
        lpUsdc
    );

    return {
        total: total,
        balance: {
            token: balances.token,
            tokenAxlUSDC: holdingUsdc,
            axlUSDC: balances.axlUSDC,
        },
        lp: {
            amount: lp.staked,
            axlUSDC: lpUsdc,
        },
        gov: {
            amount: gov.staked,
            axlUSDC: govUsdc,
        },
    };
}
