import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { participationCampaignList } from "../../libs/api/rest";
import { walletState } from "../../libs/contexts";
import TitleLabel from "../common/Labels/TitleLabel";
import style from "./MyRewards.module.scss";
import MyRewardsCampaignItem from "./MyRewardsCampaignItem";

export default function MyRewards(props: { claimPressed: () => void }) {
    const wallet = useRecoilValue(walletState);

    const [list, setList] = useState<
        { ipc: string[]; campaign: string[] } | undefined
    >(undefined);

    useEffect(() => {
        // true : ipc
        if (wallet) {
            loadList()
                .then((r) => {
                    setList(r);
                })
                .catch((e) => {});
        }
    }, [wallet]);

    async function loadList() {
        const ipc = await participationCampaignList(
            wallet ? wallet.terraAddress : undefined,
            true
        );
        const campaign = await participationCampaignList(
            wallet ? wallet.terraAddress : undefined,
            false
        );

        return {
            ipc: ipc,
            campaign: campaign,
        };
    }

    return (
        <div className={style.container}>
            <TitleLabel
                text={"Campaign Rewards"}
                size={20}
                color={"#fff"}
                fontWeight={"light"}
            />

            <MyRewardsCampaignItem
                title={"IPC"}
                ipc={true}
                list={list ? list.ipc : []}
            />

            <MyRewardsCampaignItem
                title={"Campaigns"}
                ipc={false}
                list={list ? list.campaign : []}
            />
        </div>
    );
}
