import { useEffect, useState } from "react";
import * as Utils from "../../../libs/utils";
import style from "./StakeGovernance.module.scss";

import InputField from "../../common/InputField/InputField";
import ConfirmButton from "../../common/ConfirmButton/ConfirmButton";

import {
    fixed,
    isZero,
    leftGreaterThanRight,
    multiply,
} from "../../../libs/Math";
import TxInfoView from "../../common/TxInfoView/TxInfoView";
import { environment } from "../../../libs/api/api";
import { getFeeRaw } from "../../../libs/api/fee";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";
// import { getFeeNormal } from "../../../libs/api/fee";

export default function StakeGovernancepStake(props: {
    token: string;
    pressed: (amount: string) => void;
}) {
    const wallet = useRecoilValue(walletState);

    const [token, setToken] = useState("");
    const [error1, setError1] = useState<string | undefined>(undefined);

    const [fee, setFee] = useState("0");

    useEffect(() => {
        setToken("");
    }, []);

    useEffect(() => {
        if (leftGreaterThanRight(token, Utils.getDecimal(props.token))) {
            setError1("Insufficient balance");
        } else if (isZero(token)) {
            setError1("Amount must be greater than 0");
        } else {
            setError1(undefined);
        }
    }, [token, props.token]);

    return (
        <div className={style.inner_container}>
            <InputField
                title={"Amount"}
                type="amount"
                balance={props.token}
                symbol={"VKR"}
                onChanged={(v: string) => {
                    setToken(v);

                    let exes: WasmExecute[] = [];

                    let subMsg = {
                        stake_governance_token: {},
                    };

                    exes.push({
                        contract: environment().contracts.token,
                        msg: {
                            send: {
                                contract: environment().contracts.governance,
                                amount: fixed(multiply(v, "1000000"), 0),
                                msg: btoa(JSON.stringify(subMsg)),
                            },
                        },
                        coin: undefined,
                    });

                    getFeeRaw(
                        wallet?.terraAddress ? wallet.terraAddress : "",
                        exes
                    )
                        .then((r) => {
                            let uluna = "uluna";
                            setFee(r.amount.toString().replace(uluna, ""));
                        })
                        .catch((e) => {
                            setFee("0");
                        });
                }}
                value={token}
                error={error1}
                autofocus={true}
                showSymbolIcon={true}
            />

            <InfoView fee={fee} />

            <div className={style.spreaddummy} />

            <ConfirmButton
                title={"Stake"}
                enable={!error1 && token !== ""}
                onClick={() => {
                    props.pressed(multiply(token, 1000000));
                }}
            />
        </div>
    );
}

function InfoView(props: { fee: string }) {
    return <TxInfoView fee={props.fee} />;
}
