import { useEffect, useState } from "react";
import { campaignSummary } from "../../../libs/api/campaign/query";
import { PATHS } from "../../../App";
import { isWhitelistedCampaign } from "../../../libs/api/rest";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";
import { useRef } from "react";
import { environment } from "../../../libs/api/api";

import BaseCampaignDetail from "./Base/BaseCampaignDetail";
import TPTCampaignDetail from "./TPT/TPTCampaignDetail";
import TPSmartAirdrop from "./TPSmartAirdrop/TPSmartAirdrop";
import SayveCampaignDetail from "./Sayve/SayveCampaignDetail";

export const parameter = "vkr";

export default function CampaignDetail(props: any) {
    const wallet = useRecoilValue(walletState);
    const [campaignAddress, setCampaignAddress] = useState("");

    const [summary, setSummary] = useState<CampaignSummary | undefined>(
        undefined
    );
    const isLoading = useRef(false);

    useEffect(() => {
        const campaignAddress = props.match.params.campaignAddress;

        if (!campaignAddress) {
            props.history.replace(PATHS.campaigns);
            return;
        }

        setCampaignAddress(campaignAddress);
    }, [props.history, props.match.params]);

    useEffect(() => {
        if (isLoading.current || campaignAddress === "") {
            return;
        }

        isLoading.current = true;

        if (window.location.origin.includes("localhost")) {
            campaignSummary(
                campaignAddress,
                wallet ? wallet.terraAddress : undefined
            )
                .then((r) => {
                    isLoading.current = false;
                    setSummary(r);
                })

                .catch((error) => {
                    isLoading.current = false;
                    props.history.replace(PATHS.campaigns);
                });
        } else {
            isWhitelistedCampaign(campaignAddress).then((r) => {
                if (r) {
                    campaignSummary(
                        campaignAddress,
                        wallet ? wallet.terraAddress : undefined
                    )
                        .then((r) => {
                            isLoading.current = false;
                            setSummary(r);
                        })

                        .catch((error) => {
                            isLoading.current = false;
                            props.history.replace(PATHS.campaigns);
                        });
                } else {
                    isLoading.current = false;
                    props.history.replace(PATHS.campaigns);
                }
            });
        }
    }, [campaignAddress, wallet]);

    if (summary === undefined) {
        return <div />;
    }

    return (
        <div>
            {campaignAddress ===
            environment().contracts.sayve_early.campaign ? (
                <SayveCampaignDetail
                    campaignAddress={campaignAddress}
                    summary={summary}
                    earlybird={true}
                    refresh={() => {
                        campaignSummary(
                            campaignAddress,
                            wallet ? wallet.terraAddress : undefined
                        )
                            .then((r) => {
                                setSummary(r);
                            })
                            .catch((e) => {});
                    }}
                />
            ) : campaignAddress ===
              environment().contracts.sayve_public.campaign ? (
                <SayveCampaignDetail
                    campaignAddress={campaignAddress}
                    summary={summary}
                    earlybird={false}
                    refresh={() => {
                        campaignSummary(
                            campaignAddress,
                            wallet ? wallet.terraAddress : undefined
                        )
                            .then((r) => {
                                setSummary(r);
                            })
                            .catch((e) => {});
                    }}
                />
            ) : campaignAddress ===
              environment().contracts.terrapoker_smartairdrop.campaign ? (
                <TPSmartAirdrop
                    campaignAddress={campaignAddress}
                    summary={summary}
                />
            ) : campaignAddress ===
              environment().contracts.terrapoker_exclusive.campaign ? (
                <TPTCampaignDetail
                    campaignAddress={campaignAddress}
                    summary={summary}
                    earlybird={true}
                    refresh={() => {
                        campaignSummary(
                            campaignAddress,
                            wallet ? wallet.terraAddress : undefined
                        )
                            .then((r) => {
                                setSummary(r);
                            })
                            .catch((e) => {});
                    }}
                />
            ) : campaignAddress ===
              environment().contracts.terrapoker_nonmember.campaign ? (
                <TPTCampaignDetail
                    campaignAddress={campaignAddress}
                    summary={summary}
                    earlybird={false}
                    refresh={() => {
                        campaignSummary(
                            campaignAddress,
                            wallet ? wallet.terraAddress : undefined
                        )
                            .then((r) => {
                                setSummary(r);
                            })
                            .catch((e) => {});
                    }}
                />
            ) : (
                <BaseCampaignDetail
                    campaignAddress={campaignAddress}
                    summary={summary}
                />
            )}
        </div>
    );
}
