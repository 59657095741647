import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./SayveCampaignAbout.module.scss";

export default function SayveCampaignAbout() {
    return (
        <div className={style.container}>
            <div className={style.title}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"About"}
                    size={20}
                    color={"#fff"}
                />
            </div>
            <div className={style.contents}>
                <a
                    className={style.home}
                    href="https://www.sayve.money"
                    target="_blank"
                />
                {/* <a
                    className={style.twitter}
                    href="https://twitter.com/tp_terrapoker"
                    target="_blank"
                /> */}
                {/* <a
                    className={style.discord}
                    href="https://discord.com/invite/TPTofficial"
                    target="_blank"
                /> */}
                <a
                    className={style.telegram}
                    href="https://t.me/SayveProtocolChat"
                    target="_blank"
                />
            </div>
        </div>
    );
}
