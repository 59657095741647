import { bankBalance, environment, getSymbol, wasmQuery } from "../api";

import { divide, fixed, isZero, minus, multiply } from "../../Math";
import { Coin } from "@terra-money/terra.js";

export async function tokenSimulate(
    isBuy: boolean,
    amount: string,
    spread: number //0.01 === 1%
): Promise<ResponseSimulate> {
    const msg = isBuy
        ? {
              simulation: {
                  offer_asset: {
                      info: {
                          native_token: {
                              denom: environment().contracts.usdc_token,
                          },
                      },
                      amount: fixed(amount, 0),
                  },
              },
          }
        : {
              simulation: {
                  offer_asset: {
                      info: {
                          token: {
                              contract_addr: environment().contracts.token,
                          },
                      },
                      amount: fixed(amount, 0),
                  },
              },
          };

    const simulate: any = await wasmQuery(
        environment().contracts.astroport.pair,
        msg
    );

    const to: string = simulate.return_amount;
    const rate = divide(amount, to); // rate
    const tradingFee: string = simulate.commission_amount;
    const minimumReceive = multiply(to, minus(1, spread));

    return {
        to: to,
        rate: rate,
        tradingFee: tradingFee,
        minimumReceive: minimumReceive.toString(),
    };
}

export async function tokenReverseSimulate(
    isBuy: boolean,
    amount: string,
    spread: number
): Promise<ResponseSimulate> {
    const msg = isBuy
        ? {
              reverse_simulation: {
                  ask_asset: {
                      info: {
                          token: {
                              contract_addr: environment().contracts.token,
                          },
                      },
                      amount: fixed(amount, 0),
                  },
              },
          }
        : {
              reverse_simulation: {
                  ask_asset: {
                      info: {
                          native_token: {
                              denom: environment().contracts.usdc_token,
                          },
                      },
                      amount: fixed(amount, 0),
                  },
              },
          };

    const reverseSimulate: any = await wasmQuery(
        environment().contracts.astroport.pair,
        msg
    );

    const to: string = reverseSimulate.offer_amount;
    const rate = divide(to, amount); // rate
    const tradingFee: string = reverseSimulate.commission_amount;
    const minimumReceive = multiply(amount, minus(1, spread));

    return {
        to: to,
        rate: rate,
        tradingFee: tradingFee,
        minimumReceive: minimumReceive,
    };
}

export async function queryBalances(address: string) {
    const balance = (
        await queryNativeBalance(environment().contracts.usdc_token, address)
    ).amount;
    const tokenBalance = (await queryTokenBalance(undefined, address)).amount;
    const Luna = (await queryNativeBalance("uluna", address)).amount;
    const vp = (
        await queryTokenBalance(environment().contracts.vp_token, address)
    ).amount;

    return {
        axlUSDC: balance,
        token: tokenBalance,
        Luna: Luna,
        vp: vp,
    };
}

export async function queryNativeBalance(
    denom: string | undefined,
    address: string
): Promise<ResponseBalances> {
    const balances = await bankBalance(address);
    const coin: Coin | undefined = balances.get(
        denom ?? environment().contracts.usdc_token
    );

    if (coin) {
        return {
            amount: coin.amount.toString(),
            denom: coin.denom,
            symbol: await getSymbol(coin.denom),
        };
    } else {
        return {
            amount: "0",
            denom: denom ?? "uluna",
            symbol: await getSymbol(denom ?? "uluna"),
        };
    }
}

export async function queryTokenBalance(
    contract: string | undefined,
    address: string
): Promise<ResponseBalances> {
    const denom = contract ?? environment().contracts.token;
    const balance: string = (
        await wasmQuery(denom, {
            balance: {
                address: address,
            },
        })
    ).balance;

    return {
        amount: balance,
        denom: denom,
        symbol: await getSymbol(denom),
    };
}

async function queryUsdcPairInfo(tokenAddress: string): Promise<AstroPair> {
    return await wasmQuery(environment().contracts.astroport.factory, {
        pair: {
            asset_infos: [
                {
                    native_token: {
                        denom: environment().contracts.usdc_token,
                    },
                },
                {
                    token: {
                        contract_addr: tokenAddress,
                    },
                },
            ],
        },
    });
}

export async function queryPrice(tokenAddress: string): Promise<string> {
    try {
        const env = environment();

        let pairAddress = "";

        if (tokenAddress === env.contracts.token) {
            pairAddress = env.contracts.astroport.pair;
        } else if (tokenAddress === env.contracts.astroport.token) {
            pairAddress = env.contracts.astroport.pair;
        } else {
            pairAddress = await queryUsdcPairInfo(tokenAddress)
                .then((r) => {
                    return r.contract_addr;
                })
                .catch((e) => {
                    return "";
                });
        }

        if (pairAddress === "") {
            return "0";
        }

        const pairPool = await pool(pairAddress);

        if (pairPool.assets.length < 2) {
            return "0";
        }

        let token = "0";
        let axlUSDC = "0";

        pairPool.assets.forEach((item) => {
            if (item.denom === environment().contracts.usdc_token) {
                axlUSDC = item.amount;
            } else {
                token = item.amount;
            }
        });

        if (isZero(token)) {
            return "0";
        }

        return fixed(divide(axlUSDC, token), 18);
    } catch (e) {
        return "0";
    }
}

async function pool(pairContract: string): Promise<ResponsePairPool> {
    const pairPool: {
        assets: {
            info: {
                token?: {
                    contract_addr: string;
                };
                native_token?: {
                    denom: string;
                };
            };
            amount: string;
        }[];
        total_share: string;
    } = await wasmQuery(pairContract, {
        pool: {},
    });

    return {
        assets: pairPool.assets.map((item) => {
            return {
                denom: item.info.native_token
                    ? item.info.native_token.denom
                    : item.info.token!.contract_addr,
                amount: item.amount,
            };
        }),
        total_share: pairPool.total_share,
    };
}
