import { isZero, multiply } from "../../../../../libs/Math";
import { getDecimal } from "../../../../../libs/utils";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./TPSmartAirdropCampaignGuide.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(style);

export default function TPSmartAirdropCampaignGuide(props: {
    summary: CampaignSummary;
}) {
    const state = props.summary.state;
    const reward = props.summary.reward;

    const pFiltered = state.pool_balances.filter((item) => {
        return item.denom === reward.participation.denom;
    });
    const pReward = pFiltered.length > 0 ? pFiltered[0].amount : "0";
    const pSymbol = reward.participation.symbol;

    const rFiltered = state.pool_balances.filter((item) => {
        return item.denom === reward.referral.denom;
    });
    const rReward = rFiltered.length > 0 ? rFiltered[0].amount : "0";
    const rSymbol = reward.referral.symbol;

    return (
        <div className={style.container}>
            <div className={style.header}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"Info"}
                    size={20}
                    color={"#fff"}
                />

                <div className={style.participation_count}>
                    <div className={style.icon} />
                    <div className={style.text}>
                        {getDecimal(
                            multiply(
                                props.summary.state.participation_count,
                                1000000
                            ),
                            true,
                            0
                        )}
                    </div>
                </div>
            </div>

            <div className={style.contents}>
                {props.summary.state.is_active === true ? (
                    <div className={style.wavewrapper}>
                        <div className={style.wave}></div>
                        <div className={style.wave}></div>
                    </div>
                ) : (
                    <div />
                )}
                <div className={style.pooltitle}>
                    <div className={style.pooltitle_ico} />
                    <div className={style.pooltitle_text}>
                        <TitleLabel
                            fontWeight={"medium"}
                            text={"Campaign Pool"}
                            size={14}
                            color={"#fff"}
                        />
                    </div>
                </div>
                <div className={style.poolamount}>
                    <TitleLabel
                        size={16}
                        text={getDecimal(pReward, true, 6)}
                        symbol={pSymbol}
                        color={isZero(pReward) ? "#e94f4f" : "white"}
                    />

                    <div className={style.dummy}>&nbsp;{"/"}&nbsp;</div>

                    <TitleLabel
                        size={16}
                        text={getDecimal(rReward, true, 6)}
                        symbol={rSymbol}
                        color={isZero(rReward) ? "#e94f4f" : "white"}
                    />
                </div>
            </div>
            <div className={style.description}>
                {
                    "Please input a TX Hash that proves a maximum buy in of 200BB at any table in order to validate your participation."
                }
            </div>

            {props.summary.participation &&
            props.summary.participation.last_participated_at > 0 ? (
                <div />
            ) : (
                <div className={style.gotosite_container}>
                    <a
                        className={style.gotosite}
                        href={props.summary.config.url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className={style.text}>{"Go to Site"}</div>
                        <div className={style.arrowicon} />
                    </a>
                </div>
            )}
        </div>
    );
}
