import {
    divide,
    fixed,
    leftGreaterThanRight,
    leftLessThanRight,
    multiply,
} from "../../../../../libs/Math";
import { getDecimal } from "../../../../../libs/utils";
import ConfirmButton from "../../../../common/ConfirmButton/ConfirmButton";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import SayveDepositCheck, { ConditionType } from "./SayveDepositCheck";
import style from "./SayveParticipationFooter.module.scss";
import SayveRemaining from "./SayveRemaining";
import SayveVpCheck from "./SayveVpCheck";
import classNames from "classnames/bind";
import { environment } from "../../../../../libs/api/api";
import { useEffect, useState } from "react";
import SayveInputField from "../../../../common/InputField/Sayve/SayveInputField";
const cx = classNames.bind(style);

export default function SayveParticipationFooter(props: {
    summary: CampaignSummary;
    vkrCheck: boolean;
    vpCheck: boolean;
    lunaCheck: boolean;
    setVkrCheck: (v: boolean) => void;
    setVpCheck: (v: boolean) => void;
    setLunaCheck: (v: boolean) => void;
    tokenBalance: string;
    lunaBalance: string;
    vpBalance: string;
    lunaAmount: string;
    setLunaAmount: (v: string) => void;
    requirement?: SayveRequirement;
    error: string | undefined;
    earlybird: boolean;
    started: boolean;
    ipcParticipate: (token: string) => void;
}) {
    const [minLunaAmount, setMinLunaAmount] = useState("0");
    const [maxLunaAmount, setMaxLunaAmount] = useState("0");

    const [errorText, setErrorText] = useState<string | undefined>(undefined);

    useEffect(() => {
        setMinLunaAmount(
            props.earlybird
                ? environment().contracts.sayve_early.min_uluna_amount
                : environment().contracts.sayve_public.min_uluna_amount
        );

        if (props.earlybird) {
            if (!leftLessThanRight(props.vpBalance, 20000000000)) {
                setMaxLunaAmount("1500000000");
            } else if (!leftLessThanRight(props.vpBalance, 10000000000)) {
                setMaxLunaAmount("900000000");
            } else if (!leftLessThanRight(props.vpBalance, 3000000000)) {
                setMaxLunaAmount("600000000");
            } else {
                setMaxLunaAmount("300000000");
            }
        } else {
            setMaxLunaAmount(
                environment().contracts.sayve_public.max_uluna_amount
            );
        }
    }, [props.earlybird, props.vpBalance]);

    useEffect(() => {
        if (leftLessThanRight(maxLunaAmount, minLunaAmount)) {
            setErrorText("Available Swap cap 0 Luna");
        } else {
            setErrorText(
                "Available Swap cap " +
                    getDecimal(minLunaAmount, true, 0) +
                    " ~ " +
                    getDecimal(maxLunaAmount, true, 0) +
                    " LUNA"
            );
        }
    }, [minLunaAmount, maxLunaAmount]);

    return (
        <div className={style.container}>
            <div className={style.Sayvecondition}>
                <div className={style.conditiontitle}>
                    <TitleLabel
                        text={"Participate Condition Check"}
                        fontWeight="light"
                        size={16}
                    />
                </div>

                {/* <div className={style.vkrcondition}>
                    <SayveDepositCheck
                        status={
                            props.vkrCheck
                                ? ConditionType.confirm
                                : ConditionType.deny
                        }
                        statusSub={props.vkrCheck ? "" : "You need more VKR"}
                        balance={props.tokenBalance}
                        text={
                            props.vkrCheck
                                ? fixed(
                                      divide(
                                          props.summary.config.deposit.amount,
                                          1000000
                                      ),
                                      0
                                  )
                                : getDecimal(props.tokenBalance, true, 6)
                        }
                        lockDay={props.summary.config.deposit_lock_period_day.toString()}
                    />
                </div> */}

                <div
                    className={cx(style.vpcondition, {
                        public: props.earlybird === false,
                    })}
                >
                    {props.earlybird === true ? (
                        <SayveVpCheck
                            status={
                                props.vpCheck
                                    ? ConditionType.confirm
                                    : ConditionType.deny
                            }
                            statusSub={props.vpCheck ? "" : "You need more VP"}
                            balance={props.vpBalance}
                            text={
                                props.vpCheck
                                    ? fixed(
                                          divide(
                                              props.summary.config
                                                  .vp_burn_amount,
                                              1000000
                                          ),
                                          0
                                      )
                                    : getDecimal(props.vpBalance, true, 6)
                            }
                        />
                    ) : (
                        <div />
                    )}
                </div>

                <div className={style.swapcondition}>
                    <div className={style.swapconditiontitle}>
                        <div
                            className={cx(style.swapicon, {
                                enable: !props.lunaCheck,
                            })}
                        />
                        {props.lunaCheck ? (
                            <div className={style.swaptitle}>{"Swap"}</div>
                        ) : (
                            <div className={style.swaptitlewrap}>
                                <div className={style.swaptitlemain}>
                                    {"Swap"}
                                </div>
                                <div className={style.swaptitlesub}>
                                    {errorText}
                                </div>
                            </div>
                        )}
                    </div>
                    <SayveInputField
                        symbol="LUNA"
                        maxValue={maxLunaAmount}
                        value={props.lunaAmount}
                        balance={props.lunaBalance}
                        onChanged={(v: string) => {
                            let value = v;

                            if (value === "") {
                                props.setLunaCheck(false);
                                setErrorText(
                                    "Available Swap cap " +
                                        getDecimal(minLunaAmount, true, 0) +
                                        " ~ " +
                                        getDecimal(maxLunaAmount, true, 0) +
                                        " LUNA"
                                );
                            } else {
                                if (
                                    !leftGreaterThanRight(
                                        multiply(value, "1000000"),
                                        props.lunaBalance
                                    )
                                ) {
                                    if (
                                        !leftGreaterThanRight(
                                            multiply(value, "1000000"),
                                            maxLunaAmount
                                        ) &&
                                        !leftGreaterThanRight(
                                            minLunaAmount,
                                            multiply(value, "1000000")
                                        )
                                    ) {
                                        props.setLunaCheck(true);
                                        setErrorText(undefined);
                                    } else {
                                        props.setLunaCheck(false);
                                        setErrorText(
                                            "Available Swap cap " +
                                                getDecimal(
                                                    minLunaAmount,
                                                    true,
                                                    0
                                                ) +
                                                " ~ " +
                                                getDecimal(
                                                    maxLunaAmount,
                                                    true,
                                                    0
                                                ) +
                                                " LUNA"
                                        );
                                    }
                                }
                            }

                            props.setLunaAmount(value);
                        }}
                        error={props.error ? props.error : undefined}
                        autofocus={true}
                        errorText={errorText}
                    />
                </div>

                <div className={style.participationdummy} />

                {props.started ? (
                    <div className={style.participation_button_container}>
                        {props.lunaAmount !== "" &&
                        !leftGreaterThanRight(
                            minLunaAmount,
                            multiply(props.lunaAmount, 1000000)
                        ) &&
                        !leftLessThanRight(
                            maxLunaAmount,
                            multiply(props.lunaAmount, 1000000)
                        ) ? (
                            <div className={style.estimate_container}>
                                <div className={style.text}>
                                    {getDecimal(
                                        multiply(
                                            divide(
                                                props.lunaAmount,
                                                props.earlybird
                                                    ? environment().contracts
                                                          .sayve_early.lunaprice
                                                    : environment().contracts
                                                          .sayve_public
                                                          .lunaprice
                                            ),
                                            1000000
                                        ),
                                        true
                                    ) + " SAYVE"}
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}

                        <div className={style.participationbuttonwrap}>
                            <ConfirmButton
                                title="Participation"
                                onClick={() =>
                                    props.ipcParticipate(props.lunaAmount)
                                }
                                enable={
                                    props.vkrCheck &&
                                    props.vpCheck &&
                                    props.lunaCheck
                                }
                            />
                        </div>
                    </div>
                ) : (
                    <div className={style.remaining}>
                        <SayveRemaining
                            summary={props.summary}
                            earlybird={props.earlybird}
                            requirement={props.requirement}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
