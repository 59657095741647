import { useEffect, useState } from "react";
import style from "./PriceHeader.module.scss";
import { queryPrice } from "../../../libs/api/trade/query";
import TitleLabel from "../Labels/TitleLabel";
import {
    Chart as ChartJS,
    ChartOptions,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    BarElement,
    RadialLinearScale,
    TimeScale,
    Tooltip,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import * as Utils from "../../../libs/utils";
import {
    divide,
    fixed,
    isZero,
    leftGreaterThanRight,
    leftLessThanRight,
    minus,
    multiply,
} from "../../../libs/Math";

import iconU from "../../../resources/ico-graph-u.svg";
import iconD from "../../../resources/ico-graph-d.svg";
import iconN from "../../../resources/ico-graph-n.svg";
import { useCallback } from "react";
import { vkrPriceChart } from "../../../libs/api/rest";
import { useRef } from "react";
import { environment } from "../../../libs/api/api";
import { useRecoilValue } from "recoil";
import { refreshState } from "../../../libs/contexts";

import classNames from "classnames/bind";
import { TradeFilterButton } from "../NormalButton/NormalButton";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    BarElement,
    RadialLinearScale,
    TimeScale,
    Tooltip,
    Filler
);

const cx = classNames.bind(style);

export default function PriceHeader(props: { isDashboard?: boolean }) {
    const refresh = useRecoilValue(refreshState);

    const isLoading = useRef(false);
    const [selected, setSelected] = useState(0);

    const [info, setInfo] = useState<PriceHeaderInfo | undefined>(undefined);

    useEffect(() => {
        if (isLoading.current) {
            return;
        }

        isLoading.current = true;
        loadChart()
            .then((r) => {
                setInfo({
                    price: leftGreaterThanRight("1", r.price) ? "1" : r.price,
                    lastPrice: r.lastPrice,
                    rate: r.rate,
                    color: r.color,
                    icon: r.icon,
                    chartData: r.list,
                });
                isLoading.current = false;
            })
            .catch((e) => {
                isLoading.current = false;
            });
    }, [selected, refresh]);

    const loadChart = useCallback(async () => {
        const price = await queryPrice(environment().contracts.token);
        const p = fixed(multiply(price, 1000000), 0);

        const r = await vkrPriceChart(
            selected === 0 ? "day" : selected === 1 ? "week" : "month"
        );

        let rate = "0";
        let lastPrice: string = price;
        let color = "#786f64";
        let icon = iconN;

        if (r.length > 0) {
            lastPrice = multiply(r[r.length - 1].y, 1000000);
            if (lastPrice && !isZero(lastPrice)) {
                rate = divide(minus(p, lastPrice), lastPrice);
            }

            if (leftLessThanRight(rate, 0)) {
                color = "#a02d46";
                icon = iconD;
            } else if (leftGreaterThanRight(rate, 0)) {
                color = "#0ecb81";
                icon = iconU;
            } else {
                color = "#786f64";
                icon = iconN;
            }
        }

        return {
            price: p,
            lastPrice: lastPrice,
            rate: rate,
            color: color,
            icon: icon,
            list: r,
        };
    }, [selected]);

    return (
        <div
            className={cx(style.container, {
                trade: props.isDashboard === undefined,
            })}
        >
            <div
                className={cx(style.row0, {
                    trade: props.isDashboard === undefined,
                })}
            >
                <div className={style.col0}>
                    <div className={style.logo}>
                        <div className={style.icon} />
                    </div>

                    <div className={style.title}>
                        <TitleLabel
                            text={"VKR Price"}
                            color={"#fff"}
                            size={14}
                            fontWeight={"light"}
                        />

                        <div className={style.text}>
                            <TitleLabel
                                size={24}
                                text={
                                    info
                                        ? Utils.getDecimal(info.price, true, 6)
                                        : undefined
                                }
                                symbol="axlUSDC"
                                color={"white"}
                                skeleton={{ width: 100 }}
                            />

                            {info && info.lastPrice ? (
                                <div className={style.percent}>
                                    <img
                                        className={style.icon}
                                        src={info.icon}
                                        alt=""
                                    />
                                    <div>
                                        <TitleLabel
                                            text={
                                                Utils.getPercentFormat(
                                                    info.rate
                                                ).replace("-", "") + "%"
                                            }
                                            color={info.color}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                    </div>
                </div>

                <div className={style.col1} />
            </div>
            <div
                className={cx(style.row1, {
                    trade: props.isDashboard === undefined,
                })}
            >
                <Chart
                    isDashboard={props.isDashboard}
                    lineColor={"#ffe646"}
                    data={
                        info
                            ? info.chartData
                                  .map((item) => {
                                      return {
                                          x: item.x.toString(),
                                          y: item.y,
                                      };
                                  })
                                  .reverse()
                            : undefined
                    }
                    selected={selected}
                    isTrade={true}
                />
            </div>

            <div
                className={cx(style.row2, {
                    trade: props.isDashboard === undefined,
                })}
            >
                <div
                    className={cx(style.button, {
                        trade: props.isDashboard === undefined,
                    })}
                >
                    <TradeFilterButton
                        title="Day"
                        selected={selected === 0}
                        onClick={() => {
                            if (isLoading.current) {
                                return;
                            }
                            setSelected(0);
                        }}
                    />
                </div>
                <div className={style.dummy} />
                <div
                    className={cx(style.button, {
                        trade: props.isDashboard === undefined,
                    })}
                >
                    <TradeFilterButton
                        title="Week"
                        selected={selected === 1}
                        onClick={() => {
                            if (isLoading.current) {
                                return;
                            }
                            setSelected(1);
                        }}
                    />
                </div>
                <div className={style.dummy} />
                <div
                    className={cx(style.button, {
                        trade: props.isDashboard === undefined,
                    })}
                >
                    <TradeFilterButton
                        title="Month"
                        selected={selected === 2}
                        onClick={() => {
                            if (isLoading.current) {
                                return;
                            }
                            setSelected(2);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

function Chart(props: {
    isDashboard?: boolean;
    rate?: string;
    lineColor: any;
    data?: {
        x: number | string | Date | undefined;
        y: number | string | Date | undefined;
    }[];
    selected: number;
    isTrade?: boolean;
}) {
    if (!props.data || props.data.length === 0) {
        return <div />;
    }

    const tooltipFontColor = "#2d2d2d";
    const height = props.isDashboard ? 184 : 378;

    const bottomPadding = 20;
    const data = {
        datasets: [
            {
                fill: true,
                borderColor: props.lineColor,
                borderWidth: 3,
                tension: 0.55,
                pointRadius: 0,
                pointHoverRadius: 0,
                data: props.data,
                backgroundColor: (chart: any) => {
                    const ctx = chart.chart.ctx;
                    let bg = ctx.createLinearGradient(
                        0,
                        0,
                        0,
                        height - bottomPadding
                    );
                    if (props.isTrade !== undefined && props.isTrade) {
                        bg.addColorStop(0, "rgba(246,225,96, 0.2)");
                    } else {
                        bg.addColorStop(0, "#141416");
                    }

                    bg.addColorStop(1, "transparent");
                    return bg;
                },
            },
        ],
    };

    const options: ChartOptions<"line"> = {
        responsive: true,
        maintainAspectRatio: false,

        animation: { duration: 0 },
        interaction: {
            intersect: false,
            mode: "index" as const,
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                backgroundColor: "white",
                cornerRadius: 5,
                titleColor: tooltipFontColor,
                bodyColor: tooltipFontColor,
                padding: {
                    left: 10,
                    right: 10,
                    top: 8,
                    bottom: 8,
                },
                titleFont: {
                    size: 16,
                },
                bodyFont: {
                    size: 12,
                },

                callbacks: {
                    title: function (value) {
                        let title = "";
                        if (value) {
                            title = Utils.getDecimal(
                                multiply(value[0].parsed.y, 1000000),
                                true,
                                6
                            );
                        }

                        return title;
                    },
                    label: function (value) {
                        if (!value) {
                            return "";
                        }

                        const time = Number(value.label);

                        if (props.selected === 2) {
                            return Utils.dateFormat1(time);
                        } else {
                            return Utils.dateFormat2(time);
                        }
                    },
                },
            },
        },
        layout: {
            padding: {
                left: 30,
                right: 30,
                bottom: bottomPadding,
            },
        },

        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            },
        },
    };

    return <Line height={height} data={data} options={options} />;
}
