import { useWallet } from "@terra-money/wallet-provider";
import style from "./TestnetBadge.module.scss";

export default function TestnetBadge() {
    const { network } = useWallet();

    if (network.name.toLowerCase() === "mainnet") {
        return <div />;
    } else {
        return <div className={style.testnet_badge}>{network.name}</div>;
    }
}
