import { useEffect, useState } from "react";
import * as Utils from "../../../../libs/utils";
import style from "./StakeLpStake.module.scss";
import { stakeLpEstimate } from "../../../../libs/api/astroport_liquidity/query";
import { queryPrice } from "../../../../libs/api/trade/query";
import { environment } from "../../../../libs/api/api";
import InputField from "../../../common/InputField/InputField";
import ConfirmButton from "../../../common/ConfirmButton/ConfirmButton";

import {
    divide,
    fixed,
    isNumber,
    isZero,
    leftGreaterThanRight,
    multiply,
} from "../../../../libs/Math";
import TxInfoView from "../../../common/TxInfoView/TxInfoView";
import { useRecoilValue } from "recoil";
import { balanceState, walletState } from "../../../../libs/contexts";
import LineDummy from "../../../common/LineDummy/LineDummy";
import { getFeeRaw } from "../../../../libs/api/fee";

export default function StakeLpStakeAuto(props: {
    estimateParams?: ResponseLpEstimate;
    stakeLpAutoPressed: (token: string, axlUSDC: string) => void;
}) {
    const wallet = useRecoilValue(walletState);
    const balance = useRecoilValue(balanceState);

    const [price, setPrice] = useState("0");
    const [token, setToken] = useState("");
    const [axlUSDC, setAxlUSDC] = useState("");

    const [error1, setError1] = useState<string | undefined>(undefined);
    const [error2, setError2] = useState<string | undefined>(undefined);
    const [lpEstimated, setLpEstimated] = useState("0");

    const [fee, setFee] = useState("0");

    useEffect(() => {
        if (leftGreaterThanRight(token, Utils.getDecimal(balance.token))) {
            setError1("Insufficient balance");
        } else if (isZero(token)) {
            setError1("Amount must be greater than 0");
        } else {
            setError1(undefined);
        }
    }, [token, balance.token]);

    useEffect(() => {
        if (leftGreaterThanRight(axlUSDC, Utils.getDecimal(balance.axlUSDC))) {
            setError2("Insufficient balance");
        } else if (isZero(axlUSDC)) {
            setError2("Amount must be greater than 0");
        } else {
            setError2(undefined);
        }
    }, [axlUSDC, balance.axlUSDC]);

    useEffect(() => {
        if (token && axlUSDC && props.estimateParams) {
            stakeLpEstimate(
                {
                    token: props.estimateParams.pools.token,
                    axlUSDC: props.estimateParams.pools.axlUSDC,
                },
                {
                    token: multiply(token, 1000000),
                    axlUSDC: multiply(axlUSDC, 1000000),
                },
                props.estimateParams.lp_total_supply
            )
                .then((r) => {
                    setLpEstimated(r);
                })
                .catch((e) => {});
        } else {
            setLpEstimated("");
        }
    }, [token, axlUSDC, props.estimateParams]);

    return (
        <div className={style.inner_container}>
            <div className={style.row}>
                <InputField
                    title={"Input VKR"}
                    type="amount"
                    balance={balance.token}
                    symbol={"VKR"}
                    onChanged={(v: string) => {
                        setToken(v);

                        let exes: WasmExecute[] = [];

                        let t = fixed(multiply(v, "1000000"), 0);

                        if (v) {
                            queryPrice(environment().contracts.token)
                                .then((price) => {
                                    setPrice(price);
                                    const axlUSDC = multiply(price, v);
                                    setAxlUSDC(
                                        !isNumber(axlUSDC)
                                            ? ""
                                            : fixed(axlUSDC, 6)
                                    );

                                    let u = fixed(
                                        multiply(fixed(axlUSDC, 6), "1000000"),
                                        0
                                    );

                                    exes.push({
                                        contract: environment().contracts.token,
                                        msg: {
                                            increase_allowance: {
                                                spender:
                                                    environment().contracts
                                                        .astroport.pair,
                                                amount: t,
                                            },
                                        },
                                        coin: undefined,
                                    });

                                    exes.push({
                                        contract:
                                            environment().contracts.astroport
                                                .pair,
                                        msg: {
                                            provide_liquidity: {
                                                assets: [
                                                    {
                                                        info: {
                                                            token: {
                                                                contract_addr:
                                                                    environment()
                                                                        .contracts
                                                                        .token,
                                                            },
                                                        },
                                                        amount: t,
                                                    },
                                                    {
                                                        info: {
                                                            native_token: {
                                                                denom: environment()
                                                                    .contracts
                                                                    .usdc_token,
                                                            },
                                                        },
                                                        amount: u,
                                                    },
                                                ],
                                                auto_stake: true,
                                            },
                                        },
                                        coin:
                                            u +
                                            environment().contracts.usdc_token,
                                    });

                                    getFeeRaw(
                                        wallet?.terraAddress
                                            ? wallet.terraAddress
                                            : "",
                                        exes
                                    )
                                        .then((r) => {
                                            let uluna = "uluna";
                                            setFee(
                                                r.amount
                                                    .toString()
                                                    .replace(uluna, "")
                                            );
                                        })
                                        .catch((e) => {
                                            Utils.getFeeError(
                                                e.response.data.message,
                                                setError1
                                            );
                                            setFee("0");
                                        });
                                })
                                .catch((e) => {});
                        } else {
                            setAxlUSDC("");
                        }
                    }}
                    value={token}
                    error={error1}
                    autofocus={true}
                    showSymbolIcon={true}
                />

                <div className={style.icon} />

                <InputField
                    title={"Input axlUSDC"}
                    type="amount"
                    decimal={6}
                    balance={balance.axlUSDC}
                    symbol={"axlUSDC"}
                    onChanged={(v: string) => {
                        setAxlUSDC(v);

                        let exes: WasmExecute[] = [];

                        let u = fixed(multiply(v, "1000000"), 0);

                        if (v) {
                            queryPrice(environment().contracts.token)
                                .then((price) => {
                                    setPrice(price);
                                    const token = divide(v, price);
                                    setToken(
                                        !isNumber(token) ? "" : fixed(token, 6)
                                    );

                                    let t = fixed(
                                        multiply(fixed(token, 6), "1000000"),
                                        0
                                    );

                                    exes.push({
                                        contract: environment().contracts.token,
                                        msg: {
                                            increase_allowance: {
                                                spender:
                                                    environment().contracts
                                                        .astroport.pair,
                                                amount: t,
                                            },
                                        },
                                        coin: undefined,
                                    });

                                    exes.push({
                                        contract:
                                            environment().contracts.astroport
                                                .pair,
                                        msg: {
                                            provide_liquidity: {
                                                assets: [
                                                    {
                                                        info: {
                                                            token: {
                                                                contract_addr:
                                                                    environment()
                                                                        .contracts
                                                                        .token,
                                                            },
                                                        },
                                                        amount: t,
                                                    },
                                                    {
                                                        info: {
                                                            native_token: {
                                                                denom: environment()
                                                                    .contracts
                                                                    .usdc_token,
                                                            },
                                                        },
                                                        amount: u,
                                                    },
                                                ],
                                                auto_stake: true,
                                            },
                                        },
                                        coin:
                                            u +
                                            environment().contracts.usdc_token,
                                    });

                                    getFeeRaw(
                                        wallet?.terraAddress
                                            ? wallet.terraAddress
                                            : "",
                                        exes
                                    )
                                        .then((r) => {
                                            let uluna = "uluna";
                                            setFee(
                                                r.amount
                                                    .toString()
                                                    .replace(uluna, "")
                                            );
                                        })
                                        .catch((e) => {
                                            Utils.getFeeError(
                                                e.response.data.message,
                                                setError1
                                            );
                                            setFee("0");
                                        });
                                })
                                .catch((e) => {});
                        } else {
                            setToken("");
                        }
                    }}
                    value={axlUSDC}
                    error={error2}
                    showSymbolIcon={true}
                />
            </div>

            <div className={style.line_container}>
                <LineDummy horizontal={true} marginTop={15} marginBottom={0} />
            </div>

            <div className={style.exchangeicon} />

            <InputField
                title={"VKR-axlUSDC LP"}
                type="amount"
                symbol={"LP"}
                onChanged={(v: string) => {}}
                value={lpEstimated ? Utils.getDecimal(lpEstimated, true) : ""}
                error={undefined}
                disabled={true}
                showSymbolIcon={true}
            />

            {token ? (
                <InfoView price={price} axlUSDC={axlUSDC} fee={fee} />
            ) : (
                <div />
            )}

            <div className={style.spreaddummy} />

            <ConfirmButton
                title={"Provide"}
                enable={!error1 && !error2 && token !== ""}
                onClick={() => {
                    props.stakeLpAutoPressed(
                        fixed(multiply(token, 1000000), 0),
                        fixed(multiply(axlUSDC, 1000000), 0)
                    );
                }}
            />
        </div>
    );
}

function InfoView(props: { price: string; axlUSDC: string; fee: string }) {
    const list = [
        {
            title: "Estimated Price",
            body: Utils.getDecimal(multiply(props.price, 1000000), true, 6),
            symbol: " axlUSDC per VKR",
        },
    ];

    return <TxInfoView list={list} fee={props.fee} />;
}
