import { useCallback, useEffect, useRef } from "react";

import style from "./TxLoadingView.module.scss";

import { getTxInfo } from "../../../libs/api/api";
import { TxInfo } from "@terra-money/terra.js";
import { FooterInfoRowTx } from "../FooterInfoRow/FooterInfoRow";

export default function TxLoadingView(props: {
    result: HashResult;
    txFound: (info: TxInfo) => void;
    timeout: () => void;
}) {
    //timeout?
    let txHash = "";
    if (
        props.result.response &&
        props.result.response.result &&
        props.result.response.result.txhash
    ) {
        txHash = props.result.response?.result?.txhash ?? "";
    }

    const timeout = 1000;
    const estimated = useRef(timeout);
    const timer = useRef<any>(null);

    useEffect(() => {
        // console.log("polling start");
        if (timer.current !== null) {
            clear();
        }

        timer.current = setInterval(() => {
            // console.log("estimate " + estimated.current);

            if (estimated.current === 0) {
                props.timeout();
                clear();
            } else {
                polling();
            }

            estimated.current -= 1;
        }, 1000);

        return () => {
            clear();
        };
    }, []);

    const clear = useCallback(() => {
        // console.log("clear");
        if (timer.current !== null) {
            clearInterval(timer.current);
            timer.current = null;
            estimated.current = 10;
        }
    }, []);

    const polling = useCallback(() => {
        if (txHash) {
            getTxInfo(txHash)
                .then((r) => {
                    props.txFound(r);
                    clear();
                })
                .catch((error) => {});
        }
    }, [txHash]);

    return (
        <div className={style.loading_box}>
            <div className={style.icon} />
            <div className={style.text}>{"Waiting for receipt..."}</div>

            <div className={style.info}>
                <FooterInfoRowTx title={"Tx Hash"} tx={txHash} tooltip="" />
            </div>
        </div>
    );
}
