import { useCallback, useEffect, useRef, useState } from "react";
import style from "./Stake.module.scss";
import { claimReward } from "../../libs/api/astroport_liquidity/execution";
import PageHeader from "../common/PageHeader/PageHeader";
import TxBoxView from "../common/TxBoxView/TxBoxView";
import { useRecoilValue } from "recoil";
import { walletState, refreshState } from "../../libs/contexts";
import { useWallet } from "@terra-money/wallet-provider";
import { ticketClaim } from "../../libs/api/governance/execution";
import StakeGovCard from "./StakeGovCard";
import StakeLpCard from "./StakeLpCard";
import TitleLabel from "../common/Labels/TitleLabel";
import { govState } from "../../libs/api/governance/query";
import { environment } from "../../libs/api/api";
import { queryPrice } from "../../libs/api/trade/query";
import * as Utils from "../../libs/utils";
import {
    divide,
    fixed,
    isZero,
    leftGreaterThanRight,
    leftLessThanRight,
    minus,
    multiply,
} from "../../libs/Math";

import iconU from "../../resources/ico-graph-u.svg";
import iconD from "../../resources/ico-graph-d.svg";
import iconN from "../../resources/ico-graph-n.svg";
import { vkrPriceChart } from "../../libs/api/rest";
import StakePolls from "./StakePolls";

export enum GovStatus {
    inProgress,
    rejected,
    passed,
    executed,
    failed,
}

export default function Stake(props: any) {
    const refresh = useRecoilValue(refreshState);
    const isLoading = useRef(false);
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);

    const [chartInfo, setChartInfo] = useState<PriceHeaderInfo | undefined>(
        undefined
    );
    const [info, setInfo] = useState<StakeGovHeaderInfo | undefined>(undefined);
    const [price, setPrice] = useState<string | undefined>(undefined);

    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    const loadChart = useCallback(async () => {
        const price = await queryPrice(environment().contracts.token);
        const p = fixed(multiply(price, 1000000), 0);
        const r = await vkrPriceChart("day");

        let rate = "0";
        let lastPrice: string = price;
        let color = "#786f64";
        let icon = iconN;

        if (r.length > 0) {
            lastPrice = multiply(r[r.length - 1].y, 1000000);
            if (lastPrice && !isZero(lastPrice)) {
                rate = divide(minus(p, lastPrice), lastPrice);
            }

            if (leftLessThanRight(rate, 0)) {
                color = "#a02d46";
                icon = iconD;
            } else if (leftGreaterThanRight(rate, 0)) {
                color = "#0ecb81";
                icon = iconU;
            } else {
                color = "#786f64";
                icon = iconN;
            }
        }

        return {
            price: p,
            lastPrice: lastPrice,
            rate: rate,
            color: color,
            icon: icon,
            list: r,
        };
    }, []);

    useEffect(() => {
        if (isLoading.current) {
            return;
        }
        isLoading.current = true;

        govState(wallet ? wallet.terraAddress : undefined)
            .then((r) => {
                setInfo(r);
                return queryPrice(environment().contracts.token);
            })
            .then((r) => {
                setPrice(r);
                isLoading.current = false;
            })
            .catch((e) => {
                isLoading.current = false;
            });

        loadChart()
            .then((r) => {
                setChartInfo({
                    price: r.price,
                    lastPrice: r.lastPrice,
                    rate: r.rate,
                    color: r.color,
                    icon: r.icon,
                    chartData: r.list,
                });
                isLoading.current = false;
            })
            .catch((e) => {
                isLoading.current = false;
            });
    }, [wallet, refresh]);

    function claimTicketPressed() {
        setHash({
            type: "TicketClaim",
            isPending: true,
        });

        ticketClaim(wallet!.terraAddress)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "TicketClaim",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                setHash({
                    type: "TicketClaim",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function claimLpPressed() {
        setHash({
            type: "LpClaim",
            isPending: true,
        });

        claimReward(wallet!.terraAddress)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "LpClaim",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                setHash({
                    type: "LpClaim",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    return (
        <div className={style.container}>
            {hash !== undefined ? (
                <PageHeader title="Claim" docs="" />
            ) : (
                <div />
            )}
            <TxBoxView
                hash={hash}
                clearHash={() => {
                    setHash(undefined);
                }}
                transparent={true}
            >
                <div className={style.vkr_info_container}>
                    <PageHeader title="VKR Info" docs="" />

                    <div className={style.governance_header_container}>
                        <div className={style.item}>
                            <TitleLabel
                                text="VKR Price"
                                tooltip="Price of VKR token"
                                color="#ffffff"
                                size={14}
                                fontWeight={"light"}
                            />
                            <div style={{ height: "6px" }} />

                            <div className={style.price_container}>
                                <TitleLabel
                                    size={30}
                                    text={
                                        price
                                            ? Utils.getDecimal(
                                                  multiply(price, 1000000),
                                                  true,
                                                  6
                                              )
                                            : undefined
                                    }
                                    symbol="axlUSDC"
                                    skeleton={{
                                        width: 100,
                                    }}
                                />

                                {chartInfo && chartInfo.lastPrice ? (
                                    <div className={style.percent}>
                                        <img
                                            className={style.icon}
                                            src={chartInfo.icon}
                                            alt=""
                                        />
                                        <div>
                                            <TitleLabel
                                                text={
                                                    Utils.getPercentFormat(
                                                        chartInfo.rate
                                                    ).replace("-", "") + "%"
                                                }
                                                color={chartInfo.color}
                                                size={14}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div />
                                )}
                            </div>
                        </div>
                        <div style={{ width: "40px" }} />
                        <div className={style.item}>
                            <TitleLabel
                                text="Total Staked VKR"
                                tooltip="Total quantity of VKR tokens Gov staked"
                                color="#ffffff"
                                size={14}
                                fontWeight={"light"}
                            />
                            <div style={{ height: "6px" }} />

                            <TitleLabel
                                size={30}
                                text={
                                    info
                                        ? Utils.getFormat(info.totalStaked)
                                        : undefined
                                }
                                symbol="VKR"
                                skeleton={{ width: 100 }}
                            />
                        </div>
                    </div>
                </div>

                <div className={style.stake_container}>
                    <PageHeader title="Stake" docs="" />

                    <div className={style.inner_container}>
                        <div className={style.section}>
                            <StakeGovCard claimPressed={claimTicketPressed} />
                        </div>

                        <div className={style.dummy} />

                        <div className={style.section}>
                            <StakeLpCard claimPressed={claimLpPressed} />
                        </div>
                    </div>
                </div>
                <StakePolls />
            </TxBoxView>
        </div>
    );
}
