import { dateFormat8, getDecimal, splitAmountByDot } from "../../../libs/utils";
import TitleLabel from "../../common/Labels/TitleLabel";
import style from "./MyDistributionRewardsDistributed.module.scss";
import { divide, multiply, plus } from "../../../libs/Math";
import { useEffect, useState } from "react";
import { airdropConfig } from "../../../libs/api/airdrop_v2/query";
import { blockState, walletState } from "../../../libs/contexts";
import { useRecoilValue } from "recoil";
import { getBlockTime } from "../../../libs/api/api";

export default function MyDistributionRewardsDistributed(props: {
    tokenAmount: string;
    claimedAmount: string;
    claimableAmount: string;
}) {
    const airdropStartSeconds = 1657530000000;

    const wallet = useRecoilValue(walletState);
    const block = useRecoilValue(blockState);

    const [nowBlockSeconds, setNowBlockSeconds] = useState<number>(0);

    const [nowClaimablePercent, setNowClaimablePercent] = useState<number>(0);
    const [nowClaimedPercent, setNowClaimedPercent] = useState<number>(0);

    const [vestingTimeSeconds, setVestingTimeSeconds] = useState<{
        start: number;
        end: number;
    }>({
        start: 0,
        end: 0,
    });

    const [distributedAmountSplit, setDistributedAmountSplit] =
        useState<AmountSplitModel>({
            num1: "0",
            num2: undefined,
        });

    useEffect(() => {
        setDistributedAmountSplit(
            splitAmountByDot(plus(props.claimedAmount, props.claimableAmount))
        );
    }, [props.claimedAmount, props.claimableAmount]);

    useEffect(() => {
        if (vestingTimeSeconds.start > nowBlockSeconds) {
            setNowClaimablePercent(0);
            setNowClaimedPercent(
                Number(
                    multiply(
                        divide(
                            (nowBlockSeconds - airdropStartSeconds).toString(),
                            (
                                vestingTimeSeconds.start - airdropStartSeconds
                            ).toString()
                        ),
                        "100"
                    )
                ) * 0.2
            );
        } else {
            setNowClaimedPercent(
                Number(
                    multiply(
                        divide(props.claimedAmount, props.tokenAmount),
                        "100"
                    )
                )
            );

            setNowClaimablePercent(
                Number(
                    multiply(
                        divide(props.claimableAmount, props.tokenAmount),
                        "100"
                    )
                )
            );
        }
    }, [
        props.tokenAmount,
        props.claimedAmount,
        props.claimableAmount,
        nowBlockSeconds,
        vestingTimeSeconds,
    ]);

    useEffect(() => {
        airdropConfig()
            .then((r) => {
                setVestingTimeSeconds({
                    start: r.vesting_schedule[0].start * 1000,
                    end: r.vesting_schedule[0].end * 1000,
                });
            })
            .catch((e) => {});
    }, [wallet]);

    useEffect(() => {
        getBlockTime(block.block)
            .then((r) => {
                setNowBlockSeconds(r);
            })
            .catch((e) => {});
    }, [block]);

    return (
        <div className={style.container}>
            <div className={style.distributed}>
                <div className={style.distributed_amount}>
                    <TitleLabel
                        text={"Distributed"}
                        size={14}
                        color={"#fff"}
                        fontWeight={"light"}
                    />

                    <div className={style.distributed_amountnow}>
                        {distributedAmountSplit.num1}
                        <span>
                            {distributedAmountSplit.num2 !== undefined
                                ? "." + distributedAmountSplit.num2 + " VKR"
                                : " VKR"}
                        </span>
                    </div>
                </div>
            </div>
            <div className={style.progressbar}>
                <div className={style.progressdate}>
                    <div className={style.start}>
                        {dateFormat8(vestingTimeSeconds.start)}
                    </div>
                    <div className={style.end}>
                        {dateFormat8(vestingTimeSeconds.end)}
                    </div>
                </div>
                <div className={style.bar}>
                    <div className={style.progressbar_fixed} />
                    <div className={style.progressbar_wrap}>
                        <div
                            className={style.progressbar_claimed}
                            style={{ width: nowClaimedPercent + "%" }}
                        />
                        <div
                            className={style.progressbar_claimable}
                            style={{ width: nowClaimablePercent + "%" }}
                        />
                    </div>

                    <div className={style.progressbar_pointer} />
                    <div className={style.progressbar_pointer2} />
                    <div className={style.progressbar_pointer3} />
                </div>
                <div className={style.progressamount}>
                    <div className={style.locked}>{"Locked"}</div>
                    <div className={style.end}>
                        {getDecimal(props.tokenAmount, true, 6) + " VKR"}
                    </div>
                </div>
            </div>
        </div>
    );
}
