import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { environment } from "../../../../../libs/api/api";
import { blockState } from "../../../../../libs/contexts";
import { multiply } from "../../../../../libs/Math";
import {
    dateFormat10,
    dateFormat11,
    getDecimal,
} from "../../../../../libs/utils";
import style from "./SayveRemaining.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function SayveRemaining(props: {
    summary: CampaignSummary;
    earlybird: boolean;
    requirement?: SayveRequirement;
}) {
    const block = useRecoilValue(blockState);
    const [time, setTime] = useState(0);
    const [leftBlock, setLeftBlock] = useState(999999);

    useEffect(() => {
        let time = 0;
        if (props.earlybird) {
            time = environment().contracts.sayve_early.startTimeSeconds;
        } else {
            time = environment().contracts.sayve_public.startTimeSeconds;
        }
        setTime(time * 1000);
    }, [props.earlybird]);

    useEffect(() => {
        if (props.requirement) {
            setLeftBlock(props.requirement.start_block - block.block);
        }
    }, [props.requirement, block]);

    return (
        <div className={style.container}>
            <div className={style.remainingdate}>
                {leftBlock <= 100
                    ? "Open: " + dateFormat10(time)
                    : "Open: " + dateFormat11(time)}
            </div>
            <div className={style.remainingdetail}>
                <div className={style.remainingdetailtitle}>
                    {"Blocks Remaining"}
                </div>
                <div
                    className={cx(style.remainingdetailamount, {
                        under: leftBlock <= 100,
                    })}
                >
                    {getDecimal(
                        multiply(leftBlock.toString(), "1000000"),
                        true
                    ) + " Blocks"}
                </div>
            </div>
        </div>
    );
}
