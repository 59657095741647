import { environment, getLcdClient } from "../../api";
import axios from "axios";
import { MsgExecuteContract } from "@terra-money/terra.js";
import { getReferrer } from "./query";

async function get(path: string, params?: any): Promise<any> {
    return axios
        .get(path, {
            baseURL: environment().qualification_api,
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
            params: params,
        })
        .then((response) => {
            return response.data.body;
        });
}

async function post(path: string, params: any): Promise<any> {
    return axios
        .post(path, params, {
            baseURL: environment().qualification_api,
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        })
        .then((response) => {
            return response.data.data;
        });
}

export async function getSecretKey(publicKey: string): Promise<{
    secretKey: string;
    expiredAt: number;
}> {
    const params = {
        publicKey: publicKey,
    };

    return await post("/key/secret", params);
}

export async function registerCreatorWallet(
    privateKey: string,
    publicKey: string
) {
    const params = {
        encryptedPrivateKey: publicKey,
    };

    return await post("/signer", params);
}

export async function qualificationTxInfo(
    actor: string,
    txHash: string,
    campaign: string
): Promise<TxQualificationInfo> {
    const txInfo = await getLcdClient().tx.txInfo(txHash);

    let referrer: string | undefined = undefined;
    for (let i = 0; i < txInfo.tx.body.messages.length; i++) {
        let msg = txInfo.tx.body.messages[i];
        if (msg instanceof MsgExecuteContract) {
            let m = (msg as MsgExecuteContract).execute_msg as any;
            if (m.valkyrie_meta) {
                referrer = m.valkyrie_meta.referrer;
            }
        }
    }

    let decompressedReferrer;
    if (referrer && !referrer.toLowerCase().startsWith("terra1")) {
        //referrer is compressed.
        decompressedReferrer = (await getReferrer(referrer)).address;
    } else {
        decompressedReferrer = referrer;
    }

    await post("/qualification/transaction", {
        transactionHash: txHash,
        actor: actor,
        referrer: decompressedReferrer ?? undefined,
        campaign: campaign,
    }).catch((e) => {});

    return get("/qualification/transaction/" + txHash, {
        campaign: campaign,
    }).then((r) => {
        return r;
    });
}

// async function referralAmount(
//     address: string,
//     filter: "CLAIMABLE" | "LOCKED" | undefined
// ): Promise<{ amount: string }> {
//     return get("/reward/referral/amount", {
//         wallet_address: address,
//         filter: filter,
//     })
//         .then((response) => {
//             return {
//                 amount: response.amount,
//             };
//         })
//         .catch((e) => {
//             return {
//                 amount: "0",
//             };
//         });
// }

// async function depositAmount(
//     address: string,
//     filter: "WITHDRAWABLE" | "LOCKED" | undefined
// ): Promise<{ amount: string }> {
//     return get("/reward/deposit/amount", {
//         wallet_address: address,
//         filter: filter,
//     })
//         .then((response) => {
//             return {
//                 amount: response.amount,
//             };
//         })
//         .catch((e) => {
//             return {
//                 amount: "0",
//             };
//         });
// }
