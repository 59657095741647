import { minus } from "../../Math";
import { environment, tokenInfo, wasmQuery } from "../api";
import { queryTokenBalance } from "../trade/query";

export const tpToken =
    "terra1el08yssazuupjvs2mjkzw3ctss5kj2xkj3hht95ardmzuga7naeqt2u5kt";
export const xtpToken =
    "terra1j0p55n8emuj9q63et95t4nwugrl5x5np7fm9lc8r7aferhwxe0xqykhpjw";
export const tpGov =
    "terra19ux80phz3ln2z2g66stvdveshhkfen3mnxh4d09ghnw0vdnn393q0j4c4q";

export interface TpGovState {
    stakedTpBalance: string;
    xtptTotalSupply: string;
    unstakeLocked: string;
}

export interface TpGovStakerState {
    votes: { poll_id: number; option: string; amount: string }[];
    locked_balance: string;
    unstake_locked_list: { unlock_height: number; amount: string }[];
}

export interface TpBalance {
    tpBalance: string;
    xtptBalance: string;
    availableXtptBalance: string;
}

export async function getTpBalance(address: string): Promise<TpBalance> {
    const tpBalance = (await queryTokenBalance(tpToken, address)).amount;

    const result: {
        total: string;
        locked: string;
        available: string;
    } = await wasmQuery(xtpToken, {
        available_balance: {
            address: address,
        },
    });

    return {
        tpBalance: tpBalance,
        xtptBalance: result.total,
        availableXtptBalance: result.available,
    };
}

export async function getTpGovState(): Promise<TpGovState> {
    const stakedTpBalance = (await queryTokenBalance(tpToken, tpGov)).amount;
    const xtptTotalSupply = (await tokenInfo(xtpToken)).total_supply;

    const stakingState: { total_unstake_locked: string } = await wasmQuery(
        tpGov,
        {
            staking_state: {},
        }
    );

    return {
        stakedTpBalance: minus(
            stakedTpBalance,
            stakingState.total_unstake_locked
        ),
        xtptTotalSupply: xtptTotalSupply,
        unstakeLocked: stakingState.total_unstake_locked,
    };
}

export async function getTpGovStakerState(
    address: string
): Promise<TpGovStakerState> {
    const result = await wasmQuery(tpGov, {
        staker_state: {
            address: address,
        },
    });
    console.log(result);
    return {
        votes: result.votes.map((item: any[], index: number) => {
            return {
                poll_id: item[0],
                option: item[1].option,
                amount: item[1].amount,
            };
        }),
        locked_balance: result.locked_balance,
        unstake_locked_list: result.unstake_locked_list.map(
            (item: any, index: number) => {
                return {
                    unlock_height: item[0],
                    amount: item[1],
                };
            }
        ),
    };
}

export async function simulateStake(amount: string): Promise<string> {
    return await wasmQuery(tpGov, {
        simulate_stake_amount: {
            amount: amount,
        },
    });
}

export async function simulateUnstake(amount: string): Promise<string> {
    return await wasmQuery(tpGov, {
        simulate_unstake_amount: {
            amount: amount,
        },
    });
}

export async function tpGovernancePollList(
    limit: number,
    start_after?: number,
    filter?: string
): Promise<ResponsePollDetail[]> {
    const list: ResponsePollDetail[] = (
        await wasmQuery(tpGov, {
            polls: {
                filter: filter,
                start_after: start_after,
                limit: limit,
                order_by: "desc",
            },
        })
    ).polls;
    return list;
}
