import TitleLabel from "../../../common/Labels/TitleLabel";
import style from "./ExplainPopup.module.scss";

export default function ExplainPopup(props: {
    show: number;
    setShow: (v: number) => void;
}) {
    return (
        <div className={style.container}>
            <div className={style.contents}>
                <div className={style.contents_title}>
                    <TitleLabel
                        size={18}
                        color={"white"}
                        fontWeight="medium"
                        text={"My Total Tokens"}
                    />
                </div>

                <div className={style.popdummy} />
                <div className={style.popupcontents}>
                    <div className={style.popup1}>
                        {
                            "The distribution scheme of the purchased tokens are as follows"
                        }
                    </div>
                    <div className={style.popup2}>
                        The total tokens purchased will be locked-up for 1 month
                        (30 days) from the time of purchase.
                        {/* <span className={style.popup2_sub}>10%</span> of the
                        total tokens purchased will be dropped 1 month (30 days)
                        from purchase date */}
                    </div>
                    <div className={style.popup3}>
                        Afterwards, they will begin to be linearly vested on a
                        daily basis over 3 months [1/90 * (purchased tokens)]
                        per day
                        {/* <span className={style.popup3_sub}>90%</span> is then
                        linearly vested on a daily basis over 11 months [1/330 *
                        0.9(purchased tokens)] per day */}
                    </div>
                    <div className={style.popup4}>
                        {
                            "You will receive the entirety of your purchased tokens 4 months from purchased date."
                        }
                    </div>
                </div>
                <div
                    className={style.checkbutton}
                    onClick={() => {
                        props.setShow(-1);
                    }}
                >
                    <TitleLabel
                        size={14}
                        color={"black"}
                        fontWeight="medium"
                        text={"Check"}
                    />
                </div>
            </div>
        </div>
    );
}
