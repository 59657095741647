import style from "./CampaignCell4.module.scss";

import * as Utils from "../../../libs/utils";
import classNames from "classnames/bind";
import { isZero } from "../../../libs/Math";
const cx = classNames.bind(style);

export default function CampaignCell4(props: {
    pReward: string;
    pDenom: string;
    pSymbol: string;
    rReward: string;
    rDenom: string;
    rSymbol: string;
    skeleton: boolean;
}) {
    if (props.skeleton) {
        return <div />;
    } else {
        return (
            <div className={style.campaign_cell4}>
                <div className={style.inner}>
                    <div className={style.icon} />
                    <div className={style.text1}>{"Pool :"}&nbsp;</div>

                    <div className={style.sub}>
                        <div
                            className={cx(style.text2, {
                                isZero: isZero(props.pReward),
                            })}
                        >
                            {Utils.getDecimal(
                                props.pReward,
                                true,
                                props.pDenom === "axlUSDC" ? 3 : 6
                            )}
                            &nbsp;
                            <small>{props.pSymbol}</small>
                        </div>

                        {props.pDenom !== props.rDenom ? (
                            <div className={style.dummy}>
                                &nbsp;<small>{"/"}</small>&nbsp;
                            </div>
                        ) : (
                            <div />
                        )}

                        {props.pDenom !== props.rDenom ? (
                            <div
                                className={cx(style.text3, {
                                    isZero: isZero(props.rReward),
                                })}
                            >
                                {Utils.getDecimal(
                                    props.rReward,
                                    true,
                                    props.rDenom === "axlUSDC" ? 3 : 6
                                )}
                                &nbsp;
                                <small>{props.rSymbol}</small>
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                </div>
                <div className={style.dummy2} />
            </div>
        );
    }
}
