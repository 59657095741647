import TitleLabel from "../../../../../common/Labels/TitleLabel";
import style from "./IPCDetailTitle.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function IPCDetailTitle(props: {
    earlybird: boolean;
    summary: CampaignSummary;
}) {
    return (
        <div className={style.container}>
            <div className={style.containerwrap}>
                <div className={style.logofield}>
                    <div
                        className={cx(style.logofield_ico, {
                            earlybird: props.earlybird === true,
                        })}
                    />
                    {props.summary.state.is_active === true ? (
                        <div />
                    ) : (
                        <div className={style.logoclosed}>{"CLOSED"}</div>
                    )}
                </div>
                <div className={style.contentsfield}>
                    <div className={style.titles}>
                        <div
                            className={cx(style.title, {
                                earlybird: props.earlybird === true,
                            })}
                        >
                            {props.summary.config.title}
                        </div>
                        <div className={style.symbol}>
                            <TitleLabel
                                fontWeight={"bold"}
                                text={"SAYVE"}
                                size={20}
                                color={"#fff"}
                            />
                        </div>
                        <div className={style.name}>
                            <TitleLabel
                                fontWeight={"medium"}
                                text={"Sayve Protocol"}
                                size={12}
                                color={"#fff"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
