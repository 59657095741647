import InputField from "../../common/InputField/InputField";
import { useState } from "react";
import GovCreateFormFooter from "./GovCreateFormFooter";
import { useEffect } from "react";
import GovCreateFormHeader from "./GovCreateFormHeader";
import * as Utils from "../../../libs/utils";
import { poll2 } from "../../../libs/api/governance/execution";
import { PATHS } from "../../../App";
import {
    divide,
    fixed,
    leftGreaterThanRight,
    leftLessThanRight,
    multiply,
} from "../../../libs/Math";
import {
    campaignManagerConfig,
    campaignManagerRefRewardLimitOption,
} from "../../../libs/api/campaign/query";
import { useWallet } from "@terra-money/wallet-provider";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";
import { environment } from "../../../libs/api/api";
import { pollConfig } from "../../../libs/api/governance/query";
import { getFeeRaw } from "../../../libs/api/fee";

export default function GovCreateForm2(props: {
    setHash: (h: HashResult) => void;
    deposit: string;
}) {
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);

    const [headerInfo, setHeaderInfo] = useState({
        title: "",
        desc: "",
        link: "",
    });
    const [headerValid, setHeaderValid] = useState(false);

    const [campaignCodeId, setCampaignCodeId] = useState("");
    const [campaignCodeIdPlaceHolder, setCampaignCodeIdPlaceHolder] =
        useState("");

    const [campaignDepositFeeRate, setCampaignDepositFeeRate] = useState("");
    const [
        campaignDepositFeeRatePlaceholder,
        setCampaignDepositFeeRatePlaceholder,
    ] = useState("");
    const [rewardRatio, setRewardRatio] = useState("");
    const [rewardRatioPlaceholder, setRewardRatioPlaceholder] = useState("");
    const [campaignWithdrawFeeRate, setCampaignWithdrawFeeRate] = useState("");
    const [
        campaignWithdrawFeeRatePlaceholder,
        setCampaignWithdrawFeeRatePlaceholder,
    ] = useState("");

    const [campaignDeactivatePeroid, setCampaignDeactivatePeroid] =
        useState("");
    const [
        campaignDeactivatePeroidPlaceholder,
        setCampaignDeactivatePeroidPlaceholder,
    ] = useState("");

    const [baseCount, setBaseCount] = useState("");
    const [baseCountPlaceholder, setBaseCountPlaceholder] = useState("");

    const [govStakePercent, setGovStakePercent] = useState("");
    const [govStakePercentPlaceholder, setGovStakePercentPlaceholder] =
        useState("");

    const [error1, setError1] = useState<string | undefined>(undefined);

    const [valid, setValid] = useState(false);

    const [proposal, setProposal] = useState("");

    const [fee, setFee] = useState("0");

    useEffect(() => {
        pollConfig().then((r) => {
            setProposal(r.proposal_deposit);
        });
    }, []);

    useEffect(() => {
        campaignManagerConfig()
            .then((r) => {
                setCampaignCodeIdPlaceHolder(
                    r.code_id ? r.code_id.toString() : ""
                );

                setCampaignDepositFeeRatePlaceholder(
                    multiply(r.add_pool_fee_rate ?? 0, 100)
                );

                setRewardRatioPlaceholder(
                    (r.add_pool_min_referral_reward_rate ?? 0).toString()
                );
                setCampaignWithdrawFeeRatePlaceholder(
                    multiply(r.remove_pool_fee_rate ?? 0, 100)
                );
                setCampaignDeactivatePeroidPlaceholder(
                    (r.deactivate_period ?? 0).toString()
                );
            })
            .catch((e) => {});

        campaignManagerRefRewardLimitOption()
            .then((r) => {
                setBaseCountPlaceholder(r.base_count.toString());
                setGovStakePercentPlaceholder(
                    r.percent_for_governance_staking.toString()
                );
            })
            .catch((e) => {});
    }, []);

    useEffect(() => {
        if (!campaignWithdrawFeeRate) {
            setError1(undefined);
        } else {
            if (
                leftGreaterThanRight(campaignWithdrawFeeRate, 0) &&
                leftLessThanRight(campaignWithdrawFeeRate, 100)
            ) {
                setError1(undefined);
            } else {
                setError1("range is 0~100");
            }
        }
    }, [campaignWithdrawFeeRate]);

    useEffect(() => {
        if (!error1 && headerValid) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [error1, headerValid]);

    useEffect(() => {
        let exes: WasmExecute[] = [];

        let msgs = [];

        msgs.push({
            order: 0,
            contract: environment().contracts.campaignManager,
            base64EncodedMsg: btoa(
                JSON.stringify({
                    update_config: {
                        code_id: campaignCodeId,
                        add_pool_fee_rate: campaignDepositFeeRate
                            ? fixed(divide(campaignDepositFeeRate, 100), 6)
                            : undefined,
                        remove_pool_fee_rate: campaignWithdrawFeeRate,
                        deactivate_period: campaignDeactivatePeroid,
                        add_pool_min_referral_reward_rate: rewardRatio,
                    },
                })
            ),
        });

        if (
            baseCount || govStakePercent ? parseInt(govStakePercent) : undefined
        ) {
            msgs.push({
                order: 0,
                contract: environment().contracts.campaignManager,
                base64EncodedMsg: btoa(
                    JSON.stringify({
                        update_referral_reward_limit_option: {
                            base_count: baseCount,
                            percent_for_governance_staking: govStakePercent
                                ? parseInt(govStakePercent)
                                : undefined,
                        },
                    })
                ),
            });
        }

        let subMsg = {
            create_poll: {
                title: headerInfo.title,
                description: headerInfo.desc,
                link: headerInfo.link ? headerInfo.link : undefined,
                executions: msgs
                    ? msgs.map((item) => {
                          return {
                              order: item.order,
                              contract: item.contract,
                              msg: item.base64EncodedMsg,
                          };
                      })
                    : undefined,
            },
        };

        exes.push({
            contract: environment().contracts.token,
            msg: {
                send: {
                    contract: environment().contracts.governance,
                    amount: proposal,
                    msg: btoa(JSON.stringify(subMsg)),
                },
            },
            coin: undefined,
        });

        getFeeRaw(wallet ? wallet.terraAddress : "", exes)
            .then((r) => {
                let uluna = "uluna";
                setFee(r.amount.toString().replace(uluna, ""));
            })
            .catch((e) => {
                setFee("0");
            });
    }, [headerInfo, wallet]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <GovCreateFormHeader
                setInfo={setHeaderInfo}
                setValid={setHeaderValid}
            />

            <InputField
                title="Code ID"
                type="amount"
                disabled={false}
                tooltip=""
                symbol=""
                value={campaignCodeId}
                placeholder={campaignCodeIdPlaceHolder}
                onChanged={setCampaignCodeId}
            />

            <InputField
                title="Add to Reward Pool Fee Rate"
                type="amount"
                disabled={false}
                tooltip="Base fee utilized to start a new campaign"
                symbol="%"
                value={campaignDepositFeeRate}
                placeholder={campaignDepositFeeRatePlaceholder}
                onChanged={setCampaignDepositFeeRate}
            />

            <InputField
                title="Remove from Reward Pool Fee Rate"
                type="amount"
                disabled={false}
                tooltip="Fee levied for  withdrawals of rewards of on-going campaigns"
                symbol="%"
                value={campaignWithdrawFeeRate}
                placeholder={campaignWithdrawFeeRatePlaceholder}
                onChanged={setCampaignWithdrawFeeRate}
                error={error1}
            />

            <InputField
                title="Sharing Reward Pool Minimum Ratio"
                type="amount"
                disabled={false}
                tooltip="Ratio of rewards allocated for campaign participation and sharing"
                symbol=""
                value={rewardRatio}
                placeholder={rewardRatioPlaceholder}
                onChanged={setRewardRatio}
                error={error1}
            />

            <InputField
                title="Sharing Reward Cap Base Count"
                type="amount"
                disabled={false}
                tooltip="Base cap of max rewards where Total reward pool size = 1"
                symbol=""
                value={baseCount}
                placeholder={baseCountPlaceholder}
                onChanged={(v) => {
                    let value = v.replaceAll(".", "");
                    if (leftGreaterThanRight(v, 255)) {
                        value = "255";
                    }
                    setBaseCount(value);
                }}
                error={undefined}
            />

            <InputField
                title="Sharing Reward Cap Gov Rate"
                type="amount"
                disabled={false}
                tooltip="% of limit increase to total governance staked"
                symbol={"%"}
                value={govStakePercent}
                placeholder={govStakePercentPlaceholder}
                onChanged={(v) => {
                    let value = v.replaceAll(".", "");
                    setGovStakePercent(value);
                }}
            />

            <InputField
                title="Auto Deactivate Period"
                type="amount"
                disabled={false}
                tooltip="Period before the campaign is deactivated"
                symbol="blocks"
                value={campaignDeactivatePeroid}
                placeholder={campaignDeactivatePeroidPlaceholder}
                onChanged={(v) => {
                    setCampaignDeactivatePeroid(v.replaceAll(".", ""));
                }}
                error={undefined}
            />

            <InputField
                title="Proposal Deposit"
                type="amount"
                tooltip={
                    "Proposed minimum VKR deposit required for submitting a new poll"
                }
                disabled={true}
                symbol={"VKR"}
                value={Utils.getDecimal(props.deposit, true)}
                onChanged={() => {}}
            />

            <GovCreateFormFooter
                enable={valid}
                pressed={() => {
                    props.setHash({
                        type: "GovPoll2",
                        isPending: true,
                    });

                    poll2(
                        wallet!.terraAddress,
                        headerInfo,
                        campaignCodeId ? parseInt(campaignCodeId) : undefined,
                        campaignDepositFeeRate
                            ? fixed(divide(campaignDepositFeeRate, 100), 6)
                            : undefined,
                        rewardRatio ? rewardRatio : undefined,
                        campaignWithdrawFeeRate
                            ? fixed(divide(campaignWithdrawFeeRate, 100), 6)
                            : undefined,
                        campaignDeactivatePeroid
                            ? parseInt(campaignDeactivatePeroid)
                            : undefined,
                        baseCount ? parseInt(baseCount) : undefined,
                        govStakePercent ? parseInt(govStakePercent) : undefined
                    )
                        .then((r) => {
                            return post(r);
                        })
                        .then((response) => {
                            props.setHash({
                                type: "GovPoll2",
                                response: response,
                                redirect: PATHS.governance,
                            });
                        })
                        .catch((error) => {
                            props.setHash({
                                type: "GovPoll2",
                                error: error,
                                redirect: undefined,
                            });
                        });
                }}
                fee={fee}
            />
        </div>
    );
}
