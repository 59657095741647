import { useEffect, useState } from "react";
import style from "./Trade.module.scss";
import {
    tokenSimulate,
    tokenReverseSimulate,
    queryPrice,
} from "../../libs/api/trade/query";
import * as Utils from "../../libs/utils";

import InputField from "../common/InputField/InputField";

import {
    fixed,
    isNumber,
    isZero,
    leftGreaterThanRight,
    leftLessThanRight,
    multiply,
} from "../../libs/Math";
import TxInfoView from "../common/TxInfoView/TxInfoView";
import { useRecoilValue } from "recoil";
import { spreadState, walletState } from "../../libs/contexts";
import SpreadView from "./TradeSpread";
import classNames from "classnames/bind";
import { environment } from "../../libs/api/api";
import { getFeeRaw } from "../../libs/api/fee";

const cx = classNames.bind(style);

export default function TradeBuy(props: {
    balance: { token: string; axlUSDC: string };
    buyPressed: (amount: string, spread: number) => void;
}) {
    const wallet = useRecoilValue(walletState);
    const spread = useRecoilValue(spreadState);

    const [axlUSDC, setAxlUSDC] = useState("");
    const [token, setToken] = useState("");
    const [simulate, setSimulate] = useState<ResponseSimulate | undefined>(
        undefined
    );

    const [error1, setError1] = useState<string | undefined>(undefined);
    const [error2, setError2] = useState<string | undefined>(undefined);

    const [fee, setFee] = useState("0");
    const [beliefPrice, setBeliefPrice] = useState<string>("");

    useEffect(() => {
        queryPrice(environment().contracts.token).then((r) => {
            setBeliefPrice(r);
        });
    }, []);

    useEffect(() => {
        if (
            leftGreaterThanRight(
                axlUSDC,
                Utils.getDecimal(props.balance.axlUSDC, false, 6)
            )
        ) {
            setError1("Insufficient balance");
        } else if (isZero(axlUSDC)) {
            setError1("Amount must be greater than 0");
        } else if (leftLessThanRight(axlUSDC, 0.000001)) {
            setError1("Amount must be within 6 decimal points");
        } else {
            setError1(undefined);
        }

        let exes: WasmExecute[] = [];

        if (axlUSDC !== "") {
            exes.push({
                contract: environment().contracts.astroport.pair,
                msg: {
                    swap: {
                        offer_asset: {
                            info: {
                                native_token: {
                                    denom: environment().contracts.usdc_token,
                                },
                            },
                            amount: fixed(multiply(axlUSDC, "1000000"), 0),
                        },
                        belief_price: fixed(beliefPrice, 18),
                        max_spread: spread.spreadValue.toString(),
                    },
                },
                coin:
                    fixed(multiply(axlUSDC, "1000000"), 0) +
                    environment().contracts.usdc_token,
            });

            getFeeRaw(wallet?.terraAddress ? wallet.terraAddress : "", exes)
                .then((r) => {
                    let uluna = "uluna";
                    setFee(r.amount.toString().replace(uluna, ""));
                })
                .catch((e) => {
                    Utils.getFeeError(e.response.data.message, setError1);
                    setFee("0");
                });
        }
    }, [axlUSDC, props.balance.axlUSDC, spread]);

    useEffect(() => {
        simulate1(axlUSDC, spread.spreadValue);
    }, [spread]);

    function simulate1(axlUSDC: string, spread: number) {
        if (axlUSDC) {
            tokenSimulate(true, multiply(axlUSDC, 1000000), spread)
                .then((r: ResponseSimulate) => {
                    setSimulate(r);
                    setToken(Utils.getDecimal(r.to));
                    setError2(undefined);
                })
                .catch((e) => {});
        }
    }

    return (
        <div className={style.trade_inner_container}>
            <div className={style.from}>
                <InputField
                    title="From"
                    type="amount"
                    // disabled={false}
                    symbol="axlUSDC"
                    value={axlUSDC}
                    decimal={6}
                    balance={props.balance.axlUSDC}
                    onChanged={(v: string) => {
                        setAxlUSDC(v);
                        if (v) {
                            simulate1(v, spread.spreadValue);
                        } else {
                            setToken("");
                        }
                    }}
                    error={error1}
                    autofocus={true}
                    showSymbolIcon={true}
                />
            </div>
            <div
                className={cx(style.arrow, {
                    error: error1,
                })}
            />

            <div className={style.to}>
                <InputField
                    title="To"
                    type="amount"
                    // disabled={false}
                    symbol={"VKR"}
                    value={token}
                    onChanged={(v: string) => {
                        setToken(v);

                        if (v) {
                            tokenReverseSimulate(
                                true,
                                multiply(v, 1000000),
                                spread.spreadValue
                            )
                                .then((r: ResponseSimulate) => {
                                    setSimulate(r);
                                    setAxlUSDC(
                                        Utils.getDecimal(r.to, false, 6)
                                    );

                                    setError2(undefined);
                                })
                                .catch((e) => {
                                    setError2("Simulation failed");
                                });
                        } else {
                            setAxlUSDC("");
                        }
                    }}
                    error={error2}
                    showSymbolIcon={true}
                />
            </div>

            <SpreadView />

            {axlUSDC && simulate ? (
                <InfoView
                    axlUSDC={axlUSDC}
                    simulate={simulate}
                    isTrade={true}
                    fee={fee}
                />
            ) : (
                <div />
            )}

            <div className={style.spreaddummy} />

            <div className={style.tradebutton}>
                <div
                    className={cx(style.button, {
                        enable: !error1 && !error2 && axlUSDC !== "",
                    })}
                    onClick={() => {
                        if (!error1 && !error2 && axlUSDC !== "") {
                            props.buyPressed(
                                multiply(axlUSDC, 1000000),
                                spread.spreadValue
                            );
                        }
                    }}
                >
                    {"BUY"}
                </div>
            </div>
        </div>
    );
}

function InfoView(props: {
    axlUSDC: string;
    simulate: ResponseSimulate;
    isTrade?: boolean;
    fee: string;
}) {
    const spread = useRecoilValue(spreadState);

    const [price, setPrice] = useState("0");

    useEffect(() => {
        const price = multiply(props.simulate.rate, 1000000);
        setPrice(Utils.getDecimal(isNumber(price) ? price : "0", true, 6));
    }, [props]);

    const [list, setList] = useState<any[]>([]);

    useEffect(() => {
        let list: any[] = [];

        list.push({
            title: "Estimated Price",
            body: price,
            symbol: " axlUSDC per VKR",
        });

        list.push({
            title: "Minimum Received",
            body: Utils.getDecimal(props.simulate.minimumReceive, true),
            symbol: "VKR",
        });

        list.push({
            title: "Trading Fee",
            body: Utils.getDecimal(props.simulate.tradingFee, true, 3),
            symbol: "VKR",
        });

        setList(list);
    }, [props.simulate, price, spread]);

    return <TxInfoView list={list} fee={props.fee} />;
}
