import { Coins, Fee, MsgExecuteContract } from "@terra-money/terra.js";
import { getLcdClient } from "./api";

export async function getFeeRaw(
    address: string,
    exe: WasmExecute[]
): Promise<Fee> {
    const exes: MsgExecuteContract[] = exe.map((item) => {
        const coins = item.coin ? new Coins(item.coin) : undefined;

        return new MsgExecuteContract(address, item.contract, item.msg, coins);
    });

    const acc = await getLcdClient().auth.accountInfo(address);

    const fee = await getLcdClient().tx.estimateFee(
        [
            {
                sequenceNumber: acc.getSequenceNumber(),
                publicKey: acc.getPublicKey(),
            },
        ],
        {
            msgs: exes,
            gasPrices: { uluna: "0.015" },
            gasAdjustment: "1.2",
            feeDenoms: ["uluna"],
        }
    );

    return fee;
}
