import { useEffect, useState } from "react";
import TitleLabel from "../../common/Labels/TitleLabel";
import style from "./MyDistributionRewards.module.scss";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";
import {
    genesisAirdropUserState,
    vestingAirdropUserState,
} from "../../../libs/api/airdrop_v2/query";
import { isZero, plus } from "../../../libs/Math";
import MyDistributionRewardsClaimable from "./MyDistributionRewardsClaimable";
import MyDistributionRewardsDistributed from "./MyDistributionRewardsDistributed";
import MyDistributionRewardsTotal from "./MyDistributionRewardsTotal";

export default function MyDistributionRewards(props: {
    airdropClaim: () => void;
}) {
    const wallet = useRecoilValue(walletState);

    const [genesisAmount, setGenesisAmount] = useState<AirdropAmount>({
        assigned: "0",
        claimable: "0",
        claimed: "0",
    });

    const [vestingAmount, setVestingAmount] = useState<AirdropAmount>({
        assigned: "0",
        claimable: "0",
        claimed: "0",
    });

    const [tokenAmount, setTokenAmount] = useState("0");
    const [claimedAmount, setClaimedAmount] = useState("0");
    const [claimableAmount, setClaimableAmount] = useState("0");

    useEffect(() => {
        if (wallet !== undefined) {
            genesisAirdropUserState(wallet.terraAddress)
                .then((r) => {
                    setGenesisAmount(r);
                })
                .catch((e) => {});

            vestingAirdropUserState(wallet.terraAddress)
                .then((r) => {
                    setVestingAmount(r);
                })
                .catch((e) => {});
        }
    }, [wallet]);

    useEffect(() => {
        const tokenAmount = plus(
            genesisAmount.assigned,
            vestingAmount.assigned
        );
        setTokenAmount(tokenAmount);

        const claimedAmount = plus(
            genesisAmount.claimed,
            vestingAmount.claimed
        );
        setClaimedAmount(claimedAmount);

        const claimableAmount = plus(
            genesisAmount.claimable,
            vestingAmount.claimable
        );
        setClaimableAmount(claimableAmount);
    }, [genesisAmount, vestingAmount]);

    if (
        isZero(
            plus(
                plus(genesisAmount.assigned, genesisAmount.claimable),
                genesisAmount.claimed
            )
        )
    ) {
        return <div />;
    }

    return (
        <div className={style.container}>
            <TitleLabel
                text={"Newly Distributed VKR"}
                size={20}
                color={"#fff"}
                fontWeight={"light"}
            />

            <div className={style.inner_container}>
                <MyDistributionRewardsTotal
                    tokenAmount={tokenAmount}
                    claimedAmount={claimedAmount}
                />
                <div className={style.line1} />
                <div className={style.line2} />

                <MyDistributionRewardsDistributed
                    tokenAmount={tokenAmount}
                    claimedAmount={claimedAmount}
                    claimableAmount={claimableAmount}
                />
                <div className={style.line1} />
                <div className={style.line2} />

                <MyDistributionRewardsClaimable
                    claimableAmount={claimableAmount}
                    airdropClaim={props.airdropClaim}
                />
            </div>
        </div>
    );
}
