import ConfirmButton from "../../../common/ConfirmButton/ConfirmButton";
import TxInfoView from "../../../common/TxInfoView/TxInfoView";
import { useEffect, useState } from "react";
// import { getFeeNormal } from "../../../../libs/api/fee";

export default function ProducerSettingFormFooter(props: {
    enable: boolean;
    pressed: () => void;
}) {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <InfoView />

            <ConfirmButton
                title="Change Status"
                enable={props.enable}
                onClick={props.pressed}
            />
        </div>
    );
}

function InfoView() {
    return <TxInfoView fee={"0"} />;
}
