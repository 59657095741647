import { useEffect, useState } from "react";

import {
    divide,
    isZero,
    max,
    min,
    minus,
    multiply,
} from "../../../../../libs/Math";
import {
    dateFormat8,
    getDecimal,
    splitAmountByDot,
} from "../../../../../libs/utils";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import ExplainPopup from "../ExplainPopup";
import style from "./TPTReward.module.scss";
import classNames from "classnames/bind";
import { walletState } from "../../../../../libs/contexts";
import { useRecoilValue } from "recoil";
import {
    campaignParticipation,
    campaignReward,
} from "../../../../../libs/api/campaign/query";

const cx = classNames.bind(style);

export default function TPTReward(props: {
    campaignAddress: string;
    summary: CampaignSummary;
    claimParticipationReward: () => void;
}) {
    const wallet = useRecoilValue(walletState);

    const [participation, setParticipation] = useState<
        ResponseCampaignParticipation | undefined
    >(undefined);
    const [rewardConfig, setRewardConfig] = useState<
        ResponseCampaignReward | undefined
    >(undefined);

    const [nowPercent, setNowPercent] = useState(0);
    const [nowDistributionPercent, setDistributionPercent] = useState(0);
    const [nowClaimedPercent, setNowClaimedPercent] = useState(0);

    const [claimableDisplayValue, setClaimableDisplayValue] = useState<
        AmountSplitModel | undefined
    >(undefined);

    const [distributionSeconds, setDistributionSeconds] = useState(99999999);

    const [participatedSeconds, setParticipatedSeconds] = useState(99999999);
    const [endSeconds, setEndSeconds] = useState(99999999);
    const [state, setState] = useState({
        claimed: "0",
        total: "0",
        locked: "0",
        unlocked: "0,",
    });

    useEffect(() => {
        campaignParticipation(props.campaignAddress, wallet?.terraAddress ?? "")
            .then((r) => {
                setParticipation(r);
            })
            .catch((e) => {});
        campaignReward(props.campaignAddress)
            .then((r) => {
                setRewardConfig(r);
            })
            .catch((e) => {});
    }, [props.campaignAddress]);

    useEffect(() => {
        if (participation && rewardConfig) {
            const start =
                rewardConfig.participation_reward_distribution_schedule![0][0];
            const end =
                rewardConfig.participation_reward_distribution_schedule![0][1];

            console.log(participation);
            console.log(rewardConfig);

            setParticipatedSeconds(
                participation.last_participated_at / 1000000000
            );
            setDistributionSeconds(start);
            setEndSeconds(end);

            const total = participation.participation_reward_amount;
            const claimed =
                participation.claimed_participation_reward_amount ?? "0";
            const unlocked =
                participation.unlocked_participation_reward_amount ?? "0";
            const locked = minus(minus(total, claimed), unlocked);

            setState({
                claimed: claimed,
                total: total,
                locked: locked,
                unlocked: unlocked,
            });
        }
    }, [props.summary, rewardConfig, participation]);

    useEffect(() => {
        setClaimableDisplayValue(splitAmountByDot(state.unlocked));

        const nowSeconds = new Date().getTime() / 1000;

        setNowPercent(
            Number(
                multiply(
                    divide(
                        nowSeconds - participatedSeconds,
                        endSeconds - participatedSeconds
                    ),
                    "100"
                )
            )
        );

        setDistributionPercent(
            Number(
                multiply(
                    divide(
                        nowSeconds - distributionSeconds,
                        endSeconds - distributionSeconds
                    ),
                    "100"
                )
            )
        );

        setNowClaimedPercent(
            Number(multiply(divide(state.claimed, state.total), "100"))
        );
    }, [state, distributionSeconds, endSeconds, participatedSeconds]);

    const [showExplainPopup, setShowExplainPopup] = useState(-1);

    useEffect(() => {
        const body: any = document.querySelector("body");
        body.style.overflow = showExplainPopup !== -1 ? "hidden" : "auto";
    }, [showExplainPopup]);

    if (!props.summary.participation) {
        return <div />;
    }

    return (
        <div className={style.container}>
            <div className={style.title}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"My TPT"}
                    size={20}
                    color={"#fff"}
                />
            </div>
            <div className={style.contentswrap}>
                <div className={style.contentsbg_footer} />
                <div className={style.contents}>
                    <div className={style.contents_amount}>
                        <div className={style.contents_amount_text}>
                            <div className={style.contents_amount_text_title}>
                                <div className={style.totalreceive}>
                                    <TitleLabel
                                        size={14}
                                        color={"#fff"}
                                        text={"My Total Tokens"}
                                        fontWeight={"medium"}
                                    />
                                </div>
                                <div
                                    className={style.totalreceive_amount}
                                    onClick={() => {
                                        setShowExplainPopup(1);
                                    }}
                                >
                                    <TitleLabel
                                        size={14}
                                        color={"#1bffbc"}
                                        text={
                                            getDecimal(state.total, true, 6) +
                                            " " +
                                            props.summary.reward.participation
                                                .symbol
                                        }
                                        fontWeight={"light"}
                                        underline={true}
                                    />
                                </div>
                            </div>
                            <div className={style.contents_amount_text_sub}>
                                <TitleLabel
                                    size={12}
                                    color={"#fff"}
                                    text={"Claimable"}
                                    fontWeight={"light"}
                                />
                            </div>
                            {!isZero(state.unlocked) ? (
                                <div
                                    className={
                                        style.contents_amount_text_amount
                                    }
                                >
                                    <TitleLabel
                                        size={28}
                                        color={"#ffe646"}
                                        text={
                                            claimableDisplayValue
                                                ? claimableDisplayValue.num1
                                                : undefined
                                        }
                                        fontWeight={"bold"}
                                        symbol={
                                            claimableDisplayValue &&
                                            claimableDisplayValue.num2
                                                ? "." +
                                                  claimableDisplayValue.num2 +
                                                  " " +
                                                  props.summary.reward
                                                      .participation.symbol
                                                : props.summary.reward
                                                      .participation.symbol
                                        }
                                    />
                                </div>
                            ) : (
                                <div
                                    className={
                                        style.contents_amount_text_amount
                                    }
                                >
                                    <TitleLabel
                                        size={28}
                                        color={"rgba(255,255,255,0.3)"}
                                        text={"0"}
                                        fontWeight={"bold"}
                                        symbol={
                                            props.summary.reward.participation
                                                .symbol
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div className={style.contents_amount_buttonfield}>
                            <div
                                className={cx(style.contents_amount_button, {
                                    enabled: !isZero(state.unlocked),
                                })}
                                onClick={() => {
                                    if (!isZero(state.unlocked)) {
                                        props.claimParticipationReward();
                                    }
                                }}
                            >
                                {"Claim"}
                            </div>
                        </div>
                    </div>
                    <div className={style.contents_bar}>
                        <div className={style.contents_bar_date}>
                            <div className={style.contents_bar_date_text}>
                                <TitleLabel
                                    size={12}
                                    color={"#fff"}
                                    text={dateFormat8(
                                        distributionSeconds * 1000
                                    )}
                                    fontWeight={"light"}
                                />
                            </div>
                        </div>

                        <div className={style.contents_bar_progress}>
                            <div
                                className={cx(style.progressbar_now, {
                                    full: nowPercent === 100,
                                })}
                                style={{ width: min(nowPercent, "25") + "%" }}
                            />

                            <div className={style.progressbar_after}>
                                <div
                                    className={style.progressbar_reward}
                                    style={{
                                        width: nowDistributionPercent + "%",
                                    }}
                                />
                                <div
                                    className={style.progressbar_claimed}
                                    style={{ width: nowClaimedPercent + "%" }}
                                />
                            </div>
                            <div className={style.progressbar_point} />
                        </div>

                        <div className={style.contents_bar_amount}>
                            <TitleLabel
                                size={12}
                                color={"#fff"}
                                text={
                                    "Claimed: " +
                                    getDecimal(state.claimed, true, 6) +
                                    " " +
                                    props.summary.reward.participation.symbol
                                }
                                fontWeight={"light"}
                            />
                        </div>
                    </div>
                    <div className={style.myreward_dummy} />
                    <div
                        className={cx(style.mobile_button, {
                            enabled: !isZero(state.unlocked),
                        })}
                        onClick={() => {
                            if (!isZero(state.unlocked)) {
                                props.claimParticipationReward();
                            }
                        }}
                    >
                        {"Claim"}
                    </div>
                </div>
            </div>

            {showExplainPopup !== -1 ? (
                <ExplainPopup
                    show={showExplainPopup}
                    setShow={setShowExplainPopup}
                />
            ) : (
                <div />
            )}
        </div>
    );
}
