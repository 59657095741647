import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./TPTInfoParticipation.module.scss";
import { environment } from "../../../../../libs/api/api";

export default function TPTInfoParticipation(props: {
    campaignAddress: string;
    summary: CampaignSummary;
}) {
    return (
        <div className={style.container}>
            <div className={style.participants_title}>
                <TitleLabel
                    fontWeight={"medium"}
                    text={"Swap"}
                    size={14}
                    color={"#ffe646"}
                />
            </div>
            <div className={style.participants_contents}>
                <div className={style.participants_contents_amount}>
                    <div className={style.participants_contents_amount_ico} />
                    <div className={style.participants_contents_amount_text}>
                        <div className={style.ipc_swap}>
                            <div className={style.ipc_swap_ust}>
                                <TitleLabel
                                    size={16}
                                    text={"1"}
                                    color={"#ffe646"}
                                    symbol={
                                        props.summary.reward.participation
                                            .symbol
                                    }
                                />
                            </div>
                            <div className={style.ipc_swap_dummy}>
                                <TitleLabel
                                    size={16}
                                    text={":"}
                                    color={"#ffe646"}
                                />
                            </div>
                            <div className={style.ipc_swap_token}>
                                <TitleLabel
                                    size={16}
                                    text={
                                        props.campaignAddress ===
                                        environment().contracts
                                            .terrapoker_exclusive.campaign
                                            ? environment().contracts
                                                  .terrapoker_exclusive
                                                  .lunaprice
                                            : environment().contracts
                                                  .terrapoker_nonmember
                                                  .lunaprice
                                    }
                                    color={"#ffe646"}
                                    symbol={"LUNA"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.participants_contents_dummy} />
                <div className={style.participants_contents_day}>
                    <div className={style.participants_contents_day_text}>
                        {props.summary.reward.participation.lock_day.toString() +
                            " Days"}
                    </div>

                    <div className={style.participants_contents_day_ico} />
                </div>
            </div>
        </div>
    );
}
