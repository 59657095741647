import InputField from "../../common/InputField/InputField";
import InputFieldMulti from "../../common/InputField/InputFieldMulti";
import { useState } from "react";
import { useEffect } from "react";
export default function GovCreateFormHeader(props: {
    setValid: (v: boolean) => void;
    setInfo: (v: { title: string; desc: string; link: string }) => void;
}) {
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [link, setLink] = useState("");

    const [valid, setValid] = useState(false);

    useEffect(() => {
        props.setInfo({
            title: title,
            desc: desc,
            link: link,
        });

        if (title && desc) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [title, desc, link]);

    useEffect(() => {
        props.setValid(valid);
    }, [valid]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <InputField
                title="Title"
                type="text"
                disabled={false}
                tooltip="Poll title"
                value={title}
                onChanged={setTitle}
                placeholder={"Minimum 5 letters required"}
            />

            <InputFieldMulti
                title="Proposal Rationale"
                placeholder=""
                tooltip={"Short description of poll rationale"}
                value={desc}
                onChanged={setDesc}
            />

            <InputField
                title="Information Link"
                type="text"
                disabled={false}
                tooltip="External URL for further information"
                value={link}
                onChanged={setLink}
                placeholder={"Optional"}
            />
        </div>
    );
}
