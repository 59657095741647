import { environment, wasmExecutes } from "../api";

export async function VPBurn(address: string, amount: string) {
    let exes: WasmExecute[] = [];
    exes.push({
        contract: environment().contracts.token,
        msg: {
            send: {
                contract: environment().contracts.vp_token,
                amount: amount,
                msg: btoa(
                    JSON.stringify({
                        mint: {},
                    })
                ),
            },
        },
        coin: undefined,
    });

    return await wasmExecutes(address, exes);
}
