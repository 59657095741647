import style from "./Main.module.scss";
import PageHeader from "../common/PageHeader/PageHeader";

import MainSection1 from "./MainSection1";
import MainSection2 from "./MainSection2";
import MainSectionBurned from "./MainSectionBurned";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { PATHS } from "../../App";
import { environment } from "../../libs/api/api";

export default function Main() {
    const [bannerSize, setBannerSize] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        onResize();
    }, []);

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    function onResize() {
        setBannerSize({
            width: document.body.clientWidth - 40,
            height: (document.body.clientWidth - 40) * 0.695,
        });
    }

    return (
        <div className={style.container}>
            <div className={style.campaignBanner_wrapper}>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    centeredSlidesBounds
                >
                    <SwiperSlide>
                        <div
                            className={style.campaignBanner}
                            style={
                                document.body.clientWidth <= 1000
                                    ? {
                                          height: bannerSize.height + "px",
                                          width: bannerSize.width + "px",
                                      }
                                    : {}
                            }
                        />
                    </SwiperSlide>
                </Swiper>
            </div>

            <PageHeader title="Overview" docs="" />

            <div className={style.row1_container}>
                <div className={style.row1}>
                    <div className={style.section1_container}>
                        <MainSection1 />
                    </div>
                    <div className={style.section2_container}>
                        <MainSectionBurned />
                        <MainSection2 />
                    </div>
                </div>
            </div>
        </div>
    );
}
