import { CreateTxOptions } from "@terra-money/terra.js";
import { environment, wasmExecutes } from "../api";

export async function claimAirdropV2(
    address: string
): Promise<CreateTxOptions> {
    let exes: WasmExecute[] = [];

    exes.push({
        contract: environment().contracts.vkr_airdrop_v2,
        msg: {
            claim: {
                stage: 1,
            },
        },
        coin: undefined,
    });

    return await wasmExecutes(address, exes);
}
