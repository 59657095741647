import { useEffect, useState } from "react";
import * as Utils from "../../../../libs/utils";
import style from "./StakeLpUnstake.module.scss";
import { queryTokenBalance } from "../../../../libs/api/trade/query";
import { unstakeLpEstimate } from "../../../../libs/api/astroport_liquidity/query";
import { environment } from "../../../../libs/api/api";
import InputField from "../../../common/InputField/InputField";
import ConfirmButton from "../../../common/ConfirmButton/ConfirmButton";

import {
    fixed,
    isZero,
    leftGreaterThanRight,
    multiply,
} from "../../../../libs/Math";
import TxInfoView from "../../../common/TxInfoView/TxInfoView";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../libs/contexts";
// import { getFeeNormal } from "../../../../libs/api/fee";
import LineDummy from "../../../common/LineDummy/LineDummy";
import { getFeeRaw } from "../../../../libs/api/fee";

export default function StakeLpUnstakeManual2(props: {
    estimateParams?: ResponseLpEstimate;
    divideLpPressed: (amount: string) => void;
}) {
    const wallet = useRecoilValue(walletState);
    const [balance, setBalance] = useState("0");

    const [fee, setFee] = useState("0");

    useEffect(() => {
        if (wallet) {
            queryTokenBalance(
                environment().contracts.astroport.lp_token,
                wallet.terraAddress
            )
                .then((r) => {
                    setBalance(r.amount);
                })
                .catch((e) => {
                    setBalance("0");
                });
        }
    }, [wallet]);

    const [token, setToken] = useState("");
    const [error1, setError1] = useState<string | undefined>(undefined);
    const [lpEstimated, setLpEstimated] = useState({
        token: "",
        axlUSDC: "",
    });

    useEffect(() => {
        if (leftGreaterThanRight(token, Utils.getDecimal(balance))) {
            setError1("Insufficient balance");
        } else if (isZero(token)) {
            setError1("Amount must be greater than 0");
        } else {
            setError1(undefined);
        }
    }, [token, balance]);

    useEffect(() => {
        if (token && props.estimateParams) {
            unstakeLpEstimate(
                {
                    token: props.estimateParams.pools.token,
                    axlUSDC: props.estimateParams.pools.axlUSDC,
                },
                multiply(token, 1000000),
                props.estimateParams.lp_total_supply
            )
                .then((r) => {
                    setLpEstimated(r);
                })
                .catch((e) => {});
        } else {
            setLpEstimated({
                token: "0",
                axlUSDC: "0",
            });
        }
    }, [token, props.estimateParams]);

    return (
        <div className={style.inner_container}>
            <InputField
                title={"VKR-axlUSDC LP"}
                type="amount"
                balance={balance}
                symbol={"LP"}
                onChanged={(v: string) => {
                    setToken(v);

                    let exes: WasmExecute[] = [];

                    let lp = fixed(multiply(v, "1000000"), 0);

                    exes.push({
                        contract: environment().contracts.astroport.lp_token,
                        msg: {
                            send: {
                                contract:
                                    environment().contracts.astroport.pair,
                                amount: lp,
                                msg: btoa(
                                    JSON.stringify({
                                        withdraw_liquidity: {},
                                    })
                                ),
                            },
                        },
                        coin: undefined,
                    });

                    getFeeRaw(
                        wallet?.terraAddress ? wallet.terraAddress : "",
                        exes
                    )
                        .then((r) => {
                            let uluna = "uluna";
                            setFee(r.amount.toString().replace(uluna, ""));
                        })
                        .catch((e) => {
                            if (
                                e.response.data.message ===
                                "failed to execute message; message index: 0: dispatch: submessages: dispatch: submessages: 0" +
                                    environment().contracts.usdc_token +
                                    ": invalid coins: invalid request"
                            ) {
                                setError1(
                                    "0" +
                                        environment().contracts.usdc_token +
                                        " invalid coins."
                                );
                            }
                            setFee("0");
                        });
                }}
                value={token}
                error={error1}
                autofocus={true}
                showSymbolIcon={true}
            />

            <div className={style.line_container}>
                <LineDummy horizontal={true} marginTop={10} marginBottom={0} />
            </div>
            <div className={style.exchangeicon} />

            <div className={style.row}>
                {/* <div style={{ flex: 1 }}> */}
                <InputField
                    title={"Output VKR"}
                    type="amount"
                    symbol={"VKR"}
                    disabled={true}
                    onChanged={(v: string) => {}}
                    value={Utils.getDecimal(lpEstimated.token, true)}
                    error={undefined}
                    showSymbolIcon={true}
                />
                {/* </div> */}

                <div className={style.icon} />

                {/* <div style={{ flex: 1 }}> */}
                <InputField
                    title={"Output axlUSDC"}
                    type="amount"
                    symbol={"axlUSDC"}
                    disabled={true}
                    onChanged={(v: string) => {}}
                    value={Utils.getDecimal(lpEstimated.axlUSDC, true, 6)}
                    error={undefined}
                    showSymbolIcon={true}
                />
                {/* </div> */}
            </div>

            {token ? <InfoView fee={fee} /> : <div />}

            <ConfirmButton
                title={"Withdraw"}
                enable={!error1 && token !== ""}
                onClick={() => {
                    props.divideLpPressed(fixed(multiply(token, 1000000), 0));
                }}
            />
        </div>
    );
}

function InfoView(props: { fee: string }) {
    return <TxInfoView fee={props.fee} />;
}
