import style from "./SayveVpCheck.module.scss";
import acceptIcon from "../../../../../resources/ipc-success01.svg";
import failIcon from "../../../../../resources/ipc-no01.svg";
import ingIcon from "../../../../../resources/ipc-ing.svg";
import ingIcon2 from "../../../../../resources/ipc-ing02.svg";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getDecimal } from "../../../../../libs/utils";
import { PATHS } from "../../../../../App";
import { ConditionType } from "./SayveDepositCheck";
const cx = classNames.bind(style);

export default function SayveVpCheck(props: {
    status: ConditionType;
    statusSub?: string | undefined;
    balance: string | undefined;
    text: string | undefined;
}) {
    const [icon, setIcon] = useState<any | undefined>(undefined);
    const history = useHistory();
    const [textColor, setTextColor] = useState("white");

    useEffect(() => {
        if (props.status === ConditionType.confirm) {
            setIcon(acceptIcon);
        } else if (props.status === ConditionType.deny) {
            setIcon(failIcon);
        } else if (props.status === ConditionType.swaplight) {
            setIcon(ingIcon);
        } else {
            setIcon(ingIcon2);
        }

        if (props.status === ConditionType.deny) {
            setTextColor("#fd5214");
        } else {
            setTextColor("white");
        }
    }, [props.status]);

    return (
        <div className={style.container}>
            <div className={style.mobile_status}>
                <img className={style.mobile_icon} src={icon} alt="" />
                {props.statusSub !== undefined ? (
                    <div className={style.mobile_statusfield}>
                        <div
                            className={cx(style.mobile_statustitle2, {
                                fail: props.status === ConditionType.deny,
                                ing: props.status === ConditionType.swaplight,
                                ing2: props.status === ConditionType.swapdark,
                            })}
                        >
                            {"VP For Participation"}
                        </div>
                        <div
                            className={cx(style.mobile_statussub, {
                                fail: props.status === ConditionType.deny,
                                ing: props.status === ConditionType.swaplight,
                                ing2: props.status === ConditionType.swapdark,
                            })}
                        >
                            {props.statusSub}
                        </div>
                    </div>
                ) : (
                    <div
                        className={cx(style.mobile_statustitle, {
                            fail: props.status === ConditionType.deny,
                            ing: props.status === ConditionType.swaplight,
                            ing2: props.status === ConditionType.swapdark,
                        })}
                    >
                        {"VP For Participation"}
                    </div>
                )}
            </div>
            <div className={style.balancefield}>
                <div className={style.balance}>
                    <div
                        className={cx(style.balance_main, {
                            fail: props.status === ConditionType.deny,
                        })}
                    >
                        {props.balance !== undefined
                            ? "Balance: " + getDecimal(props.balance, true, 6)
                            : "Balance: " + getDecimal("0", true, 6)}
                    </div>
                    <div
                        className={cx(style.balance_symbol, {
                            fail: props.status === ConditionType.deny,
                        })}
                    >
                        {"VP"}
                    </div>
                </div>
            </div>
            <div className={style.contentbox}>
                <div className={style.status}>
                    <img className={style.icon} src={icon} alt="" />
                    {props.statusSub !== undefined ? (
                        <div className={style.statusfield}>
                            <div
                                className={cx(style.statustitle2, {
                                    fail: props.status === ConditionType.deny,
                                    ing:
                                        props.status ===
                                        ConditionType.swaplight,
                                    ing2:
                                        props.status === ConditionType.swapdark,
                                })}
                            >
                                {"VP For Participation"}
                            </div>
                            <div
                                className={cx(style.statussub, {
                                    fail: props.status === ConditionType.deny,
                                    ing:
                                        props.status ===
                                        ConditionType.swaplight,
                                    ing2:
                                        props.status === ConditionType.swapdark,
                                })}
                            >
                                {props.statusSub}
                            </div>
                        </div>
                    ) : (
                        <div
                            className={cx(style.statustitle, {
                                fail: props.status === ConditionType.deny,
                                ing: props.status === ConditionType.swaplight,
                                ing2: props.status === ConditionType.swapdark,
                            })}
                        >
                            {"VP For Participation"}
                        </div>
                    )}
                </div>
                <div className={style.symbolfield}>
                    <div className={style.amount}>
                        <div
                            className={style.amount_text}
                            style={{ color: textColor }}
                        >
                            {props.text}
                        </div>
                    </div>
                    <div className={style.symbol}>
                        <div className={style.vp}>
                            <div className={style.vp_symbol}>{"VP"}</div>
                            <div className={style.vp_dummy} />
                            <div
                                className={style.vp_button}
                                onClick={() => {
                                    history.push(PATHS.vp);
                                }}
                            >
                                <div className={style.vp_ico} />
                                <div className={style.vp_text}>
                                    {"Create VP"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
