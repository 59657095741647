import { useEffect, useState } from "react";
import style from "./StakeGovCard.module.scss";
import * as Utils from "../../libs/utils";

import TitleLabel from "../common/Labels/TitleLabel";
import LineDummy from "../common/LineDummy/LineDummy";
import { govState } from "../../libs/api/governance/query";
import { ClaimButton, StakeButton } from "../common/NormalButton/NormalButton";
import { isZero, leftGreaterThanRight, multiply } from "../../libs/Math";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../App";
import Skeleton from "react-loading-skeleton";
import { queryPrice } from "../../libs/api/trade/query";
import { environment } from "../../libs/api/api";
import { useRecoilValue } from "recoil";
import { walletState } from "../../libs/contexts";
import classNames from "classnames/bind";
const cx = classNames.bind(style);

export default function StakeGovCard(props: { claimPressed: () => void }) {
    const wallet = useRecoilValue(walletState);
    const history = useHistory();
    const [stakeInfo, setStakeInfo] = useState<StakeGovHeaderInfo | undefined>(
        undefined
    );

    const [estimate, setEstimate] = useState("0");
    const symbol = "VKR";

    useEffect(() => {
        govState(wallet ? wallet.terraAddress : undefined)
            .then((r) => {
                setStakeInfo(r);
            })
            .catch((e) => {});
    }, [wallet]);

    useEffect(() => {
        if (stakeInfo) {
            queryPrice(environment().contracts.token)
                .then((r) => {
                    setEstimate(multiply(r, stakeInfo.staked));
                })
                .catch((e) => {});
        }
    }, [stakeInfo]);

    if (!stakeInfo) {
        return (
            <div className={style.stake_info_container}>
                <div className={style.inner}>
                    <div className={style.not_info}>
                        <div className={style.title}>{"Governance"}</div>
                        <div className={style.info}>
                            <Skeleton width={200} height={25} />
                        </div>
                        <div className={style.icon}>
                            <div className={style.icon1} />
                        </div>

                        <div className={style.info_line_container}>
                            <LineDummy
                                horizontal={true}
                                marginTop={0}
                                marginBottom={0}
                            />
                        </div>

                        <div className={style.stake_button}>
                            <Skeleton width={250} height={48} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={style.stake_info_container}>
            <div className={style.inner}>
                {leftGreaterThanRight(stakeInfo.staked, 0) ? (
                    <div className={style.staked_container}>
                        <div className={style.icon}>
                            <div className={style.icon1} />
                        </div>
                        <div className={style.title_container}>
                            <div className={style.title}>{"Governance"}</div>
                            <div className={style.apr_container}>
                                <div className={style.apr}>{"APR"}</div>
                                <TitleLabel
                                    size={20}
                                    color={"#ffe646"}
                                    text={Utils.getPercentFormat(stakeInfo.apr)}
                                    symbol={"%"}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={style.stake_container}>
                        <div className={style.title}>{"Governance"}</div>
                        <div className={style.apr_container}>
                            <div className={style.apr}>{"APR"}</div>
                            <TitleLabel
                                size={20}
                                color={"#ffe646"}
                                text={Utils.getPercentFormat(stakeInfo.apr)}
                                symbol={"%"}
                            />
                        </div>
                        <div className={style.icon}>
                            <div className={style.icon1} />
                        </div>
                    </div>
                )}

                {leftGreaterThanRight(stakeInfo.staked, 0) ? (
                    <div className={style.info}>
                        <div className={style.title}>{"My Staked Amount"}</div>
                        <div className={style.amount_container}>
                            <div className={style.icon} />
                            <TitleLabel
                                size={24}
                                text={Utils.getDecimal(stakeInfo.staked, true)}
                                color={"white"}
                                symbol={symbol}
                            />
                        </div>

                        <div style={{ height: "2px" }} />
                        <TitleLabel
                            size={12}
                            text={Utils.getDecimal(estimate, true, 3)}
                            color={"rgba(255, 255, 255, 0.3)"}
                            symbol={"axlUSDC"}
                            fontWeight={"light"}
                        />
                    </div>
                ) : (
                    <div />
                )}

                {leftGreaterThanRight(stakeInfo.staked, 0) ||
                leftGreaterThanRight(stakeInfo.pending, 0) ? (
                    <div
                        className={cx(style.reward_box, {
                            staked: leftGreaterThanRight(stakeInfo.staked, 0),
                        })}
                    >
                        <div className={style.reward1}>
                            <TitleLabel
                                text="Reward"
                                color={"rgba(255, 255, 255, 0.5)"}
                                fontWeight={"light"}
                            />
                            <div style={{ height: "8px" }} />
                            <TitleLabel
                                text={"Automatically"}
                                size={16}
                                color={"#ffe646"}
                                symbol={undefined}
                            />
                        </div>

                        <div className={style.dummy}>
                            <div className={style.dummy1} />
                            <div className={style.dummy2} />
                        </div>

                        <div className={style.reward2}>
                            <div className={style.reward2_sub}>
                                <TitleLabel
                                    text="VP Reward"
                                    color={"rgba(255, 255, 255, 0.5)"}
                                    fontWeight={"light"}
                                />
                                <div style={{ height: "8px" }} />
                                <TitleLabel
                                    text={Utils.getDecimal(
                                        stakeInfo.pending,
                                        true
                                    )}
                                    size={16}
                                    color={"#ffe646"}
                                    symbol={"VP"}
                                />
                            </div>

                            <div className={style.button}>
                                <ClaimButton
                                    title={"Claim"}
                                    textHighlight={isZero(stakeInfo.pending)}
                                    onClick={props.claimPressed}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div />
                )}
                {/* <div
                    className={cx(style.line_container, {
                        staked:
                            leftGreaterThanRight(stakeInfo.staked, 0) === true,
                    })}
                >
                    <LineDummy
                        horizontal={true}
                        marginTop={69}
                        marginBottom={0}
                    />
                </div> */}

                {leftGreaterThanRight(stakeInfo.staked, 0) ? (
                    <div className={style.stake_buttons}>
                        <StakeButton
                            title={"Stake"}
                            isStaked={true}
                            onClick={() => {
                                history.push(PATHS.stakeGov);
                            }}
                        />
                        <div className={style.dummy} />
                        <StakeButton
                            title={"Unstake"}
                            isStaked={true}
                            onClick={() => {
                                history.push(PATHS.stakeGov + "#unstake");
                            }}
                        />
                    </div>
                ) : (
                    <div
                        className={cx(style.stake_button, {
                            rewarded: leftGreaterThanRight(
                                stakeInfo.pending,
                                0
                            ),
                        })}
                    >
                        <StakeButton
                            title={"Stake"}
                            isStaked={false}
                            onClick={() => {
                                history.push(PATHS.stakeGov);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
