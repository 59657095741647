import {
    Coins,
    LCDClient,
    MsgExecuteContract,
    TxInfo,
    Fee,
    AccAddress,
    CreateTxOptions,
    Tx,
    TxLog,
} from "@terra-money/terra.js";
import axios from "axios";

import { getNetwork } from "../storage";
import * as Utils from "../utils";
import { NetworkInfo } from "@terra-money/wallet-provider";

// export const blockPerDay = 17280;
export const blockPerDay = 12758;

export enum NetworkType {
    mainnet,
    testnet,
    localterra,
}

export const WebLinks = {
    github: "https://github.com/valkyrieprotocol",
    twitter: "https://twitter.com/valkyrie_money",
    forum: "https://forum.valkyrieprotocol.com",
    telegram: "https://t.me/valkyrie_protocol",
    discord: "",
    medium: "https://medium.com/@valkyrie-protocol",
};

export const DocsLinks = {
    campaign: "https://docs.valkyrieprotocol.com/webapp/campaign#campaign-main",
    trade: "https://docs.valkyrieprotocol.com/webapp/trade#trade",
    stake: "https://docs.valkyrieprotocol.com/webapp/stake",
    governance:
        "https://docs.valkyrieprotocol.com/webapp/governance#governance-main",
};

export function isMainnet(info: NetworkInfo | undefined): NetworkType {
    const n = info ? info : getNetwork() ?? network.mainnet;

    if (
        n.name.toLowerCase().includes("mainnet") ||
        n.name.toLowerCase().includes("classic")
    ) {
        return NetworkType.mainnet;
    } else if (n.name.toLowerCase().includes("testnet")) {
        return NetworkType.testnet;
    } else {
        return NetworkType.localterra;
    }
}

export const network = {
    mainnet: {
        name: "mainnet",
        chainID: "phoenix-1",
        lcd: "https://phoenix-lcd.terra.dev",
    },
    testnet: {
        name: "testnet",
        chainID: "bombay-12",
        lcd: "https://bombay-lcd.terra.dev",
    },
    local: {
        name: "localterra",
        chainID: "localterra",
        lcd: "https://lcd.terra.dev.brzlabs.cc",
    },
};

export function environment() {
    if (isMainnet(undefined) === NetworkType.mainnet) {
        return {
            api: "https://terra-api.valkyrieprotocol.com",
            qualification_api:
                "https://terra-qualification-api.valkyrieprotocol.com",

            finder: {
                tx: "https://finder.terra.money/mainnet/tx/",
                account: "https://finder.terra.money/mainnet/address/",
            },
            contracts: {
                governance:
                    "terra1hkdpd7d5l5pn06rvkk8v8zh8segfeuutjx29xau2xvrvepcj7vvs7frq0h",
                token: "terra1gy73st560m2j0esw5c5rjmr899hvtv4rhh4seeajt3clfhr4aupszjss4j",
                campaignManager:
                    "terra1uxzxe4vukgucrlp70k988al7m2e3lsln7zd2cp00tukqtv9vldyq4m8ujn",
                vkr_airdrop_v2:
                    "terra10dxw3jhvu48dj2nnh6urq706gqwqapsa5rzmyynrep82xhnefvdq4hwn3j",
                vp_airdrop_v2:
                    "terra1vg8gvsenq3kmj898zelg90w7364uqndd0l9l3kx7xpq3rghhf44q4g55wh",
                tx_qualifier:
                    "terra10kns0kffyhfn4f2zdq5swaclgl5jp8e8fapfw7kwace8ndyvt38sazt0pu",

                distributor:
                    "terra1t35gp5v48cvkxqmc6azm4g3jrs68xs72rpvuzs4j3x5urxe5eakq8r9waj",
                community:
                    "terra1xufmuzqt9kn58phg6n082q796qjek2daxtdjft6ff822yantvcasr2c369",
                proxy: "terra1k2na93mpdknylcwae28xzdy3pwtar8w9feasq95nd8f7hzlm778qgaxs8f",
                vp_token:
                    "terra1xepgy0lp0lkt9dq89pfe3sqwnfyln29r2zj5qqkp75l2egthmmkqkn36vv",
                usdc_token:
                    "ibc/B3504E092456BA618CC28AC671A71FB08C6CA0FD0BE7C8A5B5A3E2DD933CC9E4",
                astroport: {
                    generator:
                        "terra1ksvlfex49desf4c452j6dewdjs6c48nafemetuwjyj6yexd7x3wqvwa7j9",
                    factory:
                        "terra14x9fr055x5hvr48hzy2t4q7kvjvfttsvxusa4xsdcy702mnzsvuqprer8r",
                    pair: "terra1alzkrc6hkvs8g5a064cukfxnv0jj4l3l8vhgfypfxvysk78v6dgqsymgmv",
                    lp_token:
                        "terra18mcmlf4v23ehukkh7qxgpf5tznzg6893fxmf9ffmdt9phgf365zqvmlug6",
                    lp_staking:
                        "terra1ntuxp79l68pjnj5q6ys8a0chwlu9sqd5zm47umvkcqv6ym06wghsp3qu7n",
                    token: "terra1nsuqsk6kh58ulczatwev87ttq2z6r3pusulg9r24mfj2fvtzd4uq3exn26",
                },

                terrapoker_smartairdrop: {
                    campaign:
                        "terra190kw5lnzkgspnna7pvmhx8vehez4x9xe9q0dvewwsu3sdt57e5usarhsqd",
                    table_manager:
                        "terra1vavu6en35af4wg9k5tzhssg2n0krffvs8tufqc7ddpsyre9d2gxs6ttk55",
                },
                terrapoker_exclusive: {
                    campaign:
                        "terra1d6uzypdl9l8t0at4chmcgm5tywc3723sfj9h2n6c5ft53qtv9zlsq2j45c",
                    total_distribution: "19000000000000",
                    lunaprice: "0.01",
                    startTimeSeconds: 1661256000,
                    min_uluna_amount: "75000000",
                    max_uluna_amount: "6000000000",
                },

                terrapoker_nonmember: {
                    campaign:
                        "terra1ltr379tlhklclfcnptgyml33g8k8wur6lyq8v45jmxudhycalgeqsrrvtr",
                    total_distribution: "1000000000000",
                    lunaprice: "0.01",
                    startTimeSeconds: 1661256000,
                    min_uluna_amount: "5000000",
                    max_uluna_amount: "50000000",
                },

                fanfury_early: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "2940000000000",
                    ustprice: "0.05",
                    startTimeSeconds: 1651060800,
                },

                fanfury_public: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "1260000000000",
                    ustprice: "0.05",
                    startTimeSeconds: 1651147200,
                },
                talis_early: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "50000000000000",
                    ustprice: "0.012",
                    startTimeSeconds: 1652612400,
                },

                talis_public: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "25000000000000",
                    ustprice: "0.012",
                    startTimeSeconds: 1652698800,
                },
                sayve_early: {
                    campaign:
                        "terra18gvnd3y4lf02lfcgc8g5ffn983ljcjfcfehleklamyp8eshd9neqn54w7l",
                    qualifier:
                        "terra12elkda9adejtxfneu0eu00tyl349z3v38t5ls3x4cpe2yg2setyqvslk04",
                    total_distribution: "4500000000000",
                    lunaprice: "0.0333",
                    startTimeSeconds: 1672920000,
                    min_uluna_amount: "40000000",
                    max_uluna_amount: "1500000000",
                },

                sayve_public: {
                    campaign:
                        "terra1er88ut5mky0v3fp9z2ywfrh75dsd2ymm27q09dz9ng7crdpjyjxsdduwjy",
                    qualifier:
                        "terra1fzmfnkznd4n36eswgeffw3hjrxnfcjq2msxtp9085x3nz4rcgams2l9qtf",
                    total_distribution: "500000000000",
                    lunaprice: "0.0333",
                    startTimeSeconds: 1672920000,
                    min_uluna_amount: "10000000",
                    max_uluna_amount: "150000000",
                },
            },
            assets: {
                tokens: "https://assets.terra.money/cw20/tokens.json",
            },
        };
    } else if (isMainnet(undefined) === NetworkType.testnet) {
        return {
            api: "https://terra-api.testnet.valkyrieprotocol.com/",
            qualification_api:
                "http://terra-qualification-api.testnet.valkyrieprotocol.com",

            finder: {
                tx: "https://finder.terra.money/testnet/tx/",
                account: "https://finder.terra.money/testnet/address/",
            },
            contracts: {
                governance:
                    "terra1ehh2vv7ldyr3dhcpxqxkg4a0ctlxd4umcm0ax4ldp4ruq2f2zhksftsvsj",
                token: "terra1xqtstvlwcpz3kpcpw6vqh2grstw34wcezws5pawll95uuysu0hnqk6muds",
                campaignManager:
                    "terra1ya03zfa9rt4ddq0ql8mk47d55t0wfksshu0k5ts3kkn3t5737t0qsae56d",
                vkr_airdrop_v2:
                    "terra19r0zwr3pkvagx8yyafs2pae89vx26q2s7fglw8xtcc3x7qs3gelsw0zk7p",
                vp_airdrop_v2:
                    "terra16wsemadx7zec56gqxdsgtqrycvj0am2z28wyh4q7a45jrg9hrnpsqt24jt",
                tx_qualifier:
                    "terra1gw8eaj5p44fm5kdk38axxt7q30hgnssw2l2pp6rw50e7pt0vjclqqr4ppa",

                distributor:
                    "terra1js8kt3wme7plj4e0x3kry0lczjwl3twp9qgwwv5va9f380uy3zgsg73uj5",
                community:
                    "terra1xm3n8y8hkh4je9u3hs9jmel97t6tz6nge5806gfqsz0maqp3kkwsj7flvu",
                proxy: "terra1dej0kl2lj479guuzzvwmmt2pyvm90wt46w4lnvhv9crxq8r2gj9svncmu9",
                vp_token:
                    "terra1t58m93a5v8egxq2jvzqcylyf6hfpq59tkzz7ny0u3ewu7xc6963qe4zqlq",
                usdc_token: "uluna",

                astroport: {
                    generator:
                        "terra1gc4d4v82vjgkz0ag28lrmlxx3tf6sq69tmaujjpe7jwmnqakkx0qm28j2l",
                    factory:
                        "terra1z3y69xas85r7egusa0c7m5sam0yk97gsztqmh8f2cc6rr4s4anysudp7k0",
                    pair: "terra1d5hvj5l7dd7xuz343pq9zyex47ekgqj27rskqwfde073dhck4mls39ewkk",
                    lp_token:
                        "terra15npavsvzqsnphnda67v5jpr2md4fp7gyexeffnv08wp8tlxn88xsjvxkgx",
                    lp_staking:
                        "terra1uqdtkh04hwvxy6vayz3nhrux5g8guyqaxzwpx7w948zk938pczsst3zjjs",
                    token: "",
                },
                terrapoker_smartairdrop: {
                    campaign: "",
                    table_manager: "",
                },
                terrapoker_exclusive: {
                    campaign:
                        "terra1p20y7yppfsz92ykafmfqzq89vd26mq8j6juprqu5rklad0fwt2ds7ecmja",
                    total_distribution: "15000000000000",
                    lunaprice: "0.01",
                    startTimeSeconds: 1660798800,
                    min_uluna_amount: "75000000",
                    max_uluna_amount: "6000000000",
                },

                terrapoker_nonmember: {
                    campaign:
                        "terra1gne99yhareve9c3cqqyhehh8aa96h40knytjfxkt49fczmrqxfzq2y5td2",
                    total_distribution: "5000000000000",
                    lunaprice: "0.01",
                    startTimeSeconds: 1660798800,
                    min_uluna_amount: "5000000",
                    max_uluna_amount: "50000000",
                },

                fanfury_early: {
                    campaign: "terra18jcdvstmze80kfcq3egc3a9sqy4n7p7nwl8sdp",
                    qualifier: "terra1ac40le3za2hza5a0pf24ygm8gxcnmcn88s57qq",
                    total_distribution: "2940000000000",
                    ustprice: "0.05",
                    startTimeSeconds: 1651060800,
                    // depositvkr: "1500000000",
                    // usedvp: "100000000",
                },

                fanfury_public: {
                    campaign: "terra1ux4rhwmhp460jf6qgaaprxtp2fuj0xyec3szud",
                    qualifier: "terra14ev5asdp0f8vkvq0hmt34lz2l3p4s0e0twllgg",
                    total_distribution: "1260000000000",
                    ustprice: "0.05",
                    startTimeSeconds: 1651147200,
                    // depositvkr: "600000000",
                    // usedvp: "15000000",
                },
                talis_early: {
                    campaign: "terra16drcmcv7atk47lu96lf9gezvt2wv4aejlqc7j0",
                    qualifier: "terra166254nll3zx5qe7eutsdmcfedhz09halw6ef2f",
                    total_distribution: "50000000000000",
                    ustprice: "0.012",
                    startTimeSeconds: 1652612400,
                    // depositvkr: "1500000000",
                    // usedvp: "100000000",
                },

                talis_public: {
                    campaign: "terra1huxhlrku59yzhg7q3yqmufvrrll07zpdvv5y3j",
                    qualifier: "terra1y6mmudtgd944z928g9dhw026n6q9stvq78j0vp",
                    total_distribution: "25000000000000",
                    ustprice: "0.012",
                    startTimeSeconds: 1652698800,
                    // depositvkr: "600000000",
                    // usedvp: "15000000",
                },
                sayve_early: {
                    campaign:
                        "terra1608mpvdysymcnha2rrldjt7rkfre0yvg9694xxqzwfnt0kd3qfcqpfxf6f",
                    qualifier:
                        "terra12z603zmzqgqqnyvl45vpuhx6cm39axfy2q7fc5lql0zl57shm82svushx0",
                    total_distribution: "4500000000000",
                    lunaprice: "0.0333",
                    startTimeSeconds: 1672920000,
                    min_uluna_amount: "40000000",
                    max_uluna_amount: "1500000000",
                },

                sayve_public: {
                    campaign:
                        "terra1a2n9akf9hnqymx0vxkuqghjzh269al5944dzhxx825wtaya20g3qjq8gfy",
                    qualifier:
                        "terra16c29h2tfpvxnmfvgdje7alcfkvsrakq730tzjda938zx65hy6hmscveqe4",
                    total_distribution: "500000000000",
                    lunaprice: "0.0333",
                    startTimeSeconds: 1672920000,
                    min_uluna_amount: "10000000",
                    max_uluna_amount: "150000000",
                },
            },
            assets: {
                tokens: "https://assets.terra.money/cw20/tokens.json",
            },
        };
    } else {
        return {
            api: "https://terra-api.dev.valkyrieprotocol.com",
            qualification_api:
                "https://terra-qualification-api.dev.valkyrieprotocol.com",

            finder: {
                tx: "https://finder.terra.money/testnet/tx/",
                account: "https://finder.terra.money/testnet/address/",
            },
            contracts: {
                governance:
                    "terra140g09a4pmpsleymjl5aqvc44lntln7v72gg786pm0zg9y0pzzcyqyahdzj",
                token: "terra16vv4h7ynt7gx4cu7vy2jg8xvfhv7yenn6skag8ee6xcwn2clgkas396teg",
                campaignManager:
                    "terra16kypqf40hm3sjcfrk5m4st45wqt0hyx8926f3lkcl7qnrc2gx5fslzr9yv",
                vkr_airdrop_v2:
                    "terra13yemg0h4ystkmhq0uxerz8vklq2s83nmx03apn0nzzxn224hgrsshyrujk",
                vp_airdrop_v2:
                    "terra1k2hva45d0e30jacz6jh8uqp9nkascq0wstmu0e8ceng09d5fcwwqtvzze7",
                tx_qualifier:
                    "terra1vfe7xa6umyuus9c784zyayhl8ggt3mgdtaa0hfmm5d9yvfwlljsqqn5a97",

                distributor:
                    "terra1ev957l7m53u8vha4a0cd5pfjq7wv006ts3qtn6xgqapy9zzumswqesjtxc",
                community:
                    "terra15enxpw6hn2xzxqsgyrm40rqnzzvjg7c79n3lftju6aj5m0tp8ndsxn7j88",
                proxy: "terra1qmwmlyp9e5wsp2xdgf33849le7jxxspyvk5944v7waxl3j5ejhpsg8sc7k",
                vp_token:
                    "terra1pvh5kxvne2fgnykdu8fwp2t76mzqlwjfh76xz7g6kpr9pyvy6lmqa589x6",
                usdc_token: "uluna",

                astroport: {
                    generator:
                        "terra1gr5lc8wsngv66gnwt6j0qnvsf40e79cknkej5pq7g9na64xmd5esk6t46a",
                    factory:
                        "terra1rzdldyj687vgvmmujz5hv9nmskhnexd53erdaahc7uus337rw5kstpt45a",
                    pair: "terra1q2eeete6dqj7v5lcakswn88s4ycg3njll689ln0vtkrqfpa2t9kqgqsu5a",
                    lp_token:
                        "terra1lrfgjpfknfctz4k5qa2u0a8e7jptjqya9qnunggm9cmhn840q3xs73gsxz",
                    lp_staking:
                        "terra1gajetnk2mh0xanpkqpya8sakk5ljnqtzry6wy8mmaudea036v38q5dxv6x",
                    token: "",
                },
                terrapoker_smartairdrop: {
                    campaign:
                        "terra12jxphfjk23tqctgs30pvkpz9jhrmxsy5u9tyv8dt66clcs48fd8ss98p9w",
                    table_manager:
                        "terra14csahy5v87dn3cm5n89ymcnq80uv0s5vhm44ryuk3hdh4zcc75cqz9dv0u",
                },
                terrapoker_exclusive: {
                    campaign:
                        "terra1mhsxjva075z3k4z239eehfznpfkh57lqvdn8fh4672n6jf4tfkqqjg48v4",
                    total_distribution: "20000000000000",
                    lunaprice: "0.01",
                    startTimeSeconds: 1651147200,
                    min_uluna_amount: "75000000",
                    max_uluna_amount: "6000000000",
                },

                terrapoker_nonmember: {
                    campaign:
                        "terra1mq76nl8tvcxl8l583y8wqdnzgmg82ucrrr9gn2lugelqwn7zwvus2pg8kt",
                    total_distribution: "20000000000000",
                    lunaprice: "0.01",
                    startTimeSeconds: 1651147200,
                    min_uluna_amount: "5000000",
                    max_uluna_amount: "50000000",
                },

                fanfury_early: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "2940000000000",
                    ustprice: "0.05",
                    startTimeSeconds: 1651060800,
                    // depositvkr: "1500000000",
                    // usedvp: "100000000",
                },

                fanfury_public: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "1260000000000",
                    ustprice: "0.05",
                    startTimeSeconds: 1651060800,
                    // depositvkr: "600000000",
                    // usedvp: "15000000",
                },
                talis_early: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "50000000000000",
                    ustprice: "0.012",
                    startTimeSeconds: 1651060800,
                    // depositvkr: "1500000000",
                    // usedvp: "100000000",
                },

                talis_public: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "25000000000000",
                    ustprice: "0.012",
                    startTimeSeconds: 1651147200,
                    // depositvkr: "600000000",
                    // usedvp: "15000000",
                },

                sayve_early: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "4500000000000",
                    lunaprice: "0.0333",
                    startTimeSeconds: 1672920000,
                    min_uluna_amount: "40000000",
                    max_uluna_amount: "1500000000",
                },

                sayve_public: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "500000000000",
                    lunaprice: "0.0333",
                    startTimeSeconds: 1672920000,
                    min_uluna_amount: "10000000",
                    max_uluna_amount: "150000000",
                },
            },
            assets: {
                tokens: "https://assets.terra.money/cw20/tokens.json",
            },
        };
    }
}

let lcdClient: LCDClient | undefined = undefined;
export function getLcdClient(): LCDClient {
    if (lcdClient === undefined) {
        const n = getNetwork() ?? network.mainnet;

        // lcdClient = new LCDClient({
        //     URL: n.lcd,
        //     chainID: n.chainID,
        // });

        lcdClient = new LCDClient({
            URL: n.name === "mainnet" ? "https://phoenix-lcd.terra.dev" : n.lcd,
            chainID: n.chainID,
            // isClassic: true,
        });
    }

    return lcdClient;
}

export async function latestBlock(): Promise<{
    height: number;
    time: number;
}> {
    return await blockInfo();
}

export async function getPublicKey(address: string): Promise<string> {
    const account = await getLcdClient().auth.accountInfo(address);

    let json = JSON.parse(account.toJSON());
    console.log(json);
    let publicKey = json.pub_key.key;

    return publicKey;
}

async function blockInfo(height?: number) {
    const blockInfo = (await getLcdClient().tendermint.blockInfo(height)).block;

    return {
        height: parseInt(blockInfo.header.height),
        time: Date.parse(blockInfo.header.time),
    };
}

export async function blockTime(
    latest: { height: number; time: number },
    height: number
): Promise<number> {
    const latestHeight = latest.height;

    if (height === latestHeight) {
        return latest.time;
    } else if (height < latestHeight) {
        return (await blockInfo(height)).time;
    } else {
        const block = latest.height - 100000;
        const genesis = await blockInfo(Math.max(block, 2));

        const genesisHeight = genesis.height;
        const genesisTime = genesis.time;
        const latestTime = latest.time;
        const timePerBlock =
            (latestTime - genesisTime) / (latestHeight - genesisHeight);

        return (height - latestHeight) * timePerBlock + latestTime;
    }
}

export function addressValidate(address: string) {
    return AccAddress.validate(address);
}

export async function bankBalance(address: string): Promise<Coins> {
    return getLcdClient()
        .bank.balance(address)
        .then((r) => {
            return r[0];
        })
        .catch((e) => {
            return new Coins();
        });
}

export async function getTxInfo(hash: string): Promise<TxInfo> {
    return await getLcdClient().tx.txInfo(hash);
}

export async function tokenInfo(contract: string): Promise<ResponseTokenInfo> {
    return await wasmQuery(contract, {
        token_info: {},
    });
}

export async function tokenIcon(contract: string): Promise<string> {
    return (
        await wasmQuery(contract, {
            download_logo: {},
        })
    ).data;
}

export async function getSymbol(denom: string): Promise<string> {
    if (denom === environment().contracts.usdc_token) {
        return "axlUSDC";
    } else if (Utils.isNativeToken(denom)) {
        if (denom.toLowerCase() === "uluna") {
            return "LUNA";
        } else {
            return denom.substring(1, denom.length - 1).toUpperCase() + "T";
        }
    } else if (denom === environment().contracts.token) {
        return "VKR";
    } else {
        return (
            await tokenInfo(denom)
                .then((r) => {
                    return r;
                })
                .catch((e) => {
                    return {
                        symbol: "Token",
                    };
                })
        ).symbol;
    }
}

export async function wasmQuery(contract: string, obj: any): Promise<any> {
    const r = await getLcdClient().wasm.contractQuery(contract, obj);
    return r;
}

export async function wasmExecutes(
    address: string,
    msgs: WasmExecute[],
    memo: string | undefined = undefined,
    manualFee?: Fee
): Promise<CreateTxOptions> {
    const exes: MsgExecuteContract[] = msgs.map((item) => {
        const coins = item.coin ? new Coins(item.coin) : undefined;

        return new MsgExecuteContract(address, item.contract, item.msg, coins);
    });

    const acc = await getLcdClient().auth.accountInfo(address);

    const fee = await getLcdClient().tx.estimateFee(
        [
            {
                sequenceNumber: acc.getSequenceNumber(),
                publicKey: acc.getPublicKey(),
            },
        ],
        {
            msgs: exes,
            gasPrices: { uluna: "0.015" },
            feeDenoms: ["uluna"],
        }
    );

    return {
        msgs: exes,
        memo: memo,
        fee: manualFee ? manualFee : fee,
    };
}

export async function getBlockTime(height: number): Promise<number> {
    const latest = await latestBlock();
    return await blockTime(latest, height);
}

export async function findIconByDenom(
    denom: string
): Promise<string | undefined> {
    const assets = (await axios.get(environment().assets.tokens)).data;
    const tokens =
        isMainnet(undefined) === NetworkType.mainnet
            ? assets.mainnet
            : assets.testnet;

    for (let i = 0; i < Object.keys(tokens).length; i++) {
        const key = Object.keys(tokens)[i];
        if (key === denom) {
            const value: {
                protocol: string;
                symbol: string;
                token: string;
                icon: string;
            } = tokens[key];
            return value.icon;
        }
    }
    return undefined;
}

export async function findIconBySymbol(
    symbol: string
): Promise<string | undefined> {
    const assets = (await axios.get(environment().assets.tokens)).data;
    const tokens =
        isMainnet(undefined) === NetworkType.mainnet
            ? assets.mainnet
            : assets.testnet;

    for (let i = 0; i < Object.keys(tokens).length; i++) {
        const key = Object.keys(tokens)[i];

        if (tokens[key].symbol === symbol) {
            const value: {
                protocol: string;
                symbol: string;
                token: string;
                icon: string;
            } = tokens[key];
            return value.icon;
        }
    }
    return undefined;
}

export async function getTxs(
    address: string,
    offset: number,
    searchTimestampLimit: number
): Promise<{
    offset: number | undefined;
    txs: TxInfo[];
}> {
    const url = getLcdClient().config.URL.replaceAll("lcd", "fcd");

    // https://phoenix-fcd.terra.dev/v1/txs?offset=0&limit=100&account=terra1r0rm0evrlkfvpt0csrcpmnpmrega54czajfd86

    let response = await axios.get(
        url + "/v1/txs?offset=" + offset + "&limit=100&account=" + address
    );
    console.log(response.data);

    const nextOffset = getTxOffset(response.data, searchTimestampLimit);

    let txs: TxInfo[] = [];
    (response.data.txs as any[]).forEach((item) => {
        txs.push(TxInfo.fromData(item));
    });

    return {
        offset: nextOffset,
        txs: txs,
    };
}

function getTxOffset(txData: any, searchTimestampLimit: number) {
    if (txData.next || txData.txs.length > 0) {
        const lastTx = TxInfo.fromData(txData.txs[txData.txs.length - 1]);
        const lastTxTimestamp = lastTx.timestamp;

        if (Date.parse(lastTxTimestamp) < searchTimestampLimit) {
            return undefined;
        } else {
            return parseInt(txData.next);
        }
    } else {
        return undefined;
    }
}
