import { getDecimal } from "../../../../../libs/utils";
import style from "./CampaignParticipationFooter.module.scss";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import { useEffect, useState } from "react";
import ConfirmButton from "../../../../common/ConfirmButton/ConfirmButton";
import { useRecoilValue } from "recoil";
import { balanceState } from "../../../../../libs/contexts";
import LineDummy from "../../../../common/LineDummy/LineDummy";
import { ConditionType } from "./ConditionValue";
import { isZero, leftLessThanRight } from "../../../../../libs/Math";
import { ConditionView } from "./ConditionView";

export default function CampaignParticipationFooter(props: {
    campaignAddress: string;
    participationRewardAmount: string;
    vpAmount: string;
    summary: CampaignSummary;
    txPassed: boolean;
    participatePressed: () => void;
}) {
    const balance = useRecoilValue(balanceState);

    const config = props.summary.config;
    const reward = props.summary.reward;

    const [satisfyDeposit, setSatisfyDeposit] = useState(false);

    const [satisfyVP, setSatisfyVP] = useState(false);

    useEffect(() => {
        const config = props.summary.config;

        if (isZero(config.deposit.amount)) {
            setSatisfyDeposit(true);
        } else {
            if (leftLessThanRight(balance.token, config.deposit.amount)) {
                setSatisfyDeposit(false);
            } else {
                setSatisfyDeposit(true);
            }
        }

        if (isZero(props.vpAmount)) {
            setSatisfyVP(true);
        } else {
            if (leftLessThanRight(balance.vp, props.vpAmount)) {
                setSatisfyVP(false);
            } else {
                setSatisfyVP(true);
            }
        }
    }, [props.summary, balance, props.vpAmount]);

    return (
        <div className={style.container}>
            <div className={style.dummy}>
                <LineDummy horizontal={true} />
            </div>

            <TitleLabel
                text="Participate Condition Check"
                size={16}
                fontWeight="light"
            />
            <div style={{ height: "10px" }} />

            <ConditionView
                passed={
                    !leftLessThanRight(balance.token, config.deposit.amount)
                }
                summary={props.summary}
                type={ConditionType.deposit}
            />

            {!isZero(props.vpAmount) ? (
                <ConditionView
                    passed={!leftLessThanRight(balance.vp, props.vpAmount)}
                    summary={props.summary}
                    type={ConditionType.vp}
                />
            ) : (
                <div />
            )}

            <div className={style.dummy2}>
                <LineDummy horizontal={true} />
            </div>

            <div className={style.estimate_box}>
                <div className={style.value}>
                    {getDecimal(props.participationRewardAmount, true) +
                        " " +
                        reward.participation.symbol}
                </div>
            </div>

            <ConfirmButton
                title={"Get Reward"}
                enable={props.txPassed && satisfyDeposit && satisfyVP}
                onClick={props.participatePressed}
            />
        </div>
    );
}
