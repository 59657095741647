import { useState } from "react";
import style from "./Trade.module.scss";
import { tokenBuy, tokenSell } from "../../libs/api/trade/execution";
import TxBoxView from "../common/TxBoxView/TxBoxView";
import PageHeader from "../common/PageHeader/PageHeader";
import TabBox from "../common/TabBox/TabBox";
import TradeBuy from "./TradeBuy";
import TradeSell from "./TradeSell";
import { useRecoilValue } from "recoil";
import { balanceState, walletState } from "../../libs/contexts";
import PriceHeader from "../common/PriceHeader/PriceHeader";
import { useWallet } from "@terra-money/wallet-provider";

import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function Trade() {
    const [tabIndex, setTabIndex] = useState(0);
    const { post } = useWallet();

    const wallet = useRecoilValue(walletState);
    const balance = useRecoilValue(balanceState);

    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    function buyPressed(amount: string, spread: number) {
        setHash({
            type: "Buy",
            isPending: true,
        });

        tokenBuy(wallet!.terraAddress, amount, spread)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "Buy",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                setHash({
                    type: "Buy",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function sellPressed(amount: string, spread: number) {
        setHash({
            type: "Sell",
            isPending: true,
        });

        tokenSell(wallet!.terraAddress, amount, spread)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "Sell",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                setHash({
                    type: "Sell",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    return (
        <div className={cx(style.wrap, { hash: hash !== undefined })}>
            <div className={cx(style.container, { hash: hash !== undefined })}>
                <PageHeader title="Trade" docs="/webapp/trade#trade" />

                <TxBoxView
                    hash={hash}
                    clearHash={() => {
                        setHash(undefined);
                    }}
                    transparent={true}
                >
                    <div className={style.trade_container}>
                        <div className={style.charttab}>
                            <PriceHeader />
                        </div>
                        <div className={style.tradetab}>
                            <div className={style.tradetab_top}>
                                <TabBox
                                    titleLeft={"Buy"}
                                    titleRight={"Sell"}
                                    tabIndex={tabIndex}
                                    tabChanged={setTabIndex}
                                />
                            </div>

                            <div className={style.tradedummy} />
                            <div className={style.tradetab_bottom}>
                                {tabIndex === 0 ? (
                                    <TradeBuy
                                        balance={balance}
                                        buyPressed={buyPressed}
                                    />
                                ) : (
                                    <TradeSell
                                        balance={balance}
                                        sellPressed={sellPressed}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </TxBoxView>
            </div>
        </div>
    );
}
