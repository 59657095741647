import { useEffect, useState } from "react";
import FooterInfoRow from "../FooterInfoRow/FooterInfoRow";
import style from "./TxInfoView.module.scss";
import * as Utils from "../../../libs/utils";

export default function TxInfoView(props: {
    list?: {
        tooltip?: string;
        title: string;
        body: string;
        symbol?: string;
    }[];
    fee?: string;
}) {
    const [list, setList] = useState<
        { tooltip?: string; title: string; body: string; symbol?: string }[]
    >([]);
    useEffect(() => {
        let list = (props.list ?? []).slice();
        list.push({
            title: "Tx Fee",
            body: Utils.getDecimal(props.fee ?? "0", true, 3),
            symbol: "LUNA",
        });
        setList(list);
    }, [props]);

    return (
        <div className={style.container}>
            {list.map((item, index) => {
                return (
                    <FooterInfoRow
                        key={index}
                        tooltip={item.tooltip}
                        title={item.title}
                        body={item.body}
                        symbol={item.symbol}
                    />
                );
            })}
        </div>
    );
}
