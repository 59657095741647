import { useWallet } from "@terra-money/wallet-provider";
import { useRef } from "react";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { latestBlock, WebLinks } from "../../libs/api/api";
import { blockState } from "../../libs/contexts";
import style from "./Footer.module.scss";

export default function Footer() {
    const { network } = useWallet();

    return (
        <div className={style.container}>
            <div className={style.inner_container}>
                <div className={style.block}>
                    <div className={style.icon} />
                    {network.name.toLowerCase() === "mainnet" ||
                    network.name.toLowerCase() === "classic" ? (
                        <BlockViewMainnet />
                    ) : (
                        <BlockView />
                    )}
                </div>

                <div className={style.wrapper}>
                    <div className={style.links}>
                        <a
                            className={style.icon}
                            href={WebLinks.github}
                            target={"_blank"}
                            rel={"noreferrer"}
                        >
                            <div className={style.icon1} />
                        </a>
                        <a
                            className={style.icon}
                            href={WebLinks.medium}
                            target={"_blank"}
                            rel={"noreferrer"}
                        >
                            <div className={style.icon3} />
                        </a>
                        <a
                            className={style.icon}
                            href={WebLinks.twitter}
                            target={"_blank"}
                            rel={"noreferrer"}
                        >
                            <div className={style.icon2} />
                        </a>

                        <a
                            className={style.icon}
                            href={WebLinks.telegram}
                            target={"_blank"}
                            rel={"noreferrer"}
                        >
                            <div className={style.icon4} />
                        </a>
                        <a
                            className={style.icon}
                            href={WebLinks.forum}
                            target={"_blank"}
                            rel={"noreferrer"}
                        >
                            <div className={style.icon5} />
                        </a>
                    </div>

                    <div className={style.dummy} />

                    <a
                        className={style.litepaper}
                        href="/litepaper.pdf"
                        target={"_blank"}
                        rel={"noreferrer"}
                    >
                        <div className={style.icon} />
                        <div className={style.text}>{"Lite Paper"}</div>
                    </a>
                </div>
            </div>
        </div>
    );
}

function BlockView() {
    const [block, setBlock] = useRecoilState(blockState);

    // const [block, setBlock] = useState({
    //     block: 0,
    // });
    const timer = useRef<any>(null);

    const isLoading = useRef(false);

    const load = useCallback(() => {
        if (isLoading.current) {
            return;
        }

        isLoading.current = true;

        latestBlock()
            .then((r) => {
                isLoading.current = false;
                setBlock({ block: r.height });
            })
            .catch((e) => {
                isLoading.current = false;
            });
    }, []);

    const clear = useCallback(() => {
        if (timer.current !== null) {
            clearInterval(timer.current);
            timer.current = null;
        }
    }, []);

    useEffect(() => {
        clear();
        load();

        timer.current = setInterval(() => {
            load();
        }, 60000);

        return () => {
            clear();
        };
    }, [load, clear]);

    return (
        <div className={style.text}>
            {block.block > 0 ? "Latest Block: " + block.block : ""}
        </div>
    );
}

function BlockViewMainnet() {
    const [block, setBlock] = useRecoilState(blockState);

    // const [block, setBlock] = useState({
    //     block: 0,
    // });

    const load = useCallback(() => {
        latestBlock()
            .then((r) => {
                setBlock({ block: r.height });
            })
            .catch((e) => {});
    }, []);

    useEffect(() => {
        load();

        const ws = new WebSocket("wss://observer.terra.dev");
        ws.onopen = function () {
            ws.send(
                JSON.stringify({
                    subscribe: "new_block",
                    chain_id: "phoenix-1",
                })
            );
        };
        ws.onmessage = function (message) {
            const data = JSON.parse(message.data);
            const height = data.data.block.header.height;
            setBlock({ block: height });
        };

        return () => {
            ws.close();
        };
    }, [load]);

    return (
        <div className={style.text}>
            {block.block > 0 ? "Latest Block: " + block.block : ""}
        </div>
    );
}
