import style from "./HistoryItem.module.scss";
import * as Utils from "../../../../libs/utils";

import { useEffect } from "react";
import { useState } from "react";

import TitleLabel from "../../Labels/TitleLabel";
import { getSymbol, tokenInfo } from "../../../../libs/api/api";
import { isZero } from "../../../../libs/Math";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../libs/contexts";

export default function HistoryItem(props: { item: TxHistory }) {
    const wallet = useRecoilValue(walletState);

    const [msg, setMsg] = useState<TxMsgFormat[] | undefined>(undefined);

    useEffect(() => {
        messageParse(
            props.item.type,
            props.item.jsonData,
            wallet ? wallet.terraAddress : ""
        )
            .then((r) => {
                setMsg(r);
            })
            .catch((e) => {});
    }, [props, wallet]);

    return (
        <div
            className={style.container}
            onClick={() => {
                Utils.showFinderTx(props.item.transactionHash);
            }}
        >
            <div className={style.row}>
                <div className={style.detailcontents}>
                    <div className={style.date}>
                        <div className={style.date_icon} />
                        <div className={style.date_text}>
                            <TitleLabel
                                size={14}
                                color={"rgba(255,255,255,0.5)"}
                                text={Utils.dateFormat6(props.item.date)}
                                fontWeight="light"
                            />
                        </div>
                    </div>
                    <div className={style.content}>
                        {/* {props.item.isSuccess ? (
                            <div className={style.icon} />
                        ) : (
                            <div className={style.icon2} />
                        )} */}

                        <div className={style.msg}>
                            {msg ? (
                                msg.map((item, index) => {
                                    if (!props.item.isSuccess) {
                                        return (
                                            <div
                                                key={index}
                                                className={style.fail}
                                            >
                                                {item.text}&nbsp;
                                            </div>
                                        );
                                    } else if (item.highlight) {
                                        return (
                                            <div
                                                key={index}
                                                className={style.success}
                                            >
                                                {item.text}&nbsp;
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <span key={index}>
                                                {item.text}&nbsp;
                                            </span>
                                        );
                                    }
                                })
                            ) : (
                                <div />
                            )}
                        </div>
                    </div>
                </div>
                <div className={style.detailbutton}>
                    <div className={style.detailbutton_field}>
                        <div className={style.detailbutton_field_ico} />
                    </div>
                </div>
            </div>
            {/* <LineDummy horizontal={true} marginTop={0} /> */}
            <div className={style.historydummy} />
        </div>
    );

    async function getAmountText(msg: {
        amount: string;
        denom: string;
    }): Promise<string> {
        let decimals = 3;
        if (!Utils.isNativeToken(msg.denom)) {
            let token = await tokenInfo(msg.denom);
            decimals = token.decimals;
        }

        return (
            Utils.getDecimal(msg.amount, true, decimals) +
            " " +
            (await getSymbol(msg.denom))
        );
    }

    function shortAddress(a: string) {
        return a.substring(0, 6) + "..." + a.substring(a.length - 6);
    }

    async function messageParse(
        type: string,
        json: any | undefined,
        userAddress: string
    ): Promise<TxMsgFormat[]> {
        if (type === "CREATE_CAMPAIGN") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to create a campaign",
                        highlight: true,
                    },
                ];
            }

            const msg = json as TxMsgCreateCampaign;
            if (userAddress === msg.admin) {
                return [
                    {
                        text: "Created new campaign",
                        highlight: false,
                    },
                    {
                        text: shortAddress(msg.campaign_address),
                        highlight: false,
                    },
                ];
            } else {
                return [
                    {
                        text: "Campaign created from",
                        highlight: false,
                    },
                    {
                        text: shortAddress(msg.admin),
                        highlight: false,
                    },
                ];
            }
        } else if (type === "ADD_REWARD_POOL") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to deposit reward pool",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgDeposit;
            return [
                {
                    text: "Deposit",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.participation_reward_amount),
                    highlight: true,
                },
                {
                    text: "and",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.referral_reward_amount),
                    highlight: true,
                },
                {
                    text: "(fee: ",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.referral_reward_fee_amount),
                    highlight: true,
                },
                {
                    text: ")",
                    highlight: false,
                },
                {
                    text: "to campaign reward pool from",
                    highlight: false,
                },
                {
                    text: shortAddress(msg.sender),
                    highlight: false,
                },
            ];
        } else if (type === "REMOVE_REWARD_POOL") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to withdraw reward pool",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgWithdraw;
            return [
                {
                    text: "Withdraw",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.withdraw_amount),
                    highlight: true,
                },
                {
                    text: "and paid",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.paid_amount),
                    highlight: true,
                },
                {
                    text: "to withdraw from campaign reward pool from",
                    highlight: false,
                },
                {
                    text: shortAddress(msg.receiver),
                    highlight: false,
                },
            ];
        } else if (type === "ACTIVATE") {
            return [
                {
                    text: "Activate campaign",
                    highlight: false,
                },
            ];
        } else if (type === "DEACTIVATE") {
            return [
                {
                    text: "Deactivate campaign",
                    highlight: false,
                },
            ];
        } else if (type === "PARTICIPATE") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to participate",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgParticipate;

            let list: { text: string; highlight: boolean }[] = [];
            if (!isZero(msg.participation_reward_amount.amount)) {
                list.push({
                    text: "Participation reward (",
                    highlight: false,
                });

                list.push({
                    text: await getAmountText(msg.participation_reward_amount),
                    highlight: true,
                });

                list.push({
                    text: ")",
                    highlight: false,
                });
            }

            if (
                !isZero(msg.participation_reward_amount.amount) &&
                !isZero(msg.referral_reward_amount.amount)
            ) {
                list.push({
                    text: ", ",
                    highlight: false,
                });
            }

            if (!isZero(msg.referral_reward_amount.amount)) {
                list.push({
                    text: "Sharing reward (",
                    highlight: false,
                });

                list.push({
                    text: await getAmountText(msg.referral_reward_amount),
                    highlight: true,
                });

                list.push({
                    text: ")",
                    highlight: false,
                });
            }

            list.push({
                text: "distributed for participant",
                highlight: false,
            });
            list.push({
                text: shortAddress(msg.participant),
                highlight: false,
            });

            return list;
        } else if (type === "CLAIM_PARTICIPATION_REWARD") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to claim participation reward",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgClaimReward;

            let list = [
                {
                    text: "Withdraw participation reward",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.token_amount),
                    highlight: true,
                },
            ];

            list.push({
                text: "from",
                highlight: false,
            });
            list.push({
                text: shortAddress(msg.campaign_address),
                highlight: false,
            });

            return list;
        } else if (type === "CLAIM_REFERRAL_REWARD") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to claim sharing reward",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgClaimReward;

            let list = [
                {
                    text: "Withdraw sharing reward",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.token_amount),
                    highlight: true,
                },
            ];

            list.push({
                text: "from",
                highlight: false,
            });
            list.push({
                text: shortAddress(msg.campaign_address),
                highlight: false,
            });

            return list;
        } else if (type === "VOTE_POLL") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to vote",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgVotePoll;
            return [
                {
                    text: "Voted in poll",
                    highlight: false,
                },
                {
                    text: "#" + msg.poll_id.toString(),
                    highlight: false,
                },
                {
                    text: "with",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.token_amount),
                    highlight: true,
                },
            ];
        } else if (type === "STAKE_GOVERNANCE") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to stake",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgStakeGov;
            return [
                {
                    text: "Staked",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.token_amount),
                    highlight: true,
                },
            ];
        } else if (type === "UNSTAKE_GOVERNANCE") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to unstake",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgUnstakeGov;
            return [
                {
                    text: "Unstaked",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.token_amount),
                    highlight: true,
                },
            ];
        } else if (type === "CREATE_POLL") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to create poll",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgCreatePoll;

            return [
                {
                    text: "Create poll",
                    highlight: false,
                },
                {
                    text: "#" + msg.poll_id.toString(),
                    highlight: false,
                },
            ];
        } else if (type === "SELL") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to sell",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgSell;
            return [
                {
                    text: "Swap",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.from_amount),
                    highlight: true,
                },
                {
                    text: "to",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.to_amount),
                    highlight: true,
                },
            ];
        } else if (type === "BUY") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to buy",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgBuy;
            return [
                {
                    text: "Swap",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.from_amount),
                    highlight: true,
                },
                {
                    text: "to",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.to_amount),
                    highlight: true,
                },
            ];
        } else if (type === "AUTO_STAKE_LIQUIDITY") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to provide liquidity and stake",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgAutoStakeLP;
            return [
                {
                    text: "Provide liquidity and stake",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.valkyrie_amount),
                    highlight: true,
                },
                {
                    text: "and",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.axl_usdc_amount),
                    highlight: true,
                },
            ];
        } else if (type === "AUTO_UNSTAKE_LIQUIDITY") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to withdraw liquidity and unstake",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgAutoUnstakeLP;
            return [
                {
                    text: "Withdraw liquidity and unstake",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.valkyrie_amount),
                    highlight: true,
                },
                {
                    text: "and",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.axl_usdc_amount),
                    highlight: true,
                },
            ];
        } else if (type === "CLAIM_LP_REWARD") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to claim liquidity reward",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgClaimLpReward;
            return [
                {
                    text: "Reward Claimed",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.token_amount),
                    highlight: true,
                },
            ];
        } else if (type === "DEPOSIT") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to deposit",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgDepositCollateral;
            return [
                {
                    text: "Deposited ",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.deposit_amount),
                    highlight: true,
                },
            ];
        } else if (type === "WITHDRAW") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to withdraw",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgWithdrawCollateral;
            return [
                {
                    text: "Withdrawn ",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.withdrawn_amount),
                    highlight: true,
                },
            ];
        } else if (type === "CLAIM_AIRDROP") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to claim airdrop",
                        highlight: true,
                    },
                ];
            }

            const msg = json as TxMsgAirdrop;
            return [
                {
                    text: "Airdrop",
                    highlight: true,
                },
                {
                    text: "Claimed",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.claim_amount),
                    highlight: false,
                },
            ];
        } else if (type === "SEND") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to send",
                        highlight: true,
                    },
                ];
            }
            const msg = json as TxMsgSend;

            return [
                {
                    text: "Sent",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.amount),
                    highlight: true,
                },
                {
                    text: "to",
                    highlight: false,
                },
                {
                    text: shortAddress(msg.recipient),
                    highlight: false,
                },
            ];
        } else if (type === "RECEIVE") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to receive",
                        highlight: true,
                    },
                ];
            }

            const msg = json as TxMsgReceived;
            return [
                {
                    text: "Received",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.amount),
                    highlight: true,
                },
                {
                    text: "from",
                    highlight: false,
                },
                {
                    text: shortAddress(msg.sender),
                    highlight: false,
                },
            ];
        } else if (type === "PROVIDE_LIQUIDITY") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to provide liquidity",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgProvideLiquidity;
            return [
                {
                    text: "Provide liquidity",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.valkyrie_amount),
                    highlight: true,
                },
                {
                    text: "and",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.axl_usdc_amount),
                    highlight: true,
                },
            ];
        } else if (type === "WITHDRAW_LIQUIDITY") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to withdraw liquidity",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgWithdrawLiquidity;
            return [
                {
                    text: "Withdraw liquidity",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.valkyrie_amount),
                    highlight: true,
                },
                {
                    text: "and",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.axl_usdc_amount),
                    highlight: true,
                },
            ];
        } else if (type === "STAKE_LIQUIDITY") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to Stake liquidity",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgStakeLiquidity;
            return [
                {
                    text: "Stake liquidity",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.lp_amount),
                    highlight: true,
                },
            ];
        } else if (type === "UNSTAKE_LIQUIDITY") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to Unstake liquidity",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgUnstakeLiquidity;
            return [
                {
                    text: "Unstake liquidity",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.lp_amount),
                    highlight: true,
                },
            ];
        } else if (type === "CLAIM_VP_AIRDROP") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to claim VP airdrop",
                        highlight: true,
                    },
                ];
            }

            const msg = json as TxMsgClaimVPAirdrop;
            return [
                {
                    text: "VP Airdrop",
                    highlight: true,
                },
                {
                    text: "Claimed",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.claim_amount),
                    highlight: false,
                },
            ];
        } else if (type === "CLAIM_VP") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to claim VP",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgClaimVP;
            return [
                {
                    text: "VP Claimed",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.claim_amount),
                    highlight: true,
                },
            ];
        } else if (type === "MINT_VP") {
            if (json === undefined) {
                return [
                    {
                        text: "Failed to mint VP",
                        highlight: false,
                    },
                ];
            }

            const msg = json as TxMsgMintVP;
            return [
                {
                    text: "Mint",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.burn_amount),
                    highlight: true,
                },
                {
                    text: "to",
                    highlight: false,
                },
                {
                    text: await getAmountText(msg.mint_amount),
                    highlight: true,
                },
            ];
        }

        return [];
    }
}
