import { useHistory, useLocation } from "react-router-dom";
import * as Utils from "../../libs/utils";
import { PATHS } from "../../App";
import style from "./HeaderMobile.module.scss";
import classNames from "classnames/bind";
import { useCallback, useEffect, useRef, useState } from "react";
import { HistoryButton } from "../common/NormalButton/NormalButton";
import TitleLabel from "../common/Labels/TitleLabel";
import { balanceState, refreshState, walletState } from "../../libs/contexts";
import { useRecoilState, useRecoilValue } from "recoil";
import TestnetBadge from "../common/CampaignComponents/TestnetBadge";
import { vkrPriceChart } from "../../libs/api/rest";
import {
    divide,
    fixed,
    isZero,
    leftGreaterThanRight,
    leftLessThanRight,
    minus,
    multiply,
} from "../../libs/Math";
import { queryPrice } from "../../libs/api/trade/query";
import { DocsLinks, environment } from "../../libs/api/api";
import iconU from "../../resources/ico-graph-u.svg";
import iconD from "../../resources/ico-graph-d.svg";
import iconN from "../../resources/ico-graph-n.svg";

const cx = classNames.bind(style);

export default function HeaderMobile(props: {
    isCreator: boolean;
    connect: () => void;
    disconnect: () => void;
    menus: { title: string; path: string }[];
}) {
    const wallet = useRecoilValue(walletState);
    const history = useHistory();

    const [containerShow, setContainerShow] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showWalletPopup, setShowWalletPopup] = useState(false);

    useEffect(() => {
        const body: any = document.querySelector("body");
        body.style.overflow = showMenu || showWalletPopup ? "hidden" : "auto";

        if (showMenu || showWalletPopup) {
            setContainerShow(true);
        } else if (!showMenu && !showWalletPopup) {
            setTimeout(() => {
                setContainerShow(false);
            }, 400);
        }
    }, [showMenu, showWalletPopup]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            const obj1 = document.getElementById(
                "header_mobile_wallet_wrapper"
            );
            const obj2 = document.getElementById("header_mobile_button_wallet");

            if (obj1 && obj2) {
                if (
                    !(obj1 as any).contains(event.target) &&
                    !(obj2 as any).contains(event.target)
                ) {
                    setShowWalletPopup(false);
                }
            }

            const obj3 = document.getElementById("header_mobile_menu_wrapper");
            const obj4 = document.getElementById("header_mobile_button_menu");
            if (obj3 && obj4) {
                if (
                    !(obj3 as any).contains(event.target) &&
                    !(obj4 as any).contains(event.target)
                ) {
                    setShowMenu(false);
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowMenu, setShowWalletPopup]);

    return (
        <div>
            <div className={style.container}>
                <div className={style.inner}>
                    <div
                        className={style.logo}
                        onClick={() => {
                            history.push(PATHS.home);
                        }}
                    />
                    <TestnetBadge />
                    <div className={style.spacer} />

                    <div
                        id="header_mobile_button_wallet"
                        className={cx(style.buttons, "wallet", {
                            connected: wallet !== undefined,
                        })}
                        onClick={() => {
                            if (wallet === undefined) {
                                props.connect();
                            } else {
                                setShowWalletPopup(!showWalletPopup);
                            }
                        }}
                    />
                    <div
                        id="header_mobile_button_menu"
                        className={cx(style.buttons, "menu", {
                            show: showMenu,
                        })}
                        onClick={() => {
                            setShowMenu(!showMenu);
                        }}
                    />
                </div>
                <SideBar />
            </div>

            {containerShow ? (
                <div
                    className={cx(
                        style.inner_container,
                        { showmenu: showMenu },
                        { showwallet: showWalletPopup }
                    )}
                >
                    <MenuPanel
                        menus={props.menus}
                        showMenu={showMenu}
                        isCreator={props.isCreator}
                        setShowMenu={setShowMenu}
                    />
                    <WalletPanel
                        showWalletPopup={showWalletPopup}
                        setShowWalletPopup={setShowWalletPopup}
                        disconnect={props.disconnect}
                    />
                </div>
            ) : (
                <div />
            )}
        </div>
    );
}

function SideBar() {
    const [refresh, setRefresh] = useRecoilState(refreshState);
    const isLoading = useRef(false);
    const [isHidden, setHidden] = useState(false);
    const location = useLocation();

    const [info, setInfo] = useState<
        { price: string; rate: string; color: string; icon: any } | undefined
    >(undefined);

    useEffect(() => {
        load();
    }, [isLoading, location]);

    function load() {
        if (isLoading.current) {
            return;
        }
        isLoading.current = true;

        if (info !== undefined) {
            setHidden(true);
            setTimeout(() => {
                setHidden(false);
            }, 6000);
        }

        setInfo(undefined);
        loadChart()
            .then((r) => {
                setInfo({
                    price: leftGreaterThanRight("1", r.price) ? "1" : r.price,
                    rate: r.rate,
                    color: r.color,
                    icon: r.icon,
                });
                isLoading.current = false;

                setRefresh(refresh + 1);
            })
            .catch((e) => {
                isLoading.current = false;
            });
    }

    const loadChart = useCallback(async () => {
        const price = await queryPrice(environment().contracts.token);
        const p = fixed(multiply(price, 1000000), 0);
        const r = await vkrPriceChart("day");

        let rate = "0";
        let lastPrice: string = price;
        let color = "#786f64";
        let icon = iconN;

        if (r.length > 0) {
            lastPrice = multiply(r[r.length - 1].y, 1000000);
            if (lastPrice && !isZero(lastPrice)) {
                rate = divide(minus(p, lastPrice), lastPrice);
            }

            if (leftLessThanRight(rate, 0)) {
                color = "#a02d46";
                icon = iconD;
            } else if (leftGreaterThanRight(rate, 0)) {
                color = "#0ecb81";
                icon = iconU;
            } else {
                color = "#786f64";
                icon = iconN;
            }
        }

        return {
            price: p,
            rate: rate,
            color: color,
            icon: icon,
        };
    }, []);

    if (!info) {
        return (
            <div className={style.sidebar}>
                <div className={style.text1}>{"VKR PRICE "}</div>
                &nbsp;
                <TitleLabel
                    size={12}
                    text={undefined}
                    skeleton={{ width: 100 }}
                />
                <div style={{ flex: 1 }} />
                {!isHidden ? (
                    <div
                        className={style.refresh_container}
                        onClick={() => {
                            load();
                        }}
                    >
                        <div className={style.refresh} />{" "}
                    </div>
                ) : (
                    <div />
                )}
            </div>
        );
    } else {
        return (
            <div className={style.sidebar}>
                <div className={style.text1}>{"VKR PRICE "}</div>
                &nbsp;
                <TitleLabel
                    size={12}
                    color={"white"}
                    text={Utils.getDecimal(info.price, true, 6)}
                    symbol={"axlUSDC"}
                    skeleton={{ width: 20 }}
                />
                <img className={style.icon} src={info.icon} alt="" />
                <div>
                    <TitleLabel
                        size={12}
                        fontWeight="light"
                        text={
                            Utils.getPercentFormat(info.rate).replace("-", "") +
                            "%"
                        }
                        color={info.color}
                        skeleton={{ width: 10 }}
                    />
                </div>
                <div style={{ flex: 1 }} />
                {!isHidden ? (
                    <div
                        className={style.refresh_container}
                        onClick={() => {
                            load();
                        }}
                    >
                        <div className={style.refresh} />{" "}
                    </div>
                ) : (
                    <div />
                )}
            </div>
        );
    }
}

function MenuPanel(props: {
    menus: { title: string; path: string }[];
    isCreator: boolean;
    showMenu: boolean;
    setShowMenu: (b: boolean) => void;
}) {
    const history = useHistory();

    return (
        <div id="header_mobile_menu_wrapper" className={style.menu_container}>
            {props.menus.map((item, index) => {
                if (!props.isCreator && item.path === PATHS.producer) {
                    return <div key={index} />;
                }

                return (
                    <div
                        key={index}
                        className={style.menu}
                        onClick={() => {
                            history.push(item.path);
                            props.setShowMenu(false);
                        }}
                    >
                        <div className={style.title}>{item.title}</div>
                        <div className={style.docs}>
                            <div className={style.icon} />
                            &nbsp;
                            {item.title === "Campaigns" ? (
                                <a
                                    href={DocsLinks.campaign}
                                    target={"_blank"}
                                    rel="noreferrer"
                                    style={{ color: "rgba(255,255,255,0.3)" }}
                                >
                                    {"docs"}
                                </a>
                            ) : item.title === "Trade" ? (
                                <a
                                    href={DocsLinks.trade}
                                    target={"_blank"}
                                    rel="noreferrer"
                                    style={{ color: "rgba(255,255,255,0.3)" }}
                                >
                                    {"docs"}
                                </a>
                            ) : item.title === "Stake" ? (
                                <a
                                    href={DocsLinks.stake}
                                    target={"_blank"}
                                    rel="noreferrer"
                                    style={{ color: "rgba(255,255,255,0.3)" }}
                                >
                                    {"docs"}
                                </a>
                            ) : (
                                <a
                                    href={DocsLinks.governance}
                                    target={"_blank"}
                                    rel="noreferrer"
                                    style={{ color: "rgba(255,255,255,0.3)" }}
                                >
                                    {"docs"}
                                </a>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

function WalletPanel(props: {
    showWalletPopup: boolean;
    setShowWalletPopup: (b: boolean) => void;
    disconnect: () => void;
}) {
    const wallet = useRecoilValue(walletState);
    const balance = useRecoilValue(balanceState);
    const history = useHistory();

    const [copyString, setCopyString] = useState("Copy");

    return (
        <div
            id="header_mobile_wallet_wrapper"
            className={style.wallet_container}
        >
            <div
                className={style.close_icon}
                onClick={() => {
                    props.setShowWalletPopup(false);
                }}
            />

            <div className={style.row0}>
                <div className={style.icon} />
                <div className={style.text}>{"My Wallet"}</div>
            </div>

            <div className={style.row0_2}>
                <TitleLabel
                    size={24}
                    color={"white"}
                    text={Utils.getDecimal(balance.axlUSDC, true, 3)}
                    symbol={"axlUSDC"}
                />
            </div>

            <div className={style.row1}>
                {wallet ? wallet.terraAddress : ""}
            </div>

            <div className={style.row1_2}>
                <div
                    className={style.copy_box}
                    onClick={() => {
                        if (wallet) {
                            Utils.copyString(wallet.terraAddress);
                            setCopyString("Copied");
                            setTimeout(() => {
                                setCopyString("Copy");
                            }, 1000);
                        }
                    }}
                >
                    <div className={style.copy_box_icon} />
                    <div className={style.copy_box_text}>{copyString}</div>
                </div>
            </div>

            <div className={style.row2}>
                <div className={style.dummy1} />
                <div className={style.dummy2} />
            </div>

            <div className={style.row2_1}>
                <div className={style.button}>
                    <HistoryButton
                        onClick={() => {
                            history.push(PATHS.history);
                            props.setShowWalletPopup(false);
                        }}
                    />
                </div>
            </div>

            <div className={style.row3}>
                <div
                    className={style.button}
                    onClick={() => {
                        props.setShowWalletPopup(false);
                        props.disconnect();
                    }}
                >
                    <div className={style.text}>{"Disconnect"}</div>
                    <div className={style.icon} />
                </div>
            </div>
        </div>
    );
}
