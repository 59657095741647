import { environment, wasmQuery } from "../api";

export async function airdropConfig(): Promise<ResponseAirdropStageConfig> {
    return wasmQuery(environment().contracts.vkr_airdrop_v2, {
        stage_config: {
            stage: 1,
        },
    });
}

export async function genesisAirdropUserState(
    address: string
): Promise<AirdropAmount> {
    return wasmQuery(environment().contracts.vkr_airdrop_v2, {
        user_state: {
            stage: 0,
            address: address,
        },
    }).then((r: ResponseAirdropUserState) => {
        return {
            assigned: r.assigned_amount,
            claimable: r.claimable_amount,
            claimed: r.claimed_amount,
        };
    });
}

export async function vestingAirdropUserState(
    address: string
): Promise<AirdropAmount> {
    return wasmQuery(environment().contracts.vkr_airdrop_v2, {
        user_state: {
            stage: 1,
            address: address,
        },
    }).then((r: ResponseAirdropUserState) => {
        return {
            assigned: r.assigned_amount,
            claimable: r.claimable_amount,
            claimed: r.claimed_amount,
        };
    });
}
