import { CreateTxOptions } from "@terra-money/terra.js";

import { fixed } from "../../Math";
import { environment, wasmExecutes } from "../api";

export async function makeLp(
    address: string,
    token: string,
    usdc: string
): Promise<CreateTxOptions> {
    return await provideLiquidity(address, token, usdc, false);
}

export async function stakeLp(
    address: string,
    amount: string
): Promise<CreateTxOptions> {
    const lp = fixed(amount, 0);

    const exe1: WasmExecute = {
        contract: environment().contracts.astroport.lp_token,
        msg: {
            send: {
                contract: environment().contracts.astroport.generator,
                amount: lp,
                msg: btoa(
                    JSON.stringify({
                        deposit: {},
                    })
                ),
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe1]);
}

export async function autoStakeLp(
    address: string,
    token: string,
    usdc: string
): Promise<CreateTxOptions> {
    return await provideLiquidity(address, token, usdc, true);
}

async function provideLiquidity(
    address: string,
    token: string,
    usdc: string,
    auto_stake: boolean
) {
    const t = fixed(token, 0);
    const u = fixed(usdc, 0);

    let exes: WasmExecute[] = [];

    exes.push({
        contract: environment().contracts.token,
        msg: {
            increase_allowance: {
                spender: environment().contracts.astroport.pair,
                amount: t,
            },
        },
        coin: undefined,
    });

    exes.push({
        contract: environment().contracts.astroport.pair,
        msg: {
            provide_liquidity: {
                assets: [
                    {
                        info: {
                            token: {
                                contract_addr: environment().contracts.token,
                            },
                        },
                        amount: t,
                    },
                    {
                        info: {
                            native_token: {
                                denom: environment().contracts.usdc_token,
                            },
                        },
                        amount: u,
                    },
                ],
                auto_stake: auto_stake,
            },
        },
        coin: u + environment().contracts.usdc_token,
    });

    if (auto_stake) {
        return await wasmExecutes(address, exes, undefined);
    } else {
        return await wasmExecutes(address, exes);
    }
}

export async function unstakeLp(
    address: string,
    amount: string
): Promise<CreateTxOptions> {
    const lp = fixed(amount, 0);

    const exe: WasmExecute = {
        contract: environment().contracts.astroport.generator,
        msg: {
            withdraw: {
                lp_token: environment().contracts.astroport.lp_token,
                amount: lp,
            },
        },
        coin: undefined,
    };
    return await wasmExecutes(address, [exe]);
}

export async function divideLp(
    address: string,
    amount: string
): Promise<CreateTxOptions> {
    const lp = fixed(amount, 0);
    //unstake lp
    //lp send [stake contact] => [lp contract]

    const exe1: WasmExecute = {
        contract: environment().contracts.astroport.lp_token,
        msg: {
            send: {
                contract: environment().contracts.astroport.pair,
                amount: lp,
                msg: btoa(
                    JSON.stringify({
                        withdraw_liquidity: {},
                    })
                ),
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe1]);
}

export async function autoUnstakeLp(
    address: string,
    amount: string
): Promise<CreateTxOptions> {
    const lp = fixed(amount, 0);

    const exe1: WasmExecute = {
        contract: environment().contracts.astroport.generator,
        msg: {
            withdraw: {
                lp_token: environment().contracts.astroport.lp_token,
                amount: lp,
            },
        },
        coin: undefined,
    };

    const exe2: WasmExecute = {
        contract: environment().contracts.astroport.lp_token,
        msg: {
            send: {
                contract: environment().contracts.astroport.pair,
                amount: lp,
                msg: btoa(
                    JSON.stringify({
                        withdraw_liquidity: {},
                    })
                ),
            },
        },
        coin: undefined,
    };

    // fee
    // const fee = await getFeeHigh();

    // return await wasmExecutes(address, [exe1, exe2], undefined, fee);
    return await wasmExecutes(address, [exe1, exe2], undefined);
}

export async function claimReward(address: string): Promise<CreateTxOptions> {
    return await unstakeLp(address, "0");
}
