import { useEffect, useState } from "react";
import style from "./StakeLpHeader.module.scss";
import * as Utils from "../../../libs/utils";

import TitleLabel from "../../common/Labels/TitleLabel";
import { queryPrice } from "../../../libs/api/trade/query";
import { useRecoilValue } from "recoil";
import { balanceState } from "../../../libs/contexts";
import { environment } from "../../../libs/api/api";
import { divide, leftLessThanRight, multiply } from "../../../libs/Math";
import { useCallback } from "react";
import PageHeader from "../../common/PageHeader/PageHeader";

export default function StakeLpHeader(props: { info?: StakeLpHeaderInfo }) {
    const [totalStaked, setTotalStaked] = useState<
        AmountSplitModel | undefined
    >(undefined);
    const [myStaked, setMyStaked] = useState<AmountSplitModel | undefined>(
        undefined
    );
    const [myStakableAxlUSDC, setMyStakableAxlUSDC] = useState<
        AmountSplitModel | undefined
    >(undefined);
    const [myStakableToken, setMyStakableToken] = useState<
        AmountSplitModel | undefined
    >(undefined);

    const balance = useRecoilValue(balanceState);
    const [stakable, setStakable] = useState<
        { axlUSDC: string; token: string } | undefined
    >(undefined);

    const calc = useCallback(async () => {
        const axlUSDC = balance.axlUSDC;
        const token = balance.token;

        const price = await queryPrice(environment().contracts.token);

        const token1 = token;
        const axlUSDC1 = multiply(price, token);

        const axlUSDC2 = axlUSDC;
        const token2 = divide(axlUSDC, price);

        if (
            leftLessThanRight(axlUSDC, axlUSDC1) ||
            leftLessThanRight(token, token1)
        ) {
            return {
                axlUSDC: axlUSDC2,
                token: token2,
            };
        } else {
            return {
                axlUSDC: axlUSDC1,
                token: token1,
            };
        }
    }, [balance]);

    useEffect(() => {
        if (!stakable) {
            calc()
                .then((r) => {
                    setStakable(r);

                    setMyStakableToken(Utils.splitAmountByDot(r.token));
                    setMyStakableAxlUSDC(Utils.splitAmountByDot(r.axlUSDC));
                })
                .catch((e) => {});
        }
    }, [props, calc, stakable]);

    useEffect(() => {
        if (props.info) {
            setTotalStaked(Utils.splitAmountByDot(props.info.totalStaked));
            setMyStaked(Utils.splitAmountByDot(props.info.staked));
        }
    }, [props.info]);

    return (
        <div className={style.header_container}>
            <div className={style.item_container}>
                <PageHeader title="Overview" docs="" />

                <div className={style.row}>
                    <div className={style.item}>
                        <TitleLabel
                            text="APR"
                            color={"#ffffff"}
                            size={14}
                            fontWeight={"light"}
                        />
                        <div style={{ height: "7px" }} />

                        <TitleLabel
                            size={30}
                            color={"#ffffff"}
                            text={
                                props.info
                                    ? Utils.getPercentFormat(props.info.apr)
                                    : undefined
                            }
                            symbol="%"
                            skeleton={{ width: 100 }}
                        />
                    </div>
                    <div className={style.dummy} />
                    <div className={style.number_item}>
                        <TitleLabel
                            text="Total Staked"
                            color={"#ffffff"}
                            size={14}
                            fontWeight={"light"}
                        />
                        <div style={{ height: "7px" }} />

                        <TitleLabel
                            size={30}
                            fontWeight={"medium"}
                            text={totalStaked ? totalStaked.num1 : undefined}
                            color={"#ffffff"}
                            symbol={
                                totalStaked && totalStaked.num2
                                    ? "." + totalStaked.num2 + " LP"
                                    : "LP"
                            }
                            skeleton={{ width: 100 }}
                        />
                    </div>
                </div>
            </div>
            <div className={style.spacer} />
            <div className={style.item_container}>
                <PageHeader title="My Value" docs="" />

                <div className={style.row}>
                    <div className={style.number_item}>
                        <TitleLabel
                            text="Staked"
                            color={"#ffffff"}
                            size={14}
                            fontWeight={"light"}
                        />
                        <div style={{ height: "7px" }} />
                        <TitleLabel
                            size={30}
                            fontWeight={"medium"}
                            text={myStaked ? myStaked.num1 : undefined}
                            color={"#ffe646"}
                            symbol={
                                myStaked && myStaked.num2
                                    ? "." + myStaked.num2 + " LP"
                                    : "LP"
                            }
                            skeleton={{ width: 100 }}
                        />
                    </div>
                    <div className={style.dummy} />

                    <div className={style.number_item}>
                        <TitleLabel
                            text="Stakable"
                            color={"#ffffff"}
                            size={14}
                            fontWeight={"light"}
                        />
                        <div style={{ height: "7px" }} />

                        <TitleLabel
                            size={16}
                            fontWeight={"medium"}
                            text={
                                myStakableToken
                                    ? myStakableToken.num1
                                    : undefined
                            }
                            color={"#ffe646"}
                            symbol={
                                myStakableToken && myStakableToken.num2
                                    ? "." + myStakableToken.num2 + " VKR"
                                    : "VKR"
                            }
                            skeleton={{ width: 100 }}
                        />

                        <div style={{ height: "4px" }} />
                        <TitleLabel
                            size={16}
                            fontWeight={"medium"}
                            text={
                                myStakableAxlUSDC
                                    ? " + " + myStakableAxlUSDC.num1
                                    : undefined
                            }
                            color={"#ffe646"}
                            symbol={
                                myStakableAxlUSDC && myStakableAxlUSDC.num2
                                    ? "." + myStakableAxlUSDC.num2 + " axlUSDC"
                                    : "axlUSDC"
                            }
                            skeleton={{ width: 100 }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
