import style from "./SymbolIcon.module.scss";

import defaultIcon from "../../../resources/symbols/logo_default.gif";
import vkrIcon from "../../../resources/symbols/vkr.svg";
import lunaIcon from "../../../resources/symbols/luna.svg";
import ustIcon from "../../../resources/symbols/ust.svg";
import tptIcon from "../../../resources/symbols/tpt.svg";
import { useEffect } from "react";
import { useState } from "react";
import { environment, findIconByDenom } from "../../../libs/api/api";

const tpToken =
    "terra13j2k5rfkg0qhk58vz63cze0uze4hwswlrfnm0fa4rnyggjyfrcnqcrs5z2";

export default function SymbolIcon(props: {
    denom?: string;
    isActive: boolean | undefined;
}) {
    const [icon, setIcon] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (!props.denom) {
            setIcon(defaultIcon);
        } else if (props.denom === environment().contracts.token) {
            setIcon(vkrIcon);
        } else if (props.denom === "axlUSDC") {
            setIcon(ustIcon);
        } else if (props.denom === "uluna") {
            setIcon(lunaIcon);
        } else if (props.denom === tpToken) {
            setIcon(tptIcon);
        } else {
            findIconByDenom(props.denom)
                .then((icon) => {
                    if (icon) {
                        setIcon(icon);
                    } else {
                        setIcon(defaultIcon);
                    }
                })
                .catch((e) => {
                    setIcon(defaultIcon);
                });
        }
    }, [props.denom]);

    return (
        <div className={style.container}>
            {props.isActive ? (
                <div>
                    {icon ? (
                        <div className={style.icon}>
                            <img className={style.icon_ico} src={icon} alt="" />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            ) : (
                <div>
                    <div className={style.activefalse}>
                        <div className={style.closed}>{"CLOSED"}</div>
                    </div>
                    {icon ? (
                        <div className={style.icon}>
                            <img className={style.icon_ico} src={icon} alt="" />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            )}
        </div>
    );
}

// for campaignmain mobile size
export function SymbolIcon2(props: {
    denom?: string;
    isActive: boolean | undefined;
}) {
    const [icon, setIcon] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (!props.denom) {
            setIcon(defaultIcon);
        } else if (props.denom === environment().contracts.token) {
            setIcon(vkrIcon);
        } else if (props.denom === "axlUSDC") {
            setIcon(ustIcon);
        } else if (props.denom === "uluna") {
            setIcon(lunaIcon);
        } else if (props.denom === tpToken) {
            setIcon(tptIcon);
        } else {
            findIconByDenom(props.denom)
                .then((icon) => {
                    if (icon) {
                        setIcon(icon);
                    } else {
                        setIcon(defaultIcon);
                    }
                })
                .catch((e) => {
                    setIcon(defaultIcon);
                });
        }
    }, [props.denom]);

    return (
        <div className={style.container2}>
            {props.isActive ? (
                <div>
                    {icon ? (
                        <div className={style.icon}>
                            <img className={style.icon_ico} src={icon} alt="" />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            ) : (
                <div>
                    <div className={style.activefalse}>
                        <div className={style.closed}>{"CLOSED"}</div>
                    </div>
                    {icon ? (
                        <div className={style.icon}>
                            <img className={style.icon_ico} src={icon} alt="" />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            )}
        </div>
    );
}
export function SymbolIcon3(props: {
    denom?: string;
    isActive: boolean | undefined;
}) {
    const [icon, setIcon] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (!props.denom) {
            setIcon(defaultIcon);
        } else if (props.denom === environment().contracts.token) {
            setIcon(vkrIcon);
        } else if (props.denom === "axlUSDC") {
            setIcon(ustIcon);
        } else if (props.denom === "uluna") {
            setIcon(lunaIcon);
        } else if (props.denom === tpToken) {
            setIcon(tptIcon);
        } else {
            // tokenIcon(props.denom)
            //     .then((r) => {
            //         console.log(r);
            //         setIcon("data:image/svg;base64," + r);
            //     })
            //     .catch((e) => {});
            findIconByDenom(props.denom)
                .then((icon) => {
                    if (icon) {
                        setIcon(icon);
                    } else {
                        setIcon(defaultIcon);
                    }
                })
                .catch((e) => {
                    setIcon(defaultIcon);
                });
        }
    }, [props.denom]);

    return (
        <div className={style.container3}>
            {props.isActive ? (
                <div>
                    {icon ? (
                        <div className={style.icon}>
                            <img className={style.icon_ico} src={icon} alt="" />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            ) : (
                <div>
                    <div className={style.activefalse}>
                        <div className={style.closed}>{"CLOSED"}</div>
                    </div>
                    {icon ? (
                        <div className={style.icon}>
                            <img className={style.icon_ico} src={icon} alt="" />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            )}
        </div>
    );
}
