import { useHistory } from "react-router-dom";
import { getDecimal } from "../../../../../libs/utils";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./SayveInfoVp.module.scss";
import { PATHS } from "../../../../../App";
import CreateVpButton from "../../../../common/CreateVpButton/CreateVpButton";

export default function SayveInfoVp(props: { summary: CampaignSummary }) {
    const history = useHistory();

    return (
        <div className={style.container}>
            <div className={style.required_title}>
                <TitleLabel
                    fontWeight={"medium"}
                    text={"Required VP"}
                    size={14}
                    color={"#fff"}
                />
            </div>
            <div className={style.required_contents}>
                <div className={style.required_wrap}>
                    <div className={style.required_contents_amount}>
                        <div className={style.required_contents_amount_ico} />
                        <div className={style.required_contents_amount_text}>
                            <TitleLabel
                                size={16}
                                text={getDecimal(
                                    props.summary.config.vp_burn_amount,
                                    true
                                )}
                                color={"#fff"}
                                symbol={"VP"}
                            />
                        </div>
                    </div>
                    <div className={style.required_dummy} />
                    <div className={style.required_createvp}>
                        <CreateVpButton rounded={true} />
                    </div>
                </div>
            </div>
        </div>
    );
}
