import { environment } from "../../../../../libs/api/api";
import { getDecimal } from "../../../../../libs/utils";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./TPTGuide.module.scss";

export default function TPTGuide(props: { earlybird: boolean }) {
    return (
        <div className={style.container}>
            <div className={style.title}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"Guide"}
                    size={20}
                    color={"#fff"}
                />
            </div>
            <div className={style.guidewrap}>
                {props.earlybird ? (
                    <div className={style.box}>
                        <div className={style.boxwrap}>
                            <div className={style.boxwrap_title}>
                                <TitleLabel
                                    fontWeight={"medium"}
                                    text={"Terra Poker IPC Key Notice"}
                                    size={18}
                                    color={"#fff"}
                                />
                            </div>
                            <div className={style.boxwrap_contents_text}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={"Minimum purchase limit is 75 LUNA"}
                                    size={12}
                                    color={"#fff"}
                                />

                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        "Maximum purchase limit will be differentiated by the membership tier table below."
                                    }
                                    size={12}
                                    color={"#fff"}
                                />

                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        "All VP Exclusive participants require a fixed 200 VP"
                                    }
                                    size={12}
                                    color={"#fff"}
                                />

                                <br />

                                <TitleLabel
                                    fontWeight={"medium"}
                                    text={"LOCK UP & DISTRIBUTION"}
                                    size={18}
                                    color={"#fff"}
                                />
                                <div style={{ height: "10px" }} />

                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        "- 30 days lock up from time of purchase"
                                    }
                                    size={12}
                                    color={"#fff"}
                                />

                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        "- Vesting starts after the lock up & 90 days of linear vesting"
                                    }
                                    size={12}
                                    color={"#fff"}
                                />

                                <TitleLabel
                                    fontWeight={"light"}
                                    text={"(100% completion after 120 days)"}
                                    size={12}
                                    color={"#fff"}
                                />
                            </div>
                        </div>
                        <div className={style.boxwrap_contents_more}>
                            <a
                                href="https://medium.com/@valkyrie-protocol/terra-poker-valkyrieipc-details-2f1b2d3d8651"
                                target="_blank"
                                rel="noreferrer"
                                className={style.boxwrap_contents_more_text}
                            >
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={"more"}
                                    size={12}
                                    color={"#fff"}
                                />
                            </a>
                        </div>
                    </div>
                ) : (
                    <div className={style.box}>
                        <div className={style.boxwrap}>
                            <div className={style.boxwrap_title}>
                                <TitleLabel
                                    fontWeight={"medium"}
                                    text={"Terra Poker IPC Key Notice"}
                                    size={18}
                                    color={"#fff"}
                                />
                            </div>
                            <div className={style.boxwrap_contents_text}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={"Minimum purchase limit is 5 LUNA"}
                                    size={12}
                                    color={"#fff"}
                                />

                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        "Maximum purchase limit is 50 LUNA per wallet"
                                    }
                                    size={12}
                                    color={"#fff"}
                                />

                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        "Sharing Rewards will not be provided to [Non-Member] participants"
                                    }
                                    size={12}
                                    color={"#fff"}
                                />

                                <br />

                                <TitleLabel
                                    fontWeight={"medium"}
                                    text={"LOCK UP & DISTRIBUTION"}
                                    size={18}
                                    color={"#fff"}
                                />
                                <div style={{ height: "10px" }} />

                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        "- 30 days lock up from time of purchase"
                                    }
                                    size={12}
                                    color={"#fff"}
                                />

                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        "- Vesting starts after the lock up & 90 days of linear vesting"
                                    }
                                    size={12}
                                    color={"#fff"}
                                />

                                <TitleLabel
                                    fontWeight={"light"}
                                    text={"(100% completion after 120 days)"}
                                    size={12}
                                    color={"#fff"}
                                />
                            </div>
                        </div>
                        <div className={style.boxwrap_contents_more}>
                            <a
                                href="https://medium.com/@valkyrie-protocol/terra-poker-valkyrieipc-details-2f1b2d3d8651"
                                target="_blank"
                                className={style.boxwrap_contents_more_text}
                            >
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={"more"}
                                    size={12}
                                    color={"#fff"}
                                />
                            </a>
                        </div>
                    </div>
                )}
                <div className={style.detail}>
                    <div className={style.detail_swap}>
                        <div className={style.detail_swap_title}>
                            <div className={style.detail_swap_title_ico} />
                            <div className={style.detail_swap_title_text}>
                                <TitleLabel
                                    fontWeight={"medium"}
                                    text={"Swap"}
                                    size={16}
                                    color={"#fff"}
                                />
                            </div>
                        </div>
                        <div className={style.detailwrap}>
                            <div className={style.detail_swap_rate}>
                                <div className={style.detail_swap_rate_title}>
                                    <TitleLabel
                                        fontWeight={"light"}
                                        text={"Rate"}
                                        size={14}
                                        color={"#fff"}
                                    />
                                </div>
                                <div
                                    className={style.detail_swap_rate_contents}
                                >
                                    <div className={style.rate_luna}>
                                        <div className={style.rate_luna_ico} />
                                        <div className={style.rate_luna_amount}>
                                            <TitleLabel
                                                fontWeight={"medium"}
                                                text={"1"}
                                                size={18}
                                                color={"#1bffbc"}
                                                symbol={"TPT"}
                                            />
                                        </div>
                                    </div>
                                    <div className={style.rate_dummy} />
                                    <div className={style.rate_token}>
                                        <div className={style.rate_token_ico} />
                                        <div
                                            className={style.rate_token_amount}
                                        >
                                            <TitleLabel
                                                fontWeight={"medium"}
                                                text={
                                                    props.earlybird
                                                        ? environment()
                                                              .contracts
                                                              .terrapoker_exclusive
                                                              .lunaprice
                                                        : environment()
                                                              .contracts
                                                              .terrapoker_nonmember
                                                              .lunaprice
                                                }
                                                size={18}
                                                color={"#fff"}
                                                symbol={"LUNA"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.detail_swap_available}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={"Available"}
                                    size={14}
                                    color={"#fff"}
                                />
                                <div
                                    className={style.detail_swap_available_wrap}
                                >
                                    <TitleLabel
                                        fontWeight={"medium"}
                                        text={"Min"}
                                        size={16}
                                        color={"#1bffbc"}
                                    />
                                    <div style={{ width: "4px" }} />
                                    <TitleLabel
                                        fontWeight={"medium"}
                                        text={getDecimal(
                                            props.earlybird
                                                ? environment().contracts
                                                      .terrapoker_exclusive
                                                      .min_uluna_amount
                                                : environment().contracts
                                                      .terrapoker_nonmember
                                                      .min_uluna_amount,
                                            true
                                        )}
                                        size={16}
                                        color={"#fff"}
                                        symbol={"LUNA"}
                                    />
                                    <div style={{ width: "4px" }} />
                                    <div style={{ width: "4px" }} />
                                    <TitleLabel
                                        fontWeight={"medium"}
                                        text={"~"}
                                        size={16}
                                        color={"#fff"}
                                    />
                                    <div style={{ width: "4px" }} />

                                    <TitleLabel
                                        fontWeight={"medium"}
                                        text={"Max"}
                                        size={16}
                                        color={"#1bffbc"}
                                    />
                                    <div style={{ width: "4px" }} />
                                    <TitleLabel
                                        fontWeight={"medium"}
                                        text={getDecimal(
                                            props.earlybird
                                                ? environment().contracts
                                                      .terrapoker_exclusive
                                                      .max_uluna_amount
                                                : environment().contracts
                                                      .terrapoker_nonmember
                                                      .max_uluna_amount,
                                            true
                                        )}
                                        size={16}
                                        color={"#fff"}
                                        symbol={"LUNA"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
