import style from "./MySharing.module.scss";
import { useEffect, useState } from "react";
import { calcRefRewardReceivableInfo } from "../../../../libs/api/campaign/query";
import * as Utils from "../../../../libs/utils";
import TitleLabel from "../../../common/Labels/TitleLabel";
import {
    divide,
    equalTo,
    isZero,
    leftGreaterThanRight,
} from "../../../../libs/Math";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../libs/contexts";
import ReceivablePopup from "../Common/ReceivablePopup";
import CopyButton from "../../../common/CopyButton/CopyButton";
import { environment } from "../../../../libs/api/api";

export default function MySharing(props: {
    summary: CampaignSummary;
    campaignAddress: string;
}) {
    const wallet = useRecoilValue(walletState);

    const [shareLink, setShareLink] = useState("");
    const [copyString, setCopyString] = useState("Copy");

    const [receivableInfo, setReceivableInfo] = useState<
        ReceivableReward | undefined
    >(undefined);
    const [showReceivablePopup, setShowReceivablePopup] = useState<
        ReceivableReward | undefined
    >(undefined);

    const rate = isZero(receivableInfo ? receivableInfo.receivable : "0")
        ? 0
        : parseFloat(
              divide(
                  receivableInfo ? receivableInfo.received : "0",
                  receivableInfo ? receivableInfo.receivable : "1"
              )
          ) * 100;

    const isOver = receivableInfo
        ? leftGreaterThanRight(
              receivableInfo.received,
              receivableInfo.receivable
          )
            ? true
            : false
        : false;

    useEffect(() => {
        if (props.campaignAddress && wallet) {
            calcRefRewardReceivableInfo(
                props.campaignAddress,
                wallet.terraAddress
            )
                .then((r) => {
                    setReceivableInfo(r);
                })
                .catch((e) => {});
        }
    }, [props.campaignAddress, wallet]);

    useEffect(() => {
        if (props.summary.shareLink) {
            // setShareLink(props.summary.shareLink.url.replace("https://www.sayve.money", "https://app.valkyrieprotocol.com/campaigns/terra18gvnd3y4lf02lfcgc8g5ffn983ljcjfcfehleklamyp8eshd9neqn54w7l"));
            if (
                props.campaignAddress ===
                environment().contracts.sayve_early.campaign
            ) {
                setShareLink(
                    props.summary.shareLink.url.replace(
                        "https://www.sayve.money",
                        "https://app.valkyrieprotocol.com/campaigns/" +
                            environment().contracts.sayve_early.campaign
                    )
                );
            } else if (
                props.campaignAddress ===
                environment().contracts.sayve_public.campaign
            ) {
                setShareLink(
                    props.summary.shareLink.url.replace(
                        "https://www.sayve.money",
                        "https://app.valkyrieprotocol.com/campaigns/" +
                            environment().contracts.sayve_public.campaign
                    )
                );
            } else {
                setShareLink(props.summary.shareLink.url);
            }
        }
    }, [props.summary]);

    useEffect(() => {
        const body: any = document.querySelector("body");
        body.style.overflow = showReceivablePopup ? "hidden" : "auto";
    }, [showReceivablePopup]);

    return (
        <div className={style.container}>
            <div className={style.header_mysharing_title}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"My Sharing"}
                    size={20}
                    color={"#fff"}
                />
            </div>
            <div className={style.header_mysharing_wrap}>
                <div className={style.header_mysharing_bg_footer} />
                <div className={style.header_mysharing_contents}>
                    <div className={style.header_mysharing_link}>
                        <div className={style.link_ico} />
                        <div className={style.link_title}>
                            <div className={style.link_title_name}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={"Referral Link"}
                                    size={16}
                                    color={"#fff"}
                                    tooltip={shareLink}
                                />
                            </div>
                        </div>
                        <div className={style.link_button}>
                            <CopyButton copyString={shareLink} />
                        </div>
                    </div>
                    <div className={style.header_mysharing_dummy} />
                    <div className={style.header_mysharing_cap}>
                        <div className={style.mysharing_rewardcap}>
                            <TitleLabel
                                fontWeight={"light"}
                                text={"Sharing Reward Cap"}
                                size={16}
                                color={"#fff"}
                                tooltip={"Received + Claimable"}
                            />
                        </div>
                        <div
                            className={style.mysharing_max}
                            onClick={() => {
                                if (receivableInfo) {
                                    setShowReceivablePopup(receivableInfo);
                                }
                            }}
                        >
                            <TitleLabel
                                fontWeight={"light"}
                                text={
                                    receivableInfo
                                        ? Utils.getDecimal(
                                              receivableInfo.receivable,
                                              true,
                                              props.summary.reward.referral
                                                  .denom === "axlUSDC"
                                                  ? 3
                                                  : 6
                                          )
                                        : undefined
                                }
                                size={12}
                                color={"rgba(255,255,255,0.7)"}
                                symbol={props.summary.reward.referral.symbol}
                                underline={true}
                                skeleton={{ width: 100 }}
                            />
                        </div>
                        <div className={style.mysharing_percentage}>
                            <div className={style.bar_title}>
                                {leftGreaterThanRight(rate, "80") ||
                                equalTo(rate, "80") ? (
                                    <div className={style.barover}>
                                        <div className={style.barover_ico} />
                                        <TitleLabel
                                            fontWeight={"medium"}
                                            text={rate + "%"}
                                            size={10}
                                            color={"#e94f4f"}
                                        />
                                    </div>
                                ) : (
                                    <TitleLabel
                                        fontWeight={"medium"}
                                        text={
                                            Utils.getPercentFormat(
                                                (rate / 100).toString()
                                            ) + "%"
                                        }
                                        size={10}
                                        color={"#ffe646"}
                                    />
                                )}
                            </div>
                            <div className={style.defaultbar} />
                            {leftGreaterThanRight(rate, "80") ||
                            equalTo(rate, "80") ? (
                                <div
                                    className={style.bar}
                                    style={{
                                        width: rate + "%",
                                        backgroundColor: isOver
                                            ? "rgba(255,255,255,0.5)"
                                            : "#e94f4f",
                                    }}
                                />
                            ) : (
                                <div
                                    className={style.bar}
                                    style={{
                                        width: rate + "%",
                                        backgroundColor: isOver
                                            ? "rgba(255,255,255,0.5)"
                                            : "#ffe646",
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {showReceivablePopup !== undefined ? (
                <ReceivablePopup
                    show={showReceivablePopup !== undefined}
                    setShow={() => {
                        setShowReceivablePopup(undefined);
                    }}
                    info={showReceivablePopup}
                    summary={props.summary}
                />
            ) : (
                <div />
            )}
        </div>
    );
}
