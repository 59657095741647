import { environment, wasmExecutes } from "../api";
import { queryPrice } from "./query";
import { divide, fixed } from "../../Math";

export async function tokenBuy(
    address: string,
    amount: string,
    spread: number //0.01 === 1%
) {
    const beliefPrice = await queryPrice(environment().contracts.token);

    const exe: WasmExecute = {
        contract: environment().contracts.astroport.pair,
        msg: {
            swap: {
                offer_asset: {
                    info: {
                        native_token: {
                            denom: environment().contracts.usdc_token,
                        },
                    },
                    amount: fixed(amount, 0),
                },
                belief_price: fixed(beliefPrice, 18),
                max_spread: spread.toString(),
            },
        },
        coin: fixed(amount, 0) + environment().contracts.usdc_token,
    };

    return await wasmExecutes(address, [exe]);
}

export async function tokenSell(
    address: string,
    amount: string,
    spread: number //0.01 === 1%
) {
    const beliefPrice = await queryPrice(environment().contracts.token);

    const subMsg = {
        swap: {
            belief_price: fixed(divide(1, beliefPrice), 18),
            max_spread: spread.toString(),
        },
    };

    const exe: WasmExecute = {
        contract: environment().contracts.token,
        msg: {
            send: {
                contract: environment().contracts.astroport.pair,
                amount: fixed(amount, 0),
                msg: btoa(JSON.stringify(subMsg)),
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe]);
}
