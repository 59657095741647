import TitleLabel from "../../common/Labels/TitleLabel";
import { NormalButton } from "../../common/NormalButton/NormalButton";
import style from "./MyDistributionRewardsClaimable.module.scss";
import { isZero } from "../../../libs/Math";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { splitAmountByDot } from "../../../libs/utils";
const cx = classNames.bind(style);

export default function MyDistributionRewardsClaimable(props: {
    claimableAmount: string;

    airdropClaim: () => void;
}) {
    const [claimableAmountSplit, setClaimableAmountSplit] =
        useState<AmountSplitModel>({
            num1: "0",
            num2: undefined,
        });
    useEffect(() => {
        setClaimableAmountSplit(splitAmountByDot(props.claimableAmount));
    }, [props.claimableAmount]);

    return (
        <div className={style.container}>
            <div className={style.claimable_amount}>
                <TitleLabel
                    text={"Claimable"}
                    size={14}
                    color={"#fff"}
                    fontWeight={"light"}
                />

                <div
                    className={cx(style.claimable_amountnow, {
                        zero: isZero(props.claimableAmount),
                    })}
                >
                    {claimableAmountSplit.num1}
                    <small>
                        {claimableAmountSplit.num2 !== undefined
                            ? "." + claimableAmountSplit.num2 + " VKR"
                            : " VKR"}
                    </small>
                </div>
            </div>
            <div className={style.claimable_button}>
                <NormalButton
                    title={"Claim"}
                    onClick={props.airdropClaim}
                    disabled={isZero(props.claimableAmount)}
                />
            </div>
        </div>
    );
}
