import InputField from "../../common/InputField/InputField";
import { useState } from "react";
import GovCreateFormFooter from "./GovCreateFormFooter";
import { useEffect } from "react";
import GovCreateFormHeader from "./GovCreateFormHeader";
import * as Utils from "../../../libs/utils";
import { poll6 } from "../../../libs/api/governance/execution";
import { PATHS } from "../../../App";
import { useWallet } from "@terra-money/wallet-provider";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";
import { environment } from "../../../libs/api/api";
import { pollConfig } from "../../../libs/api/governance/query";
import { getFeeRaw } from "../../../libs/api/fee";

export default function GovCreateForm6(props: {
    setHash: (h: HashResult) => void;
    deposit: string;
}) {
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);

    const [headerInfo, setHeaderInfo] = useState({
        title: "",
        desc: "",
        link: "",
    });
    const [headerValid, setHeaderValid] = useState(false);

    const [valid, setValid] = useState(false);

    const [proposal, setProposal] = useState("");

    const [fee, setFee] = useState("0");

    useEffect(() => {
        pollConfig().then((r) => {
            setProposal(r.proposal_deposit);
        });
    }, []);

    useEffect(() => {
        if (headerValid) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [headerValid]);

    useEffect(() => {
        let exes: WasmExecute[] = [];

        let subMsg = {
            create_poll: {
                title: headerInfo.title,
                description: headerInfo.desc,
                link: headerInfo.link ? headerInfo.link : undefined,
                executions: [],
            },
        };

        exes.push({
            contract: environment().contracts.token,
            msg: {
                send: {
                    contract: environment().contracts.governance,
                    amount: proposal,
                    msg: btoa(JSON.stringify(subMsg)),
                },
            },
            coin: undefined,
        });

        getFeeRaw(wallet?.terraAddress ? wallet.terraAddress : "", exes)
            .then((r) => {
                let uluna = "uluna";
                setFee(r.amount.toString().replace(uluna, ""));
            })
            .catch((e) => {
                setFee("0");
            });
    }, [headerInfo]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <GovCreateFormHeader
                setInfo={setHeaderInfo}
                setValid={setHeaderValid}
            />

            <InputField
                title="Proposal Deposit"
                type="amount"
                disabled={true}
                tooltip={
                    "Proposed minimum VKR deposit required for submitting a new poll"
                }
                symbol={"VKR"}
                value={Utils.getDecimal(props.deposit, true)}
                onChanged={(v) => {}}
            />

            <GovCreateFormFooter
                enable={valid}
                pressed={() => {
                    props.setHash({
                        type: "GovPoll6",
                        isPending: true,
                    });

                    poll6(wallet!.terraAddress, headerInfo)
                        .then((r) => {
                            return post(r);
                        })
                        .then((response) => {
                            props.setHash({
                                type: "GovPoll6",
                                response: response,
                                redirect: PATHS.governance,
                            });
                        })
                        .catch((error) => {
                            props.setHash({
                                type: "GovPoll6",
                                error: error,
                                redirect: undefined,
                            });
                        });
                }}
                fee={fee}
            />
        </div>
    );
}
