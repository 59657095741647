import { useEffect, useState } from "react";
import style from "./Test.module.scss";
import TxBoxView from "../common/TxBoxView/TxBoxView";
import PageHeader from "../common/PageHeader/PageHeader";
import { useRecoilValue } from "recoil";
import { blockState, walletState } from "../../libs/contexts";
import { useWallet } from "@terra-money/wallet-provider";

import classNames from "classnames/bind";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../App";
import TitleLabel from "../common/Labels/TitleLabel";
import { queryTokenBalance } from "../../libs/api/trade/query";
import { getDecimal } from "../../libs/utils";
import InputField from "../common/InputField/InputField";
import { NormalButton } from "../common/NormalButton/NormalButton";
import { tokenInfo } from "../../libs/api/api";
import { isZero, max, minus, multiply } from "../../libs/Math";
import {
    tpGov,
    TpGovState,
    getTpGovState,
    tpToken,
    xtpToken,
    TpBalance,
    getTpBalance,
    getTpGovStakerState,
    TpGovStakerState,
    simulateStake,
    simulateUnstake,
    tpGovernancePollList,
} from "../../libs/api/test/query";
import {
    tpCreatePoll,
    tpEndPoll,
    tpExecutePoll,
    tpForceGovReward,
    tpGovClaim,
    tpGovStake,
    tpGovUnstake,
    tpPollVote,
    tpTryTransferSimulate,
} from "../../libs/api/test/execution";

const cx = classNames.bind(style);

export default function Test() {
    const history = useHistory();
    const { post } = useWallet();
    const block = useRecoilValue(blockState);

    const wallet = useRecoilValue(walletState);
    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    const [tpBalance, setTpBalance] = useState<TpBalance | undefined>(
        undefined
    );
    const [tpGovState, setTpGovState] = useState<TpGovState | undefined>(
        undefined
    );
    const [tpGovStakerState, setTpGovStakerState] = useState<
        TpGovStakerState | undefined
    >(undefined);

    const [stakeAmount, setStakeAmount] = useState("");
    const [simulatedStakeAmount, setSimulatedStakeAmount] = useState("0");
    const [unstakeAmount, setUnstakeAmount] = useState("");
    const [simulatedUnstakeAmount, setSimulatedUnstakeAmount] = useState("0");
    const [forceRewardAmount, setForceRewardAmount] = useState("");

    const [tryTransferAmount, setTryTransferAmount] = useState("");
    const [tryTransferAmountError, setTryTransferAmountError] = useState("");

    const [pollList, setPollList] = useState<ResponsePollDetail[]>([]);

    useEffect(() => {
        if (tryTransferAmount === "" || isZero(tryTransferAmount)) {
            setTryTransferAmountError("");
            return;
        }

        if (wallet) {
            tpTryTransferSimulate(
                wallet.terraAddress,
                multiply(tryTransferAmount, 1000000)
            )
                .then((r) => {
                    setTryTransferAmountError("");
                })
                .catch((e) => {
                    setTryTransferAmountError(e.response.data.message);
                });
        }
    }, [tryTransferAmount, wallet]);

    useEffect(() => {
        if (wallet) {
            getTpBalance(wallet.terraAddress)
                .then((r) => {
                    setTpBalance(r);
                })
                .catch((e) => {});

            getTpGovStakerState(wallet.terraAddress)
                .then((r) => {
                    setTpGovStakerState(r);
                })
                .catch((e) => {});
        }
    }, [wallet]);

    useEffect(() => {
        getTpGovState()
            .then((r) => {
                setTpGovState(r);
            })
            .catch((e) => {});
        tpGovernancePollList(100)
            .then((r) => {
                setPollList(r);
            })
            .catch((e) => {});
    }, []);

    useEffect(() => {
        simulateStake(multiply(stakeAmount ?? "0", 1000000))
            .then((r) => {
                setSimulatedStakeAmount(r);
            })
            .catch((e) => {
                setSimulatedStakeAmount("0");
            });
    }, [stakeAmount]);

    useEffect(() => {
        simulateUnstake(multiply(unstakeAmount ?? "0", 1000000))
            .then((r) => {
                setSimulatedUnstakeAmount(r);
            })
            .catch((e) => {
                setSimulatedStakeAmount("0");
            });
    }, [unstakeAmount]);

    function stakePressed() {
        setHash({
            type: "Sell",
            isPending: true,
        });

        tpGovStake(wallet!.terraAddress, multiply(stakeAmount, 1000000))
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "Sell",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                console.log(error);
                setHash({
                    type: "Sell",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function unstakePressed() {
        setHash({
            type: "Buy",
            isPending: true,
        });

        tpGovUnstake(wallet!.terraAddress, multiply(unstakeAmount, 1000000))
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "Buy",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                console.log(error);
                setHash({
                    type: "Buy",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function forceGovReward() {
        setHash({
            type: "Buy",
            isPending: true,
        });

        tpForceGovReward(
            wallet!.terraAddress,
            multiply(forceRewardAmount, 1000000)
        )
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "Buy",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                console.log(error);
                setHash({
                    type: "Buy",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function claimGovReward() {
        setHash({
            type: "Buy",
            isPending: true,
        });

        tpGovClaim(wallet!.terraAddress)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "Buy",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                console.log(error);
                setHash({
                    type: "Buy",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function createPollPressed() {
        setHash({
            type: "Buy",
            isPending: true,
        });

        tpCreatePoll(wallet!.terraAddress, {
            title: "Poll Test " + pollList.length,
            desc: "poll test description",
        })
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "Buy",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                console.log(error);
                setHash({
                    type: "Buy",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function votePressed(
        id: number,
        vote: "yes" | "no" | "abstain",
        amount: string
    ) {
        setHash({
            type: "Buy",
            isPending: true,
        });

        tpPollVote(wallet!.terraAddress, id, vote, multiply(amount, 1000000))
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "Buy",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                console.log(error);
                setHash({
                    type: "Buy",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function endPoll(id: number) {
        setHash({
            type: "Buy",
            isPending: true,
        });

        tpEndPoll(wallet!.terraAddress, id)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "Buy",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                console.log(error);
                setHash({
                    type: "Buy",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function executePoll(id: number) {
        setHash({
            type: "Buy",
            isPending: true,
        });

        tpExecutePoll(wallet!.terraAddress, id)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "Buy",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                console.log(error);
                setHash({
                    type: "Buy",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    useEffect(() => {
        if (
            !window.location.origin.includes("localhost") &&
            !window.location.origin.includes("develop.vkr-webapp.pages.dev")
        ) {
            history.replace(PATHS.home);
        }
    }, []);

    return (
        <div className={cx(style.wrap, { hash: hash !== undefined })}>
            <div className={cx(style.container, { hash: hash !== undefined })}>
                <TxBoxView
                    hash={hash}
                    clearHash={() => {
                        setHash(undefined);
                    }}
                    transparent={true}
                >
                    <PageHeader title="Test Stake" docs="" />
                    <div className={style.trade_container}>
                        <div className={style.labels}>
                            <TitleLabel text={"MY TP BALANCE : "} />
                            <TitleLabel
                                text={getDecimal(
                                    tpBalance ? tpBalance.tpBalance : "0",
                                    true
                                )}
                            />
                        </div>
                        <div className={style.labels}>
                            <TitleLabel text={"MY xTPT BALANCE : "} />
                            <TitleLabel
                                text={getDecimal(
                                    tpBalance ? tpBalance.xtptBalance : "0",
                                    true
                                )}
                            />
                            &nbsp;
                            <TitleLabel
                                text={
                                    "(available: " +
                                    getDecimal(
                                        tpBalance
                                            ? tpBalance.availableXtptBalance
                                            : "0",
                                        true
                                    ) +
                                    ")"
                                }
                            />
                        </div>
                        <div
                            className={style.labels}
                            style={{ marginTop: "20px" }}
                        >
                            <TitleLabel
                                text={"Total Staked TP in Governance : "}
                            />
                            <TitleLabel
                                text={getDecimal(
                                    tpGovState
                                        ? tpGovState.stakedTpBalance
                                        : "0",
                                    true
                                )}
                            />
                        </div>
                        <div className={style.labels}>
                            <TitleLabel text={"Total Supply of xTPT : "} />
                            <TitleLabel
                                text={getDecimal(
                                    tpGovState
                                        ? tpGovState.xtptTotalSupply
                                        : "0",
                                    true
                                )}
                            />
                        </div>

                        <div
                            className={style.labels}
                            style={{ marginTop: "20px" }}
                        >
                            <TitleLabel
                                text={
                                    "Total Voted : " +
                                    getDecimal(
                                        tpGovStakerState
                                            ? tpGovStakerState?.locked_balance
                                            : "0",
                                        true
                                    ) +
                                    " xTP"
                                }
                            />
                        </div>

                        <div
                            className={style.labels}
                            style={{ marginTop: "20px" }}
                        >
                            <TitleLabel text={"Unstake Locked List "} />
                        </div>
                        <div
                            className={style.labels}
                            style={{ marginTop: "10px" }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {tpGovStakerState &&
                                tpGovStakerState.unstake_locked_list.length >
                                    0 ? (
                                    tpGovStakerState.unstake_locked_list.map(
                                        (item, index) => {
                                            return (
                                                <TitleLabel
                                                    key={index}
                                                    size={12}
                                                    text={
                                                        "- block: " +
                                                        item.unlock_height +
                                                        ", left block: " +
                                                        max(
                                                            item.unlock_height -
                                                                block.block,
                                                            0
                                                        ) +
                                                        ", amount: " +
                                                        getDecimal(
                                                            item.amount,
                                                            true
                                                        )
                                                    }
                                                />
                                            );
                                        }
                                    )
                                ) : (
                                    <TitleLabel
                                        size={12}
                                        text={"- no locked"}
                                    />
                                )}
                            </div>
                        </div>

                        <div className={style.labels}>
                            <div style={{ width: "100px", height: "50px" }}>
                                <NormalButton
                                    title="claim"
                                    onClick={claimGovReward}
                                />
                            </div>
                        </div>

                        <div
                            className={style.labels}
                            style={{ marginTop: "40px" }}
                        >
                            <InputField
                                title={"Stake amount (TP -> xTPT)"}
                                type="amount"
                                balance={tpBalance ? tpBalance.tpBalance : "0"}
                                value={stakeAmount}
                                onChanged={setStakeAmount}
                                error={
                                    "may be receive : " +
                                    getDecimal(simulatedStakeAmount, true, 6) +
                                    " xTPT"
                                }
                                symbol={"TPT"}
                            />
                            <div className={style.button}>
                                <NormalButton
                                    title={"stake"}
                                    onClick={stakePressed}
                                />
                            </div>
                        </div>

                        <div className={style.labels}>
                            <InputField
                                title={"Unstake amount (xTPT -> TP)"}
                                type="amount"
                                balance={
                                    tpBalance
                                        ? tpBalance.availableXtptBalance
                                        : "0"
                                }
                                value={unstakeAmount}
                                onChanged={setUnstakeAmount}
                                error={
                                    "may be receive : " +
                                    getDecimal(simulatedUnstakeAmount, true) +
                                    " TPT"
                                }
                                symbol={"xTPT"}
                            />
                            <div className={style.button}>
                                <NormalButton
                                    title={"unstake"}
                                    onClick={unstakePressed}
                                />
                            </div>
                        </div>

                        <div
                            className={style.labels}
                            style={{ marginTop: "20px" }}
                        >
                            <InputField
                                title={"Force send Gov reward to Gov Contract"}
                                type="amount"
                                balance={tpBalance ? tpBalance.tpBalance : "0"}
                                value={forceRewardAmount}
                                onChanged={setForceRewardAmount}
                                symbol={"TPT"}
                            />
                            <div className={style.button}>
                                <NormalButton
                                    title={"SEND"}
                                    onClick={forceGovReward}
                                />
                            </div>
                        </div>
                    </div>

                    <PageHeader title="Test Poll" docs="" />
                    <div className={style.trade_container}>
                        <div className={style.labels}>
                            <TitleLabel text={"Poll List "} />
                        </div>
                        <div className={style.labels}>
                            <div style={{ width: "200px", height: "50px" }}>
                                <NormalButton
                                    title={"Create Poll"}
                                    onClick={createPollPressed}
                                />
                            </div>
                        </div>
                        <div className={style.labels}>
                            {pollList.length === 0 ? (
                                <TitleLabel text={"- no polls"} />
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    {pollList.map((item, index) => {
                                        return (
                                            <PollCard
                                                item={item}
                                                tpGovStakerState={
                                                    tpGovStakerState
                                                }
                                                tpBalance={tpBalance}
                                                votePressed={votePressed}
                                                endPressed={endPoll}
                                                executePressed={executePoll}
                                                key={index}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </div>

                        <div
                            className={style.labels}
                            style={{ marginTop: "20px" }}
                        >
                            <InputField
                                title={"TRY transfer locked xTPT"}
                                type="amount"
                                balance={
                                    tpBalance ? tpBalance.xtptBalance : "0"
                                }
                                placeholder={
                                    "Locked " +
                                    (tpBalance
                                        ? getDecimal(
                                              minus(
                                                  tpBalance.xtptBalance,
                                                  tpBalance.availableXtptBalance
                                              )
                                          )
                                        : "0") +
                                    " xTPT"
                                }
                                value={tryTransferAmount}
                                onChanged={setTryTransferAmount}
                                error={tryTransferAmountError}
                                symbol={"xTPT"}
                            />
                        </div>
                    </div>
                </TxBoxView>
            </div>
        </div>
    );
}

function PollCard(props: {
    item: ResponsePollDetail;
    tpGovStakerState?: TpGovStakerState;
    tpBalance?: TpBalance;
    votePressed: (
        id: number,
        vote: "yes" | "no" | "abstain",
        amount: string
    ) => void;
    endPressed: (id: number) => void;
    executePressed: (id: number) => void;
}) {
    const item = props.item;

    const [voteAmount, setVoteAmount] = useState("");
    const [voted, setVoted] = useState(false);
    useEffect(() => {
        if (props.tpGovStakerState) {
            props.tpGovStakerState.votes.forEach((item) => {
                if (item.poll_id === props.item.id) {
                    setVoted(true);
                    return;
                }
            });
        }
    }, [props.tpGovStakerState, props.item]);

    return (
        <div className={style.pollcard}>
            <TitleLabel text={"poll id : " + item.id} />
            <TitleLabel text={"status : " + item.status} />
            <br />
            <TitleLabel text={"title : " + item.title} />
            <TitleLabel text={"desc  : " + item.description} />
            <TitleLabel text={"vote yes : " + item.yes_votes} />
            <TitleLabel text={"vote no : " + item.no_votes} />
            <TitleLabel text={"vote abstain : " + item.abstain_votes} />
            <br />
            <TitleLabel text={"RAW VALUE"} />
            <TitleLabel text={JSON.stringify(item, undefined, 4)} />
            <br />
            <br />

            {!voted ? (
                <div className={style.buttons}>
                    <InputField
                        title={"Vote Amount"}
                        type="amount"
                        balance={
                            props.tpBalance ? props.tpBalance.xtptBalance : "0"
                        }
                        symbol={"xTPT"}
                        value={voteAmount}
                        onChanged={setVoteAmount}
                    />
                    <div className={style.buttons2}>
                        <NormalButton
                            title="yes"
                            onClick={() => {
                                props.votePressed(item.id, "yes", voteAmount);
                            }}
                        />

                        <NormalButton
                            title="no"
                            onClick={() => {
                                props.votePressed(item.id, "no", voteAmount);
                            }}
                        />
                        <NormalButton
                            title="abstain"
                            onClick={() => {
                                props.votePressed(
                                    item.id,
                                    "abstain",
                                    voteAmount
                                );
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div className={style.buttons}>
                    <TitleLabel color={"red"} text="ALREADY VOTED" />

                    <div className={style.buttons2}>
                        <NormalButton
                            title="end poll"
                            onClick={() => {
                                props.endPressed(item.id);
                            }}
                        />
                        <NormalButton
                            title="execute poll"
                            onClick={() => {
                                props.executePressed(item.id);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
